import { useTheme } from '@/components/layout/ThemeProvider';
import { cn } from "@/lib/utils";
import type React from 'react';
import { useEffect, useState } from 'react';

// Import local styles
import '@/styles/sci-fi-loader-animations.css';

// Import logo
import logoSvg from '@/components/assets/proxima-rgb-secondary-logo-gradient.svg';

interface CustomLoaderProps {
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'large';
}

export const CustomLoader: React.FC<CustomLoaderProps> = ({
  isLoading = true,
  size = 'medium'
}): JSX.Element => {
  const [progress, setProgress] = useState<number>(0);
  const [currentWord, setCurrentWord] = useState<string>('INITIALIZING');
  const { theme } = useTheme();
  const [effectiveTheme, setEffectiveTheme] = useState<'light' | 'dark'>(
    theme === 'system'
      ? document.documentElement.classList.contains('dark') ? 'dark' : 'light'
      : theme === 'light' ? 'light' : 'dark'
  );

  // Words that will sequentially appear during loading
  const loadingWords = [
    'INITIALIZING',
    'PROCESSING',
    'COMPUTING',
    'ANALYZING',
    'RENDERING'
  ];

  // Determine the effective theme
  useEffect(() => {
    if (theme === 'system') {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setEffectiveTheme(systemPrefersDark ? 'dark' : 'light');

      // Listen for system theme changes
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = (e: MediaQueryListEvent): void => {
        setEffectiveTheme(e.matches ? 'dark' : 'light');
      };

      mediaQuery.addEventListener('change', handleChange);
      return (): void => { mediaQuery.removeEventListener('change', handleChange); };
    } else {
      // Safely cast theme to 'light' | 'dark' type after excluding 'system'
      setEffectiveTheme(theme === 'light' ? 'light' : 'dark');
    }
  }, [theme]);

  // Reset progress when loading starts
  useEffect((): void => {
    if (isLoading) {
      setProgress(0);
    }
  }, [isLoading]);

  // Simulate loading progress
  useEffect((): (() => void) => {
    if (!isLoading) return (): void => { /* Empty cleanup function */ };

    const timer = setInterval((): void => {
      setProgress((prev: number): number => {
        if (prev >= 100) {
          clearInterval(timer);
          return 100;
        }

        // Update word based on progress
        const wordIndex = Math.min(
          Math.floor(prev / 20),
          loadingWords.length - 1
        );
        setCurrentWord(loadingWords[wordIndex]);

        // More realistic progress simulation
        const increment = Math.max(1, Math.floor(Math.random() * 5));
        return Math.min(100, prev + increment);
      });
    }, 150);

    return (): void => { clearInterval(timer); };
  }, [isLoading]);

  // Return empty fragment if not loading
  if (!isLoading) return <></>;

  // Support for simple spinner when size is small or large (not medium)
  if (size !== 'medium') {
    // Determine size in pixels
    const sizeMap = {
      small: 24,
      medium: 40,
      large: 60
    };

    const pixelSize = sizeMap[size];

    return (
      <div className="flex items-center justify-center">
        <div
          className={cn(
            "relative rounded overflow-hidden",
            effectiveTheme === 'dark' ? "bg-blackish-900" : "bg-blackish-50"
          )}
          style={{
            width: pixelSize * 1.5,
            height: pixelSize
          }}
        >
          <div className={cn(
            "absolute inset-0 bg-gradient-to-r",
            effectiveTheme === 'dark'
              ? "from-primary-400 via-secondary-400 to-primary-400"
              : "from-primary-500 via-secondary-500 to-primary-500"
          )}
            style={{
              backgroundSize: '400% 100%',
              animation: 'pixel-rotate 3s linear infinite'
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div
              className={cn(
                "text-xs font-RBNo3 tracking-widest uppercase",
                effectiveTheme === 'dark' ? "text-white" : "text-blackish-800"
              )}
            >
              {progress}%
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Full-size typographic loader
  const isDarkMode = effectiveTheme === 'dark';

  // RBNO3-inspired font style className
  const rbno3ClassNames = "font-RBNo3 font-bold tracking-tight uppercase";

  return (
    <div className={cn(
      "fixed inset-0 z-[100] flex flex-col items-center justify-center",
      isDarkMode ? "bg-blackish-900 text-white" : "bg-blackish-50 text-blackish-800",
      "overflow-hidden font-RBNo3"
    )}>
      {/* Grid overlay */}
      <div className="absolute inset-0"
        style={{
          backgroundImage: `linear-gradient(to right, ${isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'} 1px, transparent 1px), 
                            linear-gradient(to bottom, ${isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'} 1px, transparent 1px)`,
          backgroundSize: '20px 20px',
          backgroundPosition: '0 0',
          opacity: 0.5,
        }}
      />

      {/* Scan line effect */}
      <div className="scanline-effect" />
      <div className="absolute left-0 w-full h-0.5 transition-all duration-300 ease-out"
        style={{
          background: `linear-gradient(to right, 
            rgba(var(--primary-rgb), 0), 
            rgba(var(--primary-rgb), 0.5) 30%, 
            rgba(var(--primary-rgb), 0.8) 50%, 
            rgba(var(--primary-rgb), 0.5) 70%, 
            rgba(var(--primary-rgb), 0))`,
          opacity: 0.3,
          filter: 'blur(1px)',
          top: `${progress}%`,
        }}
      />

      {/* Main content */}
      <div className="relative z-10 flex flex-col items-center justify-center w-full max-w-2xl px-6 py-10">
        {/* Top section */}
        <div className="flex justify-between items-center w-full mb-8">
          <div className="text-xs uppercase tracking-widest text-blackish-400 dark:text-blackish-600 flex items-center">
            <span className="w-2 h-2 rounded-full bg-primary-500 dark:bg-primary-400 mr-2 opacity-50 animate-pulse" />
            SYSTEM ACTIVE
          </div>
        </div>

        {/* Center content - main typography */}
        <div className="flex flex-col items-center justify-center flex-1 relative">
          {/* Proxima Logo */}
          <div className="mb-6 flex justify-center">
            <div className={cn(
              "relative p-2 rounded",
              isDarkMode ? "bg-primary-900/10" : "bg-transparent"
            )}
              style={{
                boxShadow: isDarkMode ? '0 0 15px rgba(var(--primary-rgb), 0.2)' : 'none',
                animation: 'pulse 2s infinite alternate',
              }}>
              <img
                src={logoSvg}
                alt="Proxima Logo"
                className="w-20 h-20 object-contain"
              />
            </div>
          </div>

          {/* Main "LOADING" text */}
          <div className={cn(
            "text-5xl leading-none mb-8 text-center text-blackish-800 dark:text-white",
            rbno3ClassNames
          )}>
            {'LOADING'.split('').map((char, idx): JSX.Element => (
              <span
                key={idx}
                className="inline-block relative"
                style={{
                  marginRight: char === ' ' ? '0.25em' : '0',
                  animation: idx % 2 === 0 ? 'textShift 3s infinite alternate' : 'none',
                }}
              >
                {char}
              </span>
            ))}
          </div>

          {/* Current process word */}
          <div className={cn(
            rbno3ClassNames,
            "text-sm tracking-widest h-6 mb-8",
            "text-primary-600 dark:text-primary-400"
          )}>
            {currentWord}
          </div>
        </div>

        {/* Bottom section - progress indicators */}
        <div className="w-full mt-auto">
          {/* Progress percentage and status */}
          <div className={cn(
            "flex justify-between mb-2",
            rbno3ClassNames,
            "text-xs"
          )}>
            <div className="text-primary-600 dark:text-primary-400">
              {progress.toString().padStart(3, '0')}%
            </div>
            <div className="text-blackish-400 dark:text-blackish-600 flex items-center">
              <span className={cn(
                "w-1 h-1 rounded-full mr-1",
                progress < 100
                  ? "bg-primary-500 dark:bg-primary-400"
                  : "bg-accent-500 dark:bg-accent-400",
                progress % 2 === 0 ? "opacity-50" : "opacity-100"
              )} />
              {progress < 100 ? 'IN PROGRESS' : 'COMPLETE'}
            </div>
          </div>

          {/* Main progress bar */}
          <div className="w-full h-px bg-blackish-200 dark:bg-blackish-700 mb-6 relative overflow-hidden">
            <div
              className="absolute top-0 left-0 h-full bg-primary-500 dark:bg-primary-400 transition-all duration-300"
              style={{ width: `${progress}%` }}
            />

            {/* Glow effect at the end of progress bar */}
            <div
              className="absolute top-0 h-full w-3 bg-gradient-to-r from-primary-500 to-transparent dark:from-primary-400 opacity-80 transition-all duration-300"
              style={{ left: `calc(${progress}% - 10px)` }}
            />
          </div>

          {/* Segmented progress indicators */}
          <div className="flex justify-between w-full px-1 mb-6">
            {[0, 20, 40, 60, 80, 100].map((mark): JSX.Element => (
              <div key={mark} className="flex flex-col items-center">
                <div className={cn(
                  "w-px h-2 mb-1",
                  progress >= mark
                    ? "bg-primary-500 dark:bg-primary-400"
                    : "bg-blackish-200 dark:bg-blackish-700"
                )} />
                <span className={cn(
                  "text-[10px] font-mono",
                  progress >= mark
                    ? "text-primary-600 dark:text-primary-400"
                    : "text-blackish-400 dark:text-blackish-600"
                )}>
                  {mark}
                </span>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className={cn(
            "flex justify-between",
            rbno3ClassNames,
            "text-xs text-blackish-400 dark:text-blackish-600"
          )}>
            <div>PROXIMA INTERFACE • 2425</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLoader;
