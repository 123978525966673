/**
 * Unified Client Types
 * 
 * Single source of truth for all client-related types in the application.
 * This file consolidates and standardizes all client data structures to ensure
 * consistent typing throughout the application.
 */

import type { BaseTableData } from './table-types';

// ======= Basic Type Definitions =======

/**
 * Represents the type of client in the system
 */
export type ClientType = 'employer' | 'serviceProvider';

/**
 * Base client interface that defines common properties across all client types
 */
export type BaseClient = {
  id: string | number;
  name: string;
  creationTime: string;
  isDisabled: boolean;
  isTest: boolean;
  mfaRequired: boolean;
  prn: string;
  creatorPrn: string;
}

/**
 * Product interface for client-associated products
 */
export type Product = {
  id: string | number;
  name: string;
  key: string;
  status?: string;
}

/**
 * Employer client type with employer-specific properties
 */
export type Employer = {
  type: 'employer';       // Discriminant field for type safety
  preferredName: string;
  brandingLogoPath: string;
  brandingPrimaryColor: string;
  expirationsEnabled: boolean;
  hasAgreement: boolean;
  subtype: string;
  products: Product[];
} & BaseClient

/**
 * Service Provider client type with service provider-specific properties
 */
export type ServiceProvider = {
  type: 'serviceProvider'; // Discriminant field for type safety
  serviceType?: string;    // Optional field for categorizing service providers
} & BaseClient

/**
 * Union type for clients that can be either an employer or service provider
 */
export type Client = Employer | ServiceProvider;

// ======= Type Guards =======

/**
 * Type guard to check if a client is an employer
 */
export function isEmployer(client: Client): client is Employer {
  return client.type === 'employer';
}

/**
 * Type guard to check if a client is a service provider
 */
export function isServiceProvider(client: Client): client is ServiceProvider {
  return client.type === 'serviceProvider';
}

// ======= API Response Types =======

/**
 * Standard pagination metadata structure
 */
export type PaginationMetadata = {
  totalCount: number;
  page: number;
  totalPages: number;
  itemsPerPage: number;
}

/**
 * Generic paginated response for all API endpoints
 */
export type PaginatedResponse<T> = {
  items: T[];
} & PaginationMetadata

/**
 * Format of the raw API response from backend
 */
export type ApiResponseData<T> = {
  data?: T[];
  items?: T[];
  metadata?: {
    totalCount?: number;
    totalItems?: number;
    page?: number;
    totalPages?: number;
    itemsPerPage?: number;
  };
}

/**
 * Query parameters for fetching clients
 */
export type ClientsQueryParams = {
  page?: number;
  itemsPerPage?: number;
  sortBy?: string;
  searchTerm?: string;
}

// ======= Error Types =======

/**
 * Standard error structure for client fetch operations
 */
export type ClientFetchError = {
  statusCode?: number;
  source?: string;
  timestamp?: string;
  retryCount?: number;
} & Error

// ======= Context Types =======

/**
 * State interface for clients data operations
 */
export type ClientsState<T extends BaseClient> = {
  data: T[];
  filteredData: T[];
  isLoading: boolean;
  error: ClientFetchError | null;
  refetch: () => void;
  pagination: PaginationMetadata;
}

/**
 * Core client context value interface
 */
export type ClientsContextValue = {
  // Current client type
  clientType: ClientType;
  setClientType: (type: ClientType) => void;

  // Data access
  clients: Client[];
  filteredClients: Client[];
  isLoading: boolean;
  error: ClientFetchError | null;
  refetch: () => void;

  // Sorting
  sorting: {
    column: string;
    direction: 'asc' | 'desc';
  };
  setSorting: (sorting: {
    column: string;
    direction: 'asc' | 'desc';
  }) => void;

  // Pagination
  pagination: PaginationMetadata;
  updateItemsPerPage: (newItemsPerPage: number) => void;
  updatePage?: (newPage: number) => void;

  // Filtering
  updateFilters: (filters: { search?: string; status?: string }) => void;
}

// ======= Table Data Types =======

/**
 * Client table data interface that extends BaseTableData
 * to ensure compatibility with the table components
 */
export type ClientTableData = {
  id: string | number;
  name: string;
  isDisabled: boolean;
  creationTime: string;
  mfaRequired: boolean;
  products?: Product[];
} & BaseTableData
