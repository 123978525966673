// src/tailwindColors.js
// This file can be imported by your tailwind.config.js file

/**
 * Custom color palette based on the project specifications
 * Updated to follow 60/30/7/3 color theory distribution
 */
export default {
  // Primary colors from project specs
  primary: {
    DEFAULT: '#9238F9', // Sugar Grape
    50: '#F5EFFF',
    100: '#EBDFFF',
    200: '#D7BEFF',
    300: '#C39EFF',
    400: '#AF7DFE',
    500: '#9238F9', // Base color
    600: '#742DE0',
    700: '#5925B3',
    800: '#401B85',
    900: '#2C1258',
    950: '#1C0A3B',
  },
  // Additional colors from the palette
  secondary: {
    DEFAULT: '#E501FF', // Phlox
    50: '#FCEAFF',
    100: '#F9D5FF',
    200: '#F3ABFF',
    300: '#EE82FF',
    400: '#E941FF',
    500: '#E501FF', // Base color
    600: '#BA00CD',
    700: '#8F009B',
    800: '#64006A',
    900: '#390038',
    950: '#21001F',
  },
  accent: {
    DEFAULT: '#3AC8AA', // Jadeite
    50: '#E9F9F5',
    100: '#D3F3EB',
    200: '#A7E8D6',
    300: '#7BDCC2',
    400: '#4FD0AD',
    500: '#3AC8AA', // Base color
    600: '#2FA089',
    700: '#257869',
    800: '#1B5048',
    900: '#122828',
    950: '#0A1614',
  },
  warning: {
    DEFAULT: '#FBB03B', // Glowing Lantern
    50: '#FFF8EB',
    100: '#FFF0D7',
    200: '#FEE2AF',
    300: '#FDD387',
    400: '#FCC55F',
    500: '#FBB03B', // Base color
    600: '#E19213',
    700: '#AC6F0F',
    800: '#774C0A',
    900: '#422A05',
    950: '#251703',
  },
  blackish: {
    DEFAULT: '#231F20', // Primary Black
    50: '#E7E6E6',
    100: '#CFCDCE',
    200: '#9F9A9B',
    300: '#6F6769',
    400: '#3F393A',
    500: '#231F20', // Base color
    600: '#1D191A',
    700: '#171415',
    800: '#110F0F',
    900: '#0A090A',
    950: '#040404',
  },
};
