import { useQuery } from '@tanstack/react-query';
import type { CompanyMetrics, TimeframeType } from '@/types';
import { metricsService } from '@/services/metricsService';

export type FetchMetricsOptions = {
  section?: 'metrics' | 'revenue' | 'all';
}

export function useFetchCompanyMetrics(
  timeframe: TimeframeType,
  options: FetchMetricsOptions = { section: 'all' }
) {
  const { section = 'all' } = options;

  const {
    data,
    isLoading,
    error,
    refetch,
  } = useQuery<CompanyMetrics>({
    queryKey: ['companyMetrics', timeframe, section],
    queryFn: () => metricsService.fetchMetrics(timeframe),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Determine if specific sections are loading
  const isMetricsLoading = isLoading && (section === 'all' || section === 'metrics');
  const isRevenueLoading = isLoading && (section === 'all' || section === 'revenue');

  // Determine if specific sections have errors
  const metricsError = error && (section === 'all' || section === 'metrics') ? error : null;
  const revenueError = error && (section === 'all' || section === 'revenue') ? error : null;

  // Section-specific refetch functions
  const refetchMetrics = () => refetch();
  const refetchRevenue = () => refetch();

  return {
    data,
    isLoading,
    error,
    refetch,
    // Section-specific states
    isMetricsLoading,
    isRevenueLoading,
    metricsError,
    revenueError,
    refetchMetrics,
    refetchRevenue,
  };
}
