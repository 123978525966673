import { useState, useEffect } from 'react';

/**
 * Hook to detect dark mode state and changes
 * @returns The current dark mode state
 */
export function useDarkModeDetection(): { isDarkMode: boolean } {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    // Initial check
    setIsDarkMode(document.documentElement.classList.contains('dark'));
    
    // Create observer to watch for class changes on html element
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.attributeName === 'class' &&
          mutation.target === document.documentElement
        ) {
          setIsDarkMode(document.documentElement.classList.contains('dark'));
        }
      });
    });
    
    // Start observing
    observer.observe(document.documentElement, { attributes: true });
    
    // Cleanup
    return () => { observer.disconnect(); };
  }, []);

  return { isDarkMode };
}

export default useDarkModeDetection;
