// src/types/index.ts
export * from './chart-types';
export * from './client.unified';
export { MetricsTimeframe, TimeframeType };

// Import the types we've refactored
import type { ProductRevenueDataItem, RevenueDataItem, TimeSeriesData } from './chart-types';

// Import TimeframeType from metrics-types to ensure consistency
import type { MetricsTimeframe, TimeframeType } from './metrics-types';

// Revenue data structure
export type RevenueData = {
  total: number;
  employer: number;
  serviceProvider: number;
  pricePerUnit: number;
  change: number;
  averageRevenue?: number; // Average revenue per time unit based on selected timeframe
  topClients: RevenueDataItem[];
  byProduct: ProductRevenueDataItem[];
}

// Import ProcessingTimes from metrics-types
import type { ProcessingTimes } from './metrics-types';
export { ProcessingTimes };

// Extended company metrics data that includes all fields needed by various components
export type CompanyMetrics = {
  // Core metrics required by MetricsSection
  totalSubmissions: number;
  submissionsChange: number;
  approvalRate: number;
  approvalRateChange: number;
  avgProcessingTimeChange: number;
  processingTimes: ProcessingTimes;

  // Additional fields used by other components
  avgProcessingTime?: number;
  pendingSubmissions?: number;
  pendingSubmissionsChange?: number;
  timeSeriesData?: TimeSeriesData[];
  timeframe?: MetricsTimeframe;
  revenue?: RevenueData;
}

import {
  FitTestStatus,
  MedicalEvaluationStatus
} from './product.types';

// Status types for UI display (mapped from server statuses)
export enum DisplayStatus {
  APPROVED = 'Approved',
  DEFERRED = 'Deferred',
  PENDING = 'Pending',
  REVIEWING = 'Reviewing'
}

// Combined status types for all products
export type ServerSubmissionStatus = MedicalEvaluationStatus | FitTestStatus;

// Legacy SubmissionStatus type kept for backward compatibility
// This is how statuses are displayed in the UI
export type SubmissionStatus = `${DisplayStatus}`;

/**
 * Type guard for SubmissionStatus (UI display status)
 * @param status - Status string to check
 * @returns True if status is a valid display SubmissionStatus
 */
export const isValidSubmissionStatus = (status: string): status is SubmissionStatus => {
  return Object.values(DisplayStatus).includes(status as DisplayStatus);
};

/**
 * Type guard for ServerSubmissionStatus
 * @param status - Status string to check
 * @returns True if status is a valid server status
 */
export const isValidServerStatus = (status: string): status is ServerSubmissionStatus => {
  return [
    ...Object.values(MedicalEvaluationStatus),
    ...Object.values(FitTestStatus)
  ].includes(status as ServerSubmissionStatus);
};

/**
 * Location data structure
 */
export type Location = {
  id: number;
  name: string;
  prn: string;
}

// Health submission base record with common fields for all subtypes
export type HealthSubmissionBase = {
  id: string;
  submissionDate: string;
  employeeName: string;
  employerId: string;
  serviceProvider: string;
  role: string;
  code: string;
  status: SubmissionStatus; // UI display status
  serverStatus?: ServerSubmissionStatus; // Original server status for filtering
  details: string;
  subtype?: string; // Product type (corresponds to ProductType enum)
  location?: Location; // Location data with id, name, and prn
} & Record<string, unknown>

// Respirator Fit Test specific fields
export type RespiratorFitTestSubmission = {
  subtype: 'RESPIRATOR_FIT_TEST';
  protocol?: string;
  respiratorMake?: string;
  respiratorModel?: string;
  respiratorSize?: string;
  quantitativeFitFactor?: number | null;
  testTime?: string;
  creationTime?: string; // Creation date from API
  result?: string; // Raw PASS/FAIL result
  serverStatus?: FitTestStatus; // Typed server status
} & HealthSubmissionBase

// Respirator Medical Evaluation specific fields
export type RespiratorMedicalEvaluationSubmission = {
  subtype: 'RESPIRATOR_MEDICAL_EVALUATION';
  judgmentTime?: string | null;
  questionnaireId?: string | number;
  riskAssessmentToken?: string;
  serverStatus?: MedicalEvaluationStatus; // Typed server status
} & HealthSubmissionBase

// Ground Cannabis Dust Medical Surveillance specific fields
export type GroundCannabisDustSubmission = {
  subtype: 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE' | 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION';
  judgmentTime?: string;
  questionnaireId?: string | number;
  serverStatus?: MedicalEvaluationStatus; // Typed server status
} & HealthSubmissionBase

// Animal Allergy Medical Surveillance specific fields
export type AnimalAllergySubmission = {
  subtype: 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE' | 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION';
  // Fields will be added when schema is known
  serverStatus?: MedicalEvaluationStatus; // Typed server status
} & HealthSubmissionBase

// Union type for all possible submission types
export type HealthSubmission =
  | RespiratorFitTestSubmission
  | RespiratorMedicalEvaluationSubmission
  | GroundCannabisDustSubmission
  | AnimalAllergySubmission
  | HealthSubmissionBase; // Fallback for unknown types

/**
 * Type guard for RespiratorFitTestSubmission
 */
export function isRespiratorFitTest(submission: HealthSubmission): submission is RespiratorFitTestSubmission {
  return submission.subtype === 'RESPIRATOR_FIT_TEST';
}

/**
 * Type guard for RespiratorMedicalEvaluationSubmission
 */
export function isRespiratorMedicalEvaluation(submission: HealthSubmission): submission is RespiratorMedicalEvaluationSubmission {
  return submission.subtype === 'RESPIRATOR_MEDICAL_EVALUATION';
}

/**
 * Type guard for GroundCannabisDustSubmission
 */
export function isGroundCannabisDust(submission: HealthSubmission): submission is GroundCannabisDustSubmission {
  return submission.subtype === 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE' ||
    submission.subtype === 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION';
}

/**
 * Type guard for AnimalAllergySubmission
 */
export function isAnimalAllergy(submission: HealthSubmission): submission is AnimalAllergySubmission {
  return submission.subtype === 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE' ||
    submission.subtype === 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION';
}

export type ClientType = 'employer' | 'serviceProvider';

export type ClientEntry = {
  name: string;
  count: number;
}

export type ClientsData = {
  employers: ClientEntry[];
  serviceProviders: ClientEntry[];
}
