/**
 * Status Types
 *
 * Single source of truth for all status-related types.
 * This file centralizes status information and provides clear type hierarchies
 * for consistent typing throughout the application.
 */
import type * as React from 'react';

/**
 * Server Status Types
 * 
 * These enums represent the status values as they come from the server.
 * They are the canonical representation of status in the system.
 */

/**
 * Medical evaluation status types from the server
 */
export enum MedicalEvaluationServerStatus {
  CLEARED = 'CLEARED',
  DEFERRED = 'DEFERRED',
  EXPIRED_INTERVIEW = 'EXPIRED_INTERVIEW',
  PENDING_INTERVIEW = 'PENDING_INTERVIEW',
  PENDING_REVIEW = 'PENDING_REVIEW'
}

/**
 * Fit test status types from the server
 */
export enum FitTestServerStatus {
  PASS = 'PASS',
  FAIL = 'FAIL'
}

/**
 * UI Status Types
 * 
 * These enums represent how statuses should be displayed in the UI.
 * They provide consistent naming across the application.
 */
export enum MedicalEvaluationDisplayStatus {
  APPROVED = 'Approved',
  DEFERRED = 'Deferred',
  EXPIRED_INTERVIEW = 'Expired',
  PENDING_INTERVIEW = 'Pended',
  PENDING_REVIEW = 'Reviewing'
}

/**
 * Medical surveillance-specific display status (GCD, LAA)
 * Same server statuses but with 'Cleared' instead of 'Approved' for UI display
 */
export enum SurveillanceDisplayStatus {
  CLEARED = 'Cleared',
  DEFERRED = 'Deferred',
  EXPIRED_INTERVIEW = 'Expired',
  PENDING_INTERVIEW = 'Pended',
  PENDING_REVIEW = 'Reviewing'
}

export enum FitTestDisplayStatus {
  PASS = 'Pass',
  FAIL = 'Fail'
}

/**
 * Union type for all possible server statuses
 */
export type ServerStatus =
  | MedicalEvaluationServerStatus
  | FitTestServerStatus;

/**
 * Union type for all possible display statuses
 */
export type DisplayStatus =
  | MedicalEvaluationDisplayStatus
  | FitTestDisplayStatus
  | SurveillanceDisplayStatus;

/**
 * Type guard for MedicalEvaluationServerStatus
 */
export function isMedicalEvaluationServerStatus(status: string): status is MedicalEvaluationServerStatus {
  return Object.values(MedicalEvaluationServerStatus).includes(status as MedicalEvaluationServerStatus);
}

/**
 * Type guard for FitTestServerStatus
 */
export function isFitTestServerStatus(status: string): status is FitTestServerStatus {
  return Object.values(FitTestServerStatus).includes(status as FitTestServerStatus);
}

/**
 * Type guard for any valid ServerStatus
 */
export function isValidServerStatus(status: string): status is ServerStatus {
  return isMedicalEvaluationServerStatus(status) || isFitTestServerStatus(status);
}

/**
 * Type guard for MedicalEvaluationDisplayStatus
 */
export function isMedicalEvaluationDisplayStatus(status: string): status is MedicalEvaluationDisplayStatus {
  return Object.values(MedicalEvaluationDisplayStatus).includes(status as MedicalEvaluationDisplayStatus);
}

/**
 * Type guard for FitTestDisplayStatus
 */
export function isFitTestDisplayStatus(status: string): status is FitTestDisplayStatus {
  return Object.values(FitTestDisplayStatus).includes(status as FitTestDisplayStatus);
}

/**
 * Type guard for SurveillanceDisplayStatus
 */
export function isSurveillanceDisplayStatus(status: string): status is SurveillanceDisplayStatus {
  return Object.values(SurveillanceDisplayStatus).includes(status as SurveillanceDisplayStatus);
}

/**
 * Type guard for any valid DisplayStatus
 */
export function isValidDisplayStatus(status: string): status is DisplayStatus {
  return isMedicalEvaluationDisplayStatus(status) ||
    isFitTestDisplayStatus(status) ||
    isSurveillanceDisplayStatus(status);
}

/**
 * Interface for status metadata
 */
export type StatusMetadata = {
  serverStatus: ServerStatus;
  displayName: string;
  description?: string;
  order: number;
}

/**
 * Icon component type that accepts className prop
 */
export type IconComponent = React.ComponentType<{ className?: string }>;

/**
 * Status styling configuration
 */
export type StatusStyling = {
  variant: 'default' | 'secondary' | 'destructive' | 'success' | 'warning' | 'outline';
  icon: IconComponent;
  colorClasses?: string;
}

/**
 * Complete status definition
 */
export type StatusDefinition = {
  styling: StatusStyling;
} & StatusMetadata

/**
 * Type for mapping server status to display status
 */
export type ServerToDisplayStatusMap = Record<ServerStatus, DisplayStatus>;

/**
 * Type for product-specific status registries
 */
export type MedicalEvaluationStatusRegistry = Record<MedicalEvaluationServerStatus, StatusDefinition>;
export type FitTestStatusRegistry = Record<FitTestServerStatus, StatusDefinition>;

/**
 * Type for combined status registry that can contain any status
 */
export type StatusRegistry = Partial<Record<ServerStatus, StatusDefinition>>;

/**
 * Status filter option for UI components
 */
export type StatusFilterOption = {
  value: string;
  label: string;
  serverStatus?: ServerStatus;
}
