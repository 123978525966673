// src/hooks/useAutoAnimate.ts
import { useAutoAnimate as useVanillaAutoAnimate } from '@formkit/auto-animate/react';
import type { RefCallback } from 'react';

/**
 * Animation configuration options
 */
type AutoAnimateOptions = {
  duration?: number;
  easing?: 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out';
  disrespectUserMotionPreference?: boolean;
}

/**
 * Enhanced type-safe wrapper for useAutoAnimate
 * 
 * This hook simplifies the use of auto-animate throughout the application,
 * providing better TypeScript type safety.
 * 
 * @param options Configuration options for the animation
 * @returns A ref callback to attach to the parent element that should have its children animated
 */
export function useAutoAnimate<T extends HTMLElement>(options?: AutoAnimateOptions): RefCallback<T> {
  const [parent] = useVanillaAutoAnimate<T>(options || {
    // Default configuration options
    duration: 200, // Animation duration in ms
    easing: 'ease-in-out', // Animation easing function
  });
  
  return parent;
}
