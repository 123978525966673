// src/components/layout/MobileSidebar.tsx
import type React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { X, LogOut, Sun, Moon, Monitor } from '@/components/ui/icon';
import { cn } from '@/lib/utils';
import { signOutUser } from '@/lib/authUtils';
import { useTheme } from '@/components/layout/ThemeProvider';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { navigationItems } from './navigationConfig';

// Import the logo
import fullLogo from '@/components/assets/primary-logo-white--MRH5X7z.png';

type MobileSidebarProps = {
  onClose: () => void;
  onNavItemClick?: () => void;
}

export const MobileSidebar: React.FC<MobileSidebarProps> = ({ 
  onClose,
  onNavItemClick
}): JSX.Element => {
  const location = useLocation();
  const { theme, setTheme } = useTheme();
  
  const handleSignOut = async (): Promise<void> => {
    try {
      await signOutUser();
      // The page will refresh when signOut completes
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error signing out:', error);
      window.showToast?.('Sign out failed', { 
        variant: 'destructive',
        description: 'Please try again'
      });
    }
  };

  // Helper function to get the current theme icon
  const getCurrentThemeIcon = (): React.ReactNode => {
    if (theme === 'system') return <Monitor size={20} className="text-accent-500" />;
    if (theme === 'dark') return <Moon size={20} className="text-primary-500" />;
    return <Sun size={20} className="text-warning-500" />;
  };

  return (
    <div className="flex flex-col h-full bg-white dark:bg-blackish-700 overflow-y-auto">
      {/* Header with logo and close button */}
      <div className="h-16 flex items-center justify-between px-4 relative overflow-hidden border-b border-gray-200 dark:border-blackish-600 bg-gray-400 dark:bg-blackish-800">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-r from-warning-400 to-secondary-400 dark:from-warning-700 dark:to-secondary-700 opacity-40 dark:opacity-20"></div>
        
        {/* Logo and Title */}
        <div className="flex items-center gap-2 relative z-10">
          <img 
            src={fullLogo} 
            alt="Proxima Logo" 
            className="h-8 w-auto"
          />
          <span className="font-rbno3 text-lg text-gray-800 dark:text-white hidden sm:block">
            Proxima
          </span>
        </div>
        
        {/* Close button */}
        <button 
          onClick={onClose}
          className="p-2 rounded-full hover:bg-black/10 relative z-10"
          aria-label="Close navigation"
        >
          <X size={20} className="text-gray-800 dark:text-white" />
        </button>
      </div>
      
      {/* Navigation items */}
      <nav className="flex-1 py-4 px-4">
        <ul className="space-y-2">
          {navigationItems.map((item) => {
            const isActive = location.pathname === item.path || 
              (item.path !== '/' && location.pathname.startsWith(item.path));
            
            return (
              <li key={item.path}>
                <NavLink 
                  to={item.path}
                  className={cn(
                    "flex items-center py-3 px-4 rounded-md transition-all duration-200 relative overflow-hidden",
                    isActive 
                      ? "text-accent-600 dark:text-accent-400 bg-accent-50 dark:bg-accent-900/20" 
                      : "text-blackish-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-blackish-600"
                  )}
                  onClick={onNavItemClick}
                >
                  {/* Active indicator with gradient */}
                  {isActive && (
                    <span className="absolute inset-y-0 left-0 w-1 bg-gradient-to-b from-accent-400 to-accent-600 rounded-r-full"></span>
                  )}
                  
                  <span className={cn(
                    "flex-shrink-0",
                    isActive ? "text-accent-500" : ""
                  )}>
                    {item.icon}
                  </span>
                  
                  <span className={cn(
                    "ml-3 text-base font-medium",
                    isActive 
                      ? "font-semibold" 
                      : "opacity-80"
                  )}>
                    {item.label}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      
      <div className="p-4 border-t border-gray-200 dark:border-blackish-600 space-y-2">
        {/* Theme selector */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button className="flex items-center w-full py-3 px-4 rounded-md hover:bg-gray-100 dark:hover:bg-blackish-600">
              <span className="flex-shrink-0">
                {getCurrentThemeIcon()}
              </span>
              <span className="ml-3 text-base font-medium text-blackish-500 dark:text-gray-300">
                Theme
              </span>
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-56">
            <DropdownMenuItem 
              onClick={() => { setTheme('light'); }}
              className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-blackish-600"
            >
              <Sun size={16} className="text-warning-500" />
              <span>Light</span>
              {theme === 'light' && <span className="ml-auto text-xs text-primary-500">✓</span>}
            </DropdownMenuItem>
            <DropdownMenuItem 
              onClick={() => { setTheme('dark'); }}
              className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-blackish-600"
            >
              <Moon size={16} className="text-primary-500" />
              <span>Dark</span>
              {theme === 'dark' && <span className="ml-auto text-xs text-primary-500">✓</span>}
            </DropdownMenuItem>
            <DropdownMenuItem 
              onClick={() => { setTheme('system'); }}
              className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-blackish-600"
            >
              <Monitor size={16} className="text-accent-500" />
              <span>System</span>
              {theme === 'system' && <span className="ml-auto text-xs text-primary-500">✓</span>}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        
        {/* Sign out button */}
        <button
          onClick={handleSignOut}
          className="flex items-center w-full py-3 px-4 rounded-md text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20"
          aria-label="Sign out"
        >
          <span className="flex-shrink-0">
            <LogOut size={20} />
          </span>
          <span className="ml-3 text-base font-medium">
            Sign Out
          </span>
        </button>
      </div>
    </div>
  );
};

// Export as default for backward compatibility
export default MobileSidebar;
