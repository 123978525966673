import React, { createContext, useContext, useEffect } from "react";
import { useDarkMode, ThemeMode } from "@/hooks/useDarkMode";

// Maintain compatibility with the existing Theme type
export type Theme = "dark" | "light" | "system";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: "system",
  setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "ui-theme",
  ...props
}: ThemeProviderProps) {
  // Use our enhanced useDarkMode hook for theme management
  const { 
    themeMode, 
    setThemeMode,
    isDarkMode
  } = useDarkMode({
    useSystemPreference: defaultTheme === 'system',
    storageKey: storageKey
  });
  
  // Map between our ThemeMode type and the Theme type for compatibility
  const mapThemeMode = (mode: ThemeMode): Theme => mode as Theme;
  
  // Apply smooth transitions when theme changes
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const root = window.document.documentElement;
    root.classList.add("theme-transition");
    
    const transitionTimeout = setTimeout(() => {
      root.classList.remove("theme-transition");
    }, 300);
    
    return () => clearTimeout(transitionTimeout);
  }, [isDarkMode]);
  
  const value = {
    theme: mapThemeMode(themeMode),
    setTheme: (newTheme: Theme) => {
      setThemeMode(newTheme as ThemeMode);
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);
  
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  
  return context;
};

// Ensure TypeScript knows about the global variable
declare global {
  interface Window {
    __INITIAL_THEME_STATE__?: {
      theme: string;
      prefersDark: boolean;
    };
  }
}