// src/components/invoices/InvoicePriceUpdaterContainer.tsx
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Tag } from '@/components/ui/icon';
import { useAutoAnimate } from '@/hooks/useAutoAnimate';
import { getCardAnimationClass, getDashboardCardClass } from '@/styles/dashboard';
import type { ClientPricing, PriceUpdateResult, PriceUpdaterState } from "@/types/invoice-pricing-types";
import type { ColorFamily } from "@/types/ui-component-types";
import {
    downloadUpdatedCsv,
    processCsvWithPricing,
    readFileAsText,
    validateClientPricingJson
} from "@/utils/invoicePriceUpdaterUtils";
import type { ChangeEvent } from "react";
import { useRef, useState } from "react";
import { FileUploadSection } from "./FileUploadSection";
import { ProcessingControls } from "./ProcessingControls";
import { ResultsSection } from "./ResultsSection";

export type InvoicePriceUpdaterContainerProps = {
    /**
     * Color family for styling consistency
     */
    colorFamily?: ColorFamily;
    /**
     * Dark mode state for styling
     */
    isDarkMode?: boolean;
}

/**
 * Container component for the invoice price updater
 * Coordinates between all subcomponents and contains main business logic
 */
export const InvoicePriceUpdaterContainer = ({
    colorFamily = 'secondary',
    isDarkMode = false
}: InvoicePriceUpdaterContainerProps): JSX.Element => {
    const containerRef = useAutoAnimate<HTMLDivElement>();

    // Component state
    const [state, setState] = useState<PriceUpdaterState>('idle');
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [clientPricingFile, setClientPricingFile] = useState<File | null>(null);
    const [result, setResult] = useState<PriceUpdateResult | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [jsonParseError, setJsonParseError] = useState<string>('');

    // Refs for file inputs
    const csvInputRef = useRef<HTMLInputElement>(null);
    const jsonInputRef = useRef<HTMLInputElement>(null);

    /**
     * Handles CSV file selection
     */
    const handleCsvFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
            setCsvFile(e.target.files[0]);
            setErrorMessage('');
            setResult(null);
        }
    };

    /**
     * Handles client pricing JSON file selection
     */
    const handleJsonFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files && e.target.files.length > 0) {
            setClientPricingFile(e.target.files[0]);
            setErrorMessage('');
            setJsonParseError('');
            setResult(null);
        }
    };

    /**
     * Triggers CSV file selection dialog
     */
    const handleCsvUploadClick = (): void => {
        if (csvInputRef.current) {
            csvInputRef.current.click();
        }
    };

    /**
     * Triggers JSON file selection dialog
     */
    const handleJsonUploadClick = (): void => {
        if (jsonInputRef.current) {
            jsonInputRef.current.click();
        }
    };

    /**
     * Processes the CSV with client pricing data
     */
    const handleProcessFiles = async (): Promise<void> => {
        if (!csvFile) {
            setErrorMessage('Please upload an invoice CSV file');
            return;
        }

        if (!clientPricingFile) {
            setErrorMessage('Please upload a client pricing JSON file');
            return;
        }

        setState('loading');
        setErrorMessage('');
        setJsonParseError('');
        setResult(null);

        try {
            // Read and parse the CSV file
            const csvContent = await readFileAsText(csvFile);

            // Read and parse the JSON file
            const jsonContent = await readFileAsText(clientPricingFile);

            let clientPricingData: ClientPricing[];
            try {
                clientPricingData = JSON.parse(jsonContent) as ClientPricing[];
                console.log('Parsed client pricing data:', clientPricingData);

                // Validate the JSON structure
                const validation = validateClientPricingJson(clientPricingData);
                if (!validation.valid) {
                    setJsonParseError(validation.error ?? 'Invalid client pricing data format');
                    setState('error');
                    return;
                }
            } catch (jsonError) {
                console.error('Error parsing JSON:', jsonError);
                setJsonParseError('Failed to parse client pricing JSON file: ' +
                    (jsonError instanceof Error ? jsonError.message : 'Invalid JSON format'));
                setState('error');
                return;
            }

            // Process CSV and update prices
            const result = processCsvWithPricing(csvContent, clientPricingData);
            setResult(result);

            if (!result.Success) {
                setState('error');
            } else {
                setState('success');
            }
        } catch (error) {
            console.error('Error processing files:', error);
            setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
            setState('error');
        }
    };

    /**
     * Handles download of updated CSV
     */
    const handleDownload = (): void => {
        if (result?.UpdatedCsv) {
            downloadUpdatedCsv(result.UpdatedCsv);
        }
    };

    /**
     * Resets the component state
     */
    const handleReset = (): void => {
        setState('idle');
        setCsvFile(null);
        setClientPricingFile(null);
        setResult(null);
        setErrorMessage('');
        setJsonParseError('');

        if (csvInputRef.current) {
            csvInputRef.current.value = '';
        }
        if (jsonInputRef.current) {
            jsonInputRef.current.value = '';
        }
    };

    return (
        <div ref={containerRef}>
            <Card className={`${getDashboardCardClass(colorFamily, isDarkMode)} w-full`}>
                <div className={getCardAnimationClass(colorFamily)}>
                    <CardHeader className="pb-2 relative z-10">
                        <CardTitle className="text-lg md:text-xl text-blackish-800 dark:text-white flex items-center gap-2">
                            <Tag className="h-5 w-5 text-secondary-500" />
                            Invoice Price Updater
                        </CardTitle>
                        <CardDescription className="text-sm text-blackish-500 dark:text-blackish-300">
                            Update invoice CSV prices using client pricing data. Compare and validate prices before submitting.
                        </CardDescription>
                    </CardHeader>

                    <CardContent className="space-y-6 relative z-10">
                        {/* File Upload Sections */}
                        <FileUploadSection
                            colorFamily={colorFamily}
                            fileType="csv"
                            file={csvFile}
                            onFileChange={handleCsvFileChange}
                            onUploadClick={handleCsvUploadClick}
                            inputRef={csvInputRef}
                        />

                        <FileUploadSection
                            colorFamily={colorFamily}
                            fileType="json"
                            file={clientPricingFile}
                            jsonParseError={jsonParseError}
                            onFileChange={handleJsonFileChange}
                            onUploadClick={handleJsonUploadClick}
                            inputRef={jsonInputRef}
                        />

                        {/* Processing Controls */}
                        <ProcessingControls
                            colorFamily={colorFamily}
                            state={state}
                            errorMessage={errorMessage}
                            disabled={!csvFile || !clientPricingFile}
                            onProcessClick={() => { void handleProcessFiles(); }}
                        />

                        {/* Results Section */}
                        {result && (
                            <ResultsSection
                                colorFamily={colorFamily}
                                result={result}
                                onDownload={handleDownload}
                                onReset={handleReset}
                            />
                        )}
                    </CardContent>

                    <CardFooter className="text-sm text-blackish-500 dark:text-blackish-400 border-t pt-4 pb-2 px-4 sm:px-6 sm:py-4 sm:pt-4 md:p-6 md:pt-6 flex flex-col items-start relative z-10">
                        <p className="mb-1">The invoice updater compares prices in the CSV against the client pricing database and updates any discrepancies.</p>
                        <p className="mb-1">Missing clients or products will be highlighted in the report for manual review.</p>
                    </CardFooter>
                </div>
            </Card>
        </div>
    );
};
