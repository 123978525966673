/**
 * Records Query Parameters
 * 
 * This file provides a single source of truth for the query parameters
 * used with the /records endpoint, ensuring consistent parameter naming,
 * validation, and mapping between frontend and backend.
 */
import { z } from 'zod';
import { FitTestStatus, MedicalEvaluationStatus, ProductType } from './product.types';
import { FitTestServerStatus, MedicalEvaluationServerStatus } from './status.types';

/**
 * Zod schema for record query parameters
 * Mirrors the backend schema from queryRecordsOptionsSchema
 */
export const recordsQuerySchema = z.object({
    // Date range filters
    completionDateStart: z.string().optional(),
    completionDateEnd: z.string().optional(),

    // Employee filters
    employeeName: z.string().optional(),
    includeEmployeeDateOfBirth: z.boolean().optional(),

    // Organization filters
    employerPrn: z.array(z.object({
        id: z.string(),
    })).optional(),
    jobRolePrn: z.array(z.object({
        id: z.string(),
    })).optional(),
    locationPrn: z.array(z.object({
        id: z.string(),
    })).optional(),
    serviceProviderPrn: z.object({
        id: z.string(),
    }).optional(),

    // Status filters
    groundCannabisDustMedicalSurveillanceEvaluationStatus: z.array(z.string()).optional(),
    respiratorMedicalEvaluationStatus: z.array(z.string()).optional(),

    // Pagination controls
    itemsPerPage: z.number().int().min(1).max(100).optional(),
    page: z.number().int().min(1).max(10000).optional(),

    // Sorting
    sortBy: z.enum([
        'completionTime', '-completionTime',
        'creationTime', '-creationTime',
        'employeeLastName', '-employeeLastName',
        'employerPreferredName', '-employerPreferredName',
        'jobRoleName', '-jobRoleName',
        'locationName', '-locationName',
        'status', '-status',
        'subtype', '-subtype'
    ]).optional(),

    // Product type filter
    subtype: z.array(z.string()).optional(),
});

/**
 * Type definitions derived from the Zod schema
 */
export type RecordsQueryParameters = z.infer<typeof recordsQuerySchema>;

/**
 * Frontend-specific query parameters type that is more user-friendly
 * for component interaction
 */
export type FrontendQueryParams = {
    // Date filters with flexible string/Date input
    dateFrom?: string | Date;
    dateTo?: string | Date;

    // Text search filters with direct string input
    employeeName?: string;
    includeEmployeeDateOfBirth?: boolean;

    // Selection filters with simplified object structure
    employerId?: string | string[];
    jobRoleId?: string | string[];
    locationId?: string | string[];
    serviceProviderId?: string;

    // Status filters
    status?: string | string[];

    // Product-specific status filters
    groundCannabisDustStatus?: string | string[];
    respiratorMedicalEvaluationStatus?: string | string[];
    respiratorFitTestResult?: string | string[];

    // Pagination
    itemsPerPage?: number;
    page?: number;

    // Sorting with simplified structure
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';

    // Product type filter with direct ProductType enum
    productType?: ProductType;

    // Allow additional dynamic parameters
    [key: string]: unknown;
};

/**
 * Mapping of frontend product types to backend subtypes
 * Creates a single source of truth for product type mappings
 */
export const PRODUCT_TYPE_TO_SERVER_SUBTYPE: Record<ProductType, string> = {
    [ProductType.ALL]: 'ALL',
    [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: 'RESPIRATOR_MEDICAL_EVALUATION',
    [ProductType.RESPIRATOR_FIT_TEST]: 'RESPIRATOR_FIT_TEST',
    [ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE]: 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION',
    [ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE]: 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION',
};

/**
 * Mapping of frontend status values to backend status values
 * to ensure consistent status filtering
 */
export const FRONTEND_TO_BACKEND_STATUS_MAP: Record<string, Record<string, string>> = {
    [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: {
        [MedicalEvaluationStatus.CLEARED]: 'CLEARED',
        [MedicalEvaluationStatus.DEFERRED]: 'NOT_CLEARED',
        [MedicalEvaluationStatus.PENDING_REVIEW]: 'PENDING_REVIEW',
        [MedicalEvaluationStatus.PENDING_INTERVIEW]: 'PENDING_INTERVIEW',
        [MedicalEvaluationStatus.EXPIRED_INTERVIEW]: 'EXPIRED_INTERVIEW',
    },
    [ProductType.RESPIRATOR_FIT_TEST]: {
        [FitTestStatus.PASS]: 'PASS',
        [FitTestStatus.FAIL]: 'FAIL',
    },
    // Add mappings for other product types as they become available
};

/**
 * Mapping for server status enums to their backend API parameter values
 * This is needed because some backend parameters expect different values than our enums
 */
export const MEDICAL_EVALUATION_SERVER_TO_BACKEND_STATUS: Record<string, string> = {
    [MedicalEvaluationServerStatus.CLEARED]: 'APPROVED',
    [MedicalEvaluationServerStatus.DEFERRED]: 'DEFERRED',
    [MedicalEvaluationServerStatus.EXPIRED_INTERVIEW]: 'EXPIRED_INTERVIEW',
    [MedicalEvaluationServerStatus.PENDING_INTERVIEW]: 'PENDING_INTERVIEW',
    [MedicalEvaluationServerStatus.PENDING_REVIEW]: 'PENDING_REVIEW'
};

/**
 * Mapping for medical surveillance status enums to their backend API parameter values
 * Uses CLEARED instead of APPROVED for GCD and LAA products
 */
export const SURVEILLANCE_SERVER_TO_BACKEND_STATUS: Record<string, string> = {
    [MedicalEvaluationServerStatus.CLEARED]: 'CLEARED', // Keep as CLEARED, not APPROVED
    [MedicalEvaluationServerStatus.DEFERRED]: 'DEFERRED',
    [MedicalEvaluationServerStatus.EXPIRED_INTERVIEW]: 'EXPIRED_INTERVIEW',
    [MedicalEvaluationServerStatus.PENDING_INTERVIEW]: 'PENDING_INTERVIEW',
    [MedicalEvaluationServerStatus.PENDING_REVIEW]: 'PENDING_REVIEW'
};

/**
 * Mapping for fit test server status enums to their backend API parameter values
 */
export const FIT_TEST_SERVER_TO_BACKEND_STATUS: Record<string, string> = {
    [FitTestServerStatus.PASS]: 'PASS',
    [FitTestServerStatus.FAIL]: 'FAIL'
};

/**
 * Converts a frontend parameter value to a backend parameter value
 * Handles array conversion, date formatting, and other transformations
 */
const convertParamValue = (
    key: string,
    value: unknown,
    productType?: ProductType
): unknown => {
    // Handle undefined or null values
    if (value === undefined || value === null) {
        return undefined;
    }

    // Handle boolean values - convert to 'true' or 'false' strings for API
    if (typeof value === 'boolean') {
        return value.toString();
    }

    // Handle date objects - convert to ISO strings
    if (value instanceof Date) {
        return value.toISOString().split('T')[0]; // YYYY-MM-DD format
    }

    // Handle array values - convert to comma-separated strings for API
    if (Array.isArray(value)) {
        // For arrays of objects with id property (e.g., PRNs)
        if (value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
            return value;
        }
        // For arrays of primitive values, join with commas
        return value.join(',');
    }

    // Handle status values with product-specific mapping
    if (key === 'status' && typeof value === 'string' && productType) {
        const statusMap = FRONTEND_TO_BACKEND_STATUS_MAP[productType];
        if (statusMap && statusMap[value]) {
            return statusMap[value];
        }
    }

    // Return the original value for all other cases
    return value;
};

/**
 * Converts frontend query parameters to backend API parameters
 * Maps parameter names and transforms values as needed
 */
export const mapFrontendToBackendParams = (
    params: FrontendQueryParams
): Record<string, unknown> => {
    const backendParams: Record<string, unknown> = {};
    const productType = params.productType;

    // Map date parameters
    if (params.dateFrom) {
        backendParams.completionDateStart = convertParamValue('completionDateStart', params.dateFrom);
    }

    if (params.dateTo) {
        backendParams.completionDateEnd = convertParamValue('completionDateEnd', params.dateTo);
    }

    // Map employee parameters
    if (params.employeeName) {
        backendParams.employeeName = params.employeeName;
    }

    if (params.includeEmployeeDateOfBirth !== undefined) {
        backendParams.includeEmployeeDateOfBirth = params.includeEmployeeDateOfBirth;
    }

    // Map organization identifiers
    if (params.employerId) {
        const employerIds = Array.isArray(params.employerId) ? params.employerId : [params.employerId];
        backendParams.employerPrn = employerIds.map(id => ({ id }));
    }

    if (params.jobRoleId) {
        const jobRoleIds = Array.isArray(params.jobRoleId) ? params.jobRoleId : [params.jobRoleId];
        backendParams.jobRolePrn = jobRoleIds.map(id => ({ id }));
    }

    if (params.locationId) {
        const locationIds = Array.isArray(params.locationId) ? params.locationId : [params.locationId];
        backendParams.locationPrn = locationIds.map(id => ({ id }));
    }

    if (params.serviceProviderId) {
        backendParams.serviceProviderPrn = { id: params.serviceProviderId };
    }

    // Map pagination parameters directly
    if (params.itemsPerPage !== undefined) {
        backendParams.itemsPerPage = params.itemsPerPage;
    }

    if (params.page !== undefined) {
        backendParams.page = params.page;
    }

    // Map sorting parameters
    if (params.sortBy) {
        // For descending sort, prefix with minus
        const prefix = params.sortDirection === 'desc' ? '-' : '';
        backendParams.sortBy = `${prefix}${params.sortBy}`;
    }

    // Map status parameters based on product type
    if (params.status) {
        if (productType === ProductType.RESPIRATOR_MEDICAL_EVALUATION) {
            const statusValues = Array.isArray(params.status) ? params.status : [params.status];
            backendParams.respiratorMedicalEvaluationStatus = statusValues.map(
                status => convertParamValue('status', status, productType)
            );
        } else if (productType === ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE) {
            const statusValues = Array.isArray(params.status) ? params.status : [params.status];
            backendParams.groundCannabisDustMedicalSurveillanceEvaluationStatus = statusValues.map(
                status => convertParamValue('status', status, productType)
            );
        }
        // Additional product types can be handled similarly
    }

    // Map product-specific status parameters directly with value translation
    if (params.respiratorMedicalEvaluationStatus) {
        const statusValues = Array.isArray(params.respiratorMedicalEvaluationStatus)
            ? params.respiratorMedicalEvaluationStatus
            : [params.respiratorMedicalEvaluationStatus];

        // Map each status value using the medical evaluation status mapping
        backendParams.respiratorMedicalEvaluationStatus = statusValues.map(status =>
            MEDICAL_EVALUATION_SERVER_TO_BACKEND_STATUS[status] || status
        );
    }

    if (params.groundCannabisDustStatus) {
        const statusValues = Array.isArray(params.groundCannabisDustStatus)
            ? params.groundCannabisDustStatus
            : [params.groundCannabisDustStatus];

        // Map each status value using the SURVEILLANCE mapping instead of medical evaluation
        backendParams.groundCannabisDustMedicalSurveillanceEvaluationStatus = statusValues.map(status =>
            SURVEILLANCE_SERVER_TO_BACKEND_STATUS[status] || status
        );
    }

    // Add animal allergy status parameters directly with value translation using SURVEILLANCE mapping
    if (params.animalAllergyStatus) {
        const statusValues = Array.isArray(params.animalAllergyStatus)
            ? params.animalAllergyStatus
            : [params.animalAllergyStatus];

        // Map each status value using the SURVEILLANCE mapping
        backendParams.animalAllergyMedicalSurveillanceEvaluationStatus = statusValues.map(status =>
            SURVEILLANCE_SERVER_TO_BACKEND_STATUS[status] || status
        );
    }

    // Handle product type to auto-set appropriate status parameters for surveillance products
    if (productType) {
        // For GCD, add status parameter with all values ONLY when initializing the view (not when a specific filter is set)
        // The params.groundCannabisDustStatus check ensures we don't override when a specific status filter is selected
        if (productType === ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE &&
            !backendParams.groundCannabisDustMedicalSurveillanceEvaluationStatus &&
            !params.groundCannabisDustStatus) {
            backendParams.groundCannabisDustMedicalSurveillanceEvaluationStatus = [
                'CLEARED',
                'DEFERRED',
                'EXPIRED_INTERVIEW',
                'PENDING_INTERVIEW',
                'PENDING_REVIEW'
            ];
        }

        // For LAA, add status parameter with all values ONLY when initializing the view (not when a specific filter is set)
        // The params.animalAllergyStatus check ensures we don't override when a specific status filter is selected
        if (productType === ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE &&
            !backendParams.animalAllergyMedicalSurveillanceEvaluationStatus &&
            !params.animalAllergyStatus) {
            backendParams.animalAllergyMedicalSurveillanceEvaluationStatus = [
                'CLEARED',
                'DEFERRED',
                'EXPIRED_INTERVIEW',
                'PENDING_INTERVIEW',
                'PENDING_REVIEW'
            ];
        }

        // Only add subtype parameter for RFT
        if (productType === ProductType.RESPIRATOR_FIT_TEST) {
            backendParams.subtype = ['RESPIRATOR_FIT_TEST'];
        }
    }

    // Map subtype parameter if explicitly provided (for workarounds)
    // But ensure we don't add it for GCD and LAA
    if (params.subtype &&
        productType !== ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE &&
        productType !== ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE) {
        backendParams.subtype = params.subtype;
    }

    return backendParams;
};

/**
 * Validates backend parameters against the schema
 * Returns validated parameters or throws an error
 */
export const validateBackendParams = (
    params: Record<string, unknown>
): RecordsQueryParameters => {
    return recordsQuerySchema.parse(params);
};
