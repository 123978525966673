import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ClipboardList, PieChart as ThumbsUp, Timer } from '@/components/ui/icon';
import { LoadingState } from '@/components/ui/loading-state';
import { StateContainer } from '@/components/ui/state-container';
import { useUIThemeState } from '@/hooks/useUIThemeState';
import type { CompanyMetrics } from '@/types/metrics-types';
import { getCardAnimationClass, getDashboardCardClass } from '@/utils/dashboardStyleUtils';
import type * as React from 'react';
import { TrendIndicator } from './TrendIndicator';

type MetricsSectionProps = {
  data?: CompanyMetrics;
  isLoading: boolean;
  error?: Error | null;
  onRetry?: () => void;
}

export const MetricsSection: React.FC<MetricsSectionProps> = ({
  data,
  isLoading,
  error,
  onRetry
}) => {
  // Use shared UI theme state hook instead of component-specific state
  const { isDarkMode } = useUIThemeState();

  return (
    <StateContainer
      isLoading={isLoading}
      error={error}
      onRetry={onRetry}
      isEmpty={!data}
      loadingComponent={
        <LoadingState
          variant="skeleton"
          cards={4}
          withChart
          className="w-full"
        />
      }
    >
      {data && (
        <div className="space-y-6">
          <h3 className="text-xl font-bold text-blackish-800 dark:text-white flex items-center gap-2 border-b pb-2">
            <ClipboardList className="h-5 w-5 text-primary-500" />
            Metrics Overview
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
            {/* Total Submissions Card */}
            <Card className={getDashboardCardClass('primary', isDarkMode)}>
              <div className={getCardAnimationClass('primary')}>
                <CardHeader className="pb-2 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <ClipboardList size={18} className="text-primary-600 mr-2" />
                    Total
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-3">
                    <span className="text-3xl md:text-5xl font-extrabold text-blackish-900 dark:text-white truncate value-focus pulse-on-load">
                      {data.totalSubmissions.toLocaleString()}
                      <span className="text-sm text-blackish-500 dark:text-blackish-400 truncate">
                        {" "} records
                      </span>
                    </span>

                    <div className="flex items-center text-sm md:text-base flex-wrap">
                      <TrendIndicator
                        value={data.submissionsChange}
                        suffix="%"
                        className="mr-2"
                      />
                      <span className="text-blackish-500 dark:text-blackish-400 truncate">
                        {data.submissionsChange < 0 ? 'less' : 'more'}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>

            {/* Review Rate Card */}
            <Card className={getDashboardCardClass('accent', isDarkMode)}>
              <div className={getCardAnimationClass('accent')}>
                <CardHeader className="pb-1 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <ThumbsUp size={18} className="text-accent-600 mr-2" />
                    Review Rate
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-3">
                    <span className="text-4xl md:text-6xl font-extrabold text-blackish-900 dark:text-white truncate tracking-tight value-focus pulse-on-load">
                      {data.approvalRate}
                      <span className="text-sm text-blackish-500 dark:text-blackish-400 truncate">
                        {" "} %
                      </span>
                    </span>

                    <div className="flex items-center text-sm md:text-base flex-wrap">
                      {/* Review time is better when it decreases */}
                      <TrendIndicator
                        value={data.approvalRateChange}
                        suffix="%"
                        positiveIsBetter={false}
                        className="mr-2"
                      />
                      <span className="text-blackish-500 dark:text-blackish-400 truncate">
                        {data.approvalRateChange < 0 ? 'better' : 'worse'}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>


            {/* Review Time Card */}
            <Card className={getDashboardCardClass('warning', isDarkMode)}>
              <div className={getCardAnimationClass('warning')}>
                <CardHeader className="pb-1 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <ThumbsUp size={18} className="text-warning-600 mr-2" />
                    Review Time
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-3">
                    <span className="text-4xl md:text-6xl font-extrabold text-blackish-900 dark:text-white truncate tracking-tight value-focus pulse-on-load">
                      {data.processingTimes.reviewTime}
                      <span className="text-sm text-blackish-500 dark:text-blackish-400 truncate">
                        {" "} mins
                      </span>
                    </span>

                    <div className="flex items-center text-sm md:text-base flex-wrap">
                      {/* Review time is better when it decreases */}
                      <TrendIndicator
                        value={data.processingTimes.reviewTimeChange}
                        suffix="%"
                        positiveIsBetter={false}
                        className="mr-2"
                      />
                      <span className="text-blackish-500 dark:text-blackish-400 truncate">
                        {data.processingTimes.reviewTimeChange < 0 ? 'faster' : 'slower'}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>

            {/* PtC Time Card */}
            <Card className={getDashboardCardClass('secondary', isDarkMode)}>
              <div className={getCardAnimationClass('secondary')}>
                <CardHeader className="pb-1 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <Timer size={18} className="text-secondary-600 mr-2" />
                    PtC Time
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-3">
                    <span className="text-4xl md:text-6xl font-extrabold text-blackish-900 dark:text-white truncate tracking-tight value-focus pulse-on-load">
                      {data.processingTimes.ptcTime}
                      <span className="text-sm text-blackish-500 dark:text-blackish-400 truncate">
                        {" "} days
                      </span>
                    </span>

                    <div className="flex items-center text-sm md:text-base flex-wrap">
                      {/* PtC time is better when it decreases */}
                      <TrendIndicator
                        value={data.avgProcessingTimeChange}
                        suffix="%"
                        positiveIsBetter={false}
                        className="mr-2"
                      />
                      <span className="text-blackish-500 dark:text-blackish-400 truncate">
                        {data.avgProcessingTimeChange < 0 ? 'faster' : 'slower'}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>
          </div>
        </div>
      )}
    </StateContainer>
  );
};
