// src/components/invoices/ResultsSection.tsx
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { AlertCircle, CheckCircle, FileDown } from '@/components/ui/icon';
import { useUIThemeState } from '@/hooks/useUIThemeState';
import { cn } from '@/lib/utils';
import { getCardAnimationClass, getDashboardCardClass } from '@/styles/dashboard';
import type { PriceUpdateResult } from "@/types/invoice-pricing-types";
import type { ColorFamily } from "@/types/ui-component-types";
import { MissingClientsSection } from './MissingClientsSection';
import { MissingProductsSection } from './MissingProductsSection';
import { RevenueBreakdownSection } from './RevenueBreakdownSection';

export type ResultsSectionProps = {
    /**
     * Color family for styling consistency
     */
    colorFamily?: ColorFamily;
    /**
     * Processing result data
     */
    result: PriceUpdateResult | null;
    /**
     * Handler for download CSV button
     */
    onDownload: () => void;
    /**
     * Handler for reset button
     */
    onReset: () => void;
}

/**
 * ResultsSection component
 * Container for all result sections (success/error, missing clients, etc.)
 */
export const ResultsSection = ({
    colorFamily = 'accent',
    result,
    onDownload,
    onReset
}: ResultsSectionProps): JSX.Element => {
    const { isDarkMode } = useUIThemeState();
    // If there's no result yet, don't render anything
    if (!result) {
        return <></>;
    }

    return (
        <div className="space-y-6">
            {/* Overall result status */}
            <Card className={`${getDashboardCardClass(result.Success ? 'primary' : 'warning', isDarkMode)} w-full`}>
                <div className={getCardAnimationClass(result.Success ? 'primary' : 'warning')}>
                    <CardHeader className="pb-2 relative z-10">
                        <CardTitle className="text-lg md:text-xl text-blackish-800 dark:text-white flex items-center gap-2">
                            <FileDown className="h-5 w-5 text-primary-500" />
                            Processing Results
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="relative z-10">
                        <div className={cn(
                            "p-3 rounded-md flex items-center gap-2",
                            result.Success
                                ? "bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800 text-green-700 dark:text-green-400"
                                : "bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-800 text-amber-700 dark:text-amber-400"
                        )}>
                            {result.Success ? (
                                <CheckCircle size={16} />
                            ) : (
                                <AlertCircle size={16} />
                            )}
                            <span>
                                {result.TotalUpdates} price updates completed.
                                {!result.Success && ' There were some issues that require attention.'}
                            </span>
                        </div>
                    </CardContent>
                </div>
            </Card>

            {/* Revenue Breakdown (shown only on success) */}
            {result.Success && result.ClientRevenueBreakdown && (
                <Card className={`${getDashboardCardClass('accent', isDarkMode)} w-full`}>
                    <div className={getCardAnimationClass('accent')}>
                        <RevenueBreakdownSection
                            colorFamily={colorFamily}
                            clientRevenueData={result.ClientRevenueBreakdown}
                            totalCompanyRevenue={result.TotalCompanyRevenue ?? 0}
                            totalCompanyUnits={result.TotalCompanyUnits ?? 0}
                            pricePerUnit={result.PricePerUnit ?? 0}
                            formattedRevenueBreakdown={result.FormattedRevenueBreakdown ?? ''}
                            onDownload={onDownload}
                            onReset={onReset}
                        />
                    </div>
                </Card>
            )}

            {/* Missing Clients Section */}
            {result.MissingClients.length > 0 && (
                <Card className={`${getDashboardCardClass('warning', isDarkMode)} w-full`}>
                    <div className={getCardAnimationClass('warning')}>
                        <MissingClientsSection
                            missingClients={result.MissingClients}
                            formattedMissingClientsJson={result.FormattedMissingClientsJson ?? ''}
                        />
                    </div>
                </Card>
            )}

            {/* Missing Products Section */}
            {result.MissingProducts.length > 0 && (
                <Card className={`${getDashboardCardClass('secondary', isDarkMode)} w-full`}>
                    <div className={getCardAnimationClass('secondary')}>
                        <MissingProductsSection
                            missingProducts={result.MissingProducts}
                        />
                    </div>
                </Card>
            )}
        </div>
    );
};
