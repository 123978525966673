import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';
import type { MetricsTimeframe, TimeframeType } from '@/types/metrics-types';
import * as React from 'react';

type TimeframeSelectorProps = {
  selected: TimeframeType;
  onChange: (value: TimeframeType) => void;
  className?: string;
}

const timeframeOptions: Array<{ value: MetricsTimeframe; label: string }> = [
  { value: 'today', label: 'Today' },
  { value: 'thisWeek', label: 'This Week' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'lastMonth', label: 'Last Month' },
  { value: 'thisQuarter', label: 'This Quarter' },
  { value: 'thisYear', label: 'This Year' },
];

export const TimeframeSelector: React.FC<TimeframeSelectorProps> = ({
  selected,
  onChange,
  className,
}) => {
  // Directly handle changes without debounce to avoid errors
  const handleValueChange = React.useCallback((value: string) => {
    if (timeframeOptions.some(option => option.value === value)) {
      onChange(value as TimeframeType);
    }
  }, [onChange]);

  return (
    <div className="relative">
      <Select
        value={selected}
        onValueChange={handleValueChange}
      >
        <SelectTrigger
          className={cn(
            "w-[180px] focus:ring-accent-500 transition-colors duration-200",
            "hover:bg-accent-50 dark:hover:bg-accent-900/20",
            className
          )}
        >
          <SelectValue placeholder="Select timeframe" />
        </SelectTrigger>
        <SelectContent className="z-50">
          {timeframeOptions.map((option) => (
            <SelectItem
              key={option.value}
              value={option.value}
              className="cursor-pointer transition-colors duration-200 hover:bg-accent-50 dark:hover:bg-accent-400 focus:bg-accent-100 dark:focus:bg-accent-400"
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
