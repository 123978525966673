/**
 * Chart Utilities
 * 
 * Utility functions for working with chart data and styling
 * These are generic utilities that work with the data structures
 * defined in src/types/chart-types.ts
 */

import type { PieChartDataItem, TimeSeriesData } from '@/types/chart-types';
import { tailwindColors } from '@/lib/tailwind-colors';

/**
 * Get status-specific color 
 */
export const getStatusColor = (
  status: 'approved' | 'notApproved',
  isDarkMode = false
): string => {
  if (status === 'approved') {
    return isDarkMode ? tailwindColors.accent[300] : tailwindColors.accent[500];
  }
  return isDarkMode ? tailwindColors.warning[400] : tailwindColors.warning[500];
};

/**
 * Format number with currency
 */
export const formatCurrency = (
  value: number,
  options: Intl.NumberFormatOptions = {}
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options
  }).format(value);
};

/**
 * Calculate percentage of a value in a set of data items
 */
export const calculatePercentage = (
  value: number, 
  data: PieChartDataItem[]
): string => {
  const total = data.reduce((sum, item) => sum + item.value, 0);
  if (total === 0) return '0%';
  return `${Math.round((value / total) * 100)}%`;
};

/**
 * Format a date for charts
 */
export const formatChartDate = (
  dateString: string,
  format: 'short' | 'medium' | 'long' = 'short'
): string => {
  const date = new Date(dateString);
  
  switch (format) {
    case 'medium':
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });
    case 'long':
      return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    case 'short':
    default:
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
};

/**
 * Process time series data for charts
 */
export const processTimeSeriesData = (
  data: TimeSeriesData[]
): { dates: string[]; submissions: number[]; approved: number[]; deferred: number[] } => {
  return {
    dates: data.map(item => formatChartDate(item.date)),
    submissions: data.map(item => item.submissions),
    approved: data.map(item => item.approved),
    deferred: data.map(item => item.deferred)
  };
};

/**
 * Generate chart colors from the application color palette
 * @param count Number of colors needed
 * @param colorFamily The color family to use for the palette
 * @param isDarkMode Whether dark mode is active
 */
export const generateChartColors = (
  count: number,
  colorFamily: 'primary' | 'secondary' | 'accent' | 'warning' = 'primary',
  isDarkMode = false
): string[] => {
  let basePalette: string[] = [];
  
  // Select the right color family
  switch (colorFamily) {
    case 'primary':
      basePalette = [
        isDarkMode ? tailwindColors.primary[600] : tailwindColors.primary[500],
        isDarkMode ? tailwindColors.primary[500] : tailwindColors.primary[400],
        isDarkMode ? tailwindColors.primary[700] : tailwindColors.primary[600],
        isDarkMode ? tailwindColors.primary[400] : tailwindColors.primary[300],
        isDarkMode ? tailwindColors.primary[800] : tailwindColors.primary[700]
      ];
      break;
    case 'secondary':
      basePalette = [
        isDarkMode ? tailwindColors.secondary[600] : tailwindColors.secondary[500],
        isDarkMode ? tailwindColors.secondary[500] : tailwindColors.secondary[400],
        isDarkMode ? tailwindColors.secondary[700] : tailwindColors.secondary[600],
        isDarkMode ? tailwindColors.secondary[400] : tailwindColors.secondary[300],
        isDarkMode ? tailwindColors.secondary[800] : tailwindColors.secondary[700]
      ];
      break;
    case 'accent':
      basePalette = [
        isDarkMode ? tailwindColors.accent[600] : tailwindColors.accent[500],
        isDarkMode ? tailwindColors.accent[500] : tailwindColors.accent[400],
        isDarkMode ? tailwindColors.accent[700] : tailwindColors.accent[600],
        isDarkMode ? tailwindColors.accent[400] : tailwindColors.accent[300],
        isDarkMode ? tailwindColors.accent[800] : tailwindColors.accent[700]
      ];
      break;
    case 'warning':
      basePalette = [
        isDarkMode ? tailwindColors.warning[600] : tailwindColors.warning[500],
        isDarkMode ? tailwindColors.warning[500] : tailwindColors.warning[400],
        isDarkMode ? tailwindColors.warning[700] : tailwindColors.warning[600],
        isDarkMode ? tailwindColors.warning[400] : tailwindColors.warning[300],
        isDarkMode ? tailwindColors.warning[800] : tailwindColors.warning[700]
      ];
      break;
  }
  
  // If we need more colors than we have in the base palette, cycle through them
  const colors: string[] = [];
  for (let i = 0; i < count; i++) {
    colors.push(basePalette[i % basePalette.length]);
  }
  
  return colors;
};

/**
 * Generate a distinct color palette when multiple categories need to be visually separated
 */
export const generateDistinctColors = (
  count: number,
  isDarkMode = false
): string[] => {
  // Define a set of distinct colors from different color families
  const distinctColors = isDarkMode
    ? [
        tailwindColors.primary[600],
        tailwindColors.secondary[600],
        tailwindColors.accent[600],
        tailwindColors.warning[600],
        tailwindColors.primary[500],
        tailwindColors.secondary[500],
        tailwindColors.accent[500],
        tailwindColors.warning[500]
      ]
    : [
        tailwindColors.primary[500],
        tailwindColors.secondary[500],
        tailwindColors.accent[500],
        tailwindColors.warning[500],
        tailwindColors.primary[600],
        tailwindColors.secondary[600],
        tailwindColors.accent[600],
        tailwindColors.warning[600]
      ];
  
  // If we need more colors than we have in our set, cycle through them
  const colors: string[] = [];
  for (let i = 0; i < count; i++) {
    colors.push(distinctColors[i % distinctColors.length]);
  }
  
  return colors;
};
