// src/components/clients/ClientDetailsPage.tsx
import type React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Edit, UserCog, Trash2, PowerOff, Shield, ChevronRight } from '@/components/ui/icon';
import { ProductBadge } from '@/utils/product-registry';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import type { ClientType, Employer, ServiceProvider, Product } from '@/types';

// Type guard to determine if client is an Employer
const isEmployer = (client: Employer | ServiceProvider): client is Employer => {
  return 'hasAgreement' in client;
};

const ClientDetailsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [client, setClient] = useState<Employer | ServiceProvider | null>(null);
  const [clientType, setClientType] = useState<ClientType>('employer');

  useEffect(() => {
    if (location.state) {
      const { client, clientType } = location.state as { 
        client: Employer | ServiceProvider; 
        clientType: ClientType;
      };
      setClient(client);
      setClientType(clientType);
    }
  }, [location.state]);

  // Scroll reset
  useEffect(() => {
    // Use setTimeout to ensure this runs after rendering completes
    const scrollTimeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto' // Using 'auto' instead of 'smooth' for immediate reset
      });
    }, 0);
    
    // Clean up the timeout if the component unmounts
    return () => { clearTimeout(scrollTimeout); };
  }, [client]); 

  if (!client) {
    return null;
  }

  const clientIsEmployer = clientType === 'employer';

  // Format creation date
  const formattedDate = new Date(client.creationTime).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  // Extract properties to display based on client type
  const displayProperties = Object.entries(client)
    .filter(([key]: [string, unknown]) => 
      !['products', 'id', 'name', 'creationTime', 'creatorPrn', 'prn'].includes(key)
    )
    .sort();

  return (
    <div className="space-y-6">
      {/* Breadcrumbs */}
      <nav className="flex items-center text-sm text-blackish-400 dark:text-blackish-300">
        <button 
          onClick={() => { navigate('/clients'); }}
          className="hover:text-primary-500 transition-colors"
        >
          Clients
        </button>
        <ChevronRight className="mx-2 h-4 w-4" />
      </nav>
      
      {/* Header with actions */}
      <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-4">
        <div>
          <h1 className="text-2xl font-bold text-blackish-900 dark:text-white">
            {client.name}
          </h1>
          <p className="text-sm text-blackish-500 dark:text-blackish-300">
            Created {formattedDate}
          </p>
        </div>
        <div className="flex flex-wrap gap-2">
          <Button 
            variant="outline" 
            size="sm" 
            className="flex items-center gap-2 hover:bg-blackish-800 hover:text-white transition-colors"
          >
            <Edit size={16} />
            <span>Edit</span>
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            className="flex items-center gap-2 text-warning-600 dark:text-warning-400 hover:bg-warning-600 hover:text-white transition-colors"
          >
            <PowerOff size={16} />
            <span>{client.isDisabled ? 'Enable' : 'Disable'}</span>
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            className="flex items-center gap-2 text-destructive hover:bg-destructive hover:text-white transition-colors"
          >
            <Trash2 size={16} />
            <span>Delete</span>
          </Button>
        </div>
      </div>

      {/* Status indicators */}
      <div className="flex flex-wrap gap-2">
        <Badge variant={client.isDisabled ? "destructive" : "success"} className="px-3 py-1 text-sm font-medium">
          {client.isDisabled ? "Disabled" : "Active"}
        </Badge>
        {client.isTest && (
          <Badge variant="warning" className="px-3 py-1 text-sm">Test Account</Badge>
        )}
        {client.mfaRequired && (
          <Badge variant="secondary" className="px-3 py-1 text-sm">MFA Required</Badge>
        )}
        {clientIsEmployer && isEmployer(client) && client.hasAgreement && (
          <Badge variant="outline" className="bg-primary-50 text-primary-700 dark:bg-primary-950 dark:text-primary-300 border-primary-200 dark:border-primary-800 px-3 py-1 text-sm">
            Has Agreement
          </Badge>
        )}
      </div>

      {/* Main content */}
      <div className="space-y-6 pt-4">

        {/* General Settings */}
        <Card>
          <CardHeader>
            <CardTitle className="text-lg flex items-center gap-2">
              <UserCog size={18} />
              General Settings
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
              {displayProperties.map(([key, value]) => (
                <div key={key}>
                  <h3 className="text-sm font-medium text-blackish-500 dark:text-blackish-300 mb-1 capitalize">
                    {key.replace(/([A-Z])/g, ' $1').trim()}
                  </h3>
                  {typeof value === 'boolean' ? (
                    <Badge variant={value ? "success" : "outline"} className="mt-1">
                      {value ? 'Enabled' : 'Disabled'}
                    </Badge>
                  ) : value === '' ? (
                    <span className="text-sm text-blackish-400 dark:text-blackish-500 italic">
                      Not set
                    </span>
                  ) : (
                    <p className="text-blackish-800 dark:text-white">
                      {String(value)}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Products (Only for employers) */}
        {clientIsEmployer && isEmployer(client) && client.products && (
          <Card>
            <CardHeader>
              <CardTitle className="text-lg flex items-center gap-2">
                <Shield size={18} />
                Assigned Products
              </CardTitle>
            </CardHeader>
            <CardContent>
              {isEmployer(client) && client.products.length > 0 ? (
                <div className="flex flex-wrap gap-4">
                  {isEmployer(client) && client.products.map((product: Product, index: number) => {
                    const productKey = product.key || '';
                    // Product information is provided directly through the ProductBadge component
                    
                    // Get product description based on product key
                    let description = '';
                    if (productKey === 'RESPIRATOR_MEDICAL_EVALUATION') {
                      description = 'Respirator medical evaluation questionnaire for workplace compliance';
                    } else if (productKey === 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE') {
                      description = 'Medical surveillance for workers exposed to ground cannabis dust';
                    } else if (productKey === 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE' || 
                               productKey === 'LAB_ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE') {
                      description = 'Surveillance program for laboratory workers with animal exposure';
                    } else {
                      description = productKey.replace(/_/g, ' ').toLowerCase();
                    }
                    
                    // Display name based on definition or fallback - not currently used
                    // const displayName = productDefinition?.name || productName;
                    
                    return (
                      <div key={index} className="p-4 rounded-md border border-gray-200 dark:border-blackish-600 w-full">
                        <div className="flex items-center gap-2 mb-2">
                          {/* Use ProductBadge with showName=true for a more descriptive badge */}
                          <ProductBadge 
                            productType={productKey} 
                            showName={true} 
                            className="px-3 py-1.5 text-sm font-medium"
                          />
                          <Badge variant={product.status === 'Active' ? "success" : "outline"} className="ml-auto">
                            {product.status || 'Active'}
                          </Badge>
                        </div>
                        <div className="mt-3 text-sm text-blackish-500 dark:text-blackish-300">
                          {description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center py-6 text-blackish-400 dark:text-blackish-500">
                  No products assigned
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default ClientDetailsPage;
