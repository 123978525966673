/**
 * Metrics Service
 * 
 * This service provides access to company metrics and revenue data from 
 * a static JSON file. It simulates different timeframes by filtering or
 * transforming the base data.
 */
import rawMetricsData from '@/data/metrics.json';
import type { CompanyMetrics, TimeframeType } from '@/types';

/**
 * Fetch metrics based on the given timeframe
 * In a real implementation, this would call an API with the timeframe parameter
 * For now, we just simulate different metrics for different timeframes based on the static data
 */
export function fetchMetrics(timeframe: TimeframeType): Promise<CompanyMetrics> {
  // Cast the imported JSON to the correct type
  const baseMetrics = rawMetricsData as CompanyMetrics;
  let metrics: CompanyMetrics;

  // Adjust metrics based on timeframe
  switch (timeframe) {
    case 'today':
      // For demonstration, reduce the numbers significantly for "today" view
      metrics = {
        ...baseMetrics,
        timeframe,
        totalSubmissions: Math.round(baseMetrics.totalSubmissions / 30),
        pendingSubmissions: Math.round((baseMetrics.pendingSubmissions ?? 0) / 30),
        approvalRate: baseMetrics.approvalRate + (Math.random() * 5 - 2.5),
        avgProcessingTime: (baseMetrics.avgProcessingTime ?? 0) * 0.9,
        revenue: {
          total: Math.round((baseMetrics.revenue?.total ?? 0) / 30),
          employer: Math.round((baseMetrics.revenue?.employer ?? 0) / 30),
          serviceProvider: Math.round((baseMetrics.revenue?.serviceProvider ?? 0) / 30),
          pricePerUnit: Math.round((baseMetrics.revenue?.pricePerUnit ?? 0) / 30),
          change: baseMetrics.revenue?.change ?? 0,
          averageRevenue: Math.round((baseMetrics.revenue?.averageRevenue ?? 0) / 30),
          topClients: baseMetrics.revenue?.topClients ?? [],
          byProduct: baseMetrics.revenue?.byProduct ?? []
        }
      };
      break;

    case 'thisWeek':
      // For demonstration, reduce the numbers for "this week" view
      metrics = {
        ...baseMetrics,
        timeframe,
        totalSubmissions: Math.round(baseMetrics.totalSubmissions / 4),
        pendingSubmissions: Math.round((baseMetrics.pendingSubmissions ?? 0) / 4),
        revenue: {
          total: Math.round((baseMetrics.revenue?.total ?? 0) / 4),
          employer: Math.round((baseMetrics.revenue?.employer ?? 0) / 4),
          serviceProvider: Math.round((baseMetrics.revenue?.serviceProvider ?? 0) / 4),
          pricePerUnit: (baseMetrics.revenue?.pricePerUnit ?? 0),
          change: (baseMetrics.revenue?.change ?? 0),
          averageRevenue: Math.round((baseMetrics.revenue?.averageRevenue ?? 0) / 4),
          topClients: baseMetrics.revenue?.topClients ?? [],
          byProduct: baseMetrics.revenue?.byProduct ?? []
        }
      };
      break;

    case 'lastMonth':
      // For demonstration, slight variations for last month
      metrics = {
        ...baseMetrics,
        timeframe,
        totalSubmissions: Math.round(baseMetrics.totalSubmissions * 0.9),
        pendingSubmissions: Math.round((baseMetrics.pendingSubmissions ?? 0) * 1.1),
        approvalRate: baseMetrics.approvalRate - 2,
        avgProcessingTime: (baseMetrics.avgProcessingTime ?? 0) * 1.1,
        revenue: {
          total: Math.round((baseMetrics.revenue?.total ?? 0) * 0.95),
          employer: Math.round((baseMetrics.revenue?.employer ?? 0) * 0.93),
          serviceProvider: Math.round((baseMetrics.revenue?.serviceProvider ?? 0) * 0.97),
          pricePerUnit: (baseMetrics.revenue?.pricePerUnit ?? 0),
          change: -3.2,
          averageRevenue: Math.round(((baseMetrics.revenue?.averageRevenue ?? 0) * 0.95)),
          topClients: baseMetrics.revenue?.topClients ?? [],
          byProduct: baseMetrics.revenue?.byProduct ?? []
        }
      };
      break;

    case 'thisQuarter':
      // For demonstration, increase the numbers for "this quarter" view
      metrics = {
        ...baseMetrics,
        timeframe,
        totalSubmissions: baseMetrics.totalSubmissions * 3,
        pendingSubmissions: (baseMetrics.pendingSubmissions ?? 0) * 3,
        revenue: {
          total: (baseMetrics.revenue?.total ?? 0) * 3,
          employer: (baseMetrics.revenue?.employer ?? 0) * 3,
          serviceProvider: (baseMetrics.revenue?.serviceProvider ?? 0) * 3,
          pricePerUnit: (baseMetrics.revenue?.pricePerUnit ?? 0),
          change: (baseMetrics.revenue?.change ?? 0),
          averageRevenue: ((baseMetrics.revenue?.averageRevenue ?? 0) * 3),
          topClients: baseMetrics.revenue?.topClients ?? [],
          byProduct: baseMetrics.revenue?.byProduct ?? []
        }
      };
      break;

    case 'thisYear':
      // For demonstration, significantly increase the numbers for "this year" view
      metrics = {
        ...baseMetrics,
        timeframe,
        totalSubmissions: baseMetrics.totalSubmissions * 12,
        pendingSubmissions: (baseMetrics.pendingSubmissions ?? 0) * 12,
        revenue: {
          total: (baseMetrics.revenue?.total ?? 0) * 12,
          employer: (baseMetrics.revenue?.employer ?? 0) * 12,
          serviceProvider: (baseMetrics.revenue?.serviceProvider ?? 0) * 12,
          pricePerUnit: (baseMetrics.revenue?.pricePerUnit ?? 0),
          change: (baseMetrics.revenue?.change ?? 0),
          averageRevenue: ((baseMetrics.revenue?.averageRevenue ?? 0) * 12),
          topClients: baseMetrics.revenue?.topClients ?? [],
          byProduct: baseMetrics.revenue?.byProduct ?? []
        }
      };
      break;

    case 'thisMonth':
    default:
      // Use base metrics for "this month" (default case)
      metrics = {
        ...baseMetrics,
        timeframe
      };
      break;
  }

  // Filter timeSeriesData based on timeframe if needed
  // This would typically come from an API with the appropriate date range

  // Return as a promise to simulate async API call
  return Promise.resolve(metrics);
}

// Export a metrics service object with methods
export const metricsService = {
  fetchMetrics
};
