import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useDarkModeDetection } from '@/hooks/useDarkModeDetection';
import { cn } from '@/lib/utils';
import type { StyledCardProps } from '@/types/app-style-types';
import { getAppCardClass, getCardAnimationClass } from '@/utils/appStyleUtils';
import * as React from 'react';

/**
 * StyledCard component with consistent styling for use across the application
 */
export const StyledCard: React.FC<StyledCardProps> = ({
  title,
  icon,
  colorFamily,
  children,
  className,
  description,
  headerClassName,
  contentClassName,
  footerContent
}) => {
  // Use the dark mode detection hook
  const { isDarkMode } = useDarkModeDetection();

  // Get the appropriate card class based on the color family
  const cardClass = getAppCardClass(colorFamily, isDarkMode);

  // Get the card animation class if available
  const animationClass = getCardAnimationClass(colorFamily);

  return (
    <Card className={cn(cardClass, className)}>
      <div className={cn(animationClass, 'h-full')}>
        <CardHeader className={cn('pb-2 relative z-10', headerClassName)}>
          <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center justify-between">
            <div className="flex items-center">
              {React.cloneElement(icon as React.ReactElement, {
                size: 18,
                className: `text-${colorFamily}-600 mr-2`
              })}
              {title}
            </div>
          </CardTitle>
          {description && (
            <CardDescription className="text-xs text-blackish-500 dark:text-blackish-400">
              {description}
            </CardDescription>
          )}
        </CardHeader>

        <CardContent className={cn(contentClassName)}>
          {children}
        </CardContent>

        {footerContent && (
          <CardFooter className="pt-2 border-t border-blackish-100 dark:border-blackish-800">
            {footerContent}
          </CardFooter>
        )}
      </div>
    </Card>
  );
};

export default StyledCard;
