import type * as React from 'react';
import { useState, useEffect } from 'react';

/**
 * Interface for security header report information
 */
type SecurityHeaderReport = {
  name: string;
  present: boolean;
  value: string | null;
  description: string;
  recommendation: string;
  severity: 'high' | 'medium' | 'low';
}

/**
 * Component to audit security headers in the application
 */
export const SecurityHeadersAudit: React.FC = () => {
  const [headerReports, setHeaderReports] = useState<SecurityHeaderReport[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkSecurityHeaders = async (): Promise<void> => {
      try {
        setLoading(true);

        // Fetch the current page to analyze its headers
        const response = await fetch(window.location.href);
        const headers = response.headers;

        // Define all headers we want to check
        const headersToCheck: SecurityHeaderReport[] = [
          {
            name: 'Content-Security-Policy',
            present: false,
            value: null,
            description: 'Controls resources the user agent is allowed to load for a page',
            recommendation: 'Implement a strict CSP to prevent XSS and data injection attacks',
            severity: 'high'
          },
          {
            name: 'Strict-Transport-Security',
            present: false,
            value: null,
            description: 'Prevents downgrade attacks by enforcing HTTPS',
            recommendation: 'Set max-age=63072000; includeSubDomains; preload',
            severity: 'high'
          },
          {
            name: 'X-Content-Type-Options',
            present: false,
            value: null,
            description: 'Prevents MIME type sniffing',
            recommendation: 'Set to nosniff',
            severity: 'medium'
          },
          {
            name: 'X-Frame-Options',
            present: false,
            value: null,
            description: 'Prevents clickjacking by controlling framing',
            recommendation: 'Set to DENY or SAMEORIGIN',
            severity: 'medium'
          },
          {
            name: 'Referrer-Policy',
            present: false,
            value: null,
            description: 'Controls referrer information sent with requests',
            recommendation: 'Set to strict-origin-when-cross-origin',
            severity: 'medium'
          },
          {
            name: 'Permissions-Policy',
            present: false,
            value: null,
            description: 'Controls browser features and APIs in the page',
            recommendation: 'Restrict unnecessary browser features',
            severity: 'medium'
          }
        ];

        // Check each header
        const reports = headersToCheck.map(header => {
          const value = headers.get(header.name);
          return {
            ...header,
            present: value !== null,
            value: value
          };
        });

        setHeaderReports(reports);
      } catch {
        setError('Failed to check security headers.');
      } finally {
        setLoading(false);
      }
    };

    checkSecurityHeaders();
  }, []);

  // Function to determine the CSS class based on severity
  const getSeverityClass = (severity: string): string => {
    switch (severity) {
      case 'high':
        return 'text-red-500 dark:text-red-400';
      case 'medium':
        return 'text-orange-500 dark:text-orange-400';
      case 'low':
        return 'text-yellow-500 dark:text-yellow-400';
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <div className="space-y-6 w-full max-w-full px-4 sm:px-5 md:px-6">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded col-span-2"></div>
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded col-span-1"></div>
              </div>
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="space-y-6 w-full max-w-full px-4 sm:px-5 md:px-6">
        <div className="p-4 border border-red-500 rounded bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-300">
          <h3 className="text-lg font-bold">Error</h3>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6 w-full max-w-full px-4 sm:px-5 md:px-6">
      <div>
        <h2 className="text-2xl font-bold mb-2">Security Headers Audit</h2>
        <p className="text-gray-500 dark:text-gray-400 mb-4">
          This audit checks for the presence and configuration of important security headers.
        </p>
      </div>

      {headerReports.length === 0 ? (
        <p>No security headers were evaluated.</p>
      ) : (
        <div className="space-y-6">
          {headerReports.map((header, index) => (
            <div key={index} className="border rounded-lg overflow-hidden">
              <div className={`p-4 border-b ${header.present ? 'bg-green-50 dark:bg-green-900/20' : 'bg-red-50 dark:bg-red-900/20'}`}>
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">{header.name}</h3>
                  <span className={`px-2 py-1 rounded text-sm ${header.present ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'}`}>
                    {header.present ? 'Present' : 'Missing'}
                  </span>
                </div>
                {header.value && (
                  <div className="mt-2 p-2 bg-gray-50 dark:bg-gray-800 rounded border border-gray-200 dark:border-gray-700 overflow-x-auto">
                    <code className="text-sm">{header.value}</code>
                  </div>
                )}
              </div>

              <div className="p-4 space-y-2 bg-white dark:bg-gray-900">
                <div>
                  <h4 className="font-medium text-gray-700 dark:text-gray-300">Description</h4>
                  <p className="text-gray-600 dark:text-gray-400">{header.description}</p>
                </div>

                <div>
                  <h4 className="font-medium text-gray-700 dark:text-gray-300">Recommendation</h4>
                  <p className={getSeverityClass(header.severity)}>
                    <span className="font-medium">[{header.severity.toUpperCase()} PRIORITY]</span> {header.recommendation}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mt-6 p-4 bg-blue-50 dark:bg-blue-900/20 rounded border border-blue-200 dark:border-blue-800">
        <h3 className="text-lg font-semibold text-blue-700 dark:text-blue-300">Implementation Resources</h3>
        <ul className="list-disc list-inside text-blue-600 dark:text-blue-400 mt-2">
          <li>
            <a 
              href="https://content-security-policy.com/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="underline hover:text-blue-800 dark:hover:text-blue-200"
            >
              Content Security Policy Reference
            </a>
          </li>
          <li>
            <a 
              href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Strict-Transport-Security" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="underline hover:text-blue-800 dark:hover:text-blue-200"
            >
              MDN: Strict-Transport-Security
            </a>
          </li>
          <li>
            <a 
              href="https://owasp.org/www-project-secure-headers/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="underline hover:text-blue-800 dark:hover:text-blue-200"
            >
              OWASP: Secure Headers Project
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
