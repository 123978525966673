import type * as React from 'react';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { X } from '@/components/ui/icon';
import { cn } from '@/lib/utils';

// Define ToastVariant locally
type ToastVariant = 'default' | 'success' | 'warning' | 'destructive';

type ToastProps = {
  id: string;
  title: string;
  description?: string;
  variant?: ToastVariant;
  duration?: number;
  onClose: (id: string) => void;
};

const Toast: React.FC<ToastProps> = ({
  id,
  title,
  description,
  variant = 'default',
  duration = 5000,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(id);
    }, duration);

    return () => { clearTimeout(timer); };
  }, [id, duration, onClose]);

  return (
    <div
      className={cn(
        'pointer-events-auto relative flex w-full max-w-md rounded-lg border p-4 pr-8 shadow-lg transition-all',
        variant === 'default' && 'bg-white text-gray-900 dark:bg-gray-800 dark:text-gray-100',
        variant === 'success' && 'bg-green-50 text-green-900 dark:bg-green-900/20 dark:text-green-300',
        variant === 'warning' && 'bg-yellow-50 text-yellow-900 dark:bg-yellow-900/20 dark:text-yellow-300',
        variant === 'destructive' && 'bg-red-50 text-red-900 dark:bg-red-900/20 dark:text-red-300'
      )}
    >
      <div className="grid gap-1">
        <div className="text-sm font-semibold">{title}</div>
        {description && (
          <div className="text-sm opacity-90">{description}</div>
        )}
      </div>
      <button
        onClick={() => { onClose(id); }}
        className="absolute right-2 top-2 rounded-md p-1 text-gray-600 opacity-70 transition-opacity hover:opacity-100 dark:text-gray-400"
      >
        <X size={14} />
      </button>
    </div>
  );
};

type ToastData = Omit<ToastProps, 'onClose'> & { id: string };

export const ToasterNew: React.FC = () => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  // Add this to the window object so it can be used from anywhere
  useEffect(() => {
    const showToast = (
      title: string,
      options: {
        description?: string;
        variant?: ToastVariant;
        duration?: number;
      } = {}
    ) => {
      const id = `toast-${Date.now()}`;
      setToasts((prev) => [
        ...prev,
        {
          id,
          title,
          ...options,
        },
      ]);
    };

  // Assign the showToast function to window
  window.showToast = showToast;

  return () => {
    // Clean up by removing the showToast function
    window.showToast = undefined;
  };
  }, []);

  const removeToast = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return createPortal(
    <div className="fixed top-0 z-[100] flex flex-col gap-2 p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col-reverse">
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} onClose={removeToast} />
      ))}
    </div>,
    document.body
  );
};

// This ensures type compatibility by exporting the toast function type
export type ShowToastFunction = (
  title: string,
  options?: {
    description?: string;
    variant?: ToastVariant;
    duration?: number;
  }
) => void;
