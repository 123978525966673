import { useAutoAnimate } from '@/hooks/useAutoAnimate';
import type * as React from 'react';
import { DashboardProvider, useDashboard } from './DashboardContext';
import { MetricsSection } from './MetricsSection';
import { RevenueSection } from './RevenueSection';
import { TimeframeSelector } from './TimeframeSelector';

// The inner dashboard component that uses the dashboard context
const DashboardContent: React.FC = (): JSX.Element => {
  const {
    timeframe,
    setTimeframe,
    metricsData,
    isMetricsLoading,
    isRevenueLoading,
    metricsError,
    revenueError,
    refetchMetrics,
    refetchRevenue,
  } = useDashboard();

  const containerRef = useAutoAnimate<HTMLDivElement>();

  return (
    <div ref={containerRef} className="space-y-6 w-full max-w-full px-3 pt-2 sm:px-4 md:px-6 md:pt-0">
      {/* Page Header - With timeframe selector */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 border-b pb-4">
        <h2 className="text-2xl font-bold text-blackish-800 dark:text-white">Executive Dashboard <span className="text-sm">(mock data)</span></h2>
        {/* Only show TimeframeSelector when not loading and no error */}
        {!isMetricsLoading && !metricsError && (
          <TimeframeSelector
            selected={timeframe}
            onChange={(value) => { setTimeframe(value); }}
            className="w-full sm:w-auto md:max-w-[320px]"
          />
        )}
      </div>

      {/* Content Organized by Priority */}
      <div className="space-y-8">
        {/* Priority 1: Revenue Section */}
        <RevenueSection
          data={metricsData}
          isLoading={isRevenueLoading}
          error={revenueError}
          onRetry={refetchRevenue}
        />

        {/* Priority 2: Metrics Section */}
        <MetricsSection
          data={metricsData}
          isLoading={isMetricsLoading}
          error={metricsError}
          onRetry={refetchMetrics}
        />

      </div>
    </div>
  );
};

// The main dashboard component that wraps the content with the provider
export const Dashboard: React.FC = () => {
  return (
    <DashboardProvider>
      <DashboardContent />
    </DashboardProvider>
  );
};
