import type React from "react";
import type { 
  ClientRevenueData
} from "@/types/invoice-pricing-types";
import { Card, CardContent } from "@/components/ui/card";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { formatCurrency } from "@/lib/utils";

type RevenueBreakdownDisplayProps = {
  clientRevenueData: ClientRevenueData[];
  totalCompanyRevenue: number;
  totalCompanyUnits: number;
  pricePerUnit: number;
}

export const RevenueBreakdownDisplay: React.FC<RevenueBreakdownDisplayProps> = ({
  clientRevenueData,
  totalCompanyRevenue,
  totalCompanyUnits,
  pricePerUnit
}) => {
  const currentDate = new Date();
  const dayOfMonth = currentDate.getDate();
  const monthName = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();
  
  // Calculate average daily revenue
  const avgDailyRevenue = totalCompanyRevenue / dayOfMonth;
  
  return (
    <div className="space-y-6">
      {/* Company Summary */}
      <div className="bg-white dark:bg-blackish-800 border rounded-lg p-4 shadow-sm">
        <h3 className="text-lg font-semibold text-blackish-900 dark:text-white mb-4">
          Revenue Summary - {monthName} {year}
        </h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <div className="text-2xl font-bold text-primary-600 dark:text-primary-400">
              {formatCurrency(totalCompanyRevenue)}
            </div>
            <div className="text-sm text-blackish-500 dark:text-blackish-300">
              Total Company Revenue
            </div>
          </div>
          
          <div className="space-y-2">
            <div className="text-xl font-semibold text-blackish-700 dark:text-blackish-200">
              {formatCurrency(avgDailyRevenue)}
              <span className="text-sm text-blackish-500 dark:text-blackish-300 ml-1">
                / day
              </span>
            </div>
            <div className="text-sm text-blackish-500 dark:text-blackish-300">
              Average Daily Revenue (Day {dayOfMonth})
            </div>
          </div>
          
          <div className="space-y-2">
            <div className="text-xl font-semibold text-blackish-700 dark:text-blackish-200">
              {formatCurrency(pricePerUnit)}
            </div>
            <div className="text-sm text-blackish-500 dark:text-blackish-300">
              Price Per Unit (PPU)
            </div>
          </div>
          
          <div className="space-y-2">
            <div className="text-xl font-semibold text-blackish-700 dark:text-blackish-200">
              {totalCompanyUnits.toLocaleString()}
            </div>
            <div className="text-sm text-blackish-500 dark:text-blackish-300">
              Total Units
            </div>
          </div>
        </div>
      </div>
      
      {/* Client Breakdown Section */}
      <div>
        <h3 className="text-lg font-semibold text-blackish-900 dark:text-white mb-4">
          Client Revenue Breakdown
        </h3>
        
        <div className="space-y-4">
          {clientRevenueData.map((client, index) => (
            <ClientRevenueCard key={index} clientData={client} />
          ))}
        </div>
      </div>
    </div>
  );
};

type ClientRevenueCardProps = {
  clientData: ClientRevenueData;
}

const ClientRevenueCard: React.FC<ClientRevenueCardProps> = ({ clientData }) => {
  // Calculate the percentage of revenue for each product
  const totalRevenue = clientData.TotalRevenue;
  const productsWithPercentage = clientData.Products.map(product => ({
    ...product,
    percentage: (product.Revenue / totalRevenue) * 100
  }));
  
  // Sort products by revenue (highest first)
  const sortedProducts = [...productsWithPercentage].sort((a, b) => b.Revenue - a.Revenue);
  
  return (
    <Card className="overflow-hidden">
      <div className="bg-gradient-to-r from-primary-50 to-primary-100 dark:from-primary-900/20 dark:to-primary-800/20 p-4">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold text-lg text-blackish-800 dark:text-white">
            {clientData.ClientName}
          </h4>
          <div className="text-xl font-bold text-primary-600 dark:text-primary-400">
            {formatCurrency(clientData.TotalRevenue)}
          </div>
        </div>
        
        <div className="flex flex-wrap gap-x-6 gap-y-2 mt-2 text-sm">
          <div className="flex items-center">
            <span className="text-blackish-500 dark:text-blackish-300 mr-1">Daily Avg:</span>
            <span className="font-medium text-blackish-800 dark:text-white">
              {formatCurrency(clientData.AverageDailyRevenue)}
            </span>
          </div>
          
          <div className="flex items-center">
            <span className="text-blackish-500 dark:text-blackish-300 mr-1">Units:</span>
            <span className="font-medium text-blackish-800 dark:text-white">
              {clientData.TotalUnits.toLocaleString()}
            </span>
          </div>
          
          <div className="flex items-center">
            <span className="text-blackish-500 dark:text-blackish-300 mr-1">PPU:</span>
            <span className="font-medium text-blackish-800 dark:text-white">
              {formatCurrency(clientData.TotalRevenue / clientData.TotalUnits)}
            </span>
          </div>
        </div>
      </div>
      
      <CardContent className="p-0">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="hover:bg-transparent">
                <TableHead>Product</TableHead>
                <TableHead>Units</TableHead>
                <TableHead>Unit Price</TableHead>
                <TableHead>Revenue</TableHead>
                <TableHead className="w-[100px]">% of Total</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedProducts.map((product, index) => (
                <TableRow key={index} className="hover:bg-blackish-50 dark:hover:bg-blackish-800/50">
                  <TableCell>
                    <div className="font-medium">{product.ProductName}</div>
                    <div className="text-xs text-blackish-500">{product.ProductCode}</div>
                  </TableCell>
                  <TableCell>{product.Units.toLocaleString()}</TableCell>
                  <TableCell>{formatCurrency(product.UnitPrice)}</TableCell>
                  <TableCell className="font-medium">{formatCurrency(product.Revenue)}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <div className="w-full bg-blackish-100 dark:bg-blackish-700 rounded-full h-2.5">
                        <div 
                          className="bg-primary-600 dark:bg-primary-400 h-2.5 rounded-full" 
                          style={{ width: `${Math.max(product.percentage, 3)}%` }}
                        ></div>
                      </div>
                      <span className="text-xs whitespace-nowrap">
                        {product.percentage.toFixed(1)}%
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};
