// src/utils/invoicePriceUpdaterUtils.ts
import type {
    ClientPricing,
    ClientRevenueData,
    MissingClient,
    MissingProduct,
    PriceUpdateResult
} from "@/types/invoice-pricing-types";
import Papa from 'papaparse';

/**
 * Finds the actual column name in the CSV that matches the required column name
 * Handles cases where the column name might have an asterisk prefix
 */
export const findMatchingColumnName = (fields: string[] | undefined, requiredCol: string): string | undefined => {
    if (!fields) return undefined;

    // Check for exact match first
    if (fields.includes(requiredCol)) {
        return requiredCol;
    }

    // Check for column with asterisk
    const asteriskVersion = `*${requiredCol}`;
    if (fields.includes(asteriskVersion)) {
        return asteriskVersion;
    }

    // No match found
    return undefined;
};

/**
 * Gets the value from a row for a specific column, handling asterisk prefixes
 */
export const getColumnValue = (row: Record<string, string>, columnName: string, fields: string[] | undefined): string => {
    // Try direct access first
    if (row[columnName] !== undefined) {
        return row[columnName];
    }

    // Try with asterisk
    const asteriskVersion = `*${columnName}`;
    if (row[asteriskVersion] !== undefined) {
        return row[asteriskVersion];
    }

    // If field names are available, look for a match
    if (fields) {
        const matchingField = findMatchingColumnName(fields, columnName);
        if (matchingField && row[matchingField] !== undefined) {
            return row[matchingField];
        }
    }

    return '';
};

/**
 * Formats missing clients into a JSON string that matches the client-pricing.json format
 */
export const formatMissingClientsToJson = (missingClients: MissingClient[]): string => {
    // Create a formatted structure for each missing client
    const clientsJson = missingClients.map(client => {
        // Create empty product entries for each product code
        const products = client.ProductCodes.map(code => ({
            ProductCode: code,
            ProductName: "",
            Price: 0
        }));

        // Remove duplicate products by ProductCode
        const uniqueProducts = products.filter((product, index, self) =>
            index === self.findIndex(p => p.ProductCode === product.ProductCode)
        );

        // Return the client structure without Id
        return {
            Name: client.Name,
            Products: uniqueProducts
        };
    });

    // Format with indentation for readability
    return JSON.stringify(clientsJson, null, 2);
};

/**
 * Formats revenue data as markdown text
 */
export const formatRevenueBreakdownMarkdown = (
    clientsData: ClientRevenueData[],
    totalRevenue: number,
    totalUnits: number
): string => {
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();
    const monthName = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    let markdown = `# Revenue Breakdown - ${monthName} ${year}\n\n`;

    // Add total company revenue and metrics
    markdown += `## TOTAL COMPANY REVENUE THIS MONTH: $${totalRevenue.toFixed(2)}\n\n`;
    markdown += `### Daily Average: $${(totalRevenue / dayOfMonth).toFixed(2)}\n`;
    markdown += `### Price Per Unit (PPU): $${totalUnits > 0 ? (totalRevenue / totalUnits).toFixed(2) : '0.00'}\n`;
    markdown += `### Total Units: ${totalUnits.toLocaleString()}\n\n`;

    // Add client breakdowns
    markdown += `## Client Breakdown\n\n`;

    clientsData.forEach(client => {
        markdown += `### ${client.ClientName}\n\n`;
        markdown += `- **Total Revenue:** $${client.TotalRevenue.toFixed(2)}\n`;
        markdown += `- **Average Daily Revenue:** $${client.AverageDailyRevenue.toFixed(2)}\n`;
        markdown += `- **Total Units:** ${client.TotalUnits}\n\n`;

        // Add product table
        markdown += `| Product Code | Product Name | Units | Unit Price | Revenue |\n`;
        markdown += `| ----------- | ------------ | ----- | ---------- | ------- |\n`;

        client.Products.forEach(product => {
            markdown += `| ${product.ProductCode} | ${product.ProductName} | ${product.Units} | $${product.UnitPrice.toFixed(2)} | $${product.Revenue.toFixed(2)} |\n`;
        });

        markdown += `\n`;
    });

    return markdown;
};

/**
 * Validates the structure of the client pricing JSON
 */
export const validateClientPricingJson = (data: unknown): { valid: boolean; error?: string } => {
    if (!Array.isArray(data)) {
        return { valid: false, error: 'Client pricing data must be an array' };
    }

    type ClientRecord = {
        Name: string;
        Products: ProductRecord[];
    }

    type ProductRecord = {
        ProductCode: string;
        ProductName: string;
        Price: number;
    }

    for (let i = 0; i < data.length; i++) {
        const client = data[i] as Partial<ClientRecord>;

        // Check client has required fields
        if (!client.Name || typeof client.Name !== 'string') {
            return { valid: false, error: `Client at index ${i} is missing a valid Name field` };
        }

        if (!Array.isArray(client.Products)) {
            return { valid: false, error: `Client "${client.Name}" has invalid Products (must be an array)` };
        }

        // Check products
        for (let j = 0; j < client.Products.length; j++) {
            const product = client.Products[j];

            if (!product.ProductCode || typeof product.ProductCode !== 'string') {
                return { valid: false, error: `Product at index ${j} for client "${client.Name}" is missing a valid ProductCode` };
            }

            if (!product.ProductName || typeof product.ProductName !== 'string') {
                return { valid: false, error: `Product "${product.ProductCode}" for client "${client.Name}" is missing a valid ProductName` };
            }

            if (typeof product.Price !== 'number' || product.Price < 0) {
                return { valid: false, error: `Product "${product.ProductCode}" for client "${client.Name}" has an invalid Price` };
            }
        }
    }

    return { valid: true };
};

/**
 * Reads a file and returns its content as text
 */
export const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject(new Error('Failed to read file as text'));
            }
        };
        reader.onerror = () => {
            reject(new Error(reader.error?.message ?? 'Error reading file'));
        };
        reader.readAsText(file);
    });
};

/**
 * Processes the CSV with client pricing data and updates prices
 */
export const processCsvWithPricing = (csvContent: string, clientPricingData: ClientPricing[]): PriceUpdateResult => {
    console.log('Processing CSV with pricing data');

    // Parse CSV
    const parsedCsv = Papa.parse(csvContent, { header: true });
    const rows = parsedCsv.data as Array<Record<string, string>>;
    const fields = parsedCsv.meta.fields;

    if (!rows.length) {
        throw new Error('CSV file is empty or invalid');
    }

    // Check if CSV has required columns (accounting for asterisk prefixes)
    const requiredColumns = ['ContactName', 'InventoryItemCode', 'UnitAmount', '*Quantity'];
    const missingColumns = requiredColumns.filter(col => !findMatchingColumnName(fields, col));

    if (missingColumns.length > 0) {
        throw new Error(`CSV is missing required columns: ${missingColumns.join(', ')}`);
    }

    // Track processing results
    const missingClients: MissingClient[] = [];
    const missingClientMap = new Map<string, string[]>(); // Map of client name to product codes
    const missingProducts: MissingProduct[] = [];
    let totalUpdates = 0;

    // Create a map of client names to their pricing data for faster lookup
    const clientMap = new Map<string, ClientPricing>();
    clientPricingData.forEach(client => {
        clientMap.set(client.Name, client);
    });

    // Revenue tracking
    const clientRevenueMap = new Map<string, ClientRevenueData>();
    let totalCompanyRevenue = 0;
    let totalCompanyUnits = 0;

    // Get current day of month for calculating average daily revenue
    const currentDay = new Date().getDate();

    // Process each row
    const updatedRows = rows.map(row => {
        // Get values handling potential asterisk prefixes
        const productCode = getColumnValue(row, 'InventoryItemCode', fields);

        // Skip rows without InventoryItemCode (headers, empty rows, etc.)
        if (!productCode || productCode.trim() === '') {
            return row;
        }

        const clientName = getColumnValue(row, 'ContactName', fields);

        // Skip processing if no client name
        if (!clientName || clientName.trim() === '') {
            return row;
        }

        // Find client in pricing data
        const client = clientMap.get(clientName);

        if (!client) {
            // Track missing client with its product code
            if (!missingClientMap.has(clientName)) {
                missingClientMap.set(clientName, [productCode]);
            } else {
                const existing = missingClientMap.get(clientName);
                if (existing) {
                    existing.push(productCode);
                }
            }
            return row;
        }

        // Find product price
        const product = client.Products.find(p => p.ProductCode === productCode);

        if (!product) {
            // Track missing product
            missingProducts.push({
                ClientName: clientName,
                ProductCode: productCode
            });
            return row;
        }

        // Update price - need to handle asterisk prefixes in the output as well
        const newRow = { ...row };

        // Find the actual UnitAmount field name (with or without asterisk)
        const unitAmountField = findMatchingColumnName(fields, 'UnitAmount') ?? 'UnitAmount';
        newRow[unitAmountField] = product.Price.toString();
        totalUpdates++;

        // --- Begin Revenue Calculations ---

        // Get quantity and calculate revenue
        const quantityField = findMatchingColumnName(fields, '*Quantity') ?? '*Quantity';
        const quantity = parseInt(row[quantityField] ?? '0');
        const unitPrice = product.Price;

        if (quantity > 0 && unitPrice >= 0) {
            const lineItemRevenue = unitPrice * quantity;

            // Get product name, ensuring RMEQ has the right name
            let productName = product.ProductName;
            if (productCode === "RMEQ") {
                productName = "Respirator Medical Evaluation";
            }

            // Initialize client revenue data if not exists
            if (!clientRevenueMap.has(clientName)) {
                clientRevenueMap.set(clientName, {
                    ClientName: clientName,
                    TotalRevenue: 0,
                    AverageDailyRevenue: 0,
                    TotalUnits: 0,
                    Products: []
                });
            }

            // Get client data after ensuring it exists
            const clientData = clientRevenueMap.get(clientName);
            // This should never happen as we just checked and set it if it doesn't exist
            if (!clientData) {
                return newRow;
            }

            // Check if we already have this product
            const existingProductIndex = clientData.Products.findIndex(
                p => p.ProductCode === productCode
            );

            if (existingProductIndex >= 0) {
                // Update existing product
                clientData.Products[existingProductIndex].Units += quantity;
                clientData.Products[existingProductIndex].Revenue += lineItemRevenue;
            } else {
                // Add new product
                clientData.Products.push({
                    ProductCode: productCode,
                    ProductName: productName,
                    Units: quantity,
                    UnitPrice: unitPrice,
                    Revenue: lineItemRevenue
                });
            }

            // Update client totals
            clientData.TotalUnits += quantity;
            clientData.TotalRevenue += lineItemRevenue;

            // Update client average daily revenue
            clientData.AverageDailyRevenue = clientData.TotalRevenue / currentDay;

            // Add to company totals
            totalCompanyRevenue += lineItemRevenue;
            totalCompanyUnits += quantity;
        }

        // --- End Revenue Calculations ---

        return newRow;
    });

    // Convert back to CSV
    const updatedCsv = Papa.unparse(updatedRows);

    // Convert the missing client map to an array of MissingClient objects
    missingClientMap.forEach((productCodes, clientName) => {
        missingClients.push({
            Name: clientName,
            ProductCodes: productCodes
        });
    });

    // Generate formatted JSON for missing clients
    const formattedMissingClientsJson = formatMissingClientsToJson(missingClients);

    // Convert client revenue map to array and sort by total revenue (highest first)
    const clientRevenueData = Array.from(clientRevenueMap.values()).sort(
        (a, b) => b.TotalRevenue - a.TotalRevenue
    );

    // Calculate price per unit
    const pricePerUnit = totalCompanyUnits > 0 ? totalCompanyRevenue / totalCompanyUnits : 0;

    // Generate formatted markdown for revenue breakdown
    const formattedRevenueBreakdown = formatRevenueBreakdownMarkdown(
        clientRevenueData,
        totalCompanyRevenue,
        totalCompanyUnits
    );

    return {
        UpdatedCsv: updatedCsv,
        MissingClients: missingClients,
        MissingProducts: missingProducts,
        TotalUpdates: totalUpdates,
        Success: !(missingClients.length > 0 || missingProducts.length > 0),
        FormattedMissingClientsJson: formattedMissingClientsJson,
        ClientRevenueBreakdown: clientRevenueData,
        TotalCompanyRevenue: totalCompanyRevenue,
        TotalCompanyUnits: totalCompanyUnits,
        PricePerUnit: pricePerUnit,
        FormattedRevenueBreakdown: formattedRevenueBreakdown
    };
};

/**
 * Creates and triggers a file download for the updated CSV
 */
export const downloadUpdatedCsv = (csvContent: string): void => {
    // Skip if no CSV content available
    if (!csvContent) return;

    try {
        // Create a blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // Create temporary link element
        const a = document.createElement('a');
        a.href = url;

        // Set filename safely
        a.setAttribute('download', 'updated-invoice.csv');

        // Trigger download
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading file:', error);
        throw new Error('Failed to download the updated CSV file');
    }
};
