/**
 * StatusBadge Component
 * 
 * A strongly typed component for displaying status badges consistently across the application.
 * Uses the centralized status registry to determine styling and appearance.
 */
import type * as React from 'react';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import type { ProductType } from '@/types/product.unified';
import { HelpCircle } from '@/components/ui/icon';
import type {
  ServerStatus
} from '@/types/status.types';


import { 
  getStatusDefinition,
  normalizeStatus
} from '@/utils/status.registry';

/**
 * StatusBadge component props
 */
export type StatusBadgeProps = {
  productType: ProductType;
  status: string | ServerStatus | undefined;
  className?: string;
  id?: string | number;
  showDescription?: boolean;
}

/**
 * StatusBadge component for displaying consistent status badges
 */
export const StatusBadge: React.FC<StatusBadgeProps> = ({ 
  productType, 
  status,
  className,
  id = 'status-badge',
  showDescription = false
}) => {
  // Get status definition from registry
  const statusDef = getStatusDefinition(productType, status);
  
  // If status not found in registry, show a fallback badge
  if (!statusDef) {
    const normalizedStatus = normalizeStatus(status);
    return (
      <Badge 
        key={id} 
        variant="outline" 
        className={cn(
          "flex items-center gap-1 text-blackish-600 dark:text-blackish-300",
          className
        )}
      >
        <HelpCircle className="h-4 w-4" />
        <span>{normalizedStatus || status || 'Unknown'}</span>
      </Badge>
    );
  }
  
  // Destructure badge styling
  const { displayName, description, styling } = statusDef;
  const { variant, icon: Icon, colorClasses } = styling;
  
  // Render consistent badge using status definition
  return (
    <Badge 
      key={id}
      variant={variant}
      className={cn(
        "flex items-center gap-1",
        colorClasses || "",
        className
      )}
      title={showDescription ? description : undefined}
    >
      <Icon className="h-4 w-4" />
      <span>{displayName}</span>
    </Badge>
  );
};
