/**
 * ClientsPage
 * 
 * Main page for clients management. Uses the ModernClientsTable component
 * which implements the ProximaTable design system with built-in card UI 
 * and tab navigation.
 */
import type * as React from 'react';
import { ClientsProvider } from '@/components/clients/ClientsContext';
import { ModernClientsTable } from '@/components/clients/modern';
import { useAutoAnimate } from '@/hooks/useAutoAnimate';
import { User } from '@/components/ui/icon';

/**
 * ClientsPage component that provides the main clients management interface.
 * Follows the Proxima design system with card-based UI and integrated tabs.
 */
export const ClientsPage: React.FC = (): JSX.Element => {
  const containerRef = useAutoAnimate<HTMLDivElement>();

  return (
    <div 
      ref={containerRef} 
      className="space-y-6 w-full max-w-full px-3 sm:px-4 md:px-6"
    >
      {/* Page Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 border-b pb-4">
        <div>
          <h2 className="text-2xl font-bold text-blackish-800 dark:text-white">
            <User className="inline-block mr-2 h-7 w-7 text-primary-500" />
            Clients
          </h2>
          <div className="text-sm text-blackish-500 dark:text-blackish-300">
            Manage employer and service provider accounts
          </div>
        </div>
      </div>
      
      {/* Main content with ModernClientsTable using proxima-table base component */}
      <ClientsProvider initialClientType="employer">
        <ModernClientsTable 
          colorFamily="primary"
          className="w-full"
          showSearch={true}
          useCard={true}
          cardTitle=""
          cardClassName="shadow-sm"
        />
      </ClientsProvider>
    </div>
  );
};

export default ClientsPage;
