// src/components/layout/navigationConfig.tsx
import { AccountBalance, BriefcaseBusiness, FileText, Home } from '@/components/ui/icon';
import type { IconComponentProps } from '@/components/ui/icon/MaterialIconComponents';
import type React from 'react';

/**
 * Interface for navigation item configuration
 */
export type NavigationItem = {
  /** Icon component to display */
  icon: React.ReactNode;
  /** Display label for the navigation item */
  label: string;
  /** Route path for the navigation item */
  path: string;
  /** Optional - for future use to manage access control */
  requiresAuth?: boolean;
}

/**
 * Type for the icon component to ensure proper typing
 */
export type IconComponent = React.ForwardRefExoticComponent<
  IconComponentProps & React.RefAttributes<HTMLSpanElement>
>;

/**
 * Shared navigation items configuration
 * Used by both desktop and mobile sidebars for consistency
 */
export const navigationItems: NavigationItem[] = [
  {
    icon: <Home size={20} />,
    label: 'Dashboard',
    path: '/'
  },
  {
    icon: <FileText size={20} />,
    label: 'Submissions',
    path: '/submissions'
  },
  {
    icon: <BriefcaseBusiness size={20} />,
    label: 'Clients',
    path: '/clients'
  },
  {
    icon: <AccountBalance size={20} />,
    label: 'Invoicing',
    path: '/invoices'
  }
];
