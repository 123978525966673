import type React from 'react';
import type { AuthenticatorProps } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Layout and Page Components
import AppLayout from '@/components/layout/AppLayout';
import { Dashboard } from '@/components/dashboard/Dashboard';
import { SubmissionsPage } from '@/components/submissions/SubmissionsPage';
import { ToasterNew } from '@/components/ui/toaster';
import ClientsPage from '@/components/clients/ClientsPage';
import ClientDetailsPage from '@/components/clients/ClientDetailsPage';
import { InvoicingPage } from '@/components/invoices/index';
import { SecurityHeadersAudit } from '@/components/security/SecurityHeadersAudit';

// Create a client for React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

// Configure Amplify with type-safe environment variables
Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_COGNITO_DOMAIN || '',
          scopes: [
            'email',
            'openid',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code',
        },
      },
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID || '',
      userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID || '',
    },
  },
  API: {
    REST: {
      providers: {
        endpoint: import.meta.env.VITE_API_BASE_URL || '/service-providers'
      },
      employers: {
        endpoint: import.meta.env.VITE_API_BASE_URL || '/employers'
      }
    }
  }
});

// Define authenticator components with explicit typing
const authenticatorComponents: NonNullable<AuthenticatorProps['components']> = {
  SignIn: {
    Footer() {
      return null;
    },
  },
};

// Main App Component with explicit React.FC type
export const App: React.FC = () => {
  return (
    <Authenticator components={authenticatorComponents} hideSignUp>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            {/* Main App Routes */}
            <Route path="/" element={<AppLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="submissions" element={<SubmissionsPage />} />
              <Route path="clients" element={<ClientsPage />} />
              <Route path="clients/:id" element={<ClientDetailsPage />} />
              <Route path="invoices" element={<InvoicingPage />} />
              <Route path="security-audit" element={<SecurityHeadersAudit />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </Router>
        <ToasterNew />
      </QueryClientProvider>
    </Authenticator>
  );
};
