import { useState, useEffect } from 'react';

// Theme mode types for more flexibility
export type ThemeMode = 'light' | 'dark' | 'system';

export type DarkModeOptions = {
  // Whether to enable enhanced contrast for charts and visualizations
  enhancedChartContrast?: boolean;
  // Multiplier for contrast in dark mode (1.0 is default, higher values increase contrast)
  contrastMultiplier?: number;
  // Whether to use system preferences as default
  useSystemPreference?: boolean;
  // Local storage key for saving preferences
  storageKey?: string;
}

/**
 * Custom hook for managing dark mode with enhanced options for charts and visualizations
 */
export const useDarkMode = (options: DarkModeOptions = {}) => {
  const {
    enhancedChartContrast = true,
    contrastMultiplier = 1.0,
    useSystemPreference = true,
    storageKey = 'dark-mode-preference',
  } = options;

  // Get initial theme mode from localStorage or default to system
  const getInitialThemeMode = (): ThemeMode => {
    if (typeof window !== 'undefined') {
      const savedMode = localStorage.getItem(`${storageKey}-mode`);
      if (savedMode && (savedMode === 'light' || savedMode === 'dark' || savedMode === 'system')) {
        return savedMode as ThemeMode;
      }
    }
    return useSystemPreference ? 'system' : 'light';
  };
  
  // Calculate isDarkMode based on theme mode and system preference
  const calculateIsDarkMode = (mode: ThemeMode): boolean => {
    if (mode === 'dark') return true;
    if (mode === 'light') return false;
    // For 'system', check system preference
    return typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const initialThemeMode = getInitialThemeMode();
  const [themeMode, setThemeMode] = useState<ThemeMode>(initialThemeMode);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(calculateIsDarkMode(initialThemeMode));
  const [contrastLevel, setContrastLevel] = useState<number>(contrastMultiplier);
  const [enhancedContrast, setEnhancedContrast] = useState<boolean>(enhancedChartContrast);
  
  // Update isDarkMode whenever themeMode changes
  const updateDarkMode = (mode: ThemeMode) => {
    setThemeMode(mode);
    setIsDarkMode(calculateIsDarkMode(mode));
  };
  
  // Check for saved preferences after mount for additional settings
  useEffect(() => {
    // Check for saved chart preferences
    if (typeof window !== 'undefined') {
      const savedContrast = localStorage.getItem(`${storageKey}-chart-contrast`);
      if (savedContrast) {
        setContrastLevel(parseFloat(savedContrast) || contrastMultiplier);
      }
      
      const savedEnhanced = localStorage.getItem(`${storageKey}-chart-enhanced`);
      if (savedEnhanced) {
        setEnhancedContrast(savedEnhanced === 'true');
      }
    }
  }, [storageKey, contrastMultiplier]);
  
  // Apply dark mode class to html element and save preferences
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      
      // Save preferences
      localStorage.setItem(`${storageKey}-mode`, themeMode);
      localStorage.setItem(`${storageKey}-chart-contrast`, String(contrastLevel));
      localStorage.setItem(`${storageKey}-chart-enhanced`, String(enhancedContrast));
    }
  }, [isDarkMode, themeMode, contrastLevel, enhancedContrast, storageKey]);
  
  // Listen for system preference changes when using 'system' mode
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      if (themeMode === 'system') {
        setIsDarkMode(e.matches);
      }
    };
    
    mediaQuery.addEventListener('change', handleChange);
    return () => { mediaQuery.removeEventListener('change', handleChange); };
  }, [themeMode]);
  
  // Toggle dark mode
  const toggleDarkMode = () => {
    if (themeMode === 'dark') {
      updateDarkMode('light');
    } else if (themeMode === 'light') {
      updateDarkMode('dark');
    } else {
      // If system, switch to explicit light/dark opposite of current state
      updateDarkMode(isDarkMode ? 'light' : 'dark');
    }
  };
  
  return {
    isDarkMode,
    toggleDarkMode,
    themeMode,
    setThemeMode: updateDarkMode,
    useSystemTheme: () => { updateDarkMode('system'); },
    contrastLevel,
    updateContrastLevel: setContrastLevel,
    enhancedContrast,
    toggleEnhancedContrast: () => { setEnhancedContrast(prev => !prev); },
  };
};

export default useDarkMode;
