import * as React from 'react';
import { InboxIcon, SearchXIcon, FileIcon, CircleXIcon } from '@/components/ui/icon';
import { Button } from '@/components/ui/button';
import { cn } from "@/lib/utils";

export type EmptyStateProps = {
  variant?: 'simple' | 'illustrated' | 'action';
  title?: string;
  message?: string;
  icon?: React.ReactNode;
  colorFamily?: 'primary' | 'secondary' | 'accent' | 'tertiary' | 'warning';
  className?: string;
  children?: React.ReactNode;
  actionLabel?: string;
  onAction?: () => void;
}

/**
 * EmptyState component for displaying when no data is available.
 * Supports multiple variants and themes to match application styling.
 */
export const EmptyState: React.FC<EmptyStateProps> = ({
  variant = 'simple',
  title = 'No data available',
  message,
  icon,
  colorFamily = 'primary',
  className,
  children,
  actionLabel,
  onAction,
}): JSX.Element => {
  // Determine the appropriate icon based on variant if not explicitly provided
  const defaultIcon = React.useMemo(() => {
    if (icon) return icon;
    
    switch(variant) {
      case 'illustrated':
        return <InboxIcon size={32} />;
      case 'action':
        return <SearchXIcon size={32} />;
      case 'simple':
      default:
        return <FileIcon size={24} />;
    }
  }, [icon, variant]);
  
  // Color classes based on colorFamily
  const colorClasses = React.useMemo(() => {
    const baseColors = {
      primary: {
        bg: 'bg-primary-50/50 dark:bg-primary-900/10',
        text: 'text-primary-700 dark:text-primary-300',
        icon: 'text-primary-500 dark:text-primary-400',
        border: 'border-primary-200 dark:border-primary-800/30',
        button: 'border-primary-200 bg-primary-50 hover:bg-primary-100 text-primary-700 dark:border-primary-800 dark:bg-primary-900/20 dark:hover:bg-primary-900/30 dark:text-primary-300'
      },
      secondary: {
        bg: 'bg-secondary-50/50 dark:bg-secondary-900/10',
        text: 'text-secondary-700 dark:text-secondary-300',
        icon: 'text-secondary-500 dark:text-secondary-400',
        border: 'border-secondary-200 dark:border-secondary-800/30',
        button: 'border-secondary-200 bg-secondary-50 hover:bg-secondary-100 text-secondary-700 dark:border-secondary-800 dark:bg-secondary-900/20 dark:hover:bg-secondary-900/30 dark:text-secondary-300'
      },
      accent: {
        bg: 'bg-accent-50/50 dark:bg-accent-900/10',
        text: 'text-accent-700 dark:text-accent-300',
        icon: 'text-accent-500 dark:text-accent-400',
        border: 'border-accent-200 dark:border-accent-800/30',
        button: 'border-accent-200 bg-accent-50 hover:bg-accent-100 text-accent-700 dark:border-accent-800 dark:bg-accent-900/20 dark:hover:bg-accent-900/30 dark:text-accent-300'
      },
      tertiary: {
        bg: 'bg-secondary-50/50 dark:bg-secondary-900/10',
        text: 'text-secondary-700 dark:text-secondary-300',
        icon: 'text-secondary-500 dark:text-secondary-400',
        border: 'border-secondary-200 dark:border-secondary-800/30',
        button: 'border-secondary-200 bg-secondary-50 hover:bg-secondary-100 text-secondary-700 dark:border-secondary-800 dark:bg-secondary-900/20 dark:hover:bg-secondary-900/30 dark:text-secondary-300'
      },
      warning: {
        bg: 'bg-warning-50/50 dark:bg-warning-900/10',
        text: 'text-warning-700 dark:text-warning-300',
        icon: 'text-warning-500 dark:text-warning-400',
        border: 'border-warning-200 dark:border-warning-800/30',
        button: 'border-warning-200 bg-warning-50 hover:bg-warning-100 text-warning-700 dark:border-warning-800 dark:bg-warning-900/20 dark:hover:bg-warning-900/30 dark:text-warning-300'
      }
    };
    
    return baseColors[colorFamily] || baseColors.primary;
  }, [colorFamily]);
  
  // Variant-specific styles
  const variantClasses = React.useMemo(() => {
    const variants = {
      simple: {
        container: 'p-4 rounded-md border',
        iconWrapper: 'h-8 w-8 mb-2',
        titleClass: 'text-base font-medium mb-1',
        messageClass: 'text-sm opacity-80'
      },
      illustrated: {
        container: 'p-6 sm:p-8 rounded-lg border shadow-sm',
        iconWrapper: 'h-16 w-16 mb-4 p-3 rounded-full bg-opacity-20',
        titleClass: 'text-lg font-semibold mb-2',
        messageClass: 'text-sm opacity-80'
      },
      action: {
        container: 'p-5 sm:p-6 rounded-lg border shadow-sm',
        iconWrapper: 'h-12 w-12 mb-3 p-2 rounded-full bg-opacity-20',
        titleClass: 'text-lg font-semibold mb-2',
        messageClass: 'text-sm opacity-80'
      }
    };
    
    return variants[variant] || variants.simple;
  }, [variant]);
  
  // Container class combining variant, color, and custom classes
  const containerClass = cn(
    'flex flex-col items-center justify-center text-center',
    variantClasses.container,
    colorClasses.bg,
    colorClasses.border,
    className
  );
  
  // Icon wrapper class
  const iconWrapperClass = cn(
    'flex items-center justify-center',
    variantClasses.iconWrapper,
    colorClasses.icon
  );
  
  // Animation classes - pulse effect on variant illustrated
  const animationClass = variant === 'illustrated' 
    ? 'animate-on-mount hover:scale-[1.03] transition-transform' 
    : 'hover:scale-[1.01] transition-transform';
  
  return (
    <div className={cn(containerClass, animationClass)}>
      <div className={iconWrapperClass}>
        {defaultIcon}
      </div>
      
      <h3 className={cn(variantClasses.titleClass, colorClasses.text)}>
        {title}
      </h3>
      
      {(message || children) && (
        <div className={cn(variantClasses.messageClass, 'text-blackish-600 dark:text-blackish-300')}>
          {children || message}
        </div>
      )}
      
      {variant === 'action' && actionLabel && onAction && (
        <Button 
          onClick={onAction}
          className={cn('mt-4', colorClasses.button)}
          variant="outline"
          size="sm"
        >
          {actionLabel}
        </Button>
      )}
    </div>
  );
};

/**
 * TableEmptyState component for displaying in table contexts.
 * Adapts EmptyState for table-specific displays.
 */
export const TableEmptyState: React.FC<EmptyStateProps> = (props): JSX.Element => {
  return (
    <EmptyState
      variant="simple"
      title="No results found"
      icon={<SearchXIcon size={24} />}
      {...props}
      className={cn(
        'max-w-xs sm:max-w-sm mx-auto',
        props.className
      )}
    />
  );
};

/**
 * ChartEmptyState component for displaying in chart contexts.
 * Adapts EmptyState for chart-specific displays.
 */
export const ChartEmptyState: React.FC<EmptyStateProps> = (props): JSX.Element => {
  return (
    <EmptyState
      variant="illustrated"
      title="No chart data"
      message="There is no data available to display in this chart."
      icon={<CircleXIcon size={28} />}
      {...props}
      className={cn(
        'max-w-xs mx-auto py-12',
        props.className
      )}
    />
  );
};
