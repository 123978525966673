/**
 * Product Types
 *
 * Single source of truth for all product-related types and definitions
 * in the application. This file centralizes product information to ensure
 * consistent typing throughout the application.
 * 
 * Includes product types, status enums, product styling, and status styling definitions.
 */
import type * as React from 'react';

/**
 * Enum of all available product types in the system
 */
export enum ProductType {
  ALL = 'ALL', // Special type to represent all products
  RESPIRATOR_MEDICAL_EVALUATION = 'RESPIRATOR_MEDICAL_EVALUATION',
  GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE = 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE',
  ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE = 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE',
  RESPIRATOR_FIT_TEST = 'RESPIRATOR_FIT_TEST',
}

/**
 * Map of server keys to product types
 * This allows handling multiple possible keys for the same product type
 */
export const SERVER_KEY_TO_PRODUCT_TYPE: Record<string, ProductType> = {
  'RESPIRATOR_MEDICAL_EVALUATION': ProductType.RESPIRATOR_MEDICAL_EVALUATION,
  'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE': ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE,
  'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION': ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE,
  'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE': ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE,
  'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION': ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE,
  'RESPIRATOR_FIT_TEST': ProductType.RESPIRATOR_FIT_TEST,
};

/**
 * Status enums for each product type to match server response values
 */

/**
 * Medical evaluation status types - Used by RESPIRATOR_MEDICAL_EVALUATION, 
 * GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION, and ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION
 */
export enum MedicalEvaluationStatus {
  CLEARED = 'CLEARED',
  DEFERRED = 'DEFERRED',
  EXPIRED_INTERVIEW = 'EXPIRED_INTERVIEW',
  PENDING_INTERVIEW = 'PENDING_INTERVIEW',
  PENDING_REVIEW = 'PENDING_REVIEW'
}

/**
 * Fit test status types - Used by RESPIRATOR_FIT_TEST
 */
export enum FitTestStatus {
  FAIL = 'FAIL',
  PASS = 'PASS'
}

/**
 * Fit test protocol types - Used by RESPIRATOR_FIT_TEST
 */
export enum FitTestProtocol {
  QUALITATIVE_BITREX_SOLUTION_AEROSOL = 'QUALITATIVE_BITREX_SOLUTION_AEROSOL',
  QUALITATIVE_IRRITANT_SMOKE = 'QUALITATIVE_IRRITANT_SMOKE',
  QUALITATIVE_ISOAMYL_ACETATE = 'QUALITATIVE_ISOAMYL_ACETATE',
  QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL = 'QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL',
  QUANTITATIVE = 'QUANTITATIVE'
}

/**
 * Protocol registry type - Maps protocols to their display names
 */
export type ProtocolRegistry = Record<FitTestProtocol, string>;

/**
 * Map of product types to their status enums for type safety when working with statuses
 */
export type ProductStatusMap = {
  [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: MedicalEvaluationStatus;
  [ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE]: MedicalEvaluationStatus;
  [ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE]: MedicalEvaluationStatus;
  [ProductType.RESPIRATOR_FIT_TEST]: FitTestStatus;
  [ProductType.ALL]: MedicalEvaluationStatus | FitTestStatus;
};

/**
 * Icon component type that accepts className prop
 */
export type IconComponent = React.ComponentType<{ className?: string }>;

/**
 * Badge styling configuration for products
 */
export type ProductBadgeStyling = {
  variant: 'default' | 'secondary' | 'destructive' | 'success' | 'warning' | 'outline';
  icon: IconComponent;
  colorClasses: string;
}

/**
 * Product definition that includes all necessary information
 */
export type ProductDefinition = {
  id: ProductType;
  key: string;
  name: string;
  shortName: string;
  description?: string;
  badge: ProductBadgeStyling;
  order: number; // Display order in lists and tabs
}

/**
 * Type for the product registry
 */
export type ProductRegistry = Record<ProductType, ProductDefinition>;

/**
 * Badge styling configuration for statuses
 */
export type StatusBadgeStyling = {
  variant: 'default' | 'secondary' | 'destructive' | 'success' | 'warning' | 'outline';
  icon: IconComponent;
  colorClasses: string;
}

/**
 * Status definition that includes all necessary display information
 */
export type StatusDefinition = {
  id: string; // Original server status string
  key: string; // Normalized key for lookups
  displayName: string; // User-friendly display name
  badge: StatusBadgeStyling;
  description?: string; // Optional description for tooltips
  order: number; // Display order in lists and selectors
}

/**
 * Type for the status registry - maps from server status keys to display definitions
 */
export type StatusRegistry = Record<string, StatusDefinition>;

/**
 * Type mapping product types to their possible status registries
 */
export type ProductStatusRegistryMap = {
  [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: StatusRegistry;
  [ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE]: StatusRegistry;
  [ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE]: StatusRegistry;
  [ProductType.RESPIRATOR_FIT_TEST]: StatusRegistry;
  [ProductType.ALL]: StatusRegistry;
};

/**
 * Convert server-provided product key to internal product type
 */
export function getProductTypeFromServerKey(serverKey: string): ProductType | undefined {
  return SERVER_KEY_TO_PRODUCT_TYPE[serverKey];
}
