/**
 * useFetchClients Hook
 * 
 * Hook for fetching and managing client data with server-side filtering support.
 */
import type {
  EmployerFetchParams,
  ServiceProviderFetchParams
} from '@/services/clients-api.service';
import {
  getEmployers,
  getServiceProviders
} from '@/services/clients-api.service';
import type {
  Client,
  ClientFetchError,
  ClientType,
  Employer,
  PaginatedResponse,
  ServiceProvider
} from '@/types/client.unified';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Helper function to perform deep comparison of objects
const isEqual = <T extends Record<string, unknown>>(objA: T, objB: T): boolean => {
  return JSON.stringify(objA) === JSON.stringify(objB);
};

// Type that resolves to the appropriate client data type based on client type
export type ClientDataType<T extends ClientType> =
  T extends 'employer' ? Employer[] : ServiceProvider[];

/**
 * Hook for fetching client data
 */
export function useFetchClients<T extends ClientType>(clientType: T): {
  data: ClientDataType<T>;
  filteredData: ClientDataType<T>;
  isLoading: boolean;
  error: ClientFetchError | null;
  refetch: () => Promise<void>;
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    itemsPerPage: number;
  };
  clientType: T;
  serviceProviderParams?: ServiceProviderFetchParams;
  updateSortBy?: (newSortBy: ServiceProviderFetchParams['sortBy']) => void;
  updateItemsPerPage: (newItemsPerPage: number) => void; // Changed: removed optional
  updatePage?: (newPage: number) => void;
  updateFilters: (filters: { search?: string; status?: string }) => void; // Added: expose this explicitly
} {
  // Simplified state management
  const [data, setData] = useState<ClientDataType<T>>([]);

  // Request state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<ClientFetchError | null>(null);

  // Basic pagination state
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    totalCount: 0,
    itemsPerPage: 10
  });

  // Employer parameters state
  const [employerParams, setEmployerParams] = useState<EmployerFetchParams>({
    sortBy: 'name',
    itemsPerPage: 10,
    page: 1
  });

  // Service provider parameters state
  const [serviceProviderParams, setServiceProviderParams] = useState<ServiceProviderFetchParams>({
    sortBy: 'name',
    itemsPerPage: 10,
    page: 1
  });

  // References for tracking previous state
  const prevFiltersRef = useRef<{ search?: string; status?: string }>({});
  const prevParamsRef = useRef<{
    employer: EmployerFetchParams | null,
    serviceProvider: ServiceProviderFetchParams | null
  }>({
    employer: null,
    serviceProvider: null
  });

  // Add a ref to track what client type was used for the initial fetch
  const initialFetchClientTypeRef = useRef<ClientType | null>(null);

  // Create a stable reference to the fetch function
  const fetchDataRef = useRef<() => Promise<void>>();

  /**
   * Fetch function with proper parameters
   */
  fetchDataRef.current = async (): Promise<void> => {
    setIsLoading(true);
    setError(null);

    try {
      // Call API directly
      let response: PaginatedResponse<Client>;

      // Use different API calls based on client type with appropriate parameters
      if (clientType === 'employer') {
        response = await getEmployers(employerParams) as PaginatedResponse<Client>;
      } else {
        response = await getServiceProviders(serviceProviderParams) as PaginatedResponse<Client>;
      }

      // Set data
      setData(response.items as ClientDataType<T>);

      // Set pagination
      setPagination({
        page: response.page || 1,
        totalPages: response.totalPages || 1,
        totalCount: response.totalCount || response.items.length,
        itemsPerPage: response.itemsPerPage || 10
      });

      setIsLoading(false);
    } catch (error) {
      setError(error instanceof Error ? error : new Error(String(error)));
      setIsLoading(false);
    }
  };

  // Stable fetch function that uses the ref
  const fetchData = useCallback(async (): Promise<void> => {
    if (fetchDataRef.current) {
      await fetchDataRef.current();
    }
  }, []);

  /**
   * Update filters with debouncing control
   */
  const updateFilters = useCallback((newFilters: { search?: string; status?: string }) => {
    const prevFilters = prevFiltersRef.current;

    // Check if search term meets criteria: empty or 2+ characters
    const validSearch = newFilters.search === undefined ||
      newFilters.search === '' ||
      newFilters.search.length >= 2;

    if (!validSearch) {
      // Don't update if search doesn't meet criteria
      return;
    }

    // Check if we need to reset to page 1
    const resetPage = (
      (prevFilters.search !== newFilters.search && newFilters.search !== undefined) ||
      (prevFilters.status !== newFilters.status && newFilters.status !== undefined)
    );

    // Update employer params
    if (clientType === 'employer') {
      setEmployerParams(prev => ({
        ...prev,
        search: newFilters.search,
        status: newFilters.status,
        page: resetPage ? 1 : prev.page
      }));
    }
    // Update service provider params
    else {
      setServiceProviderParams(prev => ({
        ...prev,
        search: newFilters.search,
        status: newFilters.status,
        page: resetPage ? 1 : prev.page
      }));
    }

    // Update prev filters reference
    prevFiltersRef.current = { ...newFilters };
  }, [clientType]);

  // Consolidated fetch effect - handles all cases for fetching data
  useEffect(() => {
    // Get current params based on client type
    const currentParams = clientType === 'employer' ? employerParams : serviceProviderParams;
    const prevParams = clientType === 'employer' ? prevParamsRef.current.employer : prevParamsRef.current.serviceProvider;

    // Check if this is initial load (first fetch)
    const isInitialLoad = initialFetchClientTypeRef.current === null;

    // Check if client type changed
    const isClientTypeChanged = initialFetchClientTypeRef.current !== null &&
      initialFetchClientTypeRef.current !== clientType;

    // Check if params changed meaningfully (using deep comparison)
    const isParamsChanged = prevParams !== null && !isEqual(currentParams, prevParams);

    // Save the current parameters for future comparisons
    if (clientType === 'employer') {
      prevParamsRef.current.employer = { ...employerParams };
    } else {
      prevParamsRef.current.serviceProvider = { ...serviceProviderParams };
    }

    // Update client type reference if needed
    if (isInitialLoad || isClientTypeChanged) {
      initialFetchClientTypeRef.current = clientType;
    }

    // Fetch data if any condition requires it
    if (isInitialLoad || isClientTypeChanged || isParamsChanged) {
      void fetchData();
    }
  }, [clientType, employerParams, serviceProviderParams, fetchData]);

  // Parameter update callbacks
  const updateSortBy = useCallback((newSortBy: ServiceProviderFetchParams['sortBy']) => {
    setServiceProviderParams(prev => ({ ...prev, sortBy: newSortBy }));
  }, []);

  // Updated to work with both client types
  const updateItemsPerPage = useCallback((newItemsPerPage: number) => {
    if (newItemsPerPage >= 1 && newItemsPerPage <= 100) {
      if (clientType === 'employer') {
        setEmployerParams(prev => ({ ...prev, itemsPerPage: newItemsPerPage }));
      } else {
        setServiceProviderParams(prev => ({ ...prev, itemsPerPage: newItemsPerPage }));
      }
    }
  }, [clientType]);

  const updatePage = useCallback((newPage: number) => {
    if (newPage >= 1) {
      if (clientType === 'employer') {
        setEmployerParams(prev => ({ ...prev, page: newPage }));
      } else {
        setServiceProviderParams(prev => ({ ...prev, page: newPage }));
      }
    }
  }, [clientType]);

  // Memoize the return value to prevent unnecessary re-renders
  return useMemo(() => ({
    data,
    filteredData: data,      // No client-side filtering needed as API does filtering
    isLoading,
    error,
    refetch: fetchData,
    pagination,
    clientType,
    updateFilters,
    updateItemsPerPage,     // Always expose this now
    updatePage,             // Always expose this now

    // Expose params based on client type
    ...(clientType === 'serviceProvider' ? {
      serviceProviderParams,
      updateSortBy,
    } : {
      employerParams,
      updateSortBy: (newSortBy: EmployerFetchParams['sortBy']) => {
        setEmployerParams(prev => ({ ...prev, sortBy: newSortBy }));
      },
    })
  }), [
    data,
    isLoading,
    error,
    fetchData,
    pagination,
    clientType,
    updateFilters,
    serviceProviderParams,
    employerParams,
    updateSortBy,
    updateItemsPerPage,
    updatePage
  ]);
}

export default useFetchClients;
