import * as React from "react";
import { XCircle, AlertTriangle, AlertOctagon, RefreshCw } from '@/components/ui/icon';
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export type ErrorStateProps = {
  title?: string;
  error?: string | Error | null;
  onRetry?: () => void;
  variant?: 'inline' | 'card' | 'full';
  icon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  colorFamily?: 'primary' | 'secondary' | 'accent' | 'tertiary' | 'warning' | 'error';
  severity?: 'error' | 'warning' | 'info';
}

/**
 * ErrorState component for displaying error messages and retry options.
 * Supports multiple variants, color families, and severity levels.
 */
export const ErrorState: React.FC<ErrorStateProps> = ({
  title = "Error",
  error,
  onRetry,
  variant = 'card',
  icon,
  className,
  children,
  colorFamily = 'error',
  severity = 'error',
}): JSX.Element => {
  // Parse error message from different error formats
  const errorMessage = React.useMemo(() => 
    error instanceof Error ? error.message : 
    typeof error === 'string' ? error : 
    "An unexpected error occurred"
  , [error]);

  // Determine the icon based on severity and provided icon
  const defaultIcon = React.useMemo(() => {
    if (icon) return icon;
    
    // Choose icon based on severity if not explicitly provided
    switch(severity) {
      case 'warning':
        return <AlertTriangle />;
      case 'info':
        return <AlertOctagon />;
      case 'error':
      default:
        return <XCircle />;
    }
  }, [icon, severity]);

  // Color classes based on colorFamily
  const colorClasses = React.useMemo(() => {
    const baseColors = {
      primary: {
        bg: 'bg-primary-50/70 dark:bg-primary-950/20',
        text: 'text-primary-700 dark:text-primary-300',
        icon: 'text-primary-500 dark:text-primary-400',
        border: 'border-primary-200 dark:border-primary-800/40',
        button: 'border-primary-200 hover:bg-primary-100/70'
      },
      secondary: {
        bg: 'bg-secondary-50/70 dark:bg-secondary-950/20',
        text: 'text-secondary-700 dark:text-secondary-300',
        icon: 'text-secondary-500 dark:text-secondary-400',
        border: 'border-secondary-200 dark:border-secondary-800/40',
        button: 'border-secondary-200 hover:bg-secondary-100/70'
      },
      accent: {
        bg: 'bg-accent-50/70 dark:bg-accent-950/20',
        text: 'text-accent-700 dark:text-accent-300',
        icon: 'text-accent-500 dark:text-accent-400',
        border: 'border-accent-200 dark:border-accent-800/40',
        button: 'border-accent-200 hover:bg-accent-100/70'
      },
      tertiary: {
        bg: 'bg-secondary-50/70 dark:bg-secondary-950/20',
        text: 'text-secondary-700 dark:text-secondary-300',
        icon: 'text-secondary-500 dark:text-secondary-400',
        border: 'border-secondary-200 dark:border-secondary-800/40',
        button: 'border-secondary-200 hover:bg-secondary-100/70'
      },
      warning: {
        bg: 'bg-warning-50/70 dark:bg-warning-950/20',
        text: 'text-warning-700 dark:text-warning-300',
        icon: 'text-warning-500 dark:text-warning-400',
        border: 'border-warning-200 dark:border-warning-800/40',
        button: 'border-warning-200 hover:bg-warning-100/70'
      },
      error: {
        bg: 'bg-red-50/70 dark:bg-red-950/20',
        text: 'text-red-700 dark:text-red-300',
        icon: 'text-red-500 dark:text-red-400',
        border: 'border-red-200 dark:border-red-800/40',
        button: 'border-red-200 hover:bg-red-100/70'
      }
    };
    
    return baseColors[colorFamily] || baseColors.error;
  }, [colorFamily]);
  
  // Variant-specific styles
  const variantStyles = React.useMemo(() => {
    const variants = {
      inline: {
        container: "flex items-center gap-2 p-2 text-sm border rounded-md",
        iconSize: 16,
        iconClass: "",
        titleClass: "font-medium",
        messageClass: "",
        animation: "hover:shadow-sm transition-shadow duration-200"
      },
      card: {
        container: "rounded-md p-4 sm:p-6 shadow-sm text-center max-w-md mx-auto border",
        iconSize: 28,
        iconClass: "mb-2",
        titleClass: "text-base font-semibold mb-1",
        messageClass: "text-sm opacity-90 text-center mb-3 break-words max-w-md",
        animation: "hover:shadow-md transition-all duration-300 ease-in-out"
      },
      full: {
        container: "flex flex-col items-center text-center p-4 rounded-md w-full border",
        iconSize: 36,
        iconClass: "mb-3",
        titleClass: "text-lg font-semibold mb-2",
        messageClass: "text-sm opacity-90 text-center mb-3 break-words max-w-lg",
        animation: "hover:bg-opacity-90 transition-all duration-300"
      },
    };
    
    return variants[variant] || variants.card;
  }, [variant]);

  // Clone and modify the icon with appropriate size and classes
  const IconComponent = defaultIcon && React.isValidElement(defaultIcon) 
    ? React.cloneElement(defaultIcon as React.ReactElement, { 
        size: variantStyles.iconSize, 
        className: cn(colorClasses.icon, variantStyles.iconClass, 
                    (defaultIcon as React.ReactElement).props.className)
      })
    : null;

  // Generate container class based on variant, color, and animation styles
  const containerClass = cn(
    variantStyles.container,
    colorClasses.bg,
    colorClasses.border,
    variantStyles.animation,
    className
  );

  // Inline variant has a different layout
  if (variant === 'inline') {
    return (
      <div className={containerClass}>
        {IconComponent}
        <span className={colorClasses.text}>{errorMessage}</span>
        {onRetry && (
          <Button 
            onClick={onRetry} 
            size="sm" 
            variant="ghost"
            className="ml-auto p-1 h-auto"
          >
            <RefreshCw size={14} className={colorClasses.icon} />
          </Button>
        )}
      </div>
    );
  }

  // Card and full variants
  return (
    <div className={containerClass}>
      <div className="flex flex-col items-center justify-center">
        {IconComponent}
        
        <h3 className={cn(variantStyles.titleClass, colorClasses.text)}>
          {title}
        </h3>
        
        <p className={cn(variantStyles.messageClass, "text-blackish-600 dark:text-blackish-300")}>
          {children || errorMessage}
        </p>
        
        {onRetry && (
          <Button 
            onClick={onRetry} 
            size="sm" 
            variant="outline"
            className={cn("flex items-center gap-2", colorClasses.button)}
          >
            <RefreshCw size={16} className={colorClasses.icon} />
            Retry
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * TableErrorState component for displaying error states in table contexts.
 * Adapts ErrorState for table-specific displays.
 */
export const TableErrorState: React.FC<ErrorStateProps> = (props): JSX.Element => {
  return (
    <ErrorState
      variant="card"
      title="Error Loading Data"
      colorFamily="error"
      severity="error"
      {...props}
      className={cn(
        "max-w-xs sm:max-w-sm mx-auto",
        props.className
      )}
    />
  );
};

/**
 * ChartErrorState component for displaying error states in chart contexts.
 * Adapts ErrorState for chart-specific displays.
 */
export const ChartErrorState: React.FC<ErrorStateProps> = (props): JSX.Element => {
  return (
    <ErrorState
      variant="full"
      title="Chart Error"
      colorFamily="error" 
      severity="error"
      {...props}
      className={cn(
        "max-w-md mx-auto py-8",
        props.className
      )}
    />
  );
};

// Form-specific error components
export type FormErrorProps = {
  fieldName?: string;
} & ErrorStateProps

/**
 * FormFieldError component for displaying field-level validation errors.
 */
export const FormFieldError: React.FC<FormErrorProps> = ({
  error,
  className
}): JSX.Element | null => {
  if (!error) return null;
  
  const errorMessage = error instanceof Error ? error.message : 
                      typeof error === 'string' ? error : 
                      "Invalid input";
  
  return (
    <div className={cn("text-sm text-red-500 mt-1", className)}>
      {errorMessage}
    </div>
  );
};

/**
 * FormError component for displaying form-level errors.
 */
export const FormError: React.FC<FormErrorProps> = (props): JSX.Element | null => {
  if (!props.error) return null;
  
  return (
    <ErrorState 
      variant="inline"
      colorFamily="error"
      severity="error"
      {...props}
    />
  );
};

/**
 * FormSubmissionError component for displaying form submission errors.
 */
export const FormSubmissionError: React.FC<FormErrorProps> = (props): JSX.Element | null => {
  if (!props.error) return null;
  
  return (
    <ErrorState 
      title={props.title || "Form Submission Error"}
      variant="card"
      colorFamily="error"
      severity="error"
      {...props}
    />
  );
};
