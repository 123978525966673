import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { BarChart2, DollarSign, Tag, Users } from '@/components/ui/icon';
import { LoadingState } from '@/components/ui/loading-state';
import { StateContainer } from '@/components/ui/state-container';
import { useUIThemeState } from '@/hooks/useUIThemeState';
import type { RevenueDataItem } from '@/types/chart-types';
import type { CompanyMetrics } from '@/types/index';
import { generateChartColors } from '@/utils/chartUtils';
import { getCardAnimationClass, getDashboardCardClass } from '@/utils/dashboardStyleUtils';
import type * as React from 'react';
import { useCallback, useMemo } from 'react';
import { TrendIndicator } from './TrendIndicator';

// Enhanced revenue data with percentage information
type EnhancedRevenueData = {
  percentage: number;
} & RevenueDataItem

type RevenueSectionProps = {
  data?: CompanyMetrics;
  isLoading: boolean;
  error?: Error | null;
  onRetry?: () => void;
}

export const RevenueSection: React.FC<RevenueSectionProps> = ({
  data,
  isLoading,
  error,
  onRetry
}) => {
  // Use shared UI theme state hook instead of component-specific state
  const {
    isDarkMode,
    activeElementId,
    handleElementHover,
    handleElementLeave
  } = useUIThemeState();

  // Prepare client revenue data for pie chart with consistent structure - memoize to prevent unnecessary rerenders
  const getClientRevenueData = useCallback((): EnhancedRevenueData[] => {
    if (!data?.revenue?.topClients || !Array.isArray(data.revenue.topClients)) {
      return [];
    }

    // Calculate total for percentage
    const total = data.revenue.topClients.reduce((sum: number, c: RevenueDataItem) => sum + c.value, 0);

    // Generate a distinct color palette for all clients
    const clientsCount = data.revenue.topClients.length;
    const distinctColors = generateChartColors(clientsCount, 'accent', isDarkMode);

    // Create client data with proper format and unique colors
    const result = data.revenue.topClients.map((client: RevenueDataItem, index: number) => {
      // Ensure value is a number, not a string
      const value = typeof client.value === 'string' ? parseFloat(client.value) : client.value;
      const clientId = client.id || `client-${index}`;

      return {
        name: client.name,
        value: value,
        unitCount: client.unitCount,
        id: clientId,
        // Assign a unique color from our distinct palette
        color: distinctColors[index],
        // Add percentage for potential use in tooltips or labels
        percentage: Math.round((value / total) * 100),
        // Mark as active if it matches the activeElementId
        isActive: activeElementId ? clientId === activeElementId : true
      };
    });

    return result;
  }, [data, isDarkMode, activeElementId]);

  // Memoize the chart data to prevent unnecessary renders
  const memoizedClientData = useMemo(() => getClientRevenueData(), [getClientRevenueData]);

  return (
    <StateContainer
      isLoading={isLoading}
      error={error}
      onRetry={onRetry}
      isEmpty={!data}
      loadingComponent={
        <div className="space-y-6">
          <div className="w-full h-8 bg-blackish-100 dark:bg-blackish-800 rounded animate-pulse"></div>
          <div className="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-4 auto-rows-min">
            <LoadingState
              variant="skeleton"
              cards={1}
              className="h-44 w-full"
            />
            <div className="sm:col-span-1 xl:col-span-1 xl:row-span-2 xl:col-start-2 xl:row-start-1 space-y-4">
              <LoadingState
                variant="skeleton"
                cards={1}
                className="h-44 w-full"
              />
              <LoadingState
                variant="skeleton"
                cards={1}
                className="h-44 w-full sm:block xl:block"
              />
            </div>
            <LoadingState
              variant="skeleton"
              cards={1}
              withChart
              className="h-[350px] sm:col-span-3 xl:col-span-1 xl:row-span-2 xl:col-start-3 xl:row-start-1"
            />
          </div>
        </div>
      }
    >
      {data && (
        <div className="space-y-6">
          <h3 className="text-xl font-bold text-blackish-800 dark:text-white flex items-center gap-2 border-b pb-2">
            <DollarSign className="h-5 w-5 text-primary-500" />
            Revenue Overview
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 gap-4 auto-rows-min">
            {/* Total Revenue Card */}
            <Card className={`${getDashboardCardClass('primary', isDarkMode)} sm:col-span-1 xl:col-span-1 xl:row-span-2 h-auto`}>
              <div className={getCardAnimationClass('primary')}>
                <CardHeader className="pb-2 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <DollarSign size={18} className="text-primary-600 mr-2" />
                    Total Revenue
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-3 h-20 justify-center">
                    <span className="text-3xl md:text-5xl font-extrabold text-blackish-900 dark:text-white truncate value-focus pulse-on-load">
                      ${data.revenue?.total.toLocaleString() ?? '0'}
                    </span>

                    <div className="flex items-center text-sm md:text-base flex-wrap">
                      <TrendIndicator
                        value={data.revenue?.change ?? 0}
                        suffix="%"
                        className="mr-2"
                      />
                      <span className="text-blackish-500 dark:text-blackish-400 truncate">
                        vs previous
                      </span>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>

            {/* Average Revenue Card */}
            <Card className={`${getDashboardCardClass('secondary', isDarkMode)} sm:col-span-1 xl:col-span-1 xl:row-span-1 xl:col-start-2 xl:row-start-1 h-auto`}>
              <div className={getCardAnimationClass('secondary')}>
                <CardHeader className="pb-1 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <BarChart2 size={18} className="text-secondary-600 mr-2" />
                    Average Revenue
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-1">
                    <span className="text-3xl md:text-5xl font-extrabold text-blackish-900 dark:text-white truncate value-focus pulse-on-load">
                      ${data.revenue?.averageRevenue?.toLocaleString() ?? '0'}
                    </span>

                    <p className="text-xs text-blackish-500 dark:text-blackish-400">
                      Per day
                    </p>
                  </div>
                </CardContent>
              </div>
            </Card>

            {/* Price Per Unit Card */}
            <Card className={`${getDashboardCardClass('warning', isDarkMode)} sm:col-span-1 xl:col-span-1 xl:row-span-1 xl:col-start-2 xl:row-start-2 h-auto`}>
              <div className={getCardAnimationClass('warning')}>
                <CardHeader className="pb-1 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center">
                    <Tag size={18} className="text-warning-600 mr-2" />
                    Price Per Unit
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col space-y-1">
                    <span className="text-3xl md:text-5xl font-extrabold text-blackish-900 dark:text-white truncate value-focus pulse-on-load">
                      ${data.revenue?.pricePerUnit.toLocaleString() ?? '0'}
                    </span>

                    <div className="flex items-center text-sm md:text-base flex-wrap">
                      <TrendIndicator
                        value={data.revenue?.change ?? 0}
                        suffix="%"
                        className="mr-2"
                      />
                      <span className="text-blackish-500 dark:text-blackish-400 truncate">
                        vs previous
                      </span>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>

            {/* Top Clients by Revenue Pie Chart */}
            <Card className={`${getDashboardCardClass('accent', isDarkMode)} sm:col-span-3 xl:col-span-1 xl:row-span-2 xl:col-start-3 xl:row-start-1`}>
              <div className={`${getCardAnimationClass('accent')} h-full`}>
                <CardHeader className="pb-2 relative z-10">
                  <CardTitle className="text-sm font-medium text-blackish-500 dark:text-blackish-300 flex items-center justify-between">
                    <div className="flex items-center">
                      <Users size={18} className="text-accent-600 mr-2" />
                      Top Clients by Revenue
                    </div>
                    <span className="text-xs text-blackish-400 dark:text-blackish-500 font-normal">
                      {data.revenue?.topClients.length ?? 0} clients
                    </span>
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col h-auto py-2 md:py-4">
                  <div className="w-full h-full flex flex-col lg:flex-row gap-4">
                    {/* Main content container without chart visualization */}
                    <div className="w-full flex justify-between">
                      {/* Client revenue data */}
                      <div className="w-full flex flex-col justify-start">
                        <div className="flex-grow overflow-hidden">
                          <ul className="flex flex-col space-y-1.5 max-h-[300px] overflow-y-auto custom-scrollbar pr-2">
                            {memoizedClientData.map((client, index) => {
                              return (
                                <li
                                  key={`legend-${index}`}
                                  className={`flex items-center p-1.5 rounded-md hover:bg-blackish-100/50 dark:hover:bg-blackish-800/50 transition-colors ${activeElementId === client.id ? 'bg-accent-50 dark:bg-accent-950/30' : ''}`}
                                  onMouseEnter={() => {
                                    handleElementHover(client.id);
                                  }}
                                  onMouseLeave={() => {
                                    handleElementLeave();
                                  }}
                                >
                                  <div className="flex flex-col flex-grow min-w-0">
                                    <div className="flex justify-between items-baseline">
                                      <span className={`text-sm font-medium truncate max-w-[300px] md:max-w-[400px] lg:max-w-[500px] ${activeElementId === client.id ? 'text-accent-700 dark:text-accent-300' : 'text-blackish-700 dark:text-blackish-300'}`}>
                                        {client.name}
                                      </span>
                                      <span className={`text-xs font-semibold ml-2 ${activeElementId === client.id ? 'text-accent-600 dark:text-accent-400' : 'text-blackish-500 dark:text-blackish-400'}`}>
                                        {client.percentage}%
                                      </span>
                                    </div>
                                    <span className="text-xs text-blackish-500 dark:text-blackish-400">
                                      ${client.value.toLocaleString()} / {client.unitCount?.toLocaleString() ?? 0}
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>
          </div>
        </div>
      )}
    </StateContainer>
  );
};
