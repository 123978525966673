// src/components/layout/RouteFallback.tsx
import type React from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, RefreshCw } from '@/components/ui/icon';
import { Button } from '@/components/ui/button';

/**
 * RouteFallback component displayed when a route fails to load
 * Provides options to refresh the page or navigate to dashboard
 */
export const RouteFallback: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  
  /**
   * Handles refresh page action
   */
  const handleRefresh = (): void => {
    window.location.reload();
  };
  
  /**
   * Handles navigation to dashboard
   */
  const handleNavigateHome = (): void => {
    navigate('/');
  };

  return (
    <div className="flex items-center justify-center p-8 min-h-[400px]">
      <div className="w-full max-w-md p-6 bg-gray-50 dark:bg-blackish-800 rounded-lg shadow-sm border border-gray-200 dark:border-blackish-700">
        <div className="flex items-center text-amber-600 dark:text-amber-400 mb-4">
          <AlertCircle className="w-5 h-5 mr-2" />
          <h2 className="text-base font-medium">Unable to load content</h2>
        </div>
        <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">
          There was a problem loading this section. This could be due to a network issue or a temporary problem with a third-party service.
        </p>
        <div className="flex flex-wrap gap-3 justify-center">
          <Button
            onClick={handleRefresh}
            variant="outline"
            size="sm"
            className="flex items-center gap-2"
          >
            <RefreshCw className="h-4 w-4" />
            <span>Refresh</span>
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleNavigateHome}
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RouteFallback;