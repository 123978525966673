/**
 * Protocol Registry
 *
 * Single source of truth for all respirator fit test protocol definitions.
 * This centralizes protocol information for consistent display across the application.
 */
import type { ProtocolRegistry } from '@/types/product.types';
import { FitTestProtocol } from '@/types/product.types';

/**
 * Fit Test Protocol Registry
 * Maps server protocol values to user-friendly display names for RESPIRATOR_FIT_TEST
 */
export const FIT_TEST_PROTOCOLS: ProtocolRegistry = {
  [FitTestProtocol.QUALITATIVE_BITREX_SOLUTION_AEROSOL]: 'QLFT (Bitrex)',
  [FitTestProtocol.QUALITATIVE_IRRITANT_SMOKE]: 'QLFT (Irritant Smoke)',
  [FitTestProtocol.QUALITATIVE_ISOAMYL_ACETATE]: 'QLFT (Isoamyl Acetate)',
  [FitTestProtocol.QUALITATIVE_SACCHARIN_SOLUTION_AEROSOL]: 'QLFT (Saccharin)',
  [FitTestProtocol.QUANTITATIVE]: 'QNFT'
};

/**
 * Helper to determine if a string is a valid FitTestProtocol
 */
export const isValidFitTestProtocol = (value: string): value is FitTestProtocol => {
  return Object.values(FitTestProtocol).includes(value as FitTestProtocol);
};

/**
 * Get display name for a protocol with fallback to original protocol or 'N/A'
 */
export const getProtocolDisplayName = (protocol: string | undefined): string => {
  if (!protocol) return 'N/A';
  
  // Check if it's a valid protocol enum value
  if (isValidFitTestProtocol(protocol)) {
    return FIT_TEST_PROTOCOLS[protocol as FitTestProtocol];
  }
  
  // Fallback to formatted protocol name
  return protocol.replace(/_/g, ' ');
};
