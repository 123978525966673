import type * as React from "react";
import { useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { LoadingState } from "@/components/ui/loading-state";
import { ErrorState } from "@/components/ui/error-state";
import { EmptyState } from "@/components/ui/empty-state";
import { cn } from "@/lib/utils";
import type { StateContainerProps } from "@/types/ui-component-types";

/**
 * StateContainer handles all possible UI states (loading, error, empty, content)
 * in a consistent and centralized manner. It automatically transitions between states
 * with optional animations.
 */
export const StateContainer: React.FC<StateContainerProps> = ({
  isLoading,
  error,
  isEmpty = false,
  loadingComponent,
  errorComponent,
  emptyComponent,
  loadingMessage = "Loading...",
  emptyMessage = "No data available",
  colorFamily = 'primary',
  loadingVariant = 'spinner',
  onRetry,
  className,
  children,
  animated = true,
  persistentElements
}): JSX.Element => {
  // Determine which state to show
  const currentState = isLoading
    ? 'loading'
    : error
      ? 'error'
      : isEmpty
        ? 'empty'
        : 'content';

  // For non-animated containers
  if (!animated) {
    return (
      <div className={cn("relative min-h-[100px]", className)}>
        {/* Always render persistent elements if provided with consistent layout */}
        {persistentElements && (
          <div className="w-full flex justify-end pb-4 relative z-10">{persistentElements}</div>
        )}
        
        {currentState === 'content' ? children : (
          <div className="flex items-center justify-center w-full py-8">
            {currentState === 'loading' && (
              loadingComponent || <LoadingState message={loadingMessage} variant={loadingVariant} colorFamily={colorFamily} />
            )}
            
            {currentState === 'error' && (
              errorComponent || (
                <ErrorState 
                  error={error} 
                  onRetry={onRetry}
                  variant="card"
                  colorFamily={colorFamily === 'warning' ? 'error' : colorFamily}
                />
              )
            )}
            
            {currentState === 'empty' && (
              emptyComponent || (
                <EmptyState 
                  title={emptyMessage} 
                  colorFamily={colorFamily}
                  variant="simple"
                />
              )
            )}
          </div>
        )}
      </div>
    );
  }

  // Create refs for transition elements
  const contentRef = useRef<HTMLDivElement>(null);
  const stateRef = useRef<HTMLDivElement>(null);

  // Animated container with transitions between states
  return (
    <div>
      {/* Always render persistent elements if provided with consistent layout */}
      {persistentElements && (
        <div className="w-full flex justify-end pb-4 relative z-10">{persistentElements}</div>
      )}
      
      {/* Use a wrapper with a min-height to stabilize layout during transitions */}
      <div className="relative min-h-[200px] w-full">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={currentState}
            nodeRef={currentState === 'content' ? contentRef : stateRef}
            timeout={200}
            classNames={{
              enter: "opacity-0",
              enterActive: "opacity-100 transition-opacity duration-200",
              exit: "opacity-100",
              exitActive: "opacity-0 transition-opacity duration-200"
            }}
            unmountOnExit
          >
            {currentState === 'content' ? (
              <div ref={contentRef} className="w-full">{children}</div>
            ) : (
              <div ref={stateRef} className="absolute inset-0 flex items-center justify-center w-full py-8">
              {currentState === 'loading' && (
                loadingComponent || <LoadingState message={loadingMessage} variant={loadingVariant} colorFamily={colorFamily} />
              )}
              
              {currentState === 'error' && (
                errorComponent || (
                  <ErrorState 
                    error={error} 
                    onRetry={onRetry}
                    variant="card"
                    colorFamily={colorFamily === 'warning' ? 'error' : colorFamily}
                  />
                )
              )}
              
              {currentState === 'empty' && (
                emptyComponent || (
                  <EmptyState
                    title={emptyMessage}
                    colorFamily={colorFamily}
                    variant="simple"
                  />
                )
              )}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

/**
 * TableStateContainer specialized for table contexts.
 * Offers more specific styling and animations for tables.
 */
export const TableStateContainer: React.FC<StateContainerProps> = (props): JSX.Element => {
  return (
    <StateContainer
      {...props}
      className={cn(
        "w-full bg-transparent dark:bg-blackish-800 border rounded-md overflow-hidden transition-colors duration-200",
        props.className
      )}
      loadingComponent={
        props.loadingComponent || <LoadingState message={props.loadingMessage || "Loading table data..."} variant="spinner" colorFamily={props.colorFamily || 'primary'} />
      }
      errorComponent={
        props.errorComponent || <ErrorState error={props.error} onRetry={props.onRetry} variant="card" colorFamily="error" />
      }
      emptyComponent={
        props.emptyComponent || <EmptyState title={props.emptyMessage || "No table data"} message="There are no records to display at this time." variant="simple" colorFamily={props.colorFamily || 'primary'} />
      }
    />
  );
};

/**
 * ChartStateContainer specialized for chart contexts.
 * Offers more specific styling and animations for charts.
 */
export const ChartStateContainer: React.FC<StateContainerProps> = (props): JSX.Element => {
  return (
    <StateContainer
      {...props}
      className={cn(
        "w-full h-full min-h-[200px] flex items-center justify-center",
        props.className
      )}
      loadingComponent={
        props.loadingComponent || <LoadingState message={props.loadingMessage || "Loading chart..."} variant="pulse" colorFamily={props.colorFamily || 'accent'} />
      }
      errorComponent={
        props.errorComponent || <ErrorState error={props.error} onRetry={props.onRetry} variant="card" colorFamily="error" />
      }
      emptyComponent={
        props.emptyComponent || <EmptyState title={props.emptyMessage || "No chart data"} variant="illustrated" colorFamily={props.colorFamily || 'accent'} />
      }
    />
  );
};
