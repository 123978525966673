/**
 * Dashboard Styling Utilities
 * 
 * Specialized styling utilities for dashboard components with priority focus
 */

import { cn } from '@/lib/utils';
import type { ColorFamily } from './style-types';
import { cardStyles, dashboardSpacing, gridConfigurations, metricDisplayStyles, transitions } from './theme';

/**
 * Get the full dashboard card class including gradient, border, and transition effects
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param isDarkMode - Whether to generate styles for dark mode
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the dashboard card
 */
export function getDashboardCardClass(
  colorFamily: ColorFamily,
  isDarkMode = false,
  className?: string
): string {
  const gradientClass = getCardGradientClass(colorFamily, isDarkMode);
  return cn(
    'shadow-md hover:shadow-lg',
    transitions.default,
    'overflow-hidden',
    gradientClass,
    className
  );
}

/**
 * Generate a consistent gradient class based on color family for dashboard cards
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param isDarkMode - Whether to generate styles for dark mode
 * @returns A string of CSS classes for the gradient
 */
export function getCardGradientClass(
  colorFamily: ColorFamily,
  isDarkMode = false
): string {
  const style = cardStyles[colorFamily];
  if (!style) return '';

  return `bg-gradient-${style.direction} ${isDarkMode ? style.dark : style.light}`;
}

/**
 * Get the animation class for a dashboard card based on color family
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @returns The animation class for the card
 */
export function getCardAnimationClass(colorFamily: ColorFamily): string {
  const style = cardStyles[colorFamily];
  return style.animationClass || '';
}

/**
 * Get the appropriate CSS classes for dashboard metrics values
 * 
 * @param isHighlighted - Whether the metric should be highlighted
 * @param colorFamily - Optional color family override
 * @returns CSS classes for the metric value
 */
export function getMetricValueClass(
  isHighlighted = false,
  colorFamily: ColorFamily = 'primary'
): string {
  const baseClass = metricDisplayStyles.value;
  if (!isHighlighted) return baseClass;

  const highlightClasses: Record<ColorFamily, string> = {
    primary: 'text-primary-700 dark:text-primary-300',
    secondary: 'text-secondary-700 dark:text-secondary-300',
    accent: 'text-accent-700 dark:text-accent-300',
    tertiary: 'text-secondary-700 dark:text-secondary-300',
    warning: 'text-warning-700 dark:text-warning-300'
  };

  return cn(baseClass, highlightClasses[colorFamily]);
}

/**
 * Get the appropriate CSS classes for dashboard metrics labels
 * 
 * @param isHighlighted - Whether the label should be highlighted
 * @returns CSS classes for the metric label
 */
export function getMetricLabelClass(isHighlighted = false): string {
  return cn(
    metricDisplayStyles.label,
    isHighlighted && 'font-medium'
  );
}

/**
 * Get the appropriate CSS classes for trend indicators
 * 
 * @param trend - The trend direction ('up', 'down', or 'neutral')
 * @returns CSS classes for the trend indicator
 */
export function getTrendClass(trend: 'up' | 'down' | 'neutral'): string {
  const trendClasses = {
    up: metricDisplayStyles.trendUp,
    down: metricDisplayStyles.trendDown,
    neutral: metricDisplayStyles.trendNeutral
  };

  return trendClasses[trend] || trendClasses.neutral;
}

/**
 * Get dashboard grid layout classes based on section type
 * 
 * @param sectionType - The type of dashboard section ('main', 'submissions', 'clients')
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the grid layout
 */
export function getDashboardGridClass(
  sectionType: 'dashboard' | 'submissions' | 'clients' = 'dashboard',
  className?: string
): string {
  const gridConfig = gridConfigurations[sectionType];

  return cn(
    'grid',
    gridConfig.columns,
    gridConfig.gap,
    className
  );
}

/**
 * Get CSS classes for dashboard section headers
 * 
 * @param colorFamily - The color family for the header
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the section header
 */
export function getDashboardSectionHeaderClass(
  colorFamily: ColorFamily = 'primary',
  className?: string
): string {
  const colorClasses: Record<ColorFamily, string> = {
    primary: 'text-primary-800 dark:text-primary-200',
    secondary: 'text-secondary-800 dark:text-secondary-200',
    accent: 'text-accent-800 dark:text-accent-200',
    tertiary: 'text-secondary-800 dark:text-secondary-200',
    warning: 'text-warning-800 dark:text-warning-200'
  };

  return cn(
    'text-xl font-bold flex items-center gap-2 border-b pb-2',
    colorClasses[colorFamily],
    'border-blackish-200 dark:border-blackish-700',
    className
  );
}

/**
 * Get CSS classes for dashboard card padding
 * 
 * @param size - The size of padding to apply ('small', 'medium', 'large')
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the card padding
 */
export function getCardPaddingClass(
  size: 'small' | 'medium' | 'large' = 'medium',
  className?: string
): string {
  const paddingClasses = {
    small: 'p-3 sm:p-4',
    medium: dashboardSpacing.cardPadding,
    large: 'p-5 sm:p-7'
  };

  return cn(paddingClasses[size], className);
}

/**
 * Get CSS classes for dashboard section spacing
 * 
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the section spacing
 */
export function getSectionSpacingClass(className?: string): string {
  return cn(
    dashboardSpacing.sectionGap,
    'mb-8',
    className
  );
}

/**
 * Get CSS classes for dashboard card header
 * 
 * @param colorFamily - The color family for the header
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the card header
 */
export function getCardHeaderClass(
  colorFamily: ColorFamily = 'primary',
  className?: string
): string {
  const colorClasses: Record<ColorFamily, string> = {
    primary: 'text-primary-700 dark:text-primary-300',
    secondary: 'text-secondary-700 dark:text-secondary-300',
    accent: 'text-accent-700 dark:text-accent-300',
    tertiary: 'text-secondary-700 dark:text-secondary-300',
    warning: 'text-warning-700 dark:text-warning-300'
  };

  return cn(
    'flex items-center justify-between mb-4',
    colorClasses[colorFamily],
    className
  );
}

/**
 * Get CSS classes for dashboard card content
 * 
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the card content
 */
export function getCardContentClass(className?: string): string {
  return cn(
    'text-blackish-800 dark:text-blackish-200',
    className
  );
}

/**
 * Get CSS classes for dashboard card footer
 * 
 * @param colorFamily - The color family for the footer
 * @param className - Additional custom classes to apply
 * @returns CSS classes for the card footer
 */
export function getCardFooterClass(
  colorFamily: ColorFamily = 'primary',
  className?: string
): string {
  const colorClasses: Record<ColorFamily, string> = {
    primary: 'border-primary-100 dark:border-primary-800/30',
    secondary: 'border-secondary-100 dark:border-secondary-800/30',
    accent: 'border-accent-100 dark:border-accent-800/30',
    tertiary: 'border-secondary-100 dark:border-secondary-800/30',
    warning: 'border-warning-100 dark:border-warning-800/30'
  };

  return cn(
    'mt-4 pt-3 border-t text-sm',
    colorClasses[colorFamily],
    'text-blackish-600 dark:text-blackish-400',
    className
  );
}
