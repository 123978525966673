/**
 * Modern ClientsTable Component
 * 
 * A modernized implementation of the ClientsTable using the TabbedTable architecture.
 * Provides a comprehensive view of clients with advanced filtering and sorting capabilities.
 */
import { Badge } from '@/components/ui/badge';
import {
  Calendar,
  CheckCircle,
  Package,
  Shield,
  User
} from '@/components/ui/icon';
import { ProximaTable } from '@/components/ui/proxima-table';
import type { TabbedTableDataState, TabbedTableTabConfig } from '@/components/ui/tabbed-table';
import { TabbedTable } from '@/components/ui/tabbed-table';
import { useDebounce } from '@/lib/useDebounce';
import { cn } from '@/lib/utils';
import type {
  Client,
  ClientType
} from '@/types/client.unified';
import {
  isEmployer
} from '@/types/client.unified';
import type { TableColumnDef, TableSortConfig } from '@/types/table-types';
import type { ColorFamily } from '@/types/ui-component-types';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClients } from '../ClientsContext';

/**
 * Import the product registry functionality
 */
import { formatProductBadges } from '@/utils/product-registry';

type ModernClientsTableProps = {
  /** Color family for styling consistency */
  colorFamily?: ColorFamily;
  /** Additional CSS classes to apply to the table */
  className?: string;
  /** Optional custom error handler */
  onError?: (error: Error) => void;
  /** Whether to display in card mode */
  useCard?: boolean;
  /** Whether to show search functionality */
  showSearch?: boolean;
  /** Card title when using card mode */
  cardTitle?: string;
  /** Additional CSS classes to apply to the card */
  cardClassName?: string;
}

/**
 * Status Badge component for displaying client status with proper styling
 */
const ClientStatusBadge = ({ active, className }: { active: boolean; className?: string }): JSX.Element => (
  <Badge
    variant={active ? "success" : "outline"}
    className={cn(
      "font-medium",
      active ? "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300" :
        "bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-300",
      className
    )}
  >
    {active ? 'Active' : 'Disabled'}
  </Badge>
);

/**
 * Modern implementation of ClientsTable using the TabbedTable architecture
 */
export const ModernClientsTable = memo(({
  colorFamily = 'primary',
  className,
  onError,
  useCard = true,
}: ModernClientsTableProps): JSX.Element => {
  const navigate = useNavigate();

  // Use the context for data and state management
  const {
    clientType,
    setClientType,
    clients,
    isLoading,
    error,
    refetch,
    sorting,
    setSorting,
    pagination,
    updateFilters,
    updateItemsPerPage
  } = useClients();

  // State for search and filtering
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('all');

  // Debounced search value with 750ms delay to prevent excessive filtering
  const debouncedSearchValue = useDebounce(searchTerm, 750);

  // Previous search value reference to prevent unnecessary updates
  const previousSearchRef = useRef<string>(debouncedSearchValue);

  /**
   * Handle row click navigation to client detail page
   */
  const handleRowClick = useCallback((client: Client) => {
    try {
      if (!client.id) return;

      navigate(`/clients/${client.id}`, {
        state: {
          client,
          clientType
        }
      });
    } catch (error) {
      onError?.(error instanceof Error ? error : new Error('Navigation error'));
    }
  }, [navigate, clientType, onError]);

  /**
   * Create column definitions with proper icons and typing
   */
  const createColumns = useCallback((type: ClientType): Array<TableColumnDef<Client>> => {
    // Common columns for all client types
    const baseColumns: Array<TableColumnDef<Client>> = [
      {
        id: 'name',
        header: (
          <div className="flex items-center gap-2">
            <User className={`h-4 w-4 text-${colorFamily}-500`} />
            Name
          </div>
        ),
        accessorKey: 'name',
        cell: ({ row }) => (
          <span className={cn(
            "font-medium transition-colors",
            `text-${colorFamily}-600 hover:text-${colorFamily}-800`,
            `dark:text-${colorFamily}-400 dark:hover:text-${colorFamily}-300`
          )}>
            {row.name || 'Unnamed'}
          </span>
        ),
        sortable: true,
        headerClassName: "whitespace-nowrap"
      },
      {
        id: 'creationTime',
        header: (
          <div className="flex items-center gap-2">
            <Calendar className={`h-4 w-4 text-${colorFamily}-500`} />
            Created
          </div>
        ),
        accessorKey: 'creationTime',
        cell: ({ row }) => (
          <div>
            {row.creationTime
              ? new Date(row.creationTime).toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
              : 'Unknown date'}
          </div>
        ),
        sortable: true,
        headerClassName: "whitespace-nowrap"
      },
      {
        id: 'status',
        header: (
          <div className="flex items-center gap-2">
            <CheckCircle className={`h-4 w-4 text-${colorFamily}-500`} />
            Status
          </div>
        ),
        accessorKey: 'isDisabled',
        cell: ({ row }) => <ClientStatusBadge active={!row.isDisabled} />,
        sortable: true,
        headerClassName: "whitespace-nowrap"
      },
      {
        id: 'mfa',
        header: (
          <div className="flex items-center gap-2">
            <Shield className={`h-4 w-4 text-${colorFamily}-500`} />
            MFA
          </div>
        ),
        accessorKey: 'mfaRequired',
        cell: ({ row }) => (
          <div className={cn(
            "font-medium"
          )}>
            {row.mfaRequired ? 'Enabled' : 'Disabled'}
          </div>
        ),
        sortable: true,
        headerClassName: "whitespace-nowrap"
      }
    ];

    // Add product column only for employers
    if (type === 'employer') {
      return [
        ...baseColumns,
        {
          id: 'products',
          header: (
            <div className="flex items-center gap-2">
              <Package className={`h-4 w-4 text-${colorFamily}-500`} />
              Products
            </div>
          ),
          // Use generic key as a workaround
          accessorKey: 'id',
          cell: ({ row }) => {
            // Type narrowing with isEmployer type guard
            if (isEmployer(row)) {
              return formatProductBadges(row.products);
            }
            return null;
          },
          sortable: false,
          headerClassName: "whitespace-nowrap"
        }
      ];
    }

    return baseColumns;
  }, [colorFamily]);

  // Search handler to update filtering - temporarily commented out
  /*
  const handleSearchChange = useCallback((term: string) => {
    console.log('Search term changed:', term);
    setSearchTerm(term);
    // API call will be triggered by the effect below using the debounced value
  }, []);
  */

  // Status filter handler - temporarily commented out
  /*
  const handleStatusFilterChange = useCallback((value: string) => {
    console.log('Status filter changed:', value);
    setStatusFilter(value);

    // Immediately update server-side filtering with status change
    updateFilters({
      search: debouncedSearchValue,
      status: value
    });
  }, [updateFilters, debouncedSearchValue]);
  */

  // Effect to update search params when debounced value changes
  useEffect(() => {
    // Only apply if the debounced value has actually changed
    if (previousSearchRef.current !== debouncedSearchValue) {
      previousSearchRef.current = debouncedSearchValue;

      // Only update if empty (to clear filter) or has at least 2 characters
      if (debouncedSearchValue === '' || debouncedSearchValue.length >= 2) {
        console.log('Debounced search updated, calling API with:', {
          search: debouncedSearchValue,
          status: statusFilter
        });
        updateFilters({
          search: debouncedSearchValue,
          status: statusFilter
        });
      }
    }
  }, [debouncedSearchValue, statusFilter, updateFilters]);

  // Memoized column definitions
  const columns = useMemo(() => createColumns(clientType), [createColumns, clientType]);

  // Tab change handler
  const handleTabChange = useCallback((tabId: string) => {
    setClientType(tabId as ClientType);

    // Reset filters in UI state
    setStatusFilter('all');
    setSearchTerm('');

    // Reset filters in API state - server-side filtering
    updateFilters({
      search: '',
      status: 'all'
    });
  }, [setClientType, updateFilters]);

  // Generate tabs configuration
  const tabs = useMemo<Array<TabbedTableTabConfig<ClientType>>>(() => [
    {
      id: 'employer' as ClientType,
      label: (
        <div className="flex items-center gap-2">
          <User className="h-4 w-4" />
          <span>Employers</span>
        </div>
      )
    },
    {
      id: 'serviceProvider' as ClientType,
      label: (
        <div className="flex items-center gap-2">
          <Package className="h-4 w-4" />
          <span>Service Providers</span>
        </div>
      )
    }
  ], []);

  // Create tabsData with only client type filtering since search/status filtering is now server-side
  const tabsData = useMemo(() => {
    const result: Record<ClientType, TabbedTableDataState<Client>> = {
      employer: {
        isLoading,
        error,
        data: clients,
        filteredData: []
      },
      serviceProvider: {
        isLoading,
        error,
        data: clients,
        filteredData: []
      }
    };

    // Filter data only by client type - server handles search and status filtering
    const filteredByType = clients.filter(client =>
      (clientType === 'employer' && isEmployer(client)) ||
      (clientType === 'serviceProvider' && !isEmployer(client))
    );

    // Set the filtered data
    result[clientType].filteredData = filteredByType;

    return result;
  }, [clients, clientType, isLoading, error]);

  // Status filter component - temporarily commented out
  /*
  const statusFilterComponent = useMemo(() => (
    <Select
      value={statusFilter}
      onValueChange={handleStatusFilterChange}
    >
      <SelectTrigger className="w-full sm:w-[180px] bg-transparent text-blackish-300">
        <SelectValue placeholder="Filter by status" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All Statuses</SelectItem>
        <SelectItem value="active">Active</SelectItem>
        <SelectItem value="disabled">Disabled</SelectItem>
      </SelectContent>
    </Select>
  ), [statusFilter, handleStatusFilterChange]);
  */

  // Handle sorting
  const handleSort = useCallback((sortConfig: TableSortConfig) => {
    setSorting(sortConfig);
  }, [setSorting]);

  return (
    <div className={cn("space-y-3 sm:space-y-4 pt-2 sm:pt-3 md:pt-4", className)}>
      <TabbedTable<ClientType, Client>
        tabs={tabs}
        activeTab={clientType}
        onTabChange={handleTabChange}
        tabsData={tabsData}
        searchEnabled={false}
        colorFamily={colorFamily}
        onRetry={refetch}
        className="pb-2"
        loadingMessage={`Loading ${clientType === 'employer' ? 'employers' : 'service providers'} data...`}
        isClientTable={true} // Add flag to indicate this is a client table, not a product table
      >
        {/* Search and filter container - stack vertically on mobile, horizontal on larger screens */}
        <div className="flex flex-col sm:flex-row items-start gap-3 pb-4 mt-1 w-full">
          {/* Search input - temporarily commented out */}
          <div className="relative w-full sm:w-64 max-w-full sm:max-w-[260px]">
            {/* 
            <Search
              className={`absolute left-2 top-2.5 h-4 w-4 text-${colorFamily}-400 dark:text-${colorFamily}-500`}
            />
            <Input
              placeholder="Search client..."
              value={searchTerm}
              onChange={(e) => { handleSearchChange(e.target.value); }}
              className="pl-8 bg-transparent w-full"
            />
            */}
            {/* Empty div to maintain spacing */}
            <div className="h-9"></div>
          </div>

          {/* Status filter - temporarily commented out */}
          <div className="w-full sm:w-auto">
            {/* 
            {statusFilterComponent}
            */}
            {/* Empty div to maintain spacing */}
            <div className="h-9"></div>
          </div>
        </div>

        <ProximaTable<Client>
          data={tabsData[clientType].filteredData ?? []}
          columns={columns}
          colorFamily={colorFamily}
          isInteractive={true}
          aria-label={`${clientType === 'employer' ? 'Employers' : 'Service Providers'} table`}

          // Card properties
          useCard={useCard}
          cardHeaderClassName="hidden"

          // Sorting
          sorting={sorting}
          onSort={handleSort}

          // Event handlers
          onRowClick={handleRowClick}

          // Pagination (integrate with context pagination)
          pagination={{
            enabled: true,
            disabled: isLoading || !!error,
            page: 0, // Client-side pagination is 0-based
            pageSize: pagination.itemsPerPage,
            totalItems: tabsData[clientType].filteredData?.length ?? 0,
            totalPages: Math.max(1, Math.ceil((tabsData[clientType].filteredData?.length ?? 0) / pagination.itemsPerPage)),
            onPageChange: (page: number) => { console.log('Page changed to:', page); }, // Placeholder implementation
            onPageSizeChange: (size: number) => { updateItemsPerPage(size); } // Use actual method from context
          }}
        />
      </TabbedTable>
    </div>
  );
});

// Add display name for debugging and DevTools
ModernClientsTable.displayName = 'ModernClientsTable';

export default ModernClientsTable;
