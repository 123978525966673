import type * as React from 'react';
import { TrendingUp, TrendingDown, Minus } from '@/components/ui/icon';
import { cn } from '@/lib/utils';

type TrendIndicatorProps = {
  value: number;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  positiveIsBetter?: boolean;
  suffix?: string;
  prefix?: string;
  showText?: boolean;
  showIcon?: boolean;
  iconSize?: number;
  neutralThreshold?: number;
  format?: (value: number) => string;
}

/**
 * A component that displays a trend indicator with optional text and icon
 * 
 * @example
 * // Basic usage with percentage
 * <TrendIndicator value={5} suffix="%" />
 * 
 * // Revenue with currency
 * <TrendIndicator value={-2.5} prefix="$" suffix="" />
 * 
 * // Processing time where negative is better
 * <TrendIndicator value={-10} positiveIsBetter={false} suffix="%" />
 */
export const TrendIndicator: React.FC<TrendIndicatorProps> = ({
  value,
  className = '',
  iconClassName = '',
  textClassName = '',
  positiveIsBetter = true,
  suffix = '%',
  prefix = '',
  showText = true,
  showIcon = true,
  iconSize = 16,
  neutralThreshold = 0,
  format,
}) => {
  // Determine if the trend is positive, negative, or neutral
  const isPositive = value > neutralThreshold;
  const isNegative = value < -neutralThreshold;
  const isNeutral = !isPositive && !isNegative;

  // Determine if the trend is good or bad for the business context
  const isGood = positiveIsBetter ? isPositive : isNegative;
  const isBad = positiveIsBetter ? isNegative : isPositive;

  // Format the value
  const formattedValue = format ? format(value) : Math.abs(value).toString();

  return (
    <div 
      className={cn(
        "flex items-center",
        isGood && "text-green-600 dark:text-green-400",
        isBad && "text-red-600 dark:text-red-400",
        isNeutral && "text-blackish-500 dark:text-blackish-400",
        className
      )}
      data-trend={isPositive ? 'up' : isNegative ? 'down' : 'neutral'}
      data-good={isGood ? 'true' : 'false'}
    >
      {showIcon && (
        <>
          {isPositive && <TrendingUp size={iconSize} className={cn("mr-2", iconClassName)} aria-label="Trending up" />}
          {isNegative && <TrendingDown size={iconSize} className={cn("mr-2", iconClassName)} aria-label="Trending down" />}
          {isNeutral && <Minus size={iconSize} className={cn("mr-2", iconClassName)} aria-label="No change" />}
        </>
      )}
      
      {showText && (
        <span className={cn("flex items-center whitespace-nowrap", textClassName)}>
          {prefix}{formattedValue}{suffix}
        </span>
      )}
    </div>
  );
};
