import type * as React from 'react';
import { getTableTabClass } from '@/utils/appStyleUtils';
import type { TabButtonProps } from '@/types/app-style-types';
import { cn } from '@/lib/utils';

/**
 * TableTab component for individual tab buttons
 */
export const TableTab: React.FC<TabButtonProps> = ({
  variant,
  onClick,
  className,
  children,
  colorFamily = 'primary',
  disabled = false
}) => {
  return (
    <button
      type="button"
      className={getTableTabClass({ variant, colorFamily, className })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

/**
 * TableTabs component to render a set of tabs for tables
 */
export type TableTabsProps = {
  activeTab: string;
  onTabChange: (tabId: string) => void;
  tabs: Array<{ id: string; label: string | React.ReactNode }>;
  className?: string;
  colorFamily?: TabButtonProps['colorFamily'];
  isDisabled?: boolean;
}

export const TableTabs: React.FC<TableTabsProps> = ({
  activeTab,
  onTabChange,
  tabs,
  className,
  colorFamily = 'primary',
  isDisabled = false
}) => {
  return (
    <div className={cn("flex space-x-2 w-full justify-start", className)}>
      {tabs.map(tab => (
        <TableTab
          key={tab.id}
          variant={activeTab === tab.id ? 'default' : 'outline'}
          onClick={() => { onTabChange(tab.id); }}
          colorFamily={colorFamily}
          disabled={isDisabled}
        >
          {tab.label}
        </TableTab>
      ))}
    </div>
  );
};
