// src/components/invoices/MissingClientsSection.tsx
import { Button } from "@/components/ui/button";
import { Check, Copy, XCircle } from '@/components/ui/icon';
import type { MissingClient } from "@/types/invoice-pricing-types";
import { useState } from "react";

export type MissingClientsSectionProps = {
    /**
     * List of missing clients
     */
    missingClients: MissingClient[];
    /**
     * Formatted JSON string for missing clients
     */
    formattedMissingClientsJson: string;
}

/**
 * MissingClientsSection displays information about clients that are missing from pricing data
 * Follows Proxima design system styles
 */
export const MissingClientsSection = ({
    missingClients,
    formattedMissingClientsJson
}: MissingClientsSectionProps): JSX.Element => {
    const [copySuccess, setCopySuccess] = useState<boolean>(false);

    /**
     * Copies the formatted JSON to clipboard
     */
    const handleCopyJson = (): void => {
        if (formattedMissingClientsJson) {
            navigator.clipboard.writeText(formattedMissingClientsJson)
                .then(() => {
                    setCopySuccess(true);
                    // Reset copy success indicator after 2 seconds
                    setTimeout(() => { setCopySuccess(false); }, 2000);
                })
                .catch(err => {
                    console.error('Failed to copy JSON: ', err);
                });
        }
    };

    // If there are no missing clients, don't render anything
    if (missingClients.length === 0) {
        return <></>;
    }

    return (
        <div className="p-3 border rounded-md bg-red-50 dark:bg-red-900/20">
            <h4 className="font-medium mb-2 flex items-center gap-2 text-red-700 dark:text-red-400">
                <XCircle size={16} />
                <span>Missing Clients ({missingClients.length})</span>
            </h4>
            <p className="mb-2 text-sm text-red-700 dark:text-red-400">
                These clients were found in the invoice but not in the pricing data.
            </p>

            {/* JSON formatted missing clients with copy button */}
            <div className="relative mt-3 border rounded bg-gray-100 dark:bg-blackish-800 p-3">
                <div className="flex justify-between mb-2">
                    <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                        JSON Format (copy and add to client-pricing.json)
                    </h5>
                    <Button
                        onClick={handleCopyJson}
                        variant="ghost"
                        size="sm"
                        className="flex items-center gap-1 h-6 text-xs"
                    >
                        {copySuccess ?
                            <><Check size={12} /> Copied!</> :
                            <><Copy size={12} /> Copy JSON</>
                        }
                    </Button>
                </div>
                <pre className="text-xs overflow-auto max-h-60 p-2 bg-gray-50 dark:bg-blackish-900/50 rounded border">
                    {formattedMissingClientsJson}
                </pre>
                <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                    Fill in the ProductName and Price fields for each product.
                </p>
            </div>
        </div>
    );
};
