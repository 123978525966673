/**
 * ClientsContext
 * 
 * Context provider for client data management and state.
 */

import useFetchClients from '@/hooks/useFetchClients.unified';
import type { ClientType, ClientsContextValue } from '@/types/client.unified';
import type * as React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

// Create the context with undefined initial value
const ClientsContext = createContext<ClientsContextValue | undefined>(undefined);

type ClientsProviderProps = {
  children: React.ReactNode;
  initialClientType?: ClientType;
}

/**
 * ClientsProvider - Client data state management
 */
export const ClientsProvider: React.FC<ClientsProviderProps> = ({
  children,
  initialClientType = 'employer',
}) => {
  // Active client type state
  const [clientType, setClientType] = useState<ClientType>(initialClientType);

  // Default sorting state
  const [sorting, setSorting] = useState<{ column: string; direction: 'asc' | 'desc' }>({
    column: 'name',
    direction: 'asc'
  });

  // Use the fetch hook for client data
  const {
    data,
    filteredData,
    isLoading,
    error,
    refetch,
    pagination,
    updateFilters,
    updateItemsPerPage,
    updatePage
  } = useFetchClients(clientType);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo<ClientsContextValue>(() => ({
    // Client type
    clientType,
    setClientType,

    // Data access
    clients: data,
    filteredClients: filteredData,
    isLoading,
    error,
    refetch,

    // Sorting
    sorting,
    setSorting,

    // Pagination
    pagination,
    updateItemsPerPage,
    updatePage,

    // Filtering
    updateFilters
  }), [
    clientType,
    data,
    filteredData,
    isLoading,
    error,
    refetch,
    sorting,
    pagination,
    updateItemsPerPage,
    updatePage,
    updateFilters
  ]);

  return (
    <ClientsContext.Provider value={contextValue}>
      {children}
    </ClientsContext.Provider>
  );
};

/**
 * Custom hook for accessing client data
 * Throws an error if used outside of ClientsProvider
 */
export const useClients = (): ClientsContextValue => {
  const context = useContext(ClientsContext);

  if (context === undefined) {
    throw new Error('useClients must be used within a ClientsProvider');
  }

  return context;
};

export default ClientsContext;
