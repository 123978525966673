import type * as React from 'react';
import { createContext, useContext, useState, useMemo } from 'react';
import type { TimeframeType, CompanyMetrics } from '@/types/';
import { useFetchCompanyMetrics } from '@/hooks/useFetchCompanyMetrics';

type DashboardContextValue = {
  // State
  timeframe: TimeframeType;
  setTimeframe: (timeframe: TimeframeType) => void;
  
  // Metrics data
  metricsData: CompanyMetrics | undefined;
  isMetricsLoading: boolean;
  isRevenueLoading: boolean;
  metricsError: Error | null;
  revenueError: Error | null;
  refetchMetrics: () => void;
  refetchRevenue: () => void;
}

const DashboardContext = createContext<DashboardContextValue | undefined>(undefined);

type DashboardProviderProps = {
  children: React.ReactNode;
  initialTimeframe?: TimeframeType;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({ 
  children,
  initialTimeframe = 'today'
}) => {
  // Timeframe state
  const [timeframe, setTimeframe] = useState<TimeframeType>(initialTimeframe);

  // Fetch metrics based on timeframe
  const {
    data: metricsData,
    isMetricsLoading,
    isRevenueLoading,
    metricsError,
    revenueError,
    refetchMetrics,
    refetchRevenue,
  } = useFetchCompanyMetrics(timeframe);

  // Memoize context value to prevent unnecessary renders
  const contextValue = useMemo(() => ({
    // State
    timeframe,
    setTimeframe,
    
    // Metrics data
    metricsData,
    isMetricsLoading,
    isRevenueLoading,
    metricsError,
    revenueError,
    refetchMetrics,
    refetchRevenue,
  }), [
    timeframe,
    metricsData,
    isMetricsLoading,
    isRevenueLoading,
    metricsError,
    revenueError,
  ]);

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

// Custom hook for consuming the dashboard context
export const useDashboard = (): DashboardContextValue => {
  const context = useContext(DashboardContext);
  
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  
  return context;
};
