import * as React from "react";
import type { ButtonProps } from "@/components/ui/button";
import { Button } from "@/components/ui/button";
import { Loader2, AlertCircle } from '@/components/ui/icon';
import { cn } from "@/lib/utils";

export type LoadingButtonProps = {
  isLoading?: boolean;
  isError?: boolean;
  loadingText?: string;
  errorText?: string;
  children: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onRetry?: () => void;
} & Omit<ButtonProps, "asChild">

export const LoadingButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ 
    className, 
    isLoading = false,
    isError = false, 
    loadingText, 
    errorText,
    children, 
    leftIcon, 
    rightIcon,
    onRetry,
    onClick,
    disabled,
    variant,
    ...props 
  }, ref) => {
    // Handle retry click
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (isError && onRetry) {
        onRetry();
      } else if (onClick) {
        onClick(e);
      }
    };

    return (
      <Button
        className={cn("flex items-center gap-2", className)}
        disabled={isLoading || disabled}
        ref={ref}
        variant={isError ? "destructive" : variant}
        onClick={handleClick}
        {...props}
      >
        {isLoading ? (
          <>
            <Loader2 className="h-4 w-4 animate-spin" />
            <span>{loadingText || children}</span>
          </>
        ) : isError ? (
          <>
            <AlertCircle className="h-4 w-4" />
            <span>{errorText || "Retry"}</span>
          </>
        ) : (
          <>
            {leftIcon}
            <span>{children}</span>
            {rightIcon}
          </>
        )}
      </Button>
    );
  }
);

LoadingButton.displayName = "LoadingButton";
