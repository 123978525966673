import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import type { StyledTableProps, TableStyleConfig } from '@/types/app-style-types';
import * as React from 'react';

/**
 * Context for providing table styling information to child components
 */
type StyledTableContext = {
  colorFamily: keyof TableStyleConfig;
  isInteractive: boolean;
}

const TableContext = React.createContext<StyledTableContext>({
  colorFamily: 'primary',
  isInteractive: true
});

/**
 * Hook to access the StyledTable context
 */
export const useStyledTable = () => React.useContext(TableContext);

/**
 * StyledTable component with consistent styling using CSS variables
 */
export const StyledTable: React.FC<StyledTableProps> = ({
  colorFamily,
  className,
  isCompact = false,
  isInteractive = true,
  children,
  'aria-label': ariaLabel,
  'aria-describedby': ariaDescribedBy
}) => {
  // Create CSS custom properties based on color family
  const getTableStyles = () => {
    const style: React.CSSProperties = {
      backgroundColor: `var(--table-${colorFamily}-bg, var(--table-bg))`,
      borderColor: `var(--table-border)`,
      color: `var(--table-${colorFamily}-text, var(--table-cell-color))`,
      transition: 'background-color 0.2s ease-out, color 0.2s ease-out, border-color 0.2s ease-out',
    };

    return style;
  };

  return (
    <TableContext.Provider value={{ colorFamily, isInteractive }}>
      <div
        className={cn(
          'w-full overflow-auto rounded-md hardware-accelerated',
          className
        )}
        style={getTableStyles()}
      >
        <Table
          className={cn(isCompact ? 'text-sm' : '')}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
        >
          {children}
        </Table>
      </div>
    </TableContext.Provider>
  );
};

/**
 * StyledTableHeader component with consistent styling
 */
export const StyledTableHeader: React.FC<{ className?: string; children?: React.ReactNode }> = ({
  className,
  children
}) => {
  return (
    <TableHeader className={className}>
      {children}
    </TableHeader>
  );
};

/**
 * StyledTableBody component with consistent styling
 */
export const StyledTableBody: React.FC<{ className?: string; children?: React.ReactNode }> = ({
  className,
  children
}) => {
  return (
    <TableBody className={className}>
      {children}
    </TableBody>
  );
};

/**
 * Props for clickable table elements
 */
type ClickableProps = {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

/**
 * StyledTableHead component with consistent styling based on CSS variables
 */
export const StyledTableHead: React.FC<ClickableProps> = ({
  className,
  children,
  onClick
}) => {
  const { colorFamily } = useStyledTable();

  // Use CSS variables for styling
  const headStyle: React.CSSProperties = {
    backgroundColor: `var(--table-head-bg)`,
    color: `var(--table-${colorFamily}-text, var(--table-head-color))`,
    borderColor: `var(--table-border)`,
  };

  return (
    <TableHead
      className={cn(
        'font-medium transition-colors duration-200',
        className,
        onClick && 'cursor-pointer'
      )}
      style={headStyle}
      onClick={onClick}
    >
      {children}
    </TableHead>
  );
};

/**
 * StyledTableRow component with styling from CSS variables
 */
export const StyledTableRow: React.FC<ClickableProps> = ({
  className,
  children,
  onClick
}) => {
  const { colorFamily, isInteractive } = useStyledTable();

  // Base styles using CSS variables
  const rowStyle: React.CSSProperties = {
    borderColor: `var(--table-border)`,
    transition: 'background-color 0.2s ease-out, border-color 0.2s ease-out',
  };

  return (
    <TableRow
      className={cn(
        'border-b',
        isInteractive && 'hover:bg-opacity-80',
        isInteractive && `hover:bg-[var(--table-${colorFamily}-hover,var(--table-row-hover))]`,
        onClick && 'cursor-pointer',
        className
      )}
      style={rowStyle}
      onClick={onClick}
    >
      {children}
    </TableRow>
  );
};

/**
 * StyledTableCell component with styling from CSS variables
 */
export const StyledTableCell: React.FC<{ className?: string; children?: React.ReactNode }> = ({
  className,
  children
}) => {
  const { colorFamily } = useStyledTable();

  // Use CSS variables for styling
  const cellStyle: React.CSSProperties = {
    color: `var(--table-${colorFamily}-text, var(--table-cell-color))`,
    transition: 'color 0.2s ease-out',
  };

  return (
    <TableCell
      className={cn('p-2 align-middle', className)}
      style={cellStyle}
    >
      {children}
    </TableCell>
  );
};

export default StyledTable;
