/**
 * Theme Configuration
 * 
 * Central theme configuration for application styling
 */

import type { CardStyleConfig, GradientStyle, TableStyleConfig, TableStyleVariant } from './style-types';

/**
 * Gradient styles for use across the application
 */
const gradientStyles: Record<string, GradientStyle> = {
  primary: {
    light: 'from-primary-50 to-transparent',
    dark: 'dark:from-primary-900/50 dark:to-transparent',
    direction: 'to-br',
    animationClass: 'gradient-border-animate primary'
  },
  secondary: {
    light: 'from-secondary-50 to-transparent',
    dark: 'dark:from-secondary-900/50 dark:to-transparent',
    direction: 'to-br',
    animationClass: 'gradient-border-animate secondary'
  },
  accent: {
    light: 'from-accent-50 to-transparent',
    dark: 'dark:from-accent-900/50 dark:to-transparent',
    direction: 'to-br',
    animationClass: 'gradient-border-animate accent'
  },
  tertiary: {
    light: 'from-secondary-50 to-transparent',
    dark: 'dark:from-secondary-900/50 dark:to-transparent',
    direction: 'to-br',
    animationClass: 'gradient-border-animate tertiary'
  },
  warning: {
    light: 'from-warning-50 to-transparent',
    dark: 'dark:from-warning-900/50 dark:to-transparent',
    direction: 'to-br',
    animationClass: 'gradient-border-animate warning'
  }
};

/**
 * Table style variants for use across the application
 */
const tableStyleVariants: Record<string, TableStyleVariant> = {
  primary: {
    headerClasses: 'bg-primary-50 dark:bg-primary-900/30 text-primary-700 dark:text-primary-300',
    rowClasses: 'border-primary-100 dark:border-primary-800/30',
    cellClasses: 'text-blackish-700 dark:text-blackish-300',
    hoverClasses: 'hover:bg-primary-50/50 dark:hover:bg-primary-900/20'
  },
  secondary: {
    headerClasses: 'bg-secondary-50 dark:bg-secondary-900/30 text-secondary-700 dark:text-secondary-300',
    rowClasses: 'border-secondary-100 dark:border-secondary-800/30',
    cellClasses: 'text-blackish-700 dark:text-blackish-300',
    hoverClasses: 'hover:bg-secondary-50/50 dark:hover:bg-secondary-900/20'
  },
  accent: {
    headerClasses: 'bg-accent-50 dark:bg-accent-900/30 text-accent-700 dark:text-accent-300',
    rowClasses: 'border-accent-100 dark:border-accent-800/30',
    cellClasses: 'text-blackish-700 dark:text-blackish-300',
    hoverClasses: 'hover:bg-accent-50/50 dark:hover:bg-accent-900/20'
  },
  tertiary: {
    headerClasses: 'bg-secondary-50 dark:bg-secondary-900/30 text-secondary-700 dark:text-secondary-300',
    rowClasses: 'border-secondary-100 dark:border-secondary-800/30',
    cellClasses: 'text-blackish-700 dark:text-blackish-300',
    hoverClasses: 'hover:bg-secondary-50/50 dark:hover:bg-secondary-900/20'
  },
  warning: {
    headerClasses: 'bg-warning-50 dark:bg-warning-900/30 text-warning-700 dark:text-warning-300',
    rowClasses: 'border-warning-100 dark:border-warning-800/30',
    cellClasses: 'text-blackish-700 dark:text-blackish-300',
    hoverClasses: 'hover:bg-warning-50/50 dark:hover:bg-warning-900/20'
  }
};

/**
 * Card style configuration for the entire application
 */
export const cardStyles: CardStyleConfig = {
  primary: gradientStyles.primary,
  secondary: gradientStyles.secondary,
  accent: gradientStyles.accent,
  tertiary: gradientStyles.tertiary,
  warning: gradientStyles.warning
};

/**
 * Table style configuration for the entire application
 */
export const tableStyles: TableStyleConfig = {
  primary: tableStyleVariants.primary,
  secondary: tableStyleVariants.secondary,
  accent: tableStyleVariants.accent,
  tertiary: tableStyleVariants.tertiary,
  warning: tableStyleVariants.warning
};

/**
 * Dashboard-specific spacing configuration
 */
export const dashboardSpacing = {
  cardGap: 'gap-6',
  sectionGap: 'gap-8',
  cardPadding: 'p-4 sm:p-6',
  sectionPadding: 'p-4 sm:p-6 md:p-8'
};

/**
 * Button style variants
 */
export const buttonVariants = {
  default: {
    primary: 'bg-primary-700 text-white dark:bg-primary-900',
    secondary: 'bg-secondary-700 text-white dark:bg-secondary-900',
    accent: 'bg-accent-700 text-white dark:bg-accent-900',
    tertiary: 'bg-secondary-700 text-white dark:bg-secondary-900',
    warning: 'bg-warning-700 text-white dark:bg-warning-900'
  },
  outline: {
    primary: 'bg-transparent text-primary-700 hover:bg-primary-50/30 dark:text-primary-400 dark:hover:bg-primary-900/10',
    secondary: 'bg-transparent text-secondary-700 hover:bg-secondary-50/30 dark:text-secondary-400 dark:hover:bg-secondary-900/10',
    accent: 'bg-transparent text-accent-700 hover:bg-accent-50/30 dark:text-accent-400 dark:hover:bg-accent-900/10',
    tertiary: 'bg-transparent text-secondary-700 hover:bg-secondary-50/30 dark:text-secondary-400 dark:hover:bg-secondary-900/10',
    warning: 'bg-transparent text-warning-700 hover:bg-warning-50/30 dark:text-warning-400 dark:hover:bg-warning-900/10'
  }
};

/**
 * Dashboard-specific metrics display styles
 */
export const metricDisplayStyles = {
  value: 'text-2xl font-bold text-blackish-900 dark:text-white',
  label: 'text-sm text-blackish-600 dark:text-blackish-400',
  icon: 'w-8 h-8 text-primary-600 dark:text-primary-400',
  trendUp: 'text-green-600 dark:text-green-400',
  trendDown: 'text-red-600 dark:text-red-400',
  trendNeutral: 'text-blue-600 dark:text-blue-400'
};

/**
 * Responsive grid configurations
 */
export const gridConfigurations = {
  dashboard: {
    columns: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
    gap: 'gap-6'
  },
  submissions: {
    columns: 'grid-cols-1 lg:grid-cols-2',
    gap: 'gap-4'
  },
  clients: {
    columns: 'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3',
    gap: 'gap-6'
  }
};

/**
 * Animation class configurations
 */
export const animationClasses = {
  fadeIn: 'animate-fadeIn',
  slideIn: 'animate-slideIn',
  pulse: 'animate-pulse',
  bounce: 'animate-bounce',
  spin: 'animate-spin'
};

/**
 * Shared transition properties
 */
export const transitions = {
  default: 'transition-all duration-300',
  fast: 'transition-all duration-150',
  slow: 'transition-all duration-500'
};
