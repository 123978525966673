/**
 * Records API Service
 *
 * Provides standardized API access for record data with consistent typing,
 * error handling, and response transformation.
 */
import type {
  AnimalAllergySubmission,
  GroundCannabisDustSubmission,
  HealthSubmission,
  HealthSubmissionBase,
  Location,
  RespiratorFitTestSubmission,
  RespiratorMedicalEvaluationSubmission,
  SubmissionStatus
} from '@/types';
import type {
  ApiRecord,
  PaginatedRecordResponse,
  RecordApiResponse,
  RecordFetchError,
  RecordQueryParams
} from '@/types/records-api.types';
import { fetchAuthSession } from 'aws-amplify/auth';


import {
  FitTestStatus,
  MedicalEvaluationStatus
} from '@/types/product.types';

// Standard timeout for API requests
const API_TIMEOUT = 15000;

/**
 * Standard API error with additional metadata for better error handling
 */
export class ApiError extends Error implements RecordFetchError {
  statusCode?: number;
  source = 'records-api';
  timestamp: string;
  retryCount = 0;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = statusCode;
    this.timestamp = new Date().toISOString();
  }
}

/**
 * Get the authentication token from Amplify
 */
const getAuthToken = async (): Promise<string> => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken;

    if (!accessToken) {
      throw new ApiError('No access token available', 401);
    }

    return accessToken.toString();
  } catch (error) {
    console.error('[API ERROR] Authentication error:', error);

    if (error instanceof Error) {
      if (error.message.includes('No current user')) {
        throw new ApiError('Authentication required. Please log in.', 401);
      }

      if (error.message.includes('expire')) {
        throw new ApiError('Your session has expired. Please log in again.', 401);
      }

      // Already an ApiError, just pass it through
      if (error instanceof ApiError) {
        throw error;
      }
    }

    throw new ApiError(
      'Failed to authenticate: ' + (error instanceof Error ? error.message : String(error)),
      500
    );
  }
};

/**
 * Reusable fetch function with proper authentication and error handling
 */
const fetchWithAuth = async <T>(
  url: string,
  options: RequestInit = {},
  timeout = API_TIMEOUT
): Promise<T> => {
  // Create an AbortController for request timeouts
  const controller = new AbortController();
  const timeoutId = setTimeout(() => { controller.abort(); }, timeout);

  try {
    const token = await getAuthToken();

    const response = await fetch(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...options.headers
      },
      signal: controller.signal
    });

    // Clear the timeout since we got a response
    clearTimeout(timeoutId);

    // Handle non-OK responses
    if (!response.ok) {
      const errorText = await response.text();
      console.error('[API ERROR] Request failed:', response.status, errorText);
      throw new ApiError(
        `API error (${response.status}): ${errorText || response.statusText}`,
        response.status
      );
    }

    // Get the raw text for controlled parsing
    const responseText = await response.text();

    // Handle empty responses (valid in some cases)
    if (!responseText.trim()) {
      return {} as T;
    }

    // Try to parse the response as JSON
    try {
      return JSON.parse(responseText) as T;
    } catch (parseError) {
      console.error('[API ERROR] Failed to parse response as JSON:', parseError);
      throw new ApiError('Failed to parse API response as JSON', 500);
    }
  } catch (error) {
    // Clear the timeout to avoid memory leaks
    clearTimeout(timeoutId);

    // Handle request timeout
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new ApiError(`Request timed out after ${timeout}ms`, 408);
    }

    // Propagate ApiError instances
    if (error instanceof ApiError) {
      throw error;
    }

    // Wrap other errors
    console.error('[API ERROR] Fetch error:', error);
    throw new ApiError(
      `Failed to fetch: ${error instanceof Error ? error.message : String(error)}`,
      500
    );
  }
};

/**
 * Simplified response transformation to minimize data manipulation
 */
const simplifyApiResponse = <T>(responseData: RecordApiResponse<T>): PaginatedRecordResponse<T> => {
  // Get items directly with minimal manipulation
  const items: T[] = responseData.data || responseData.items || [];

  // Use metadata directly if available or create a simple version
  const metadata = responseData.metadata || {};

  // Create simplified response with minimal transformations
  return {
    items,
    itemsPerPage: metadata && 'itemsPerPage' in metadata && typeof metadata.itemsPerPage === 'number' ?
      metadata.itemsPerPage : 25, // Default to 25 items per page
    page: metadata && 'page' in metadata && typeof metadata.page === 'number' ?
      metadata.page : 1,
    totalItems: metadata && 'totalItems' in metadata && typeof metadata.totalItems === 'number' ?
      metadata.totalItems : (metadata && 'totalCount' in metadata && typeof metadata.totalCount === 'number' ?
        metadata.totalCount : items.length),
    totalPages: metadata && 'totalPages' in metadata && typeof metadata.totalPages === 'number' ?
      metadata.totalPages : 1
  };
};

/**
 * Utility functions for mapping API records to HealthSubmission objects
 */

// Helper to safely access potentially undefined nested properties
const safeGet = (obj: any, path: string): any => {
  if (!obj) return undefined;
  const parts = path.split('.');
  let current = obj;

  for (const part of parts) {
    if (current === null || current === undefined) return undefined;
    current = current[part];
  }

  return current;
};

// Helper to find field by potential names
const findField = (record: ApiRecord, possibleNames: string[]): unknown | undefined => {
  for (const name of possibleNames) {
    if (record[name] !== undefined) {
      return record[name];
    }
  }
  return undefined;
};

// Helper to extract employee name from nested structures
const extractEmployeeName = (record: ApiRecord): string => {
  if (typeof record.employee === 'object' && record.employee !== null) {
    // Handle nested employee object
    const employee = record.employee;
    const firstName = safeGet(employee, 'firstName') || '';
    const lastName = safeGet(employee, 'lastName') || '';
    const middleName = safeGet(employee, 'middleName') || '';
    const nameSuffix = safeGet(employee, 'nameSuffix') || '';

    // Combine name parts into full name
    let fullName = `${firstName} ${lastName}`.trim();
    if (middleName) fullName = `${firstName} ${middleName} ${lastName}`;
    if (nameSuffix) fullName = `${fullName} ${nameSuffix}`;

    if (fullName) {
      return fullName;
    } else if (safeGet(employee, 'name')) {
      // If there's a direct name property
      return String(safeGet(employee, 'name'));
    } else if (safeGet(employee, 'id')) {
      // Fallback to ID if nothing else
      return `Employee #${safeGet(employee, 'id')}`;
    }
  } else {
    // Try flat structure for employee name if nested not found
    const nameField = findField(record, [
      'employeeName', 'employee', 'name', 'fullName', 'userName', 'user'
    ]);
    if (nameField && typeof nameField === 'string') {
      return nameField;
    }
  }

  return 'Unknown Employee';
};

// Helper to extract employer name from nested structures
const extractEmployerName = (record: ApiRecord): string => {
  if (typeof record.employer === 'object' && record.employer !== null) {
    // Get employer name from the nested employer object
    const employer = record.employer;
    if (safeGet(employer, 'name')) {
      return String(safeGet(employer, 'name'));
    } else if (safeGet(employer, 'preferredName')) {
      return String(safeGet(employer, 'preferredName'));
    } else if (safeGet(employer, 'id')) {
      return `Employer #${safeGet(employer, 'id')}`;
    }
  } else {
    // Try flat structure for employer if nested not found
    const employerField = findField(record, [
      'employerId', 'employer', 'company', 'companyId', 'organization', 'organizationId'
    ]);
    if (employerField && typeof employerField === 'string') {
      return employerField;
    }
  }

  return '-';
};

// Helper to extract service provider from nested structures
const extractServiceProvider = (record: ApiRecord): string => {
  if (typeof record.serviceProvider === 'object' && record.serviceProvider !== null) {
    const provider = record.serviceProvider;
    if (safeGet(provider, 'name')) {
      return String(safeGet(provider, 'name'));
    } else if (safeGet(provider, 'preferredName')) {
      return String(safeGet(provider, 'preferredName'));
    } else if (safeGet(provider, 'id')) {
      return `Provider #${safeGet(provider, 'id')}`;
    }
  } else {
    // Try flat structure for service provider
    const providerField = findField(record, [
      'serviceProvider', 'provider', 'service', 'vendor'
    ]);
    if (providerField && typeof providerField === 'string') {
      return providerField;
    }
  }

  return 'None';
};

// Helper to extract job role from nested structures
const extractJobRole = (record: ApiRecord): string => {
  if (typeof record.jobRole === 'object' && record.jobRole !== null) {
    const role = record.jobRole;
    if (safeGet(role, 'name')) {
      return String(safeGet(role, 'name'));
    }
  } else if (record.protocol && typeof record.protocol === 'string') {
    // Use protocol as a fallback for role
    return record.protocol.replace(/_/g, ' ');
  } else {
    // Try other flat structure fields for role
    const roleField = findField(record, [
      'role', 'jobTitle', 'position', 'title', 'occupation'
    ]);
    if (roleField && typeof roleField === 'string') {
      return roleField;
    }
  }

  return 'None';
};

// Helper to extract submission date
const extractSubmissionDate = (record: ApiRecord): string => {
  // First check testTime which appears to be the actual test date in the API
  if (record.testTime && typeof record.testTime === 'string') {
    return record.testTime;
  } else if (record.creationTime && typeof record.creationTime === 'string') {
    // Fall back to creationTime if testTime isn't available
    return record.creationTime;
  } else {
    // Try other date fields as a last resort
    const dateField = findField(record, [
      'date', 'submissionDate', 'created', 'createdAt', 'timestamp', 'submitted'
    ]);
    if (dateField && (typeof dateField === 'string' || dateField instanceof Date)) {
      return dateField instanceof Date ?
        dateField.toISOString() : dateField;
    }
  }

  return new Date().toISOString();
};

// Helper to extract code/identifier
const extractCode = (record: ApiRecord): string => {
  if (record.prn && typeof record.prn === 'string') {
    // Use PRN as code if available
    return record.prn.split(':').pop() || record.prn;
  } else {
    // Try other code fields
    const codeField = findField(record, [
      'code', 'submissionCode', 'referenceCode', 'reference', 'identifier'
    ]);
    if (codeField && typeof codeField === 'string') {
      return codeField;
    }
  }

  return 'None';
};

// Helper to map status/result to a valid SubmissionStatus
const mapStatus = (record: ApiRecord): SubmissionStatus => {
  // Status mapping table
  const statusMap: Record<string, SubmissionStatus> = {
    // Approved status mappings
    'PASS': 'Approved',
    'pass': 'Approved',
    'passed': 'Approved',
    'approved': 'Approved',
    'complete': 'Approved',
    'completed': 'Approved',
    'accepted': 'Approved',
    'CLEARED': 'Approved',
    'cleared': 'Approved',

    // Deferred status mappings
    'FAIL': 'Deferred',
    'fail': 'Deferred',
    'failed': 'Deferred',
    'deferred': 'Deferred',
    'denied': 'Deferred',
    'rejected': 'Deferred',
    'NOT_CLEARED': 'Deferred',
    'not_cleared': 'Deferred',

    // Pending status mappings
    'PENDING': 'Pending',
    'pending': 'Pending',
    'waiting': 'Pending',
    'submitted': 'Pending',
    'in_progress': 'Pending',
    'PENDING_REVIEW': 'Pending',
    'pending_review': 'Pending',

    // Reviewing status mappings
    'REVIEWING': 'Reviewing',
    'reviewing': 'Reviewing',
    'in_review': 'Reviewing',
    'under_review': 'Reviewing',
    'processing': 'Reviewing'
  };

  // Try to match on result field first
  if (record.result && typeof record.result === 'string') {
    const result = record.result.trim();

    // First try exact matches, then try lowercase matches
    if (result in statusMap) {
      return statusMap[result];
    } else {
      const lowercaseStatus = result.toLowerCase();
      if (lowercaseStatus in statusMap) {
        return statusMap[lowercaseStatus];
      }
    }
  }

  // Next try status field
  if (record.status && typeof record.status === 'string') {
    const status = record.status.trim();

    // First try exact matches, then try lowercase matches
    if (status in statusMap) {
      return statusMap[status];
    } else {
      const lowercaseStatus = status.toLowerCase();
      if (lowercaseStatus in statusMap) {
        return statusMap[lowercaseStatus];
      }
    }
  }

  // Try other status fields
  const statusField = findField(record, [
    'state', 'submissionStatus'
  ]);

  if (statusField && typeof statusField === 'string') {
    const statusValue = statusField.trim();

    // First try exact matches, then try lowercase matches
    if (statusValue in statusMap) {
      return statusMap[statusValue];
    } else {
      const lowercaseStatus = statusValue.toLowerCase();
      if (lowercaseStatus in statusMap) {
        return statusMap[lowercaseStatus];
      }
    }
  }

  return 'Pending'; // Default to Pending if no status found
};

// Helper to extract location from record
const extractLocation = (record: ApiRecord): Location | undefined => {
  if (typeof record.location === 'object' && record.location !== null) {
    const location = record.location;
    return {
      id: Number(safeGet(location, 'id')) || 0,
      name: String(safeGet(location, 'name') || ''),
      prn: String(safeGet(location, 'prn') || '')
    };
  }
  return undefined;
};

// Helper to gather details from various fields
const gatherDetails = (record: ApiRecord): string => {
  const detailsParts: string[] = [];

  // Include protocol in details if available
  if (record.protocol && typeof record.protocol === 'string') {
    detailsParts.push(`Protocol: ${record.protocol}`);
  }

  // Include respirator info in details if available
  if (typeof record.employerRespirator === 'object' && record.employerRespirator !== null) {
    const respirator = record.employerRespirator;
    if (safeGet(respirator, 'make') && safeGet(respirator, 'model')) {
      detailsParts.push(`Respirator: ${safeGet(respirator, 'make')} ${safeGet(respirator, 'model')}`);
    }
  }

  // Include location in details if available
  if (typeof record.location === 'object' && record.location !== null) {
    const location = record.location;
    if (safeGet(location, 'name')) {
      detailsParts.push(`Location: ${safeGet(location, 'name')}`);
    }
  }

  // Check for other detail fields
  const detailsFields = ['details', 'notes', 'comments', 'description', 'information'];
  detailsFields.forEach(field => {
    if (record[field] !== undefined && typeof record[field] === 'string') {
      detailsParts.push(record[field]);
    }
  });

  return detailsParts.length > 0 ? detailsParts.join(' | ') : '';
};

/**
 * Extract the server status for a Fit Test record
 */
const extractFitTestServerStatus = (record: ApiRecord): FitTestStatus | undefined => {
  if (record.result) {
    // First check the result field (most reliable for fit tests)
    const resultValue = String(record.result).trim().toUpperCase();
    if (resultValue === FitTestStatus.PASS || resultValue === FitTestStatus.FAIL) {
      return resultValue as FitTestStatus;
    }
  }

  // Then check the status field
  if (record.status) {
    const statusValue = String(record.status).trim().toUpperCase();
    if (statusValue === FitTestStatus.PASS || statusValue === FitTestStatus.FAIL) {
      return statusValue as FitTestStatus;
    }
  }

  // Default to FAIL if we can't determine (should rarely happen)
  return undefined;
};

/**
 * Extract the server status for a Medical Evaluation record
 */
const extractMedicalEvaluationServerStatus = (record: ApiRecord): MedicalEvaluationStatus | undefined => {
  if (record.status) {
    const statusValue = String(record.status).trim().toUpperCase();

    // Try to match with enum values
    if (Object.values(MedicalEvaluationStatus).includes(statusValue as MedicalEvaluationStatus)) {
      return statusValue as MedicalEvaluationStatus;
    }

    // Manual mapping for some common variations
    if (statusValue === 'CLEARED' || statusValue === 'CLEAR') {
      return MedicalEvaluationStatus.CLEARED;
    } else if (statusValue === 'DEFERRED' || statusValue === 'NOT_CLEARED') {
      return MedicalEvaluationStatus.DEFERRED;
    } else if (statusValue.includes('PENDING_INTERVIEW')) {
      return MedicalEvaluationStatus.PENDING_INTERVIEW;
    } else if (statusValue.includes('EXPIRED_INTERVIEW')) {
      return MedicalEvaluationStatus.EXPIRED_INTERVIEW;
    } else if (statusValue.includes('PENDING') || statusValue.includes('REVIEW')) {
      return MedicalEvaluationStatus.PENDING_REVIEW;
    }
  }

  // Default to undefined if we can't determine
  return undefined;
};

// Function to map respirator fit test specific fields
const mapRespiratorFitTest = (record: ApiRecord): RespiratorFitTestSubmission => {
  // Extract the server status for fit tests
  const serverStatus = extractFitTestServerStatus(record);

  // Create base submission object
  const baseSubmission = {
    id: record.id || String(Math.random()),
    submissionDate: extractSubmissionDate(record),
    employeeName: extractEmployeeName(record),
    employerId: extractEmployerName(record),
    serviceProvider: extractServiceProvider(record),
    role: extractJobRole(record),
    code: extractCode(record),
    status: mapStatus(record),
    serverStatus, // Add the typed server status
    details: gatherDetails(record),
    location: extractLocation(record),
    subtype: 'RESPIRATOR_FIT_TEST' as const
  };

  // Add respirator fit test specific fields
  return {
    ...baseSubmission,
    protocol: record.protocol as string | undefined,
    respiratorMake: safeGet(record, 'employerRespirator.make') as string | undefined,
    respiratorModel: safeGet(record, 'employerRespirator.model') as string | undefined,
    respiratorSize: record.respiratorSize as string | undefined,
    quantitativeFitFactor: record.quantitativeFitFactor as number | null | undefined,
    testTime: record.testTime as string | undefined,
    creationTime: record.creationTime as string | undefined,
    result: record.result as string | undefined
  };
};

// Function to map respirator medical evaluation specific fields
const mapRespiratorMedicalEvaluation = (record: ApiRecord): RespiratorMedicalEvaluationSubmission => {
  // Extract the server status for medical evaluations
  const serverStatus = extractMedicalEvaluationServerStatus(record);

  // Create base submission object
  const baseSubmission = {
    id: record.id || String(Math.random()),
    submissionDate: extractSubmissionDate(record),
    employeeName: extractEmployeeName(record),
    employerId: extractEmployerName(record),
    serviceProvider: extractServiceProvider(record),
    role: extractJobRole(record),
    code: extractCode(record),
    status: mapStatus(record),
    serverStatus, // Add the typed server status
    details: gatherDetails(record),
    location: extractLocation(record),
    subtype: 'RESPIRATOR_MEDICAL_EVALUATION' as const
  };

  // Add respirator medical evaluation specific fields
  return {
    ...baseSubmission,
    judgmentTime: record.judgmentTime as string | null | undefined,
    questionnaireId: safeGet(record, 'questionnaire.id') as string | number | undefined,
    riskAssessmentToken: record.riskAssessmentToken as string | undefined
  };
};

// Function to map ground cannabis dust medical surveillance specific fields
const mapGroundCannabisDust = (record: ApiRecord): GroundCannabisDustSubmission => {
  // Extract the server status for medical evaluations
  const serverStatus = extractMedicalEvaluationServerStatus(record);

  // Create base submission object
  const baseSubmission = {
    id: record.id || String(Math.random()),
    submissionDate: extractSubmissionDate(record),
    employeeName: extractEmployeeName(record),
    employerId: extractEmployerName(record),
    serviceProvider: extractServiceProvider(record),
    role: extractJobRole(record),
    code: extractCode(record),
    status: mapStatus(record),
    serverStatus, // Add the typed server status
    details: gatherDetails(record),
    location: extractLocation(record),
    subtype: 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION' as const
  };

  // Add ground cannabis dust medical surveillance specific fields
  return {
    ...baseSubmission,
    judgmentTime: record.judgmentTime as string | undefined,
    questionnaireId: safeGet(record, 'questionnaire.id') as string | number | undefined
  };
};

// Function to map animal allergy medical surveillance specific fields
const mapAnimalAllergy = (record: ApiRecord): AnimalAllergySubmission => {
  // Extract the server status for medical evaluations
  const serverStatus = extractMedicalEvaluationServerStatus(record);

  // Create base submission object
  const baseSubmission = {
    id: record.id || String(Math.random()),
    submissionDate: extractSubmissionDate(record),
    employeeName: extractEmployeeName(record),
    employerId: extractEmployerName(record),
    serviceProvider: extractServiceProvider(record),
    role: extractJobRole(record),
    code: extractCode(record),
    status: mapStatus(record),
    serverStatus, // Add the typed server status
    details: gatherDetails(record),
    location: extractLocation(record),
    subtype: 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION' as const
  };

  // Animal allergy specific fields will be added when schema is known
  return baseSubmission;
};

/**
 * Maps an API record to a HealthSubmission object based on its subtype.
 * Uses specialized mappers for each product type to handle their unique schema.
 */
export const mapRecordToHealthSubmission = (record: ApiRecord): HealthSubmission => {
  // Get subtype to determine which mapper to use
  const subtype = (record.subtype || record.productType) as string;

  // Choose the appropriate mapper based on subtype
  switch (subtype) {
    case 'RESPIRATOR_FIT_TEST':
      return mapRespiratorFitTest(record);

    case 'RESPIRATOR_MEDICAL_EVALUATION':
      return mapRespiratorMedicalEvaluation(record);

    case 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE':
    case 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION':
      return mapGroundCannabisDust(record);

    case 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE':
    case 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION':
      return mapAnimalAllergy(record);

    default:
      // For unknown types, create a base submission
      const baseSubmission: HealthSubmissionBase = {
        id: record.id || String(Math.random()),
        submissionDate: extractSubmissionDate(record),
        employeeName: extractEmployeeName(record),
        employerId: extractEmployerName(record),
        serviceProvider: extractServiceProvider(record),
        role: extractJobRole(record),
        code: extractCode(record),
        status: mapStatus(record),
        details: gatherDetails(record),
        location: extractLocation(record),
        subtype: subtype
      };

      return baseSubmission;
  }
};

/**
 * Get records from the API
 */
export const getRecords = async (params: RecordQueryParams = {}): Promise<PaginatedRecordResponse<HealthSubmission>> => {
  try {
    // Build query string from parameters
    const queryParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          // For arrays, append each value separately
          value.forEach(item => {
            queryParams.append(key, String(item));
          });
        } else {
          // For non-arrays, behave as before
          queryParams.append(key, String(value));
        }
      }
    });

    // Create URL with query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${import.meta.env.VITE_API_BASE_URL}/records${queryString ? `?${queryString}` : ''}`;

    const responseData = await fetchWithAuth<RecordApiResponse>(apiUrl);

    // Safety check for response data structure
    if (!responseData) {
      return {
        items: [],
        itemsPerPage: 25,
        page: 1,
        totalItems: 0,
        totalPages: 1
      };
    }

    // Transform API response to our internal format
    const paginatedResponse = simplifyApiResponse<ApiRecord>(responseData);

    // Map each record to a HealthSubmission
    const mappedItems = paginatedResponse.items.map(mapRecordToHealthSubmission);

    return {
      ...paginatedResponse,
      items: mappedItems
    };
  } catch (error) {
    console.error('[API ERROR] Error in getRecords:', error);

    // Rethrow ApiError instances
    if (error instanceof ApiError) {
      throw error;
    }

    // Wrap other errors
    throw new ApiError(
      'Failed to fetch records: ' + (error instanceof Error ? error.message : String(error)),
      500
    );
  }
};
