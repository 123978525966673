// src/hooks/useScrollReset.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A hook that resets scroll position when the route changes
 * @param elementId - ID of the element to reset scroll (defaults to 'main-content-area')
 */
export function useScrollReset(elementId = 'main-content-area'): void {
  const { pathname } = useLocation();
  
  useEffect((): (() => void) => {
    // Reset scroll for both the specified element and window
    const resetScroll = (): void => {
      // First try to find the element by ID
      const elementById = document.getElementById(elementId);
      if (elementById) {
        elementById.scrollTop = 0;
      }
      
      // Then try with querySelector as fallback for when ID might not be available yet
      const contentArea = document.querySelector('.flex-1.p-4.md\\:p-6.overflow-auto');
      if (contentArea instanceof HTMLElement) {
        contentArea.scrollTop = 0;
      }
      
      // Also reset window scroll as a final fallback
      window.scrollTo(0, 0);
    };
    
    // Apply immediately
    resetScroll();
    
    // Also apply after a small delay to ensure content is fully rendered
    const timeoutId = setTimeout(resetScroll, 50);
    
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [pathname, elementId]); // Re-run when the route changes
}