/**
 * Status Registry
 *
 * Single source of truth for all status information and transformations.
 * This centralized registry ensures consistent status handling across the application.
 */
import {
  AlertTriangle,
  CheckCircle,
  Timer,
  XCircle
} from '@/components/ui/icon';
import { ProductType } from '@/types/product.unified';
import type {
  DisplayStatus,
  FitTestStatusRegistry,
  MedicalEvaluationStatusRegistry,
  ServerStatus,
  StatusDefinition,
  StatusFilterOption,
  StatusRegistry
} from '@/types/status.types';
import {
  FitTestDisplayStatus,
  FitTestServerStatus,
  isValidServerStatus,
  MedicalEvaluationDisplayStatus,
  MedicalEvaluationServerStatus,
  SurveillanceDisplayStatus
} from '@/types/status.types';

/**
 * Map server statuses to their display values for medical evaluations
 */
export const SERVER_TO_DISPLAY_STATUS: Record<ServerStatus, DisplayStatus> = {
  // Medical Evaluation statuses
  [MedicalEvaluationServerStatus.CLEARED]: MedicalEvaluationDisplayStatus.APPROVED,
  [MedicalEvaluationServerStatus.DEFERRED]: MedicalEvaluationDisplayStatus.DEFERRED,
  [MedicalEvaluationServerStatus.EXPIRED_INTERVIEW]: MedicalEvaluationDisplayStatus.EXPIRED_INTERVIEW,
  [MedicalEvaluationServerStatus.PENDING_INTERVIEW]: MedicalEvaluationDisplayStatus.PENDING_INTERVIEW,
  [MedicalEvaluationServerStatus.PENDING_REVIEW]: MedicalEvaluationDisplayStatus.PENDING_REVIEW,

  // Fit Test statuses
  [FitTestServerStatus.PASS]: FitTestDisplayStatus.PASS,
  [FitTestServerStatus.FAIL]: FitTestDisplayStatus.FAIL
};

/**
 * Map server statuses to their display values specifically for surveillance products (GCD, LAA)
 * Uses "Cleared" instead of "Approved" for UI display
 */
export const SURVEILLANCE_SERVER_TO_DISPLAY_STATUS: Record<ServerStatus, DisplayStatus> = {
  // Medical Surveillance statuses (uses "Cleared" instead of "Approved") 
  [MedicalEvaluationServerStatus.CLEARED]: SurveillanceDisplayStatus.CLEARED,
  [MedicalEvaluationServerStatus.DEFERRED]: SurveillanceDisplayStatus.DEFERRED,
  [MedicalEvaluationServerStatus.EXPIRED_INTERVIEW]: SurveillanceDisplayStatus.EXPIRED_INTERVIEW,
  [MedicalEvaluationServerStatus.PENDING_INTERVIEW]: SurveillanceDisplayStatus.PENDING_INTERVIEW,
  [MedicalEvaluationServerStatus.PENDING_REVIEW]: SurveillanceDisplayStatus.PENDING_REVIEW,

  // Include Fit Test statuses for type completeness
  [FitTestServerStatus.PASS]: FitTestDisplayStatus.PASS,
  [FitTestServerStatus.FAIL]: FitTestDisplayStatus.FAIL
};

/**
 * Legacy status mapping to standardized server statuses
 */
export const LEGACY_STATUS_TO_SERVER_STATUS: Record<string, ServerStatus> = {
  // Common legacy formats for "Approved" status
  'Approved': MedicalEvaluationServerStatus.CLEARED,
  'approved': MedicalEvaluationServerStatus.CLEARED,
  'Cleared': MedicalEvaluationServerStatus.CLEARED,
  'cleared': MedicalEvaluationServerStatus.CLEARED,
  'APPROVED': MedicalEvaluationServerStatus.CLEARED,

  // Common legacy formats for "Deferred" status
  'Deferred': MedicalEvaluationServerStatus.DEFERRED,
  'deferred': MedicalEvaluationServerStatus.DEFERRED,
  'NOT_CLEARED': MedicalEvaluationServerStatus.DEFERRED,
  'not_cleared': MedicalEvaluationServerStatus.DEFERRED,

  // Common legacy formats for "Pending" status
  'Pending': MedicalEvaluationServerStatus.PENDING_REVIEW,
  'pending': MedicalEvaluationServerStatus.PENDING_REVIEW,
  'PENDING': MedicalEvaluationServerStatus.PENDING_REVIEW,

  // Common legacy formats for "Waiting" or "In Progress" status
  'waiting': MedicalEvaluationServerStatus.PENDING_REVIEW,
  'in_progress': MedicalEvaluationServerStatus.PENDING_REVIEW,

  // Common legacy formats for "Pass" status
  'pass': FitTestServerStatus.PASS,
  'PASS': FitTestServerStatus.PASS,
  'Pass': FitTestServerStatus.PASS,

  // Common legacy formats for "Fail" status
  'fail': FitTestServerStatus.FAIL,
  'FAIL': FitTestServerStatus.FAIL,
  'Fail': FitTestServerStatus.FAIL
};

/**
 * Medical Evaluation status registry with complete definitions
 */
export const MEDICAL_EVALUATION_STATUS_REGISTRY: MedicalEvaluationStatusRegistry = {
  [MedicalEvaluationServerStatus.CLEARED]: {
    serverStatus: MedicalEvaluationServerStatus.CLEARED,
    displayName: MedicalEvaluationDisplayStatus.APPROVED,
    description: 'Employee has been medically cleared to use the requested equipment',
    order: 1,
    styling: {
      variant: 'success',
      icon: CheckCircle,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.DEFERRED]: {
    serverStatus: MedicalEvaluationServerStatus.DEFERRED,
    displayName: MedicalEvaluationDisplayStatus.DEFERRED,
    description: 'Employee has not been cleared for equipment use and requires further evaluation',
    order: 2,
    styling: {
      variant: 'destructive',
      icon: XCircle,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.PENDING_REVIEW]: {
    serverStatus: MedicalEvaluationServerStatus.PENDING_REVIEW,
    displayName: MedicalEvaluationDisplayStatus.PENDING_REVIEW,
    description: 'Evaluation is awaiting review by a medical professional',
    order: 3,
    styling: {
      variant: 'outline',
      icon: Timer,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.PENDING_INTERVIEW]: {
    serverStatus: MedicalEvaluationServerStatus.PENDING_INTERVIEW,
    displayName: MedicalEvaluationDisplayStatus.PENDING_INTERVIEW,
    description: 'Employee needs to complete an interview before evaluation can proceed',
    order: 4,
    styling: {
      variant: 'warning',
      icon: Timer,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.EXPIRED_INTERVIEW]: {
    serverStatus: MedicalEvaluationServerStatus.EXPIRED_INTERVIEW,
    displayName: MedicalEvaluationDisplayStatus.EXPIRED_INTERVIEW,
    description: 'The scheduled interview time has passed and needs to be rescheduled',
    order: 5,
    styling: {
      variant: 'warning',
      icon: AlertTriangle,
      colorClasses: '',
    }
  }
};

/**
 * Fit Test status registry with complete definitions
 */
export const FIT_TEST_STATUS_REGISTRY: FitTestStatusRegistry = {
  [FitTestServerStatus.PASS]: {
    serverStatus: FitTestServerStatus.PASS,
    displayName: FitTestDisplayStatus.PASS,
    description: 'Fit test passed successfully',
    order: 1,
    styling: {
      variant: 'success',
      icon: CheckCircle,
      colorClasses: '',
    }
  },
  [FitTestServerStatus.FAIL]: {
    serverStatus: FitTestServerStatus.FAIL,
    displayName: FitTestDisplayStatus.FAIL,
    description: 'Fit test did not pass criteria',
    order: 2,
    styling: {
      variant: 'destructive',
      icon: XCircle,
      colorClasses: '',
    }
  }
};

/**
 * Medical Surveillance status registry with "Cleared" instead of "Approved"
 */
export const MEDICAL_SURVEILLANCE_STATUS_REGISTRY: MedicalEvaluationStatusRegistry = {
  [MedicalEvaluationServerStatus.CLEARED]: {
    serverStatus: MedicalEvaluationServerStatus.CLEARED,
    displayName: SurveillanceDisplayStatus.CLEARED, // "Cleared" instead of "Approved"
    description: 'Employee has been medically cleared for this surveillance program',
    order: 1,
    styling: {
      variant: 'success',
      icon: CheckCircle,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.DEFERRED]: {
    serverStatus: MedicalEvaluationServerStatus.DEFERRED,
    displayName: SurveillanceDisplayStatus.DEFERRED,
    description: 'Employee has not been cleared and requires further evaluation',
    order: 2,
    styling: {
      variant: 'destructive',
      icon: XCircle,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.PENDING_REVIEW]: {
    serverStatus: MedicalEvaluationServerStatus.PENDING_REVIEW,
    displayName: SurveillanceDisplayStatus.PENDING_REVIEW,
    description: 'Evaluation is awaiting review by a medical professional',
    order: 3,
    styling: {
      variant: 'outline',
      icon: Timer,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.PENDING_INTERVIEW]: {
    serverStatus: MedicalEvaluationServerStatus.PENDING_INTERVIEW,
    displayName: SurveillanceDisplayStatus.PENDING_INTERVIEW,
    description: 'Employee needs to complete an interview before evaluation can proceed',
    order: 4,
    styling: {
      variant: 'warning',
      icon: Timer,
      colorClasses: '',
    }
  },
  [MedicalEvaluationServerStatus.EXPIRED_INTERVIEW]: {
    serverStatus: MedicalEvaluationServerStatus.EXPIRED_INTERVIEW,
    displayName: SurveillanceDisplayStatus.EXPIRED_INTERVIEW,
    description: 'The scheduled interview time has passed and needs to be rescheduled',
    order: 5,
    styling: {
      variant: 'warning',
      icon: AlertTriangle,
      colorClasses: '',
    }
  }
};

/**
 * Combined status registry for ALL product type
 */
export const ALL_STATUS_REGISTRY: StatusRegistry = {
  ...MEDICAL_EVALUATION_STATUS_REGISTRY,
  ...FIT_TEST_STATUS_REGISTRY,
  ...MEDICAL_SURVEILLANCE_STATUS_REGISTRY
};

/**
 * Map of product types to their status registries
 */
export const PRODUCT_STATUS_REGISTRIES: Record<ProductType, StatusRegistry> = {
  [ProductType.ALL]: ALL_STATUS_REGISTRY,
  [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: MEDICAL_EVALUATION_STATUS_REGISTRY,
  [ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE]: MEDICAL_SURVEILLANCE_STATUS_REGISTRY,
  [ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE]: MEDICAL_SURVEILLANCE_STATUS_REGISTRY,
  [ProductType.RESPIRATOR_FIT_TEST]: FIT_TEST_STATUS_REGISTRY
};

/**
 * Normalize any status string to a valid ServerStatus
 * @param status Any status string (legacy or server format)
 * @returns Normalized ServerStatus or undefined if not found
 */
export function normalizeStatus(status: string | undefined): ServerStatus | undefined {
  if (!status) return undefined;

  // Already a valid ServerStatus
  if (isValidServerStatus(status)) {
    return status;
  }

  // Check legacy status mapping
  return LEGACY_STATUS_TO_SERVER_STATUS[status];
}

/**
 * Get display status from server status, with product type context
 * @param serverStatus Server status enum value
 * @param productType Optional product type to get context-specific display status
 * @returns Display status for UI or undefined if not found
 */
export function getDisplayStatus(
  serverStatus: ServerStatus | undefined,
  productType?: ProductType
): DisplayStatus | undefined {
  if (!serverStatus) return undefined;

  // Use the surveillance display mapping for GCD and LAA products
  if (productType === ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE ||
    productType === ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE) {
    return SURVEILLANCE_SERVER_TO_DISPLAY_STATUS[serverStatus];
  }

  // Default to standard display mapping
  return SERVER_TO_DISPLAY_STATUS[serverStatus];
}

/**
 * Get status definition by product type and status
 * @param productType Product type to get status for 
 * @param status Server status (will be normalized)
 * @returns Status definition or undefined if not found
 */
export function getStatusDefinition(
  productType: ProductType,
  status: string | undefined
): StatusDefinition | undefined {
  if (!status) return undefined;

  // Get the normalized status
  const normalizedStatus = normalizeStatus(status);
  if (!normalizedStatus) return undefined;

  // Get the applicable registry for this product type
  const registry = PRODUCT_STATUS_REGISTRIES[productType];
  if (!registry) return undefined;

  // Return the status definition
  return registry[normalizedStatus];
}

/**
 * Get status filter options for a product type
 * @param productType Product type to get status options for
 * @returns Array of filter options for UI components
 */
export function getStatusFilterOptions(productType: ProductType): StatusFilterOption[] {
  // Always start with the "all" option
  const options: StatusFilterOption[] = [
    { value: 'all', label: 'All Statuses' }
  ];

  // Get the applicable registry for this product type
  const registry = PRODUCT_STATUS_REGISTRIES[productType];
  if (!registry) return options;

  // Convert status definitions to filter options
  const statusOptions = Object.values(registry)
    .sort((a, b) => a.order - b.order)
    .map(def => ({
      value: def.serverStatus, // Use serverStatus as value (used for keys in React)
      label: def.displayName,   // Keep displayName for UI text
      serverStatus: def.serverStatus
    }));

  return [...options, ...statusOptions];
}

/**
 * Convert a display status to server status for API use
 * @param displayStatus Display status from UI
 * @param productType Product type context
 * @returns Server status value or undefined
 */
export function getServerStatusFromDisplayValue(
  displayStatus: string,
  productType: ProductType
): ServerStatus | undefined {
  // Special case for "all"
  if (displayStatus === 'all') return undefined;

  // Get the registry for this product type
  const registry = PRODUCT_STATUS_REGISTRIES[productType];
  if (!registry) return undefined;

  // Find the status definition that matches this display name
  const statusDef = Object.values(registry).find(
    def => def.displayName.toLowerCase() === displayStatus.toLowerCase()
  );

  if (statusDef) {
    return statusDef.serverStatus;
  }

  // If not found by display name, try direct normalization
  return normalizeStatus(displayStatus);
}
