// src/components/invoices/MissingProductsSection.tsx
import { AlertCircle } from '@/components/ui/icon';
import type { MissingProduct } from "@/types/invoice-pricing-types";

export type MissingProductsSectionProps = {
    /**
     * List of missing products
     */
    missingProducts: MissingProduct[];
}

/**
 * MissingProductsSection displays information about products missing from client pricing data
 * Follows Proxima design system styles
 */
export const MissingProductsSection = ({
    missingProducts
}: MissingProductsSectionProps): JSX.Element => {
    // If there are no missing products, don't render anything
    if (missingProducts.length === 0) {
        return <></>;
    }

    return (
        <div className="p-3 border rounded-md bg-amber-50 dark:bg-amber-900/20">
            <h4 className="font-medium mb-2 flex items-center gap-2 text-amber-700 dark:text-amber-400">
                <AlertCircle size={16} />
                <span>Missing Products ({missingProducts.length})</span>
            </h4>
            <ul className="space-y-1 text-sm text-amber-700 dark:text-amber-400">
                {missingProducts.map((item, index) => (
                    <li key={index} className="ml-5 list-disc">
                        Client: <strong>{item.ClientName}</strong>, Product: <strong>{item.ProductCode}</strong>
                    </li>
                ))}
            </ul>
            <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                These product codes were not found in the pricing data for the specified clients.
            </p>
        </div>
    );
};
