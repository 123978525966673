/**
 * Product Registry
 *
 * Single source of truth for all product definitions and badge rendering.
 */
import { Activity, Leaf, Masks, Package, Rat } from '@/components/ui/icon';
import type { 
  ProductDefinition, 
  ProductRegistry} from '@/types/product.types';
import { 
  ProductType
} from '@/types/product.types';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import type React from 'react';

/**
 * The product registry containing all product definitions
 */
export const PRODUCTS: ProductRegistry = {
  [ProductType.ALL]: {
    id: ProductType.ALL,
    key: 'ALL',
    name: 'All Products',
    shortName: 'All',
    description: 'View submissions from all product types',
    badge: {
      variant: 'outline',
      icon: Package,
      colorClasses: 'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300',
    },
    order: 1, // Always first
  },
  [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: {
    id: ProductType.RESPIRATOR_MEDICAL_EVALUATION,
    key: 'RESPIRATOR_MEDICAL_EVALUATION',
    name: 'Respirator MEQ',
    shortName: 'MEQ',
    description: 'Medical evaluation for respirator use',
    badge: {
      variant: 'default',
      icon: Activity,
      colorClasses: 'bg-primary-100 text-primary-800 dark:bg-primary-900/30 dark:text-primary-300',
    },
    order: 2, // Second
  },
  [ProductType.RESPIRATOR_FIT_TEST]: {
    id: ProductType.RESPIRATOR_FIT_TEST,
    key: 'RESPIRATOR_FIT_TEST',
    name: 'Respirator Fit Test',
    shortName: 'RFT',
    description: 'Testing for proper respirator fit',
    badge: {
      variant: 'default',
      icon: Masks,
      colorClasses: 'bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-300',
    },
    order: 3, // Third
  },
  [ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE]: {
    id: ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE,
    key: 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION',
    name: 'Ground Cannabis Dust MSQ',
    shortName: 'GCD',
    description: 'Medical surveillance for cannabis dust exposure',
    badge: {
      variant: 'success',
      icon: Leaf,
      colorClasses: 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
    },
    order: 4, // Fourth
  },
  [ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE]: {
    id: ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE,
    key: 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION',
    name: 'Lab Animal Allergy MSQ',
    shortName: 'LAA',
    description: 'Medical surveillance for laboratory animal allergies',
    badge: {
      variant: 'default',
      icon: Rat,
      colorClasses: 'bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-300',
    },
    order: 5, // Fifth
  },
  // Add additional products as needed
};

/**
 * Get product types in their defined display order
 * @returns Array of ProductType in display order
 */
export const getOrderedProductTypes = (): ProductType[] => {
  return Object.values(PRODUCTS)
    .sort((a, b) => a.order - b.order)
    .map(product => product.id);
};

/**
 * ProductBadge component renders a consistent badge for any product
 */
export type ProductBadgeProps = {
  productType: ProductType | string;
  className?: string;
  id?: string | number;
  showName?: boolean;
}

/**
 * Helper to determine if a string is a valid ProductType
 */
export const isValidProductType = (value: string): value is ProductType => {
  return Object.values(ProductType).includes(value as ProductType);
};

/**
 * Get product definition by key or type
 */
export const getProductDefinition = (productIdentifier: string): ProductDefinition | undefined => {
  // First try direct lookup by enum
  if (isValidProductType(productIdentifier)) {
    return PRODUCTS[productIdentifier as ProductType];
  }
  
  // Then search by key
  return Object.values(PRODUCTS).find(product => product.key === productIdentifier);
};

/**
 * ProductBadge component for displaying consistent product badges
 */
export const ProductBadge: React.FC<ProductBadgeProps> = ({ 
  productType, 
  className,
  id = 'product-badge',
  showName = false
}) => {
  // Get product definition from registry
  const product = getProductDefinition(productType);
  
  // If product not found in registry, show a fallback badge
  if (!product) {
    return (
      <Badge 
        key={id} 
        variant="outline" 
        className={cn(
          "flex items-center gap-1 text-blackish-600 dark:text-blackish-300",
          className
        )}
      >
        {typeof productType === 'string' ? productType : 'Unknown'}
      </Badge>
    );
  }
  
  // Destructure badge styling
  const { badge } = product;
  const { variant, icon: Icon, colorClasses } = badge;
  
  // Render consistent badge using product definition
  return (
    <Badge 
      key={id}
      variant={variant}
      className={cn(
        "flex items-center gap-1",
        colorClasses,
        className
      )}
    >
      <Icon className="h-4 w-4" />
      <span>{showName ? product.name : product.shortName}</span>
    </Badge>
  );
};

/**
 * Renders multiple product badges for an array of products
 */
export const formatProductBadges = (
  products?: Array<{ id?: string | number; key?: string; name?: string }>,
  className?: string
): JSX.Element => {
  if (!products?.length) {
    return <span className="text-muted text-blackish-400 dark:text-blackish-500">None</span>;
  }

  return (
    <div className={cn("flex flex-wrap gap-2", className)}>
      {products.map((product, index) => {
        const id = product.id || index;
        const key = product.key || '';
        
        return (
          <ProductBadge 
            key={`product-${id}`}
            id={id}
            productType={key}
            showName={false}
          />
        );
      })}
    </div>
  );
};
