/**
 * Modern SubmissionsTable Component
 * 
 * A modernized implementation of the SubmissionsTable using the records API.
 * Provides a comprehensive view of health submissions with dynamic field mapping.
 * Organized with tabs for different product types.
 */
import { Badge } from '@/components/ui/badge';
import { Briefcase, Building, Calendar, OctagonAlert, Package, QrCode, Search, User } from '@/components/ui/icon';
import { Input } from '@/components/ui/input';
import { ProximaTable } from '@/components/ui/proxima-table';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import {
  StatusBadge
} from '@/components/ui/status-badge';
import type { TabbedTableDataState } from '@/components/ui/tabbed-table';
import { TabbedTable } from '@/components/ui/tabbed-table';
import type { SubmissionFetchError } from '@/hooks/useFetchSubmissionsCompat';
import { useDebounce } from '@/lib/useDebounce';
import { cn } from '@/lib/utils';
import type {
  GroundCannabisDustSubmission,
  HealthSubmission,
  RespiratorFitTestSubmission,
  RespiratorMedicalEvaluationSubmission
} from '@/types';
import {
  isValidSubmissionStatus
} from '@/types';
import { ProductType } from '@/types/product.unified';
import type { FrontendQueryParams } from '@/types/records-query-parameters';
import { FitTestServerStatus, MedicalEvaluationServerStatus } from '@/types/status.types';
import type { TableColumnDef, TableSortConfig } from '@/types/table-types';
import { PRODUCTS, getOrderedProductTypes, getProductDefinition } from '@/utils/product-registry';
import { getProtocolDisplayName } from '@/utils/protocol-registry';
import {
  getServerStatusFromDisplayValue,
  getStatusFilterOptions
} from '@/utils/status.registry';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSubmissions } from '../SubmissionsContext';

type SubmissionsTableProps = {
  data?: HealthSubmission[];
  error?: SubmissionFetchError | null;
  isLoading?: boolean;
  onRetry?: () => void;
  pagination?: boolean;
  useContext?: boolean; // Flag to determine whether to use context or props
}

/**
 * Modern implementation of the SubmissionsTable that fetches from the records API
 * and handles dynamic data structures with intelligent field mapping.
 * Now organized with tabs for different product types.
 */
export const ModernSubmissionsTable = memo(({
  data: propData = [],
  error: propError = null,
  isLoading: propIsLoading = false,
  onRetry: propOnRetry = () => { },
  pagination = true,
  useContext = false
}: SubmissionsTableProps): JSX.Element => {
  // Use the submissions context if enabled, otherwise use props
  const contextValue = useSubmissions();

  // Merge context and props based on the useContext flag
  const submissionsData = useMemo(() =>
    useContext ? contextValue.submissionsData : propData
    , [useContext, contextValue.submissionsData, propData]);

  const error = useMemo(() =>
    useContext ? contextValue.submissionsError : propError
    , [useContext, contextValue.submissionsError, propError]);

  const isLoading = useMemo(() =>
    useContext ? contextValue.isSubmissionsLoading : propIsLoading
    , [useContext, contextValue.isSubmissionsLoading, propIsLoading]);

  const onRetry = useMemo(() =>
    useContext ? contextValue.refetchSubmissions : propOnRetry
    , [useContext, contextValue.refetchSubmissions, propOnRetry]);

  // Get active product type from context when using context, or use default (ALL)
  const activeProductType = useMemo(() =>
    useContext ? contextValue.activeProductType : ProductType.ALL
    , [useContext, contextValue.activeProductType]);

  // Apply data from context or props
  const data = submissionsData || [];

  // State for search, sorting, and pagination
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [clientCurrentPage, setClientCurrentPage] = useState(0); // For client-side pagination
  const [clientPageSize] = useState(25); // Default to 25 items per page for client-side pagination
  const [sorting, setSorting] = useState<TableSortConfig>({
    column: 'submissionDate',
    direction: 'desc'
  });

  // Get pagination metadata from context when using context
  const paginationMetadata = useMemo(() =>
    useContext ? contextValue.paginationMetadata : {
      itemsPerPage: clientPageSize,
      page: 1,
      totalItems: data.length,
      totalPages: Math.max(1, Math.ceil(data.length / clientPageSize))
    }
    , [useContext, contextValue.paginationMetadata, data.length, clientPageSize]);

  // Current page and page size based on whether we're using context or not
  const currentPage = useContext ? paginationMetadata.page - 1 : clientCurrentPage; // Convert 1-based to 0-based for UI
  const pageSize = useContext ? paginationMetadata.itemsPerPage : clientPageSize;

  // Debounced search value with 750ms delay to prevent excessive API calls (increased from 500ms)
  const debouncedSearchValue = useDebounce(searchTerm, 750);


  // Search handler to update server-side filtering parameters
  const handleSearchChange = useCallback((term: string) => {
    setSearchTerm(term);

    // Only apply to server if using context
    if (useContext) {
      // Note: The actual API request will use the debounced version of this term
      // We're just updating the UI state here
    }
  }, [useContext]);

  // Effect to update search params when debounced value changes, but use a ref to prevent infinite loops
  const previousSearchRef = useRef<string>(debouncedSearchValue);

  useEffect(() => {
    // Only apply if the debounced value has actually changed
    if (previousSearchRef.current !== debouncedSearchValue && useContext) {
      previousSearchRef.current = debouncedSearchValue;

      // Only update search params if empty (to clear) or has at least 2 characters
      const searchParam = debouncedSearchValue === '' || debouncedSearchValue.length >= 2
        ? debouncedSearchValue
        : undefined;

      // Update the query params - React Query will automatically refetch
      contextValue.setQueryParams({
        ...contextValue.queryParams,
        employeeName: searchParam,
        page: 1 // Reset to first page when search changes
      });
    }
  }, [debouncedSearchValue, useContext, contextValue]);

  // Status filter handler to update server-side filtering parameters
  const handleStatusFilterChange = useCallback((value: string) => {
    // If same status is selected again, force a refetch
    const isSameStatus = value === statusFilter;
    setStatusFilter(value);

    // Only apply to server if using context
    if (useContext) {
      const updatedParams: Record<string, unknown> = {
        ...contextValue.queryParams,
        page: 1, // Reset to first page when filter changes
        ...(isSameStatus ? { _t: Date.now() } : {}) // Only add timestamp if same status selected again
      };

      // Handle different product types differently
      if (activeProductType === ProductType.RESPIRATOR_MEDICAL_EVALUATION) {
        if (value === 'all') {
          // When "All Statuses" is selected, include all status values
          updatedParams.respiratorMedicalEvaluationStatus = Object.values(MedicalEvaluationServerStatus);
        } else {
          // When a specific status is selected, only include that status
          const serverStatus = getServerStatusFromDisplayValue(value, activeProductType);
          if (serverStatus) {
            updatedParams.respiratorMedicalEvaluationStatus = [serverStatus];
          } else {
            // Fallback if no server status is found
            delete updatedParams.respiratorMedicalEvaluationStatus;
          }
        }
      } else if (activeProductType === ProductType.RESPIRATOR_FIT_TEST) {
        // Add explicit subtype parameter to work around server issue
        updatedParams.subtype = ['RESPIRATOR_FIT_TEST'];

        if (value === 'all') {
          // When "All Statuses" is selected, include all status values
          updatedParams.respiratorFitTestResult = Object.values(FitTestServerStatus);
        } else {
          // When a specific status is selected, only include that status
          const serverStatus = getServerStatusFromDisplayValue(value, activeProductType);
          if (serverStatus) {
            updatedParams.respiratorFitTestResult = [serverStatus];
          } else {
            // Fallback if no server status is found
            delete updatedParams.respiratorFitTestResult;
          }
        }
      } else if (activeProductType === ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE) {
        if (value === 'all') {
          // When "All Statuses" is selected, don't include any status filter
          delete updatedParams.groundCannabisDustMedicalSurveillanceEvaluationStatus;
        } else {
          // When a specific status is selected, only include that status
          const serverStatus = getServerStatusFromDisplayValue(value, activeProductType);
          if (serverStatus) {
            // Use the original serverStatus value (not mapped)
            updatedParams.groundCannabisDustMedicalSurveillanceEvaluationStatus = [serverStatus];
            // Also set the parameter that the records-query-parameters.ts checks for
            updatedParams.groundCannabisDustStatus = [serverStatus];
          } else {
            // Fallback if no server status is found
            delete updatedParams.groundCannabisDustMedicalSurveillanceEvaluationStatus;
          }
        }
      } else if (activeProductType === ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE) {
        if (value === 'all') {
          // When "All Statuses" is selected, don't include any status filter
          delete updatedParams.animalAllergyMedicalSurveillanceEvaluationStatus;
        } else {
          // When a specific status is selected, only include that status
          const serverStatus = getServerStatusFromDisplayValue(value, activeProductType);
          if (serverStatus) {
            // Use the original serverStatus value (not mapped)
            updatedParams.animalAllergyMedicalSurveillanceEvaluationStatus = [serverStatus];
            // Also set the parameter that the records-query-parameters.ts checks for
            updatedParams.animalAllergyStatus = [serverStatus];
          } else {
            // Fallback if no server status is found
            delete updatedParams.animalAllergyMedicalSurveillanceEvaluationStatus;
          }
        }
      } else {
        // For other product types, use the existing behavior
        const serverStatus = value !== 'all'
          ? getServerStatusFromDisplayValue(value, activeProductType)
          : undefined;

        updatedParams.status = serverStatus;
      }

      // Apply the updated parameters
      contextValue.setQueryParams(updatedParams);
    }
  }, [useContext, contextValue, activeProductType, statusFilter]);

  // Page change handler
  const handlePageChange = useCallback((page: number) => {
    if (useContext) {
      // Use the context pagination for API-based pagination
      contextValue.goToPage(page + 1); // +1 because API pagination is 1-based, but UI is 0-based
    } else {
      // Use client-side pagination when not using context
      setClientCurrentPage(page);
    }
  }, [useContext, contextValue]);

  // Handle page size changes
  const handlePageSizeChange = useCallback((size: number) => {
    if (useContext) {
      contextValue.setPageSize(size);
    }
  }, [useContext, contextValue]);

  // Format date safely with fallbacks for invalid values
  const formatDate = useCallback((dateValue: string | Date | unknown): string => {
    try {
      if (!dateValue) return 'Unknown date';

      const date = dateValue instanceof Date
        ? dateValue
        : new Date(String(dateValue));

      // Format: m/d/yy hh:mm (12hr format)
      return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    } catch (error) {
      return 'Invalid date';
    }
  }, []);

  // Column definitions for ProximaTable with robust error handling
  const columns = useMemo<Array<TableColumnDef<HealthSubmission>>>(() => {
    // Define base columns that are common to all submission types
    const baseColumns: Array<TableColumnDef<HealthSubmission>> = [
      {
        id: 'submissionDate',
        header: (
          <div className="flex items-center">
            <Calendar className="h-4 w-4 mr-2 text-warning-500" />
            Date
          </div>
        ),
        accessorKey: 'submissionDate',
        cell: ({ row }) => {
          // For RFT records, use creationTime instead of submissionDate (regardless of active tab)
          if (activeProductType === ProductType.RESPIRATOR_FIT_TEST || row.subtype === ProductType.RESPIRATOR_FIT_TEST) {
            // Use creationTime if available, otherwise fall back to submissionDate
            const creationTime = row.creationTime;
            return <div>{creationTime ? formatDate(creationTime) : formatDate(row.submissionDate)}</div>;
          }
          return <div>{formatDate(row.submissionDate)}</div>;
        },
        sortable: true
      },
      {
        id: 'status',
        header: (
          <div className="flex items-center">
            <OctagonAlert className="h-4 w-4 mr-2 text-warning-500" />
            Status
          </div>
        ),
        accessorKey: 'status',
        cell: ({ row }) => {
          // Get the status from either the serverStatus (preferred) or the legacy status field
          const serverStatus = row.serverStatus as string | undefined;
          const legacyStatus = row.status as string | undefined;

          // Always prefer serverStatus for consistent display across product types
          return (
            <div className="inline-flex w-auto">
              <StatusBadge
                productType={activeProductType}
                status={serverStatus || legacyStatus}
              />
            </div>
          );
        },
        sortable: true
      },
      {
        id: 'employeeName',
        header: (
          <div className="flex items-center">
            <User className="h-4 w-4 mr-2 text-warning-500" />
            Employee
          </div>
        ),
        accessorKey: 'employeeName',
        cell: ({ row }) => <div>{row.employeeName || 'Unknown'}</div>,
        sortable: true
      },
      {
        id: 'employerId',
        header: (
          <div className="flex items-center">
            <Building className="h-4 w-4 mr-2 text-warning-500" />
            Employer
          </div>
        ),
        accessorKey: 'employerId',
        cell: ({ row }) => <div>{row.employerId || 'Unknown'}</div>,
        sortable: true
      },
      {
        id: 'serviceProvider',
        header: (
          <div className="flex items-center">
            <Building className="h-4 w-4 mr-2 text-warning-500" />
            SP
          </div>
        ),
        accessorKey: 'serviceProvider',
        cell: ({ row }) => <div>{row.serviceProvider || 'Unknown'}</div>,
        sortable: true
      },
      {
        id: 'role',
        header: (
          <div className="flex items-center">
            <Briefcase className="h-4 w-4 mr-2 text-warning-500" />
            Role
          </div>
        ),
        accessorKey: 'role',
        cell: ({ row }) => <div>{row.role || 'Unknown'}</div>,
        sortable: true
      },
      {
        id: 'location',
        header: (
          <div className="flex items-center">
            <QrCode className="h-4 w-4 mr-2 text-warning-500" />
            Location
          </div>
        ),
        accessorKey: 'location',
        cell: ({ row }) => {
          // Access the location using the proper type
          const location = row.location;
          return <div>{location?.name || 'N/A'}</div>;
        },
        sortable: true
      },
    ];

    // Add product-specific columns based on active product type
    let productSpecificColumns: Array<TableColumnDef<HealthSubmission>> = [];

    // Add type-specific columns based on the active product type
    if (activeProductType === ProductType.RESPIRATOR_FIT_TEST) {
      productSpecificColumns = [
        {
          id: 'testTime',
          header: (
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2 text-warning-500" />
              Test Time
            </div>
          ),
          accessorKey: 'testTime',
          cell: ({ row }) => {
            const submission = row as RespiratorFitTestSubmission;
            return <div>{submission.testTime ? formatDate(submission.testTime) : 'N/A'}</div>;
          },
          sortable: true
        },
        {
          id: 'protocol',
          header: (
            <div className="flex items-center">
              <QrCode className="h-4 w-4 mr-2 text-warning-500" />
              Protocol
            </div>
          ),
          accessorKey: 'protocol',
          cell: ({ row }) => {
            const submission = row as RespiratorFitTestSubmission;
            return <div>{getProtocolDisplayName(submission.protocol)}</div>;
          },
          sortable: true
        },
        {
          id: 'respirator',
          header: (
            <div className="flex items-center">
              <QrCode className="h-4 w-4 mr-2 text-warning-500" />
              Respirator
            </div>
          ),
          accessorKey: 'respiratorMake',
          cell: ({ row }) => {
            const submission = row as RespiratorFitTestSubmission;
            if (submission.respiratorMake && submission.respiratorModel) {
              return <div>{`${submission.respiratorMake} ${submission.respiratorModel}`}</div>;
            }
            return <div>N/A</div>;
          },
          sortable: false
        }
      ];
    } else if (activeProductType === ProductType.RESPIRATOR_MEDICAL_EVALUATION) {
      productSpecificColumns = [
        {
          id: 'judgmentTime',
          header: (
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2 text-warning-500" />
              Judgment
            </div>
          ),
          accessorKey: 'judgmentTime',
          cell: ({ row }) => {
            const submission = row as RespiratorMedicalEvaluationSubmission;
            return <div>{submission.judgmentTime ? formatDate(submission.judgmentTime) : 'Pending'}</div>;
          },
          sortable: true
        }
      ];
    } else if (activeProductType === ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE) {
      productSpecificColumns = [
        {
          id: 'judgmentTime',
          header: (
            <div className="flex items-center">
              <Calendar className="h-4 w-4 mr-2 text-warning-500" />
              Clearance Date
            </div>
          ),
          accessorKey: 'judgmentTime',
          cell: ({ row }) => {
            const submission = row as GroundCannabisDustSubmission;
            return <div>{submission.judgmentTime ? formatDate(submission.judgmentTime) : 'Pending'}</div>;
          },
          sortable: true
        }
      ];
    }

    // For the ALL tab, don't add product-specific columns
    if (activeProductType === ProductType.ALL) {
      return baseColumns;
    }

    // Combine base columns with product-specific columns
    return [...baseColumns, ...productSpecificColumns];
  }, [formatDate, activeProductType]);


  // Status filter component - always interactive even during loading
  const statusFilterComponent = useMemo(() => {
    // Get dynamic status options based on active product type
    const statusOptions = getStatusFilterOptions(activeProductType);

    return (
      <Select
        value={statusFilter}
        onValueChange={handleStatusFilterChange}
      // Not disabled during loading - allowing filtering while loading
      >
        <SelectTrigger className="w-full sm:w-[180px] bg-transparent text-blackish-300">
          <SelectValue placeholder="Filter by status" />
        </SelectTrigger>
        <SelectContent className="text-blackish-300 dark:text-blackish-50">
          {statusOptions.map((option) => (
            <SelectItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }, [statusFilter, handleStatusFilterChange, activeProductType]);

  // Empty and error states will be handled by TabbedTable's built-in components

  // Data pagination is now handled directly in the ProximaTable component

  // Get ordered product types from the registry - single source of truth
  const orderedProductTypes = useMemo(() => getOrderedProductTypes(), []);

  // Create tabsData object with data state for each product type
  const tabsData = useMemo(() => {
    // Initialize with all product types to satisfy TypeScript
    const result: Record<ProductType, TabbedTableDataState<HealthSubmission>> = {
      [ProductType.ALL]: {
        isLoading,
        error,
        data: data, // ALL tab shows all data
        filteredData: []
      },
      [ProductType.RESPIRATOR_MEDICAL_EVALUATION]: {
        isLoading,
        error,
        data: [],
        filteredData: []
      },
      [ProductType.RESPIRATOR_FIT_TEST]: {
        isLoading,
        error,
        data: [],
        filteredData: []
      },
      [ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE]: {
        isLoading,
        error,
        data: [],
        filteredData: []
      },
      [ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE]: {
        isLoading,
        error,
        data: [],
        filteredData: []
      }
    };

    // Now populate with actual data
    orderedProductTypes.forEach(productType => {
      // For filtering, we let the API handle filtering by product type
      // local filtering (when not using context) would be done here
      let tabData = data;

      // Apply client-side filtering for non-context mode
      if (!useContext && productType !== ProductType.ALL) {
        tabData = data.filter(item => item.subtype === productType);
      }

      // Only apply additional client-side filtering when not using context
      // When using context, server handles filtering
      const filtered = !useContext ? tabData.filter(item => {
        // Apply search filter
        if (debouncedSearchValue) {
          const lowerSearch = debouncedSearchValue.toLowerCase();
          if (!item.employeeName.toLowerCase().includes(lowerSearch)) {
            return false;
          }
        }

        // Apply status filter
        if (statusFilter !== 'all') {
          if (!isValidSubmissionStatus(statusFilter) || item.status !== statusFilter) {
            return false;
          }
        }

        return true;
      }) : tabData;

      result[productType] = {
        isLoading,
        error,
        data: tabData,
        filteredData: filtered
      };
    });

    return result;
  }, [data, debouncedSearchValue, statusFilter, isLoading, error, orderedProductTypes, useContext]);

  // Generate tabs configuration with icons only (no count badges)
  const tabs = useMemo(() =>
    orderedProductTypes.map(productType => {
      const product = PRODUCTS[productType];
      const Icon = product.badge.icon;

      // Create label with icon only, no count badge
      return {
        id: productType,
        label: (
          <div className="flex items-center gap-2">
            <Icon className="h-4 w-4" />
            <span>{product.shortName}</span>
          </div>
        )
      };
    })
    , [orderedProductTypes]);

  // Handle tab change
  const handleTabChange = useCallback((tabId: ProductType) => {
    if (useContext) {
      // Set active product type for UI only - we don't include it in query params
      contextValue.setActiveProductType(tabId);

      // Reset status filter in UI
      setStatusFilter('all');

      // Reset search term when changing tabs
      setSearchTerm('');

      // Create query parameters that only use status parameters, without product type/subtype
      const updatedParams: FrontendQueryParams = {
        page: 1, // Reset to first page
        employeeName: undefined, // Clear search filter
        itemsPerPage: contextValue.queryParams.itemsPerPage
      };

      if (tabId === ProductType.ALL) {
        // ALL tab should be a simple query with minimal parameters - no additional params needed
      } else if (tabId === ProductType.RESPIRATOR_MEDICAL_EVALUATION) {
        // Include all status values for Respirator Medical Evaluation, using enums as source of truth
        updatedParams.respiratorMedicalEvaluationStatus = Object.values(MedicalEvaluationServerStatus);
      } else if (tabId === ProductType.RESPIRATOR_FIT_TEST) {
        // Include all status values for Respirator Fit Test
        updatedParams.respiratorFitTestResult = Object.values(FitTestServerStatus);
        // Add explicit subtype parameter to work around server issue with respiratorfitteststatus
        updatedParams.subtype = ['RESPIRATOR_FIT_TEST'];
      } else if (tabId === ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE) {
        // Don't set any status parameters on initial tab load to match the "all" behavior
        // This allows mapFrontendToBackendParams.ts to handle the default parameters
      } else if (tabId === ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE) {
        // Don't set any status parameters on initial tab load to match the "all" behavior
        // This allows mapFrontendToBackendParams.ts to handle the default parameters
      }

      // Update query params - the mapping function will handle the CLEARED -> APPROVED conversion
      contextValue.setQueryParams(updatedParams);
    }
  }, [useContext, contextValue]);

  // Add product type column that only shows in the "All" tab
  const productTypeColumn = useMemo<TableColumnDef<HealthSubmission>>(() => ({
    id: 'productType',
    header: (
      <div className="flex items-center">
        <Package className="h-4 w-4 mr-2 text-warning-500" />
        Type
      </div>
    ),
    accessorKey: 'subtype',
    cell: ({ row }) => {
      // Get the product type, which could be either a ProductType enum value
      // or a string key that needs to be mapped to a ProductType
      const subtypeValue = row.subtype!;

      if (!subtypeValue) {
        return <span className="text-blackish-400">Unknown</span>;
      }

      // Try to get the product definition using the registry's helper function
      // which can handle both enum values and server key strings
      const productDef = getProductDefinition(subtypeValue);

      if (productDef) {
        // If we have a product definition, use its badge styling
        const { badge } = productDef;
        const { variant, icon: Icon, colorClasses } = badge;

        return (
          <div className="inline-flex w-auto">
            <Badge
              variant={variant}
              className={cn(
                "flex items-center gap-1 w-auto",
                colorClasses
              )}
            >
              <Icon className="h-4 w-4" />
              <span>{productDef.shortName}</span>
            </Badge>
          </div>
        );
      } else {
        // Fallback for unknown types
        return <span className="text-blackish-400">{subtypeValue}</span>;
      }
    },
    sortable: true
  }), []);

  // Get dynamic columns based on active tab
  const getColumnsForTab = useCallback((tabId: ProductType) => {
    // Only include product type column for the ALL tab
    if (tabId === ProductType.ALL) {
      return [productTypeColumn, ...columns];
    }
    return columns;
  }, [columns, productTypeColumn]);

  return (
    <div className="space-y-3 sm:space-y-4 pt-2 sm:pt-3 md:pt-4">
      <TabbedTable<ProductType, HealthSubmission>
        tabs={tabs}
        activeTab={activeProductType}
        onTabChange={handleTabChange}
        tabsData={tabsData}
        searchEnabled={false}
        colorFamily="warning"
        onRetry={() => { onRetry(); }}
        className="pb-2"
        loadingMessage={`Loading ${PRODUCTS[activeProductType].name} data...`}
      >
        {/* Search and filter container - stack vertically on mobile, horizontal on larger screens */}
        <div className="flex flex-col sm:flex-row items-start gap-3 pb-4 mt-1 w-full">
          {/* Search input - full width on mobile, fixed width on desktop */}
          <div className="relative w-full sm:w-64 max-w-full sm:max-w-[260px]">
            <Search
              className="absolute left-2 top-2.5 h-4 w-4 text-warning-400 dark:text-warning-500"
            />
            <Input
              placeholder="Search employee..."
              value={searchTerm}
              onChange={(e) => { handleSearchChange(e.target.value); }}
              className="pl-8 bg-transparent text-blackish dark:text-blackish-100 w-full"
            />
          </div>

          {/* Status filter - full width on mobile */}
          {/* Only show status filter for tabs other than ALL and RESPIRATOR_FIT_TEST */}
          {activeProductType !== ProductType.ALL &&
            activeProductType !== ProductType.RESPIRATOR_FIT_TEST && (
              <div className="w-full sm:w-auto">
                {statusFilterComponent}
              </div>
            )}
        </div>

        <ProximaTable<HealthSubmission>
          data={
            sorting ?
              // Apply sorting to filtered data
              [...(tabsData[activeProductType].filteredData || [])].sort((a, b) => {
                let aValue: string | number | Date = '';
                let bValue: string | number | Date = '';

                // Safely get values with dynamic field access
                try {
                  aValue = a[sorting.column] as string | number | Date;
                  bValue = b[sorting.column] as string | number | Date;
                } catch (error) {
                  return 0;
                }

                // Handle undefined values
                if (aValue === undefined || bValue === undefined) {
                  return 0;
                }

                // Handle date strings by converting to Date objects
                if (sorting.column === 'submissionDate') {
                  try {
                    const aDate = aValue instanceof Date ? aValue : new Date(aValue as string);
                    const bDate = bValue instanceof Date ? bValue : new Date(bValue as string);
                    return sorting.direction === 'asc'
                      ? aDate.getTime() - bDate.getTime()
                      : bDate.getTime() - aDate.getTime();
                  } catch (error) {
                    return 0;
                  }
                }

                // Handle string comparisons
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                  const comparison = aValue.localeCompare(bValue);
                  return sorting.direction === 'asc' ? comparison : -comparison;
                }

                // Default comparison for non-string values
                return sorting.direction === 'asc'
                  ? (aValue > bValue ? 1 : -1)
                  : (aValue < bValue ? 1 : -1);
              }) :
              tabsData[activeProductType].filteredData || []
          }
          columns={getColumnsForTab(activeProductType)}
          colorFamily="warning"
          isInteractive={true}
          aria-label={`${PRODUCTS[activeProductType].name} submissions table`}

          // Card properties
          useCard={true}
          cardHeaderClassName="hidden"

          // Sorting
          sorting={sorting}
          onSort={setSorting}

          // Pagination (optional)
          pagination={pagination ? {
            enabled: true,
            disabled: isLoading || !!error,
            page: currentPage,
            pageSize: pageSize, // Use dynamic pageSize instead of hardcoded value
            totalItems: useContext ? paginationMetadata.totalItems : (tabsData[activeProductType].filteredData?.length || 0),
            totalPages: useContext ? paginationMetadata.totalPages : Math.max(1, Math.ceil((tabsData[activeProductType].filteredData?.length || 0) / pageSize)),
            onPageChange: handlePageChange,
            onPageSizeChange: useContext ? handlePageSizeChange : undefined // Only show page size selector if using context
          } : undefined}
        />
      </TabbedTable>
    </div>
  );
});

// Add display name for React DevTools and debugging
ModernSubmissionsTable.displayName = 'ModernSubmissionsTable';
