// src/components/invoices/index.tsx
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import { AccountBalance, AlertCircle, FileDown, RefreshCw } from '@/components/ui/icon';
import { useUIThemeState } from '@/hooks/useUIThemeState';
import { cn } from "@/lib/utils";
import {
  getCardAnimationClass,
  getDashboardCardClass
} from '@/styles/dashboard';
import { fetchAuthSession } from 'aws-amplify/auth';
import type React from "react";
import { useState } from "react";
import { InvoicePriceUpdater } from './InvoicePriceUpdater';

// Define types for our state and response
type InvoiceState = '' | 'error' | 'loading' | 'success';

type InvoiceResponseData = {
  downloadUrl?: string;
  message?: string;
  rawText?: string;
  error?: string;
  timestamp?: string;
  [key: string]: unknown;
}

// Note: We're using the existing showToast declaration from elsewhere in the project
// and not redeclaring it to avoid TypeScript errors

export const InvoicingPage: React.FC = () => {
  const { isDarkMode } = useUIThemeState();
  const [state, setState] = useState<InvoiceState>('');
  const [debugResponse, setDebugResponse] = useState<InvoiceResponseData | null>(null);

  const handleGenerateInvoice = async (): Promise<void> => {
    setState('loading');
    setDebugResponse(null);

    try {
      console.log("Starting invoice generation process");

      // Get authentication token
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken;

      console.log("Auth session obtained:", !!accessToken);

      if (!accessToken) {
        console.error("No access token available");
        setState('error');
        window.showToast?.('Authentication error', {
          variant: 'destructive',
          description: 'Unable to authenticate. Please try again.'
        });
        return;
      }

      const accessTokenString = accessToken.toString();

      // Log the API endpoint being called
      const apiEndpoint = `${import.meta.env.VITE_API_BASE_URL}/invoices/csv`;
      console.log("Calling API endpoint:", apiEndpoint);

      // Prepare data for the request body and query parameters
      const requestData = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        debug: true
      };

      console.log("Full URL:", apiEndpoint);
      console.log("Request data:", requestData);

      // Log both approaches we're going to try
      console.log("[CSV DEBUG] Will first try POST with JSON body");
      console.log("[CSV DEBUG] Will fallback to GET with query params if needed");

      // Try both request methods to see which one works
      console.log('[INVOICE DEBUG] Attempting POST request with JSON body');
      try {
        // Log detailed request info for debugging
        console.log('[INVOICE DEBUG] Request headers:', {
          authorization: 'Bearer [TOKEN_REDACTED]',
          'Content-Type': 'application/json'
        });
        console.log('[INVOICE DEBUG] Request body:', JSON.stringify(requestData));

        const result = await fetch(apiEndpoint, {
          body: '',
          headers: {
            authorization: `Bearer ${accessTokenString}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
        });

        console.log("API response status:", result.status);
        console.log("API response headers:", Object.fromEntries([...result.headers.entries()]));

        // Try to get the response content
        let responseData: InvoiceResponseData;
        const contentType = result.headers.get('content-type');
        console.log("Response content type:", contentType);

        if (contentType && contentType.includes('application/json')) {
          responseData = await result.json() as InvoiceResponseData;
          console.log("JSON response data:", responseData);
        } else {
          const textData = await result.text();
          console.log("Text response data:", textData);
          try {
            // Try to parse as JSON even if content-type is not application/json
            responseData = JSON.parse(textData) as InvoiceResponseData;
          } catch (
          _e) {
            responseData = { rawText: textData };
          }
        }

        // Save response for debugging UI
        setDebugResponse(responseData);

        if (result.status !== 200) {
          throw new Error(`Request failed with status ${result.status}`);
        }

        // Check if the response includes a downloadable URL
        if (responseData.downloadUrl) {
          console.log("Download URL found, initiating download:", responseData.downloadUrl);
          // Trigger download from the provided URL
          window.open(responseData.downloadUrl, '_blank');
        } else if (responseData.message === "success") {
          console.log("Success message received, but no download URL. Showing success message.");
          // If there's just a success message but no direct download
          setState('success');
          window.showToast?.('Invoice CSV Generated', {
            variant: 'success',
            description: 'Your invoice CSV file was successfully generated.'
          });
        } else {
          console.log("No clear success indicators found in the response");
          setState('success'); // Default to success if status is 200
          window.showToast?.('Invoice CSV Generated', {
            variant: 'success',
            description: 'The invoice CSV was generated, but automatic download is not available.'
          });
        }
      } catch (apiError) {
        console.error('[INVOICE DEBUG] Request failed:', apiError);
        setState('error');
        setDebugResponse({
          error: apiError instanceof Error ? apiError.message : 'Unknown API error',
          timestamp: new Date().toISOString()
        });
        window.showToast?.('Error Generating Invoice', {
          variant: 'destructive',
          description: 'The invoice service is currently unavailable. Please try again later.'
        });
      }
    } catch (err) {
      console.error('Error generating invoice:', err);
      setState('error');
      setDebugResponse({
        error: err instanceof Error ? err.message : 'Unknown error',
        timestamp: new Date().toISOString()
      });
      window.showToast?.('Error Generating Invoice', {
        variant: 'destructive',
        description: 'There was a problem generating the invoice CSV. Please try again.'
      });
    }
  };

  return (
    <div className="space-y-6 w-full max-w-full px-3 sm:px-4 md:px-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 border-b pb-4">
        <h2 className="text-2xl font-bold text-blackish-800 dark:text-white flex items-center gap-2">
          <AccountBalance className="h-5 w-5 text-primary-500" />
          Invoicing
        </h2>
      </div>

      {/* Generate Invoice Card */}
      <Card className={`${getDashboardCardClass('primary', isDarkMode)} w-full`}>
        <div className={getCardAnimationClass('primary')}>
          <CardHeader className="pb-2 relative z-10">
            <CardTitle className="text-lg md:text-xl text-blackish-800 dark:text-white flex items-center gap-2">
              <FileDown className="h-5 w-5 text-primary-500" />
              Generate Monthly Invoices
            </CardTitle>
            <CardDescription className="text-sm text-blackish-500 dark:text-blackish-300">
              Create a CSV file containing all invoice data for all customers for use with your accounting software.
            </CardDescription>
          </CardHeader>

          <CardContent className="relative z-10">
            <div className="flex justify-center mt-2">
              <Button
                onClick={() => {
                  void handleGenerateInvoice();
                }}
                disabled={state === 'loading'}
                className={cn(
                  "flex items-center gap-2 px-8",
                  "bg-primary-700 text-white dark:bg-primary-900",
                  "hover:bg-primary-800 dark:hover:bg-primary-800"
                )}
              >
                {state === 'loading' ? (
                  <>
                    <RefreshCw size={16} className="animate-spin" />
                    <span>Generating...</span>
                  </>
                ) : (
                  <>
                    <FileDown size={16} />
                    <span>Generate Invoice CSV</span>
                  </>
                )}
              </Button>
            </div>

            {/* Status Message */}
            {state === 'error' && (
              <div className="mt-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-md flex items-center gap-2 text-red-700 dark:text-red-400">
                <AlertCircle size={16} />
                <span>Error generating invoice. Please try again.</span>
              </div>
            )}

            {state === 'success' && (
              <div className="mt-4 p-3 bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800 rounded-md flex items-center gap-2 text-green-700 dark:text-green-400">
                <AlertCircle size={16} />
                <span>Invoice CSV generated successfully!</span>
              </div>
            )}

            {/* Debug Response - only visible when there's data */}
            {debugResponse && (
              <div className="mt-6 border rounded-lg p-4 bg-gray-50 dark:bg-blackish-800/50">
                <h3 className="text-sm font-medium mb-2 text-blackish-600 dark:text-blackish-300">API Response Details</h3>
                <pre className="text-xs bg-gray-100 dark:bg-blackish-700 p-3 rounded-md overflow-x-auto">
                  {JSON.stringify(debugResponse, null, 2)}
                </pre>
              </div>
            )}
          </CardContent>

          <CardFooter className="text-sm text-blackish-500 dark:text-blackish-400 border-t pt-4 pb-2 px-4 sm:px-4 sm:py-4 sm:pt-4 md:p-6 md:pt-6 flex flex-col items-start relative z-10">
            <p className="mb-1">The generated CSV file contains all invoice data for all customers and can be imported into your accounting software.</p>
          </CardFooter>
        </div>
      </Card>

      {/* Invoice Price Updater Card */}
      <InvoicePriceUpdater />
    </div>
  );
};
