/**
 * ProximaTable Component
 * 
 * A unified table component that provides consistent styling, behavior, and state management
 * across the application. Supports various features like sorting, filtering, pagination,
 * and tabbed views while maintaining Proxima's design system.
 */

import { Button } from '@/components/ui/button';
import { TableErrorState } from '@/components/ui/error-state';
import { ArrowUpDown, Search, Table as TableIcon } from '@/components/ui/icon';
import { Input } from '@/components/ui/input';
import { TableLoadingState } from '@/components/ui/loading-state';
import { PageSizeSelector } from '@/components/ui/page-size-selector';
import { TableStateContainer } from '@/components/ui/state-container';
import { StyledCard } from '@/components/ui/styled-card';
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableHead,
  StyledTableHeader,
  StyledTableRow
} from '@/components/ui/styled-table';
import { TabbedTable } from '@/components/ui/tabbed-table';
import { cn } from '@/lib/utils';
import type {
  ProximaTableProps,
  TabbedTableDataState,
  TableVariantConfig
} from '@/types/table-types';
import type * as React from 'react';
import { useCallback, useMemo } from 'react';

// Default variant configuration
const defaultVariant: TableVariantConfig = {
  headerClasses: '',
  rowClasses: '',
  cellClasses: '',
  hoverClasses: 'hover:bg-blackish-50/50 dark:hover:bg-blackish-900/20',
  allowSorting: true,
  allowSelection: false,
  allowFiltering: true,
  rowHeight: 'normal',
  padding: 'normal'
};

/**
 * ProximaTable component implementation
 */
export function ProximaTable<T extends Record<string, unknown>, TabID extends string = string>({
  // Core props
  data,
  columns,
  className,
  colorFamily = 'primary',
  isCompact = false,
  isInteractive = true,

  // State management
  state,

  // Feature configurations
  variant = defaultVariant,
  sorting,
  tabs,
  search,
  pagination,
  statusFilter,
  showSearch = false,

  // Event handlers
  onRowClick,
  onSort,

  // Accessibility
  'aria-label': ariaLabel,
  'aria-describedby': ariaDescribedBy,

  // Card-related props
  useCard = true, // Default to true to ensure dashboard styling with card
  cardTitle = '',
  cardIcon = <TableIcon />,
  cardDescription = '',
  cardClassName = 'shadow-sm',
  cardHeaderClassName = 'hidden',
  cardContentClassName = 'p-0 sm:px-4 sm:py-4 md:px-6 md:py-6',
  cardFooterContent
}: ProximaTableProps<T, TabID>): JSX.Element {
  // Memoize the table content to prevent unnecessary re-renders
  const tableContent = useMemo(() => (
    <StyledTable
      colorFamily={colorFamily}
      isCompact={isCompact}
      isInteractive={isInteractive}
      className={cn("w-full", className)}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
    >
      <StyledTableHeader>
        <StyledTableRow>
          {columns.map((column) => (
            <StyledTableHead
              key={column.id}
              className={cn(column.headerClassName, {
                'cursor-pointer': column.sortable && variant.allowSorting
              })}
              onClick={column.sortable && variant.allowSorting && onSort ? () => {
                const direction = sorting?.column === column.id && sorting.direction === 'asc' ? 'desc' : 'asc';
                onSort({ column: column.id, direction });
              } : undefined}
            >
              <div className="flex items-center">
                {column.header}
                {column.sortable && variant.allowSorting && (
                  <Button
                    variant="ghost"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onSort) {
                        const direction = sorting?.column === column.id && sorting.direction === 'asc' ? 'desc' : 'asc';
                        onSort({ column: column.id, direction });
                      }
                    }}
                    className="ml-1 p-0 h-8 w-8"
                  >
                    <ArrowUpDown className="h-4 w-4" />
                  </Button>
                )}
              </div>
            </StyledTableHead>
          ))}
        </StyledTableRow>
      </StyledTableHeader>

      <StyledTableBody>
        {data.map((row, rowIndex) => (
          <StyledTableRow
            key={rowIndex}
            onClick={onRowClick ? () => { onRowClick(row); } : undefined}
            className={cn(variant.rowClasses, variant.hoverClasses, {
              'cursor-pointer': !!onRowClick
            })}
          >
            {columns.map((column) => (
              <StyledTableCell
                key={column.id}
                className={cn(column.cellClassName, variant.cellClasses)}
              >
                {column.cell ? column.cell({ row }) : String(row[column.accessorKey!] || '')}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </StyledTableBody>
    </StyledTable>
  ), [
    data,
    columns,
    colorFamily,
    isCompact,
    isInteractive,
    className,
    variant,
    sorting,
    onSort,
    onRowClick,
    ariaLabel,
    ariaDescribedBy
  ]);

  // Handle search input changes
  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    search?.onChange?.(e.target.value);
  }, [search]);

  // Handle page size changes
  const handlePageSizeChange = useCallback((size: number) => {
    pagination?.onPageSizeChange?.(size);
  }, [pagination]);

  // Wrap table content in state container if state props are provided
  const wrappedContent = state ? (
    <TableStateContainer
      isLoading={state.isLoading}
      error={state.error}
      isEmpty={state.isEmpty}
      loadingComponent={
        state.loadingComponent || (
          <TableLoadingState
            message={state.loadingMessage}
            colorFamily={colorFamily}
          />
        )
      }
      errorComponent={
        state.errorComponent || (
          <TableErrorState
            error={state.error}
            onRetry={state.onRetry}
            colorFamily={colorFamily}
          />
        )
      }
      emptyComponent={state.emptyComponent}
      animated={state.animated}
    >
      {tableContent}
    </TableStateContainer>
  ) : tableContent;

  // Create the standard table content
  const standardContent = (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row sm:justify-between items-end gap-4 px-4 py-2 mb-2">
        {/* Status filter section - no label */}
        {statusFilter ? (
          <div className="w-full sm:w-auto mb-1 sm:mb-0">
            {statusFilter}
          </div>
        ) : null}

        {/* Search input section - no label */}
        {showSearch && search?.enabled && (
          <div className="w-full sm:w-64 ml-auto">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder={search.placeholder || "Search..."}
                value={search.term}
                onChange={handleSearchChange}
                className="pl-8 pr-4 bg-transparent w-full"
              />
            </div>
          </div>
        )}
      </div>
      {wrappedContent}
      {/* Table footer with pagination */}
      {pagination?.enabled && (
        <div className={cn(
          "flex flex-col sm:flex-row sm:items-center sm:justify-between px-2 py-4 md:px-4",
          "border-t mt-2",
          `border-${colorFamily}-100/30 dark:border-${colorFamily}-900/20`
        )}>
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            {/* Page size selector */}
            {pagination.onPageSizeChange && (
              <PageSizeSelector
                value={pagination.pageSize}
                onValueChange={handlePageSizeChange}
                colorFamily={colorFamily}
                disabled={pagination.disabled}
                className="mb-2 sm:mb-0"
              />
            )}

            {/* Record count display */}
            <div className={cn(
              "text-sm font-medium text-center sm:text-left",
              `text-${colorFamily}-700 dark:text-${colorFamily}-300`
            )}>
              Showing {Math.min(pagination.totalItems, pagination.page === pagination.totalPages - 1 ? pagination.totalItems - (pagination.page * pagination.pageSize) || pagination.totalItems : pagination.pageSize)} records of {pagination.totalItems}
            </div>
          </div>

          <div className={cn(
            "flex items-center gap-2 sm:gap-3 mt-4 sm:mt-0",
            "w-full flex justify-center sm:w-auto sm:justify-start"
          )}>
            <Button
              variant="outline"
              size="sm"
              onClick={() => pagination.onPageChange?.(pagination.page - 1)}
              disabled={pagination.page === 0 || pagination.disabled}
              className={cn(
                `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
                `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                `hover:bg-${colorFamily}-50 dark:hover:bg-${colorFamily}-950/30`,
                `hover:text-${colorFamily}-800 dark:hover:text-${colorFamily}-200`,
                "focus-visible:ring-2",
                `focus-visible:ring-${colorFamily}-500/30`,
                "disabled:opacity-50"
              )}
            >
              Previous
            </Button>
            <div className={cn(
              "px-2 py-1 rounded-md text-sm font-medium",
              `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
              `bg-${colorFamily}-50/50 dark:bg-${colorFamily}-900/20`
            )}>
              {pagination.page + 1} of {Math.max(1, pagination.totalPages)}
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={() => pagination.onPageChange?.(pagination.page + 1)}
              disabled={pagination.page >= pagination.totalPages - 1 || pagination.disabled}
              className={cn(
                `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
                `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                `hover:bg-${colorFamily}-50 dark:hover:bg-${colorFamily}-950/30`,
                `hover:text-${colorFamily}-800 dark:hover:text-${colorFamily}-200`,
                "focus-visible:ring-2",
                `focus-visible:ring-${colorFamily}-500/30`,
                "disabled:opacity-50"
              )}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  // Wrap in StyledCard if useCard is true, using dashboard styling
  const finalContent = useCard ? (
    <StyledCard
      title={cardTitle}
      icon={cardIcon}
      colorFamily={colorFamily}
      description={cardDescription}
      className={cardClassName}
      headerClassName={cardHeaderClassName}
      contentClassName={cardContentClassName}
      footerContent={cardFooterContent}
    >
      {standardContent}
    </StyledCard>
  ) : standardContent;

  // Create the tabbed content if tabs are provided and enabled
  let contentWithTabs = finalContent;

  if (tabs?.enabled && tabs.items && tabs.items.length > 0) {
    // Safely create tabsData with null checks
    const tabsData = tabs.items.reduce<Record<string, TabbedTableDataState<T>>>((acc, tab) => {
      acc[tab.id] = {
        isLoading: state?.isLoading || false,
        error: state?.error || null,
        data: data || [],  // Ensure data is never undefined
        filteredData: data || []
      };
      return acc;
    }, {});

    // Prepare a version of standardContent with the tabs inside
    const standardContentWithTabs = (
      <TabbedTable
        tabs={tabs.items}
        activeTab={tabs.activeTab!}
        onTabChange={tabs.onTabChange!}
        isDisabled={tabs.disabled}
        colorFamily={colorFamily}
        className={className}
        tabsData={tabsData as Record<TabID, TabbedTableDataState<T>>}
        searchEnabled={!showSearch || !search?.enabled} // Disable TabbedTable search if ProximaTable is already showing search
        searchTerm={search?.term}
        onSearchChange={search?.onChange}
        searchPlaceholder={search?.placeholder}
      >
        {wrappedContent}
      </TabbedTable>
    );

    // If using a card, create a card with the tabbed content inside
    if (useCard) {
      contentWithTabs = (
        <StyledCard
          title={cardTitle}
          icon={cardIcon}
          colorFamily={colorFamily}
          description={cardDescription}
          className={cardClassName}
          headerClassName={cardHeaderClassName}
          contentClassName={cardContentClassName}
          footerContent={cardFooterContent}
        >
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:justify-between items-center gap-4 px-2 py-2 md:px-4">
              {/* Status filter - only render div if there's content */}
              {statusFilter ? (
                <div className="w-full sm:w-auto mb-2 sm:mb-0">
                  {statusFilter}
                </div>
              ) : null}

              {/* Search input */}
              {showSearch && search?.enabled && (
                <div className="relative w-full sm:w-64 ml-auto">
                  <Search className={`absolute left-2 top-2.5 h-4 w-4 text-${colorFamily}-400 dark:text-${colorFamily}-500`} />
                  <Input
                    placeholder={search.placeholder || "Search..."}
                    value={search.term}
                    onChange={handleSearchChange}
                    className="pl-8 pr-4 bg-transparent"
                  />
                </div>
              )}
            </div>
            {standardContentWithTabs}
            {/* Table footer with pagination */}
            {pagination?.enabled && (
              <div className={cn(
                "flex flex-col sm:flex-row sm:items-center sm:justify-between px-2 py-4 md:px-4",
                "border-t mt-2",
                `border-${colorFamily}-100/30 dark:border-${colorFamily}-900/20`
              )}>
                <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                  {/* Page size selector */}
                  {pagination.onPageSizeChange && (
                    <PageSizeSelector
                      value={pagination.pageSize}
                      onValueChange={handlePageSizeChange}
                      colorFamily={colorFamily}
                      disabled={pagination.disabled}
                      className="mb-2 sm:mb-0"
                    />
                  )}

                  {/* Record count display */}
                  <div className={cn(
                    "text-sm font-medium text-center sm:text-left",
                    `text-${colorFamily}-700 dark:text-${colorFamily}-300`
                  )}>
                    Showing {Math.min(pagination.totalItems, pagination.page === pagination.totalPages - 1 ? pagination.totalItems - (pagination.page * pagination.pageSize) || pagination.totalItems : pagination.pageSize)} records of {pagination.totalItems}
                  </div>
                </div>

                <div className={cn(
                  "flex items-center gap-2 sm:gap-3 mt-4 sm:mt-0",
                  "w-full flex justify-center sm:w-auto sm:justify-start"
                )}>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => pagination.onPageChange?.(pagination.page - 1)}
                    disabled={pagination.page === 0 || pagination.disabled}
                    className={cn(
                      `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
                      `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                      `hover:bg-${colorFamily}-50 dark:hover:bg-${colorFamily}-950/30`,
                      `hover:text-${colorFamily}-800 dark:hover:text-${colorFamily}-200`,
                      "focus-visible:ring-2",
                      `focus-visible:ring-${colorFamily}-500/30`,
                      "disabled:opacity-50"
                    )}
                  >
                    Previous
                  </Button>
                  <div className={cn(
                    "px-2 py-1 rounded-md text-sm font-medium",
                    `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                    `bg-${colorFamily}-50/50 dark:bg-${colorFamily}-900/20`
                  )}>
                    {pagination.page + 1} of {Math.max(1, pagination.totalPages)}
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => pagination.onPageChange?.(pagination.page + 1)}
                    disabled={pagination.page >= pagination.totalPages - 1 || pagination.disabled}
                    className={cn(
                      `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
                      `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                      `hover:bg-${colorFamily}-50 dark:hover:bg-${colorFamily}-950/30`,
                      `hover:text-${colorFamily}-800 dark:hover:text-${colorFamily}-200`,
                      "focus-visible:ring-2",
                      `focus-visible:ring-${colorFamily}-500/30`,
                      "disabled:opacity-50"
                    )}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        </StyledCard>
      );
    } else {
      // If not using a card, just return the tabbed content with standard layout
      contentWithTabs = (
        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row sm:justify-between items-center gap-4 px-2 py-2 md:px-4">
            {/* Status filter - only render div if there's content */}
            {statusFilter ? (
              <div className="w-full sm:w-auto mb-2 sm:mb-0">
                {statusFilter}
              </div>
            ) : null}

            {/* Search input */}
            {showSearch && search?.enabled && (
              <div className="relative w-full sm:w-64 ml-auto">
                <Search className={`absolute left-2 top-2.5 h-4 w-4 text-${colorFamily}-400 dark:text-${colorFamily}-500`} />
                <Input
                  placeholder={search.placeholder || "Search..."}
                  value={search.term}
                  onChange={handleSearchChange}
                  className="pl-8 pr-4 bg-transparent"
                />
              </div>
            )}
          </div>
          {standardContentWithTabs}
          {/* Table footer with pagination */}
          {pagination?.enabled && (
            <div className={cn(
              "flex flex-col sm:flex-row sm:items-center sm:justify-between px-2 py-4 md:px-4",
              "border-t mt-2",
              `border-${colorFamily}-100/30 dark:border-${colorFamily}-900/20`
            )}>
              <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                {/* Page size selector */}
                {pagination.onPageSizeChange && (
                  <PageSizeSelector
                    value={pagination.pageSize}
                    onValueChange={handlePageSizeChange}
                    colorFamily={colorFamily}
                    disabled={pagination.disabled}
                    className="mb-2 sm:mb-0"
                  />
                )}

                {/* Record count display */}
                <div className={cn(
                  "text-sm font-medium text-center sm:text-left",
                  `text-${colorFamily}-700 dark:text-${colorFamily}-300`
                )}>
                  Showing {Math.min(pagination.totalItems, pagination.page === pagination.totalPages - 1 ? pagination.totalItems - (pagination.page * pagination.pageSize) || pagination.totalItems : pagination.pageSize)} records of {pagination.totalItems}
                </div>
              </div>

              <div className={cn(
                "flex items-center gap-2 sm:gap-3 mt-4 sm:mt-0",
                "w-full flex justify-center sm:w-auto sm:justify-start"
              )}>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => pagination.onPageChange?.(pagination.page - 1)}
                  disabled={pagination.page === 0 || pagination.disabled}
                  className={cn(
                    `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
                    `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                    `hover:bg-${colorFamily}-50 dark:hover:bg-${colorFamily}-950/30`,
                    `hover:text-${colorFamily}-800 dark:hover:text-${colorFamily}-200`,
                    "focus-visible:ring-2",
                    `focus-visible:ring-${colorFamily}-500/30`,
                    "disabled:opacity-50"
                  )}
                >
                  Previous
                </Button>
                <div className={cn(
                  "px-2 py-1 rounded-md text-sm font-medium",
                  `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                  `bg-${colorFamily}-50/50 dark:bg-${colorFamily}-900/20`
                )}>
                  {pagination.page + 1} of {Math.max(1, pagination.totalPages)}
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => pagination.onPageChange?.(pagination.page + 1)}
                  disabled={pagination.page >= pagination.totalPages - 1 || pagination.disabled}
                  className={cn(
                    `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
                    `text-${colorFamily}-700 dark:text-${colorFamily}-300`,
                    `hover:bg-${colorFamily}-50 dark:hover:bg-${colorFamily}-950/30`,
                    `hover:text-${colorFamily}-800 dark:hover:text-${colorFamily}-200`,
                    "focus-visible:ring-2",
                    `focus-visible:ring-${colorFamily}-500/30`,
                    "disabled:opacity-50"
                  )}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  // Return the content (either with tabs or without)
  return contentWithTabs;
}

export type { ProximaTableProps };
