// src/components/ui/table-error-state.tsx
import type * as React from 'react';
import { Button } from '@/components/ui/button';
import { AlertCircle, RefreshCw } from '@/components/ui/icon';

type TableErrorStateProps = {
  error: string;
  onRetry: () => void;
}

/**
 * TableErrorState component for displaying error messages with a retry button.
 * This component is designed to be used with the TableStateContainer.
 * Responsively adapts to mobile and desktop displays.
 */
export const TableErrorState: React.FC<TableErrorStateProps> = ({ 
  error, 
  onRetry 
}) => {
  return (
    <div className="bg-red-50 dark:bg-red-900/10 rounded-md p-4 sm:p-6 shadow-sm text-center w-full max-w-[280px] sm:max-w-sm md:max-w-md mx-auto transform-gpu">
      <div className="flex flex-col items-center justify-center">
        <AlertCircle size={28} className="text-red-500 mb-2" />
        
        <h3 className="text-base font-semibold text-red-700 dark:text-red-400 mb-1">
          Error Loading Data
        </h3>
        
        <p className="text-sm text-blackish-600 dark:text-blackish-300 text-center mb-3 break-words">
          {error}
        </p>
        
        <Button 
          onClick={onRetry} 
          size="sm" 
          variant="outline"
          className="flex items-center gap-2"
        >
          <RefreshCw size={16} />
          Retry
        </Button>
      </div>
    </div>
  );
};
