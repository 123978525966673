// src/components/invoices/ProcessingControls.tsx
import { Button } from "@/components/ui/button";
import { AlertCircle, RefreshCw, Upload } from '@/components/ui/icon';
import { cn } from "@/lib/utils";
import type { PriceUpdaterState } from "@/types/invoice-pricing-types";
import type { ColorFamily } from "@/types/ui-component-types";

export type ProcessingControlsProps = {
    /**
     * Color family for styling consistency
     */
    colorFamily?: ColorFamily;
    /**
     * Current state of the price updater
     */
    state: PriceUpdaterState;
    /**
     * Error message to display
     */
    errorMessage: string;
    /**
     * Whether the process button should be disabled
     */
    disabled: boolean;
    /**
     * Handler for process button click
     */
    onProcessClick: () => void;
}

/**
 * ProcessingControls component for the invoice price updater
 * Contains the process button and error message display
 */
export const ProcessingControls = ({
    colorFamily = 'primary',
    state,
    errorMessage,
    disabled,
    onProcessClick
}: ProcessingControlsProps): JSX.Element => {
    return (
        <div className="space-y-6">
            {/* Process Button */}
            <div className="flex justify-center">
                <Button
                    onClick={onProcessClick}
                    disabled={disabled || state === 'loading'}
                    className={cn(
                        "flex items-center gap-2 px-8",
                        `bg-${colorFamily}-700 text-white dark:bg-${colorFamily}-900`,
                        `hover:bg-${colorFamily}-800 dark:hover:bg-${colorFamily}-800`
                    )}
                >
                    {state === 'loading' ? (
                        <>
                            <RefreshCw size={16} className="animate-spin" />
                            <span>Processing...</span>
                        </>
                    ) : (
                        <>
                            <Upload size={16} />
                            <span>Process Files</span>
                        </>
                    )}
                </Button>
            </div>

            {/* Error Message */}
            {errorMessage && (
                <div className="p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-md flex items-start gap-2 text-red-700 dark:text-red-400">
                    <AlertCircle size={16} className="mt-0.5 flex-shrink-0" />
                    <span>{errorMessage}</span>
                </div>
            )}
        </div>
    );
};
