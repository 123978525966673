/**
 * useRecordsQuery Hook
 * 
 * A unified hook for querying the /records endpoint with type-safe parameters
 * that handles proper mapping between frontend and backend parameter formats.
 * Provides a single source of truth for API query parameters.
 */
import { ApiError, getRecords } from '@/services/records-api.service';
import type { HealthSubmission } from '@/types';
import type {
    FrontendQueryParams
} from '@/types/records-query-parameters';
import {
    mapFrontendToBackendParams
} from '@/types/records-query-parameters';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

/**
 * Error type for record query errors
 */
export type RecordQueryError = {
    statusCode?: number;
    source?: string;
    timestamp?: string;
    retryCount?: number;
    message: string;
} & Error;

/**
 * Response type including both data and metadata
 */
export type RecordsQueryResponse = {
    items: HealthSubmission[];
    metadata: {
        itemsPerPage: number;
        page: number;
        totalItems: number;
        totalPages: number;
    };
}

/**
 * Options for the useRecordsQuery hook
 */
export type UseRecordsQueryOptions = {
    enabled?: boolean;
    staleTime?: number;
    refetchInterval?: number | false;
    retry?: boolean | number;
    onSuccess?: (data: RecordsQueryResponse) => void;
    onError?: (error: RecordQueryError) => void;
}

/**
 * A unified hook for querying the /records endpoint with type-safe parameters.
 * Uses our single source of truth for parameter mapping.
 */
export function useRecordsQuery(
    params: FrontendQueryParams = {},
    options: UseRecordsQueryOptions = {}
): ReturnType<typeof useQuery<RecordsQueryResponse, RecordQueryError>> {
    // Create a stable query key that changes when important parameters change
    const stableQueryKey = useMemo(() => {
        // Extract parameters that should cause URL updates when they change
        const {
            productType,
            dateFrom,
            dateTo,
            employeeName,
            status,
            groundCannabisDustStatus,
            respiratorMedicalEvaluationStatus,
            respiratorFitTestResult,
            animalAllergyStatus,
            page,
            itemsPerPage,
            sortBy,
            sortDirection
        } = params;

        // Return a stable query key that only includes parameters we care about
        return ['records', {
            productType,
            dateFrom,
            dateTo,
            employeeName,
            status,
            groundCannabisDustStatus,
            respiratorMedicalEvaluationStatus,
            respiratorFitTestResult,
            animalAllergyStatus,
            page,
            itemsPerPage,
            sortBy,
            sortDirection,
            // Add a timestamp to ensure unique key when same filter is clicked
            _t: params._t
        }];
    }, [params]);

    // Update browser URL
    useEffect(() => {
        if (window.history) {
            // Build query string from parameters
            const queryParams = new URLSearchParams();
            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined && value !== null && key !== '_t') {
                    if (Array.isArray(value)) {
                        value.forEach(v => { queryParams.append(key, String(v)); });
                    } else {
                        queryParams.set(key, String(value));
                    }
                }
            });

            // Get current URL and replace query parameters
            const url = new URL(window.location.href);
            url.search = queryParams.toString();

            // Replace current URL without adding to browser history
            window.history.replaceState(null, '', url.toString());
        }
    }, [params]);

    return useQuery<RecordsQueryResponse, RecordQueryError>({
        queryKey: stableQueryKey,
        queryFn: async () => {
            try {
                // Convert frontend params to backend format
                const backendParams = mapFrontendToBackendParams(params);

                // Validate parameters (uncomment to enable validation)
                // validateBackendParams(backendParams);

                // Fetch data using the mapped parameters
                const response = await getRecords(backendParams);

                return {
                    items: response.items,
                    metadata: {
                        itemsPerPage: response.itemsPerPage,
                        page: response.page,
                        totalItems: response.totalItems,
                        totalPages: response.totalPages
                    }
                };
            } catch (error) {
                // Standardize error format
                if (error instanceof ApiError) {
                    throw error as RecordQueryError;
                }

                if (error instanceof Error) {
                    const queryError = error as RecordQueryError;
                    if (!queryError.name) {
                        queryError.name = 'RecordQueryError';
                    }
                    throw queryError;
                }

                // Create a proper Error object for non-Error throws
                const genericError = new Error(
                    'Failed to load records data'
                ) as RecordQueryError;
                genericError.name = 'RecordQueryError';

                throw genericError;
            }
        },
        staleTime: options.staleTime ?? 5 * 60 * 1000, // 5 minutes before refetching
        refetchInterval: options.refetchInterval ?? 5 * 60 * 1000, // Refetch every 5 minutes
        retry: options.retry ?? 1, // Only retry once by default
        enabled: options.enabled ?? true,
        ...options
    });
}
