
// src/components/ui/button.tsx
import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        // 30% usage - Primary brand color (purple)
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        
        // Special case - Destructive stays as is
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        
        // 60% usage - Neutral colors for outline
        outline:
          "text-blackish-500 border border-input bg-background hover:bg-gray-100 dark:hover:bg-blackish-600 dark:text-gray-300",
        
        // 60% usage - Changed to neutral gray (from pink)
        secondary:
          "bg-gray-200 text-blackish-700 hover:bg-gray-300 dark:bg-blackish-600 dark:text-gray-300 dark:hover:bg-blackish-500",
        
        // 7% usage - Accent color for special actions
        accent: 
          "bg-accent text-white hover:bg-accent/90",
          
        // 60% usage - Ghost uses neutral colors with accent hover
        ghost: "hover:bg-gray-100 hover:text-blackish-700 dark:hover:bg-blackish-600 dark:hover:text-gray-300",
        
        // 30% usage - Links use primary color
        link: "text-primary underline-offset-4 hover:underline",
        
        // 3% usage - Micro-accent for notification badges, alerts
        micro: 
          "bg-warning-400 text-blackish-700 hover:bg-warning-500 dark:bg-warning-600 dark:text-white",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export type ButtonProps = {
  asChild?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof buttonVariants>

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
