import * as React from 'react';
import { useMemo } from 'react';
import { cn } from '@/lib/utils';
import type { ColorFamily } from '@/types/ui-component-types';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '@/components/ui/select';

export type PageSizeSelectorProps = {
  value: number;
  onValueChange: (value: number) => void;
  colorFamily?: ColorFamily;
  disabled?: boolean;
  options?: number[];
  className?: string;
}

export const PageSizeSelector: React.FC<PageSizeSelectorProps> = ({
  value = 25, // Set default to 25
  onValueChange,
  colorFamily = 'primary',
  disabled = false,
  options = [10, 25, 50, 100],
  className
}) => {
  const handleValueChange = React.useCallback(
    (newValue: string) => {
      onValueChange(Number(newValue));
    },
    [onValueChange]
  );

  // Memoize options to prevent unnecessary rerenders
  const selectOptions = useMemo(() => 
    options.map(option => ({
      value: String(option),
      label: String(option)
    })),
    [options]
  );

  return (
      <Select
        value={String(value)}
        onValueChange={handleValueChange}
        disabled={disabled}
      >
        <SelectTrigger 
          className={cn(
            "w-[80px] h-9",
            `border-${colorFamily}-200 dark:border-${colorFamily}-800`,
            `bg-${colorFamily}-50/50 dark:bg-${colorFamily}-900/20`,
            className
          )}
        >
          <SelectValue placeholder={String(value)} />
        </SelectTrigger>
        <SelectContent>
          {selectOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
  );
};
