// src/components/invoices/InvoicePriceUpdater.tsx
import { useUIThemeState } from '@/hooks/useUIThemeState';
import type { FC } from "react";
import { InvoicePriceUpdaterContainer } from "./InvoicePriceUpdaterContainer";

/**
 * Invoice Price Updater component
 * Uses the container component for all functionality
 */
export const InvoicePriceUpdater: FC = () => {
  const { isDarkMode } = useUIThemeState();
  return <InvoicePriceUpdaterContainer isDarkMode={isDarkMode} />;
};
