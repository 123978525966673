// src/utils/authUtils.ts
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

/**
 * Utility to check if user is authenticated
 */
export const isAuthenticated = async (): Promise<boolean> => {
  try {
    const session = await fetchAuthSession();
    return !!session.tokens?.accessToken;
  } catch (error) {
    return false;
  }
};

/**
 * Utility to perform a sign out
 */
export const signOutUser = async (): Promise<void> => {
  try {
    await signOut();
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};