import * as React from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { LoadingButton } from '@/components/ui/loading-button';
import { Sparkles, RefreshCw } from '@/components/ui/icon';
import { cn } from "@/lib/utils";
import type { LoadingStateProps } from '@/types/ui-component-types';

/**
 * LoadingState component for displaying loading indicators.
 * Supports multiple variants, sizes, and color families for consistent styling.
 * Core component that powers both general loading states and specialized table/chart loading states.
 */
export const LoadingState: React.FC<LoadingStateProps> = ({
  variant = 'spinner',
  message = 'Loading...',
  className = '',
  size = 'medium',
  children,
  colorFamily = 'accent',
  rows,
  cards,
  withChart,
  withHeader,
  density = 'normal',
  onRetry,
  compact = false,
}): JSX.Element => {
  // Size classes for different spinner sizes
  const spinnerSizeClasses = {
    small: 'h-6 w-6',
    medium: 'h-10 w-10',
    large: 'h-16 w-16',
  };

  // Color classes based on colorFamily
  const colorClasses = React.useMemo(() => {
    const baseColors = {
      primary: {
        bg: 'bg-primary-50/60 dark:bg-primary-900/10',
        text: 'text-primary-700 dark:text-primary-300',
        icon: 'text-primary-500 dark:text-primary-400',
        border: 'border-primary-200 dark:border-primary-800/30',
        spinner: 'border-primary-500 border-t-transparent dark:border-primary-400 dark:border-t-transparent',
        progress: 'bg-primary-500 dark:bg-primary-400',
        pulse: 'bg-primary-500 dark:bg-primary-400'
      },
      secondary: {
        bg: 'bg-secondary-50/60 dark:bg-secondary-900/10',
        text: 'text-secondary-700 dark:text-secondary-300',
        icon: 'text-secondary-500 dark:text-secondary-400',
        border: 'border-secondary-200 dark:border-secondary-800/30',
        spinner: 'border-secondary-500 border-t-transparent dark:border-secondary-400 dark:border-t-transparent',
        progress: 'bg-secondary-500 dark:bg-secondary-400',
        pulse: 'bg-secondary-500 dark:bg-secondary-400'
      },
      accent: {
        bg: 'bg-accent-50/60 dark:bg-accent-900/10',
        text: 'text-accent-700 dark:text-accent-300',
        icon: 'text-accent-500 dark:text-accent-400',
        border: 'border-accent-200 dark:border-accent-800/30',
        spinner: 'border-accent-500 border-t-transparent dark:border-accent-400 dark:border-t-transparent',
        progress: 'bg-accent-500 dark:bg-accent-400',
        pulse: 'bg-accent-500 dark:bg-accent-400'
      },
      tertiary: {
        bg: 'bg-secondary-50/60 dark:bg-secondary-900/10',
        text: 'text-secondary-700 dark:text-secondary-300',
        icon: 'text-secondary-500 dark:text-secondary-400',
        border: 'border-secondary-200 dark:border-secondary-800/30',
        spinner: 'border-secondary-500 border-t-transparent dark:border-secondary-400 dark:border-t-transparent',
        progress: 'bg-secondary-500 dark:bg-secondary-400',
        pulse: 'bg-secondary-500 dark:bg-secondary-400'
      },
      warning: {
        bg: 'bg-warning-50/60 dark:bg-warning-900/10',
        text: 'text-warning-700 dark:text-warning-300',
        icon: 'text-warning-500 dark:text-warning-400',
        border: 'border-warning-200 dark:border-warning-800/30',
        spinner: 'border-warning-500 border-t-transparent dark:border-warning-400 dark:border-t-transparent',
        progress: 'bg-warning-500 dark:bg-warning-400',
        pulse: 'bg-warning-500 dark:bg-warning-400'
      }
    };
    
    return baseColors[colorFamily] || baseColors.primary;
  }, [colorFamily]);

  // Spinner variant
  if (variant === 'spinner') {
    return (
      <div className={cn(
        "flex flex-col items-center justify-center rounded-md border shadow-sm",
        compact ? "p-3 sm:p-4" : "p-4", // Support compact mode from table-loading-state
        {
          "bg-primary-50/60 dark:bg-primary-900/10 border-primary-200 dark:border-primary-800/30": colorFamily === 'primary',
          "bg-secondary-50/60 dark:bg-secondary-900/10 border-secondary-200 dark:border-secondary-800/30": colorFamily === 'secondary',
          "bg-accent-50/60 dark:bg-accent-900/10 border-accent-200 dark:border-accent-800/30": colorFamily === 'accent',
          "bg-tertiary-50/60 dark:bg-tertiary-900/10 border-tertiary-200 dark:border-tertiary-800/30": colorFamily === 'tertiary',
          "bg-warning-50/60 dark:bg-warning-900/10 border-warning-200 dark:border-warning-800/30": colorFamily === 'warning'
        },
        className
      )}>
        <div className="relative flex items-center justify-center">
          <div 
            className={cn(
              spinnerSizeClasses[size],
              "rounded-full border-4 animate-spin",
              colorClasses.spinner
            )} 
          />
        </div>
        {message && (
          <div className={cn("mt-4 text-sm", colorClasses.text)}>
            {message}
          </div>
        )}
      </div>
    );
  }

  // Pulse variant
  if (variant === 'pulse') {
    return (
      <div className={cn(
        "flex flex-col items-center justify-center p-4 rounded-md border shadow-sm",
        colorClasses.bg,
        colorClasses.border,
        className
      )}>
        <div className="relative">
          <Sparkles className={cn(
            spinnerSizeClasses[size],
            colorClasses.icon,
            "animate-pulse"
          )} />
          <div className={cn(
            "absolute inset-0 rounded-full animate-ping opacity-25",
            colorClasses.pulse
          )} />
        </div>
        {message && (
          <div className={cn("mt-4 text-sm", colorClasses.text)}>
            {message}
          </div>
        )}
      </div>
    );
  }
  
  // Progress bar variant
  if (variant === 'progress') {
    return (
      <div className={cn(
        "flex flex-col items-center justify-center p-4 rounded-md border shadow-sm",
        colorClasses.bg,
        colorClasses.border,
        className
      )}>
        <div className="w-full max-w-xs bg-blackish-100 dark:bg-blackish-800 rounded-full h-2 mb-4 overflow-hidden">
          <div 
            className={cn(
              "h-full rounded-full animate-progress",
              colorClasses.progress
            )}
            style={{ width: '75%' }}
          />
        </div>
        {message && (
          <div className={cn("text-sm", colorClasses.text)}>
            {message}
          </div>
        )}
        {onRetry && (
          <LoadingButton
            onClick={onRetry}
            size="sm"
            variant="ghost"
            className="mt-2"
            leftIcon={<RefreshCw size={14} />}
          >
            Retry
          </LoadingButton>
        )}
      </div>
    );
  }

  // Skeleton variant with children override
  if (children) {
    return <div className={className}>{children}</div>;
  }
  
  // Table skeleton
  if (variant === 'skeleton' && rows) {
    return (
      <div className={cn("animate-pulse-subtle", className)}>
        <Skeleton className="h-10 w-full mb-4" />
        {Array.from({ length: rows }).map((_, i) => (
          <Skeleton 
            key={i} 
            className={cn(
              "h-12 w-full", 
              density === 'compact' ? 'mb-1' : density === 'relaxed' ? 'mb-4' : 'mb-2'
            )} 
          />
        ))}
      </div>
    );
  }
  
  // Card grid skeleton
  if (variant === 'skeleton' && cards) {
    return (
      <div className={cn("animate-pulse-subtle", className)}>
        {withHeader && <Skeleton className="h-8 w-48 mb-6" />}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {Array.from({ length: cards }).map((_, i) => (
            <div key={i} className={cn(
              "rounded-md border p-4", 
              colorClasses.border
            )}>
              <Skeleton className="h-5 w-28 mb-2" />
              <Skeleton className="h-8 w-full" />
            </div>
          ))}
        </div>
        {withChart && (
          <Skeleton className="h-64 w-full mt-6 rounded-md" />
        )}
      </div>
    );
  }
  
  // Default skeleton
  return (
    <div className={cn("space-y-3 animate-pulse-subtle", className)}>
      <Skeleton className="h-8 w-32 mb-4" />
      <Skeleton className="h-16 w-full mb-2" />
      <Skeleton className="h-16 w-full" />
    </div>
  );
};

/**
 * TableLoadingState component for displaying loading states in table contexts.
 * Adapts LoadingState for table-specific displays.
 * 
 * This component unifies the functionality from both the inline TableLoadingState 
 * and the standalone table-loading-state.tsx component.
 */
export const TableLoadingState: React.FC<Omit<LoadingStateProps, 'variant'>> = (props): JSX.Element => {
  return (
    <LoadingState
      {...props}
      variant="spinner"
      message={props.message || "Loading data..."}
      colorFamily={props.colorFamily || "primary"}
      size="medium"
      compact={props.compact || false}
      className={cn(
        "max-w-xs sm:max-w-sm mx-auto",
        props.compact ? "p-3 sm:p-4" : "p-4 sm:p-6",
        props.className
      )}
    />
  );
};

/**
 * ChartLoadingState component for displaying loading states in chart contexts.
 * Adapts LoadingState for chart-specific displays.
 */
export const ChartLoadingState: React.FC<Omit<LoadingStateProps, 'variant'>> = (props): JSX.Element => {
  return (
    <LoadingState
      {...props}
      variant="pulse"
      message="Loading chart data..."
      colorFamily={props.colorFamily || "accent"}
      size="large"
      className={cn(
        "max-w-xs mx-auto py-12",
        props.className
      )}
    />
  );
};
