import * as React from 'react';
import type { MaterialSymbolProps } from 'react-material-symbols';
import { MaterialSymbol } from 'react-material-symbols';

/**
 * Common props interface for our icon components
 */
export type IconComponentProps = {
  size?: number;
  color?: string;
  strokeWidth?: number;
  fill?: boolean;
  className?: string;
} & React.HTMLAttributes<HTMLSpanElement>

/**
 * Create Material Symbol components that match the Lucide icon API
 */

// Helper function to create a material symbol component with proper type safety
function createMaterialIcon(iconName: string): React.ForwardRefExoticComponent<IconComponentProps & React.RefAttributes<HTMLSpanElement>> {
  // Using React.forwardRef to safely forward refs without direct DOM manipulation
  const Component = React.forwardRef<HTMLSpanElement, IconComponentProps>(
    ({ size = 16, color, strokeWidth, className, fill = false, ...props }, ref) => {
      // Convert lucide props to material symbols props
      const weight = strokeWidth ? Math.min(Math.max(Math.round(strokeWidth * 100), 100), 700) : 400;

      return (
        <MaterialSymbol
          icon={iconName as MaterialSymbolProps['icon']}
          size={size}
          color={color}
          fill={fill}
          weight={weight as MaterialSymbolProps['weight']}
          className={className}
          {...props}
          ref={ref}
        />
      );
    }
  );

  // Set display name for better debugging experience
  Component.displayName = `Icon${iconName}`;
  return Component;
}

// Export Material Icon components with the same names as Lucide
export const Activity = createMaterialIcon('health_metrics');
export const AccountBalance = createMaterialIcon('account_balance');
export const AlertCircle = createMaterialIcon('error');
export const AlertOctagon = createMaterialIcon('report_problem');
export const AlertTriangle = createMaterialIcon('warning');
export const ArrowUpDown = createMaterialIcon('swap_vert');
export const BarChart2 = createMaterialIcon('bar_chart');
export const Beaker = createMaterialIcon('science');
export const Briefcase = createMaterialIcon('work');
export const BriefcaseBusiness = createMaterialIcon('business_center');
export const Building = createMaterialIcon('domain');
export const Calendar = createMaterialIcon('calendar_month');
export const Check = createMaterialIcon('check');
export const CheckCircle = createMaterialIcon('check_circle');
export const ChevronDown = createMaterialIcon('keyboard_arrow_down');
export const ChevronRight = createMaterialIcon('chevron_right');
export const ChevronUp = createMaterialIcon('keyboard_arrow_up');
export const Circle = createMaterialIcon('circle');
export const CircleX = createMaterialIcon('cancel');
export const CircleXIcon = createMaterialIcon('cancel');
export const ClipboardList = createMaterialIcon('assignment');
export const Copy = createMaterialIcon('content_copy');
export const DollarSign = createMaterialIcon('attach_money');
export const Download = createMaterialIcon('download_for_offline');
export const Edit = createMaterialIcon('edit');
export const FileDown = createMaterialIcon('file_download');
export const FileIcon = createMaterialIcon('insert_drive_file');
export const FileText = createMaterialIcon('description');
export const FileUp = createMaterialIcon('file_upload');
export const HelpCircle = createMaterialIcon('help');
export const Home = createMaterialIcon('home');
export const InboxIcon = createMaterialIcon('inbox');
export const Leaf = createMaterialIcon('spa');
export const LineChart = createMaterialIcon('show_chart');
export const Loader2 = createMaterialIcon('rotate_right');
export const LogOut = createMaterialIcon('logout');
export const Masks = createMaterialIcon('masks');
export const Menu = createMaterialIcon('menu');
export const Minus = createMaterialIcon('remove');
export const Monitor = createMaterialIcon('monitor');
export const Moon = createMaterialIcon('dark_mode');
export const OctagonAlert = createMaterialIcon('report');
export const Package = createMaterialIcon('inventory');
export const PieChart = createMaterialIcon('pie_chart');
export const PowerOff = createMaterialIcon('power_settings_new');
export const QrCode = createMaterialIcon('qr_code');
export const Rat = createMaterialIcon('science');
export const Receipt = createMaterialIcon('receipt');
export const RefreshCw = createMaterialIcon('refresh');
export const Search = createMaterialIcon('search');
export const SearchXIcon = createMaterialIcon('search_off');
export const Shield = createMaterialIcon('shield');
export const Sparkles = createMaterialIcon('auto_awesome');
export const Sun = createMaterialIcon('light_mode');
export const Table = createMaterialIcon('table_chart');
export const Tag = createMaterialIcon('local_offer');
export const Timer = createMaterialIcon('timer');
export const Trash2 = createMaterialIcon('delete');
export const TrendingDown = createMaterialIcon('trending_down');
export const TrendingUp = createMaterialIcon('trending_up');
export const Upload = createMaterialIcon('file_upload');
export const User = createMaterialIcon('person');
export const UserCog = createMaterialIcon('manage_accounts');
export const Users = createMaterialIcon('people');
export const X = createMaterialIcon('close');
export const XCircle = createMaterialIcon('cancel');