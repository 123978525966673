// src/components/ui/badge.tsx
import { cva, type VariantProps } from "class-variance-authority";
import type * as React from "react";
import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary-800 text-primary hover:bg-primary/80",
        secondary: "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive: "border-transparent bg-destructive-100 text-destructive-700 dark:bg-destructive-900/30 dark:text-destructive-300",
        success: "border-transparent bg-success-100 text-success-700 dark:bg-success-900/30 dark:text-success-300",
        warning: "border-transparent bg-warning-100 text-warning-800 dark:bg-warning-900/30 dark:text-warning-300",
        outline: "text-foreground text-blackish-300 dark:text-blackish-200",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export type BadgeProps = {} & React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof badgeVariants>

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
