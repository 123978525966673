// src/components/invoices/RevenueBreakdownSection.tsx
import { Button } from "@/components/ui/button";
import { Check, CheckCircle, Copy, Download, RefreshCw } from '@/components/ui/icon';
import type { ClientRevenueData } from "@/types/invoice-pricing-types";
import type { ColorFamily } from "@/types/ui-component-types";
import { useState } from "react";
import { RevenueBreakdownDisplay } from "./RevenueBreakdownDisplay";

export type RevenueBreakdownSectionProps = {
    /**
     * Color family for styling consistency
     */
    colorFamily?: ColorFamily;
    /**
     * Client revenue data for breakdown display
     */
    clientRevenueData: ClientRevenueData[];
    /**
     * Total company revenue
     */
    totalCompanyRevenue: number;
    /**
     * Total company units
     */
    totalCompanyUnits: number;
    /**
     * Price per unit
     */
    pricePerUnit: number;
    /**
     * Markdown formatted revenue breakdown
     */
    formattedRevenueBreakdown: string;
    /**
     * Handler for download CSV button
     */
    onDownload: () => void;
    /**
     * Handler for reset button
     */
    onReset: () => void;
}

/**
 * RevenueBreakdownSection component
 * Contains the revenue breakdown display with action buttons
 */
export const RevenueBreakdownSection = ({
    colorFamily = 'primary',
    clientRevenueData,
    totalCompanyRevenue,
    totalCompanyUnits,
    pricePerUnit,
    formattedRevenueBreakdown,
    onDownload,
    onReset
}: RevenueBreakdownSectionProps): JSX.Element => {
    const [copySuccess, setCopySuccess] = useState<boolean>(false);

    /**
     * Copies the revenue breakdown to clipboard
     */
    const handleCopyRevenueBreakdown = (): void => {
        if (formattedRevenueBreakdown) {
            navigator.clipboard.writeText(formattedRevenueBreakdown)
                .then(() => {
                    setCopySuccess(true);
                    // Reset copy success indicator after 2 seconds
                    setTimeout(() => { setCopySuccess(false); }, 2000);
                })
                .catch(err => {
                    console.error('Failed to copy revenue breakdown: ', err);
                });
        }
    };

    return (
        <div className={`p-3 border rounded-md bg-${colorFamily}-50 dark:bg-${colorFamily}-900/20 mb-4`}>
            <div className="flex justify-between items-center mb-3">
                <h4 className={`font-medium flex items-center gap-2 text-${colorFamily}-700 dark:text-${colorFamily}-400`}>
                    <CheckCircle size={16} className={`text-${colorFamily}-500`} />
                    <span>Revenue Breakdown</span>
                </h4>

                {/* Action Buttons - Top right */}
                <div className="flex items-center gap-2">
                    <Button
                        onClick={onDownload}
                        variant="outline"
                        size="sm"
                        className="flex items-center gap-1"
                    >
                        <Download size={14} />
                        <span>Download CSV</span>
                    </Button>

                    <Button
                        onClick={onReset}
                        variant="ghost"
                        size="sm"
                        className="flex items-center gap-1"
                    >
                        <RefreshCw size={14} />
                        <span>Reset</span>
                    </Button>
                </div>
            </div>

            {/* Enhanced UI Display */}
            <div className="mb-4">
                <RevenueBreakdownDisplay
                    clientRevenueData={clientRevenueData}
                    totalCompanyRevenue={totalCompanyRevenue}
                    totalCompanyUnits={totalCompanyUnits}
                    pricePerUnit={pricePerUnit}
                />
            </div>

            {/* Copy button */}
            <div className="flex justify-end mb-2">
                <Button
                    onClick={handleCopyRevenueBreakdown}
                    variant="ghost"
                    size="sm"
                    className="flex items-center gap-1 h-6 text-xs"
                >
                    {copySuccess ?
                        <><Check size={12} /> Copied!</> :
                        <><Copy size={12} /> Copy Breakdown</>
                    }
                </Button>
            </div>
            <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                This breakdown shows each client&apos;s revenue data based on the updated CSV information.
            </p>
        </div>
    );
};
