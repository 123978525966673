/**
 * App Style Utilities
 * 
 * DEPRECATED: This file is maintained for backward compatibility.
 * Please import from '@/styles' or '@/styles/components' instead.
 */

import { cardStyles, componentStyles, tableStyles } from '@/styles';
import type { ColorFamily } from '@/styles/style-types';
import type { TabButtonProps, TableStyleConfig } from '@/types/app-style-types';
import type { CardStyleConfig } from '@/types/dashboard-style-types';

/**
 * Standard table styles for application tables
 * @deprecated Use tableStyles from '@/styles' instead
 */
export const appTableStyles: TableStyleConfig = tableStyles;

/**
 * Export dashboard card styles for app-wide use
 * @deprecated Use cardStyles from '@/styles' instead
 */
export const appCardStyles: CardStyleConfig = cardStyles;

/**
 * Generate a consistent gradient class based on color family
 * @deprecated Use componentStyles.getCardGradientClass from '@/styles' instead
 */
export function getCardGradientClass(
  colorFamily: keyof CardStyleConfig,
  isDarkMode = false
): string {
  return componentStyles.getCardGradientClass(
    colorFamily as ColorFamily,
    isDarkMode
  );
}

/**
 * Get the full application card class including gradient, border, and transition effects
 * @deprecated Use componentStyles.getCardClass from '@/styles' instead
 */
export function getAppCardClass(
  colorFamily: keyof CardStyleConfig,
  isDarkMode = false
): string {
  return componentStyles.getCardClass(
    colorFamily as ColorFamily,
    isDarkMode
  );
}

/**
 * Get the animation class for a card based on color family
 * @deprecated Please import from '@/styles' instead
 */
export function getCardAnimationClass(
  colorFamily: keyof CardStyleConfig,
): string {
  return cardStyles[colorFamily as ColorFamily].animationClass || '';
}

/**
 * Get consistent table header classes based on color family
 * @deprecated Use componentStyles.getTableHeaderClass from '@/styles' instead
 */
export function getTableHeaderClass(
  colorFamily: keyof TableStyleConfig,
  className?: string
): string {
  return componentStyles.getTableHeaderClass(
    colorFamily as ColorFamily,
    className
  );
}

/**
 * Get consistent table row classes based on color family
 * @deprecated Use componentStyles.getTableRowClass from '@/styles' instead
 */
export function getTableRowClass(
  colorFamily: keyof TableStyleConfig,
  isInteractive = true,
  className?: string
): string {
  return componentStyles.getTableRowClass(
    colorFamily as ColorFamily,
    isInteractive,
    className
  );
}

/**
 * Get consistent table cell classes based on color family
 * @deprecated Use componentStyles.getTableCellClass from '@/styles' instead
 */
export function getTableCellClass(
  colorFamily: keyof TableStyleConfig,
  className?: string
): string {
  return componentStyles.getTableCellClass(
    colorFamily as ColorFamily,
    className
  );
}

/**
 * Get consistent page section header classes
 * @deprecated Use componentStyles.getSectionHeaderClass from '@/styles' instead
 */
export function getSectionHeaderClass(className?: string): string {
  return componentStyles.getSectionHeaderClass(className);
}

/**
 * Get table tab button classes based on state and color family
 * @deprecated Use componentStyles.getTableTabClass from '@/styles' instead
 */
export function getTableTabClass(props: Pick<TabButtonProps, 'variant' | 'colorFamily' | 'className'>): string {
  return componentStyles.getTableTabClass(props);
}

// Dark mode detection is now handled by the useDarkModeDetection hook in src/hooks/useDarkModeDetection.ts
