import { useState, useCallback } from 'react';
import { useDarkModeDetection } from './useDarkModeDetection';

/**
 * Shared UI state hook combining theme state and utility functions
 * @returns UI state object with theme preferences and utility functions
 */
export function useUIThemeState() {
  // Use the existing dark mode detection hook
  const { isDarkMode } = useDarkModeDetection();
  
  // Store any active elements for hover effects
  const [activeElementId, setActiveElementId] = useState<string | null>(null);
  
  // Handler to set active element on hover
  const handleElementHover = useCallback((elementId: string | undefined): void => {
    if (elementId) {
      setActiveElementId(elementId);
    }
  }, []);

  // Handler to clear active element on mouse leave
  const handleElementLeave = useCallback((): void => {
    setActiveElementId(null);
  }, []);

  // Determine if an element is active
  const isElementActive = useCallback((elementId: string | undefined): boolean => {
    if (!elementId || !activeElementId) return false;
    return elementId === activeElementId;
  }, [activeElementId]);

  return {
    // Theme state
    isDarkMode,
    
    // Active element state
    activeElementId,
    setActiveElementId,
    
    // Utility functions
    handleElementHover,
    handleElementLeave,
    isElementActive,
    
    // Compound utilities
    getElementActiveClass: useCallback((elementId: string | undefined, activeClass: string, inactiveClass = ''): string => {
      return isElementActive(elementId) ? activeClass : inactiveClass;
    }, [isElementActive]),
  };
}

export default useUIThemeState;
