// src/components/layout/Sidebar.tsx
import type React from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { LogOut, Sun, Moon, Monitor } from '@/components/ui/icon';
import { cn } from '@/lib/utils';
import { signOutUser } from '@/lib/authUtils';
import { useTheme } from '@/components/layout/ThemeProvider';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { navigationItems } from './navigationConfig';

// Import the images from the uploaded files
import fullLogo from '@/components/assets/primary-logo-white--MRH5X7z.png';
import iconLogo from '@/components/assets/proxima-p-white.png';

export const Sidebar: React.FC = (): JSX.Element => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const location = useLocation();
  const { theme, setTheme } = useTheme();
  
  // Remember sidebar state in localStorage
  useEffect(() => {
    const savedState = localStorage.getItem('sidebar-expanded');
    if (savedState !== null) {
      setSidebarExpanded(savedState === 'true');
    }
  }, []);

  const toggleSidebar = (): void => {
    const newState = !sidebarExpanded;
    setSidebarExpanded(newState);
    localStorage.setItem('sidebar-expanded', String(newState));
  };

  const handleSignOut = async (): Promise<void> => {
    try {
      await signOutUser();
      // The page will refresh when signOut completes
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Helper function to get the current theme icon - uses jadeite accent for system theme (7% usage)
  const getCurrentThemeIcon = (): React.ReactNode => {
    if (theme === 'system') return <Monitor size={20} className="text-accent-500" />;
    if (theme === 'dark') return <Moon size={20} className="text-primary-500" />;
    return <Sun size={20} className="text-warning-400" />;
  };

  return (
    <aside 
      className={cn(
        "h-screen bg-white dark:bg-blackish-700 border-r border-gray-200 dark:border-blackish-600 flex flex-col shadow-md z-10",
        // Use specific transitions instead of transition-all for better performance
        "transition-[width] duration-300 ease-in-out",
        sidebarExpanded ? "w-56" : "w-16"
      )}
      aria-expanded={sidebarExpanded}
    >
      {/* Logo area with gradient background - now serves as toggle button for sidebar */}
      <div 
        onClick={toggleSidebar}
        className="h-16 flex items-center justify-center relative overflow-hidden border-b border-gray-200 dark:border-blackish-600 bg-gray-400 dark:bg-blackish-600 cursor-pointer"
      >
        {/* Fixed gradient overlay with darker appearance in light mode */}
        <div className="absolute inset-0 bg-gradient-to-r from-warning-400 to-secondary-400 dark:from-warning-700 dark:to-secondary-700 opacity-40 dark:opacity-20"></div>
        
        <div className="relative z-10 transition-opacity duration-200">
          {sidebarExpanded ? (
            <img 
              src={fullLogo} 
              alt="Proxima Logo" 
              className="h-8 w-auto"
            />
          ) : (
            <img 
              src={iconLogo} 
              alt="Proxima Icon" 
              className="h-8 w-auto"
            />
          )}
        </div>
      </div>
      
      {/* Navigation items with staggered animations */}
      <nav className="flex-1 py-4 px-2 overflow-y-auto scrollbar-thin">
        <ul className="space-y-1">
          {navigationItems.map((item, index) => {
            const isActive = location.pathname === item.path || 
              (item.path !== '/' && location.pathname.startsWith(item.path));
            
            return (
              <li 
                key={item.path}
                className={cn(
                  "transform transition-transform duration-200 ease-out animate-fade-in sidebar-nav-item",
                  item.path === '/security-audit' && "security-audit" // Apply special class for security audit
                )}
                style={{ animationDelay: `${index * 75}ms` }}
              >
                <NavLink 
                  to={item.path}
                  className={cn(
                    "flex items-center rounded-md relative overflow-hidden",
                    "transition-colors duration-200 ease-out",
                    isActive 
                      ? "text-accent-600 dark:text-accent-400" 
                      : "text-blackish-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-blackish-600",
                    sidebarExpanded 
                      ? "py-3 px-3" 
                      : "py-3 px-3 justify-center"
                  )}
                  title={!sidebarExpanded ? item.label : undefined}
                >
                  {/* Active indicator with accent color */}
                  {isActive && (
                    <span className="absolute inset-y-0 left-0 w-1 bg-accent-500 rounded-r-full"></span>
                  )}
                  
                  <span className={cn(
                    "flex-shrink-0 transition-transform duration-200",
                    isActive ? "text-accent-500 transform scale-110" : "",
                    sidebarExpanded ? "" : "transform scale-110"
                  )}>
                    {item.icon}
                  </span>
                  
                  <div className={cn(
                    "sidebar-text-container",
                    sidebarExpanded ? "w-auto opacity-100 ml-3" : "w-0 opacity-0"
                  )}>
                    <span 
                      className={cn(
                        "text-sm font-medium sidebar-nav-item-text",
                        isActive 
                          ? "opacity-100 font-semibold" 
                          : "opacity-80 group-hover:opacity-100"
                      )}
                    >
                      {item.label}
                    </span>
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      
      <div className="p-2 border-t border-gray-200 dark:border-blackish-600 flex flex-col gap-1">
        {/* Theme toggle with dropdown */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className={cn(
              "flex items-center rounded-md py-2 px-3 hover:bg-gray-100 dark:hover:bg-blackish-600 cursor-pointer",
              "transition-all duration-200 ease-out",
              !sidebarExpanded && "justify-center"
            )}>
              <span className="flex-shrink-0">
                {getCurrentThemeIcon()}
              </span>
              
              <div className={cn(
                "sidebar-text-container",
                sidebarExpanded ? "w-auto opacity-100 ml-3" : "w-0 opacity-0"
              )}>
                <span className="text-sm font-medium text-blackish-500 dark:text-gray-300 sidebar-nav-item-text">
                  Theme
                </span>
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem 
              onClick={() => { setTheme('light'); }}
              className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-blackish-600"
            >
              <Sun size={16} className="text-warning-400" />
              <span>Light</span>
              {theme === 'light' && <span className="ml-auto text-xs text-primary-500">✓</span>}
            </DropdownMenuItem>
            <DropdownMenuItem 
              onClick={() => { setTheme('dark'); }}
              className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-blackish-600"
            >
              <Moon size={16} className="text-primary-500" />
              <span>Dark</span>
              {theme === 'dark' && <span className="ml-auto text-xs text-primary-500">✓</span>}
            </DropdownMenuItem>
            <DropdownMenuItem 
              onClick={() => { setTheme('system'); }}
              className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-blackish-600"
            >
              <Monitor size={16} className="text-accent-500" />
              <span>System</span>
              {theme === 'system' && <span className="ml-auto text-xs text-primary-500">✓</span>}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        
        <button
          onClick={handleSignOut}
          className={cn(
            "flex items-center rounded-md py-2 px-3",
            "text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20",
            "transition-all duration-200 ease-out",
            !sidebarExpanded && "justify-center"
          )}
          aria-label="Sign out"
        >
          <span className="flex-shrink-0">
            <LogOut size={20} />
          </span>
          
          <div className={cn(
            "sidebar-text-container",
            sidebarExpanded ? "w-auto opacity-100 ml-3" : "w-0 opacity-0"
          )}>
            <span className="text-sm font-medium sidebar-nav-item-text">
              Sign Out
            </span>
          </div>
        </button>
      </div>
    </aside>
  );
};
