/**
 * Clients API Service - Simplified Version
 *
 * Provides standardized API access for client data with consistent typing,
 * error handling, and response transformation.
 */
import type {
  ApiResponseData,
  Client,
  ClientFetchError,
  Employer,
  PaginatedResponse,
  ServiceProvider
} from '@/types/client.unified';
import { fetchAuthSession } from 'aws-amplify/auth';



// Standard timeout for API requests
const API_TIMEOUT = 15000;

/**
 * Standard API error with additional metadata for better error handling
 */
export class ApiError extends Error implements ClientFetchError {
  statusCode?: number;
  source = 'clients-api';
  timestamp: string;
  retryCount = 0;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = statusCode;
    this.timestamp = new Date().toISOString();
  }
}

/**
 * Get the authentication token from Amplify
 */
const getAuthToken = async (): Promise<string> => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken;

    if (!accessToken) {
      throw new ApiError('No access token available', 401);
    }

    return accessToken.toString();
  } catch (error) {
    console.error('[API ERROR] Authentication error:', error);

    if (error instanceof Error) {
      if (error.message.includes('No current user')) {
        throw new ApiError('Authentication required. Please log in.', 401);
      }

      if (error.message.includes('expire')) {
        throw new ApiError('Your session has expired. Please log in again.', 401);
      }

      // Already an ApiError, just pass it through
      if (error instanceof ApiError) {
        throw error;
      }
    }

    throw new ApiError(
      'Failed to authenticate: ' + (error instanceof Error ? error.message : String(error)),
      500
    );
  }
};

/**
 * Reusable fetch function with proper authentication and error handling
 */
const fetchWithAuth = async <T>(
  url: string,
  options: RequestInit = {},
  timeout = API_TIMEOUT
): Promise<T> => {
  // Create an AbortController for request timeouts
  const controller = new AbortController();
  const timeoutId = setTimeout(() => { controller.abort(); }, timeout);

  try {
    const token = await getAuthToken();

    const response = await fetch(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...options.headers
      },
      signal: controller.signal
    });

    // Clear the timeout since we got a response
    clearTimeout(timeoutId);

    // Handle non-OK responses
    if (!response.ok) {
      const errorText = await response.text();
      console.error('[API ERROR] Request failed:', response.status, errorText);
      throw new ApiError(
        `API error (${response.status}): ${errorText || response.statusText}`,
        response.status
      );
    }

    // Get the raw text for controlled parsing
    const responseText = await response.text();

    // Handle empty responses (valid in some cases)
    if (!responseText.trim()) {
      return {} as T;
    }

    // Try to parse the response as JSON
    try {
      return JSON.parse(responseText) as T;
    } catch (parseError) {
      console.error('[API ERROR] Failed to parse response as JSON:', parseError);
      throw new ApiError('Failed to parse API response as JSON', 500);
    }
  } catch (error) {
    // Clear the timeout to avoid memory leaks
    clearTimeout(timeoutId);

    // Handle request timeout
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new ApiError(`Request timed out after ${timeout}ms`, 408);
    }

    // Propagate ApiError instances
    if (error instanceof ApiError) {
      throw error;
    }

    // Wrap other errors
    console.error('[API ERROR] Fetch error:', error);
    throw new ApiError(
      `Failed to fetch: ${error instanceof Error ? error.message : String(error)}`,
      500
    );
  }
};

/**
 * Simplified response transformation to minimize data manipulation
 */
const simplifyApiResponse = <T>(responseData: ApiResponseData<T>): PaginatedResponse<T> => {
  // Get items directly with minimal manipulation
  const items: T[] = responseData.data || responseData.items || [];

  // Use metadata directly if available or create a simple version
  const metadata = responseData.metadata || {};

  // Create simplified response with minimal transformations
  return {
    items,
    totalCount: typeof metadata.totalCount === 'number' ? metadata.totalCount : items.length,
    page: typeof metadata.page === 'number' ? metadata.page : 1,
    totalPages: typeof metadata.totalPages === 'number' ? metadata.totalPages : 1,
    itemsPerPage: typeof metadata.itemsPerPage === 'number' ? metadata.itemsPerPage : 10
  };
};

/**
 * Enhanced type discriminant adder with robust error handling
 */
function addClientTypeDiscriminant<T extends Client[]>(
  clients: T,
  clientType: 'employer' | 'serviceProvider'
): T {
  // Return directly if array is empty to avoid unnecessary operations
  if (!clients || !Array.isArray(clients) || clients.length === 0) {
    return clients;
  }

  try {
    const result = clients.map((client: any) => {
      // Safety check for null/undefined clients and ensure client is an object
      if (!client || typeof client !== 'object') {
        return client;
      }

      // Don't override type if already defined
      if ('type' in client && client.type) {
        return client;
      }

      // For service providers, add more robust checks
      if (clientType === 'serviceProvider') {
        // Service providers may not need as many fields as employers
        const serviceProvider: ServiceProvider = {
          id: client.id || 'unknown-id',
          name: client.name || 'Unnamed Service Provider',
          creationTime: client.creationTime || new Date().toISOString(),
          isDisabled: typeof client.isDisabled === 'boolean' ? client.isDisabled : false,
          isTest: typeof client.isTest === 'boolean' ? client.isTest : false,
          mfaRequired: typeof client.mfaRequired === 'boolean' ? client.mfaRequired : false,
          prn: client.prn || '',
          creatorPrn: client.creatorPrn || '',
          type: 'serviceProvider',
          serviceType: client.serviceType || undefined
        };
        return serviceProvider;
      }

      // For employers, ensure products exist
      if (clientType === 'employer') {
        const employer: Employer = {
          id: client.id || 'unknown-id',
          name: client.name || 'Unnamed Employer',
          creationTime: client.creationTime || new Date().toISOString(),
          isDisabled: typeof client.isDisabled === 'boolean' ? client.isDisabled : false,
          isTest: typeof client.isTest === 'boolean' ? client.isTest : false,
          mfaRequired: typeof client.mfaRequired === 'boolean' ? client.mfaRequired : false,
          prn: client.prn || '',
          creatorPrn: client.creatorPrn || '',
          type: 'employer',
          preferredName: client.preferredName || client.name || 'Unnamed Employer',
          brandingLogoPath: client.brandingLogoPath || '',
          brandingPrimaryColor: client.brandingPrimaryColor || '#000000',
          expirationsEnabled: typeof client.expirationsEnabled === 'boolean' ? client.expirationsEnabled : false,
          hasAgreement: typeof client.hasAgreement === 'boolean' ? client.hasAgreement : false,
          subtype: client.subtype || '',
          products: Array.isArray(client.products) ? client.products : []
        };
        return employer;
      }

      // Fallback - just add type
      return Object.assign({}, client, { type: clientType });
    }) as T;

    return result;
  } catch (error) {
    console.error('[API ERROR] Error adding type discriminant:', error);
    // Return original array on error rather than failing completely
    return clients;
  }
}

/**
 * Interface for employer fetch parameters
 */
export type EmployerFetchParams = {
  search?: string;         // For name searching
  status?: string;         // For filtering by active/disabled
  sortBy?: 'name' | '-name' | 'creationTime' | '-creationTime';
  itemsPerPage?: number;
  page?: number;
}

/**
 * Get employers - enhanced version with filtering support
 */
export const getEmployers = async (
  params?: EmployerFetchParams
): Promise<PaginatedResponse<Employer>> => {
  try {
    // Build query string from parameters if provided
    const queryParams = new URLSearchParams();

    if (params) {
      // Only add search if it's empty (to clear) or has 2+ characters
      if (params.search !== undefined && (params.search === '' || params.search.length >= 2)) {
        queryParams.append('name', params.search);
      }

      if (params.status && params.status !== 'all') {
        queryParams.append('isDisabled', params.status === 'disabled' ? 'true' : 'false');
      }

      if (params.sortBy) {
        queryParams.append('sortBy', params.sortBy);
      }

      if (params.itemsPerPage && params.itemsPerPage >= 1 && params.itemsPerPage <= 100) {
        queryParams.append('itemsPerPage', params.itemsPerPage.toString());
      }

      if (params.page && params.page >= 1) {
        queryParams.append('page', params.page.toString());
      }
    }

    // Create URL with or without query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${import.meta.env.VITE_API_BASE_URL}/employers${queryString ? `?${queryString}` : ''}`;

    // Execute the API call with the constructed URL
    const responseData = await fetchWithAuth<ApiResponseData<Employer>>(apiUrl);

    // Safety check for response data structure (matching service providers)
    if (!responseData) {
      return {
        items: [],
        totalCount: 0,
        page: 1,
        totalPages: 1,
        itemsPerPage: 10
      };
    }

    // Minimal transformation with enhanced logging
    const paginatedResponse = simplifyApiResponse<Employer>(responseData);

    // Only add type if needed and preserve original data
    paginatedResponse.items = addClientTypeDiscriminant(
      paginatedResponse.items,
      'employer'
    );

    return paginatedResponse;
  } catch (error) {
    console.error('[API ERROR] Error in getEmployers:', error);

    // Rethrow ApiError instances
    if (error instanceof ApiError) {
      throw error;
    }

    // Wrap other errors
    throw new ApiError(
      'Failed to fetch employers: ' + (error instanceof Error ? error.message : String(error)),
      500
    );
  }
};

/**
 * Interface for service provider fetch parameters
 */
export type ServiceProviderFetchParams = {
  search?: string;         // For name searching
  status?: string;         // For filtering by active/disabled
  sortBy?: 'name' | '-name' | 'creationTime' | '-creationTime';
  itemsPerPage?: number;
  page?: number;
}

/**
 * Get service providers - enhanced version with detailed error logging
 */
export const getServiceProviders = async (
  params?: ServiceProviderFetchParams
): Promise<PaginatedResponse<ServiceProvider>> => {
  try {
    // Build query string from parameters if provided
    const queryParams = new URLSearchParams();

    if (params) {
      // Only add search if it's empty (to clear) or has 2+ characters
      if (params.search !== undefined && (params.search === '' || params.search.length >= 2)) {
        queryParams.append('name', params.search);
      }

      if (params.status && params.status !== 'all') {
        queryParams.append('isDisabled', params.status === 'disabled' ? 'true' : 'false');
      }

      if (params.sortBy) {
        queryParams.append('sortBy', params.sortBy);
      }

      if (params.itemsPerPage && params.itemsPerPage >= 1 && params.itemsPerPage <= 100) {
        queryParams.append('itemsPerPage', params.itemsPerPage.toString());
      }

      if (params.page && params.page >= 1) {
        queryParams.append('page', params.page.toString());
      }
    }

    // Create URL with or without query parameters
    const queryString = queryParams.toString();
    const apiUrl = `${import.meta.env.VITE_API_BASE_URL}/service-providers${queryString ? `?${queryString}` : ''}`;

    try {
      // Remove custom headers that might be causing 400 errors
      const responseData = await fetchWithAuth<ApiResponseData<ServiceProvider>>(apiUrl);

      // Safety check for response data structure
      if (!responseData) {
        return {
          items: [],
          totalCount: 0,
          page: 1,
          totalPages: 1,
          itemsPerPage: 10
        };
      }

      // Minimal transformation with enhanced logging
      const paginatedResponse = simplifyApiResponse<ServiceProvider>(responseData);

      // Only add type if needed and preserve original data
      paginatedResponse.items = addClientTypeDiscriminant(
        paginatedResponse.items,
        'serviceProvider'
      );

      return paginatedResponse;
    } catch (fetchError) {
      // Enhanced error logging specific to fetchWithAuth
      console.error('[API ERROR] fetchWithAuth error details:', {
        message: fetchError instanceof Error ? fetchError.message : String(fetchError),
        status: fetchError instanceof ApiError ? fetchError.statusCode : 'unknown',
        timestamp: new Date().toISOString()
      });
      throw fetchError; // Re-throw to be caught by the outer try/catch
    }
  } catch (error) {
    console.error('[API ERROR] Error in getServiceProviders:', error);

    // Enhanced error logging
    if (error instanceof ApiError && error.statusCode === 400) {
      console.error('[API ERROR] Bad Request (400) details:', {
        message: error.message,
        endpoint: `${import.meta.env.VITE_API_BASE_URL}/service-providers`,
        time: new Date().toISOString()
      });
    }

    // Provide a user-friendly error with enhanced details
    let errorMessage = 'Failed to fetch service providers.';
    let statusCode = 500;

    if (error instanceof ApiError) {
      errorMessage = `API Error (${error.statusCode || 'unknown'}): ${error.message}`;
      statusCode = error.statusCode || 500;
    } else if (error instanceof Error) {
      errorMessage += ' ' + error.message;
      console.error('[API ERROR] Stack trace:', error.stack);
    }

    throw new ApiError(errorMessage, statusCode);
  }
};

/**
 * Get clients by type with unified interface - enhanced version with parameters
 */
export const getClientsByType = async (
  clientType: 'employer' | 'serviceProvider',
  params?: EmployerFetchParams | ServiceProviderFetchParams
): Promise<PaginatedResponse<Client>> => {
  return clientType === 'employer'
    ? getEmployers(params as EmployerFetchParams) as Promise<PaginatedResponse<Client>>
    : getServiceProviders(params as ServiceProviderFetchParams) as Promise<PaginatedResponse<Client>>;
};
