/**
 * SubmissionsPage Component
 * 
 * Displays a comprehensive view of health record submissions using the records API endpoint
 * with dynamic data handling, advanced filtering, sorting, and state management capabilities.
 */
import type * as React from 'react';
import { ModernSubmissionsTable } from './modern';
import { FileText } from '@/components/ui/icon';
import { SubmissionsProvider } from './SubmissionsContext';

const SubmissionsPageContent: React.FC = () => {
  return (
    <div className="space-y-6 w-full max-w-full px-3 sm:px-4 md:px-6">
      {/* Page Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 border-b pb-4">
        <div>
          <h2 className="text-2xl font-bold text-blackish-800 dark:text-white">
            <FileText className="inline-block mr-2 h-7 w-7 text-warning-500" />
            Record Submissions
          </h2>
          <div className="text-sm text-blackish-500 dark:text-blackish-300">
            View and manage all questionnaire submissions from the records API
          </div>
        </div>
      </div>

      {/* Modern Submissions Table with built-in state management */}
      <ModernSubmissionsTable
        useContext={true}
        pagination={true}
      />
    </div>
  );
};

export const SubmissionsPage: React.FC = () => {
  return (
    <SubmissionsProvider>
      <SubmissionsPageContent />
    </SubmissionsProvider>
  );
};
