/**
 * Component Styling Utilities
 * 
 * General styling utilities for application-wide components
 */

import { cn } from '@/lib/utils';
import type { ColorFamily, TabButtonProps } from './style-types';
import { cardStyles, tableStyles, buttonVariants, transitions } from './theme';

/**
 * Get the full card class including gradient, border, and transition effects
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param isDarkMode - Whether to generate styles for dark mode
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the card
 */
export function getCardClass(
  colorFamily: ColorFamily,
  isDarkMode = false,
  className?: string
): string {
  const gradientClass = getCardGradientClass(colorFamily, isDarkMode);
  return cn(
    'app-card shadow-md border-blackish-100 dark:border-blackish-800 hover:shadow-lg',
    transitions.default,
    'overflow-hidden',
    gradientClass,
    className
  );
}

/**
 * Generate a consistent gradient class based on color family
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param isDarkMode - Whether to generate styles for dark mode
 * @returns A string of CSS classes for the gradient
 */
export function getCardGradientClass(
  colorFamily: ColorFamily,
  isDarkMode = false
): string {
  const style = cardStyles[colorFamily];
  if (!style) return '';
  
  return `bg-gradient-${style.direction} ${isDarkMode ? style.dark : style.light}`;
}

/**
 * Get consistent table header classes based on color family
 * 
 * @param colorFamily - The color family (primary, secondary, etc.) 
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the table header
 */
export function getTableHeaderClass(
  colorFamily: ColorFamily,
  className?: string
): string {
  const style = tableStyles[colorFamily];
  if (!style) return className || '';
  
  return cn(style.headerClasses, className);
}

/**
 * Get consistent table row classes based on color family
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param isInteractive - Whether the row should have hover effects
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the table row
 */
export function getTableRowClass(
  colorFamily: ColorFamily,
  isInteractive = true,
  className?: string
): string {
  const style = tableStyles[colorFamily];
  if (!style) return className || '';
  
  return cn(
    style.rowClasses,
    isInteractive ? style.hoverClasses : '',
    className
  );
}

/**
 * Get consistent table cell classes based on color family
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the table cell
 */
export function getTableCellClass(
  colorFamily: ColorFamily,
  className?: string
): string {
  const style = tableStyles[colorFamily];
  if (!style) return className || '';
  
  return cn(style.cellClasses, className);
}

/**
 * Get consistent page section header classes
 * 
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the section header
 */
export function getSectionHeaderClass(className?: string): string {
  return cn(
    'text-xl font-bold text-blackish-800 dark:text-white flex items-center gap-2 border-b pb-2',
    className
  );
}

/**
 * Get table tab button classes based on state and color family
 * 
 * @param props - Tab button properties including variant and color family
 * @returns A string of CSS classes for the tab button
 */
export function getTableTabClass({
  variant,
  colorFamily = 'primary',
  className
}: Pick<TabButtonProps, 'variant' | 'colorFamily' | 'className'>): string {
  const baseClasses = 'px-4 py-2 text-sm font-medium rounded-lg transition-colors';
  
  const variantClasses = buttonVariants[variant === 'default' ? 'default' : 'outline'];
  const colorClasses = variantClasses[colorFamily] || variantClasses.primary;

  return cn(
    baseClasses,
    colorClasses,
    className
  );
}

/**
 * Get standardized classes for a content container
 * 
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the content container
 */
export function getContentContainerClass(className?: string): string {
  return cn(
    'w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8',
    className
  );
}

/**
 * Get standardized classes for a section container
 * 
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the section container
 */
export function getSectionContainerClass(className?: string): string {
  return cn(
    'mb-8',
    className
  );
}

/**
 * Get standardized classes for an action button
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param isOutline - Whether to use outline variant
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the action button
 */
export function getActionButtonClass(
  colorFamily: ColorFamily = 'primary',
  isOutline = false,
  className?: string
): string {
  const variantKey = isOutline ? 'outline' : 'default';
  const variantClasses = buttonVariants[variantKey];
  const colorClasses = variantClasses[colorFamily] || variantClasses.primary;
  
  return cn(
    'px-4 py-2 rounded-md text-sm font-medium',
    transitions.default,
    colorClasses,
    className
  );
}

/**
 * Get standardized classes for form elements
 * 
 * @param colorFamily - The color family (primary, secondary, etc.)
 * @param hasError - Whether the form element has an error
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the form element
 */
export function getFormElementClass(
  colorFamily: ColorFamily = 'primary',
  hasError = false,
  className?: string
): string {
  const colorClasses: Record<ColorFamily, string> = {
    primary: 'focus-within:border-primary-500 focus-within:ring-primary-500/30',
    secondary: 'focus-within:border-secondary-500 focus-within:ring-secondary-500/30',
    accent: 'focus-within:border-accent-500 focus-within:ring-accent-500/30',
    tertiary: 'focus-within:border-secondary-500 focus-within:ring-secondary-500/30',
    warning: 'focus-within:border-warning-500 focus-within:ring-warning-500/30'
  };
  
  const errorClasses = 'border-red-500 focus-within:border-red-500 focus-within:ring-red-500/30 text-red-600';
  
  return cn(
    'block w-full px-3 py-2 text-blackish-900 dark:text-white bg-white dark:bg-blackish-900 rounded-md border border-blackish-300 dark:border-blackish-700',
    'shadow-sm',
    transitions.default,
    'focus-within:outline-none focus-within:ring-4',
    hasError ? errorClasses : colorClasses[colorFamily],
    className
  );
}

/**
 * Get standardized classes for informational text
 * 
 * @param type - The type of information (info, success, warning, error)
 * @param className - Additional custom classes to apply
 * @returns A string of CSS classes for the informational text
 */
export function getInfoTextClass(
  type: 'info' | 'success' | 'warning' | 'error' = 'info',
  className?: string
): string {
  const typeClasses = {
    info: 'text-blue-600 dark:text-blue-400',
    success: 'text-green-600 dark:text-green-400',
    warning: 'text-yellow-600 dark:text-yellow-400',
    error: 'text-red-600 dark:text-red-400'
  };
  
  return cn(
    'text-sm',
    typeClasses[type],
    className
  );
}
