// src/components/invoices/FileUploadSection.tsx
import { Button } from "@/components/ui/button";
import { CheckCircle, FileUp } from '@/components/ui/icon';
import { cn } from "@/lib/utils";
import type { ColorFamily } from "@/types/ui-component-types";
import type { ChangeEvent, RefObject } from "react";

export type FileUploadSectionProps = {
    /**
     * Color family for styling consistency
     */
    colorFamily?: ColorFamily;
    /**
     * Type of file to upload
     */
    fileType: 'csv' | 'json';
    /**
     * The selected file
     */
    file: File | null;
    /**
     * Error message for JSON parsing
     */
    jsonParseError?: string;
    /**
     * Handler for file change
     */
    onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    /**
     * Handler for upload button click
     */
    onUploadClick: () => void;
    /**
     * Reference to the file input element
     */
    inputRef: RefObject<HTMLInputElement>;
}

/**
 * FileUploadSection component for handling file uploads
 * Follows Proxima design system styles
 */
export const FileUploadSection = ({
    colorFamily = 'primary',
    fileType,
    file,
    jsonParseError,
    onFileChange,
    onUploadClick,
    inputRef
}: FileUploadSectionProps): JSX.Element => {

    const fileTypeLabel = fileType === 'csv' ? 'Invoice CSV' : 'Client Pricing Data (Required)';
    const buttonLabel = file ? 'Change File' : `Upload ${fileType.toUpperCase()}`;
    const description = fileType === 'csv'
        ? 'Upload the invoice CSV file generated by the "Generate Invoice CSV" button.'
        : 'Upload a JSON file containing your client pricing data. This will be used to update the invoice prices.';

    return (
        <div className="p-4 border rounded-md bg-white dark:bg-blackish-800/30 shadow-sm">
            {/* Hidden file input */}
            <input
                type="file"
                ref={inputRef}
                className="hidden"
                accept={fileType === 'csv' ? '.csv' : '.json'}
                onChange={onFileChange}
            />

            {/* Header with upload button */}
            <div className="flex items-center justify-between mb-2">
                <h3 className="text-sm font-medium text-blackish-800 dark:text-white">{fileTypeLabel}</h3>
                <Button
                    onClick={onUploadClick}
                    variant="outline"
                    size="sm"
                    className={cn(
                        "flex items-center gap-1",
                        `hover:bg-${colorFamily}-50/30 dark:hover:bg-${colorFamily}-900/10`
                    )}
                >
                    <FileUp size={14} className={`text-${colorFamily}-500`} />
                    {buttonLabel}
                </Button>
            </div>

            {/* File status display */}
            {file ? (
                <div className="text-sm flex items-center gap-2 text-green-600 dark:text-green-500">
                    <CheckCircle size={16} />
                    <span>{file.name}</span>
                </div>
            ) : (
                <p className="text-sm text-blackish-500 dark:text-blackish-300">
                    {description}
                </p>
            )}

            {/* JSON parse error display */}
            {fileType === 'json' && jsonParseError && (
                <div className="mt-2 p-2 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-md text-xs text-red-700 dark:text-red-400">
                    <strong>JSON Error:</strong> {jsonParseError}
                </div>
            )}
        </div>
    );
};
