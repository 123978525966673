import { Search } from '@/components/ui/icon';
import { Input } from '@/components/ui/input';
import { TableStateContainer } from '@/components/ui/state-container';
import { TableEmptyState } from '@/components/ui/table-empty-state';
import { TableErrorState } from '@/components/ui/table-error-state';
import { TableLoadingState } from '@/components/ui/table-loading-state';
import { TableTabs } from '@/components/ui/table-tabs';
import { cn } from '@/lib/utils';
import type { ProductType } from '@/types/product.unified';
import type { ColorFamily } from '@/types/ui-component-types';
import * as React from 'react';
import { useCallback } from 'react';

export type TabbedTableTabConfig<T extends string = string> = {
  id: T;
  label: string | React.ReactNode;
}

export type TabbedTableDataState<T> = {
  isLoading: boolean;
  error: Error | string | null;
  data: T[] | null | undefined;
  filteredData?: T[] | null;
}

export type TabbedTableProps<TabID extends string = string, DataType = unknown> = {
  // Tab configuration
  tabs: Array<TabbedTableTabConfig<TabID>>;
  activeTab: TabID;
  onTabChange: (tabId: TabID) => void;

  // Data state for each tab
  tabsData: Record<TabID, TabbedTableDataState<DataType>>;

  // Clear filters functionality
  onClearFilters?: () => void;

  // Search functionality
  searchEnabled?: boolean;
  searchTerm?: string;
  onSearchChange?: (term: string) => void;
  searchPlaceholder?: string;

  // Disabled state
  isDisabled?: boolean;

  // Retry handlers
  onRetry?: (tabId: TabID) => void;

  // UI customization
  colorFamily?: ColorFamily;
  className?: string;
  loadingMessage?: string;
  emptyMessage?: string;
  emptyComponent?: React.ReactNode;

  // Table type flag
  isClientTable?: boolean; // Set to true for client tables where tabs are not product types

  // Children - the actual table contents
  children: React.ReactNode;
}

/**
 * TabbedTable component providing a standardized approach for tables with tabs
 * in the application. Handles loading states, errors, and empty states per tab.
 */
export function TabbedTable<TabID extends string = string, DataType = unknown>({
  tabs,
  activeTab,
  onTabChange,
  tabsData,
  searchEnabled = true,
  searchTerm = '',
  onSearchChange,
  searchPlaceholder = 'Search...',
  isDisabled = false,
  onRetry,
  colorFamily = 'primary',
  className,
  loadingMessage,
  emptyMessage,
  emptyComponent,
  isClientTable = false, // Default to false for backward compatibility
  children
}: TabbedTableProps<TabID, DataType>): JSX.Element {
  // Get the current tab's data state
  const currentTabData = tabsData[activeTab];

  // Handle search input changes
  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (onSearchChange) {
      onSearchChange(e.target.value);
    }
  }, [onSearchChange]);

  // Handle retry for the current tab
  const handleRetry = useCallback(() => {
    if (onRetry) {
      onRetry(activeTab);
    }
  }, [onRetry, activeTab]);

  // Determine if the current tab has no data
  const isEmpty: boolean = !currentTabData.data ||
    currentTabData.data.length === 0 ||
    (!!currentTabData.filteredData && currentTabData.filteredData.length === 0);

  // Wrapper for onRetry to match onClearFilters signature
  const handleClearFilters = useCallback(() => {
    if (onRetry) {
      onRetry(activeTab);
    }
  }, [onRetry, activeTab]);

  // Default empty component if none provided
  const defaultEmptyComponent = (
    <TableEmptyState
      productType={isClientTable ? undefined : (activeTab as unknown as ProductType)}
      message={emptyMessage}
      colorFamily={colorFamily}
      showFiltersButton={!!onRetry}
      onClearFilters={handleClearFilters}
    />
  );

  // Default loading message based on active tab
  const defaultLoadingMessage = loadingMessage ?? `Loading ${activeTab} data...`;

  return (
    <div className={cn("flex flex-col gap-3 sm:gap-4 pt-2 sm:pt-3 md:pt-4", className)}>
      {/* Tabs and Search Row */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between w-full gap-3">
        <div className="flex-shrink-0">
          <TableTabs
            activeTab={activeTab}
            onTabChange={(tabId) => { onTabChange(tabId as TabID); }}
            tabs={tabs}
            colorFamily={colorFamily}
            isDisabled={isDisabled}
          />
        </div>

        {/* Search input with icon - always show when search is enabled, even during loading */}
        {searchEnabled && (
          <div className="relative w-64 max-w-[260px] flex-shrink-0 hidden sm:block">
            <Search
              className={`absolute left-2 top-2.5 h-4 w-4 text-${colorFamily}-400 dark:text-${colorFamily}-500`}
            />
            <Input
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={handleSearchChange}
              // Not disabled during loading - allowing search while loading
              className={`pl-8 bg-transparent text-blackish dark:text-blackish-100`}
            />
          </div>
        )}

        {/* Mobile search (shown only on small screens) - always visible */}
        {searchEnabled && (
          <div className="relative w-full sm:hidden flex-shrink-0">
            <Search
              className={`absolute left-2 top-2.5 h-4 w-4 text-${colorFamily}-400 dark:text-${colorFamily}-500`}
            />
            <Input
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={handleSearchChange}
              // Not disabled during loading - allowing search while loading
              className={`pl-8 bg-transparent text-blackish dark:text-blackish-100`}
            />
          </div>
        )}
      </div>

      {/* Check if children has multiple elements to extract the first one as persistentElements */}
      {React.Children.count(children) > 1 ? (
        <TableStateContainer
          isLoading={currentTabData.isLoading}
          error={currentTabData.error}
          isEmpty={isEmpty}
          loadingComponent={
            <TableLoadingState
              message={defaultLoadingMessage}
              colorFamily={colorFamily}
            />
          }
          errorComponent={
            <TableErrorState
              error={currentTabData.error ? String(currentTabData.error) : "Failed to load data"}
              onRetry={handleRetry}
            />
          }
          emptyComponent={emptyComponent ?? defaultEmptyComponent}
          animated={true}
          persistentElements={React.Children.toArray(children)[0]}
        >
          {React.Children.toArray(children).slice(1)}
        </TableStateContainer>
      ) : (
        <TableStateContainer
          isLoading={currentTabData.isLoading}
          error={currentTabData.error}
          isEmpty={isEmpty}
          loadingComponent={
            <TableLoadingState
              message={defaultLoadingMessage}
              colorFamily={colorFamily}
            />
          }
          errorComponent={
            <TableErrorState
              error={currentTabData.error ? String(currentTabData.error) : "Failed to load data"}
              onRetry={handleRetry}
            />
          }
          emptyComponent={emptyComponent ?? defaultEmptyComponent}
          animated={true}
        >
          {children}
        </TableStateContainer>
      )}
    </div>
  );
}
