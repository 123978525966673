import { Button } from '@/components/ui/button';
import { Package, SearchXIcon, Tag } from '@/components/ui/icon';
import { cn } from '@/lib/utils';
import { ProductType } from '@/types/product.unified';
import { PRODUCTS } from '@/utils/product-registry';
import * as React from 'react';

export type TableEmptyStateProps = {
    productType?: ProductType;
    message?: string;
    colorFamily?: 'primary' | 'secondary' | 'accent' | 'tertiary' | 'warning';
    showFiltersButton?: boolean;
    onClearFilters?: () => void;
    className?: string;
}

/**
 * TableEmptyState component for displaying empty state messages in tables.
 * Provides contextual information and clear visuals following Proxima's design system.
 */
export const TableEmptyState: React.FC<TableEmptyStateProps> = ({
    productType,
    message,
    colorFamily = 'warning',
    showFiltersButton = false,
    onClearFilters,
    className,
}) => {
    // Generate human-readable product name from the product type
    const productName = React.useMemo(() => {
        if (!productType || productType === ProductType.ALL) {
            return 'data';
        }

        // Check if the product type exists in PRODUCTS registry
        if (!PRODUCTS[productType]) {
            console.warn(`Product type "${productType}" not found in PRODUCTS registry`);
            return 'data';
        }

        return PRODUCTS[productType].name ?? 'data';
    }, [productType]);

    // Determine the appropriate icon based on product type
    const EmptyStateIcon = React.useMemo(() => {
        if (!productType || productType === ProductType.ALL) {
            return SearchXIcon;
        }

        // Show different icons based on product types
        switch (productType) {
            case ProductType.RESPIRATOR_FIT_TEST:
            case ProductType.RESPIRATOR_MEDICAL_EVALUATION:
                return Package;
            default:
                return SearchXIcon;
        }
    }, [productType]);

    // Determine if we're likely showing empty due to filters
    const isEmpty = !message?.toLowerCase().includes('filter');
    const defaultMessage = isEmpty
        ? `No ${productName} found`
        : `No ${productName} matching your filters`;

    // Color classes based on colorFamily
    const colorClasses = React.useMemo(() => {
        const baseColors = {
            primary: {
                bg: 'bg-primary-50/60 dark:bg-primary-950/20',
                text: 'text-primary-700 dark:text-primary-300',
                icon: 'text-primary-500 dark:text-primary-400',
                border: 'border-primary-200 dark:border-primary-800/40',
                button: 'border-primary-200 bg-primary-50 hover:bg-primary-100 text-primary-700 dark:border-primary-800 dark:bg-primary-900/20 dark:hover:bg-primary-900/30 dark:text-primary-300'
            },
            secondary: {
                bg: 'bg-secondary-50/60 dark:bg-secondary-950/20',
                text: 'text-secondary-700 dark:text-secondary-300',
                icon: 'text-secondary-500 dark:text-secondary-400',
                border: 'border-secondary-200 dark:border-secondary-800/40',
                button: 'border-secondary-200 bg-secondary-50 hover:bg-secondary-100 text-secondary-700 dark:border-secondary-800 dark:bg-secondary-900/20 dark:hover:bg-secondary-900/30 dark:text-secondary-300'
            },
            accent: {
                bg: 'bg-accent-50/60 dark:bg-accent-950/20',
                text: 'text-accent-700 dark:text-accent-300',
                icon: 'text-accent-500 dark:text-accent-400',
                border: 'border-accent-200 dark:border-accent-800/40',
                button: 'border-accent-200 bg-accent-50 hover:bg-accent-100 text-accent-700 dark:border-accent-800 dark:bg-accent-900/20 dark:hover:bg-accent-900/30 dark:text-accent-300'
            },
            tertiary: {
                bg: 'bg-tertiary-50/60 dark:bg-tertiary-950/20',
                text: 'text-tertiary-700 dark:text-tertiary-300',
                icon: 'text-tertiary-500 dark:text-tertiary-400',
                border: 'border-tertiary-200 dark:border-tertiary-800/40',
                button: 'border-tertiary-200 bg-tertiary-50 hover:bg-tertiary-100 text-tertiary-700 dark:border-tertiary-800 dark:bg-tertiary-900/20 dark:hover:bg-tertiary-900/30 dark:text-tertiary-300'
            },
            warning: {
                bg: 'bg-warning-50/60 dark:bg-warning-950/20',
                text: 'text-warning-700 dark:text-warning-300',
                icon: 'text-warning-500 dark:text-warning-400',
                border: 'border-warning-200 dark:border-warning-800/40',
                button: 'border-warning-200 bg-warning-50 hover:bg-warning-100 text-warning-700 dark:border-warning-800 dark:bg-warning-900/20 dark:hover:bg-warning-900/30 dark:text-warning-300'
            }
        };

        return baseColors[colorFamily] || baseColors.warning;
    }, [colorFamily]);

    // Subtitle based on whether it's empty due to no data or due to filters
    const subtitle = React.useMemo(() => {
        if (message) return null; // Don't show subtitle if custom message provided

        return isEmpty
            ? "There are no records available at this time."
            : "Try adjusting your search criteria or filters.";
    }, [isEmpty, message]);

    return (
        <div
            className={cn(
                "rounded-md border shadow-sm py-6 px-4 my-2 text-center w-full",
                "max-w-md mx-auto transform-gpu transition-all duration-200",
                "hover:shadow-md hover:border-opacity-80",
                colorClasses.bg,
                colorClasses.border,
                className
            )}
        >
            <div className="flex flex-col items-center justify-center animate-on-mount">
                {/* Icon with subtle animation */}
                <div className={cn(
                    "rounded-full p-3 mb-3",
                    "bg-opacity-10 dark:bg-opacity-10",
                    {
                        "bg-primary-100 dark:bg-primary-800": colorFamily === 'primary',
                        "bg-secondary-100 dark:bg-secondary-800": colorFamily === 'secondary',
                        "bg-accent-100 dark:bg-accent-800": colorFamily === 'accent',
                        "bg-tertiary-100 dark:bg-tertiary-800": colorFamily === 'tertiary',
                        "bg-warning-100 dark:bg-warning-800": colorFamily === 'warning',
                    }
                )}>
                    <EmptyStateIcon
                        size={32}
                        className={cn(
                            colorClasses.icon,
                            "animate-pulse-subtle"
                        )}
                    />
                </div>

                {/* Title with product-specific message */}
                <h3 className={cn(
                    "text-base font-semibold mb-1",
                    colorClasses.text
                )}>
                    {message ?? defaultMessage}
                </h3>

                {/* Optional subtitle */}
                {subtitle && (
                    <p className="text-sm text-blackish-600 dark:text-blackish-300 opacity-75 mb-3">
                        {subtitle}
                    </p>
                )}

                {/* Conditional clear filters button */}
                {showFiltersButton && onClearFilters && (
                    <Button
                        onClick={onClearFilters}
                        size="sm"
                        variant="outline"
                        className={cn(
                            "flex items-center gap-2 mt-2",
                            colorClasses.button
                        )}
                    >
                        <Tag size={14} />
                        Clear Filters
                    </Button>
                )}
            </div>
        </div>
    );
};
