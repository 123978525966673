/**
 * Tailwind Colors Library
 * Provides properly typed color values from the tailwind theme
 * for use in SVG contexts where CSS variables don't work properly
 */

// Define the color palette type structure
export type ColorPalette = {
  DEFAULT: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  950: string;
}

export type TailwindColors = {
  primary: ColorPalette;
  secondary: ColorPalette;
  accent: ColorPalette;
  warning: ColorPalette;
  blackish: ColorPalette;
  [key: string]: ColorPalette; // Allow accessing other color palettes dynamically
}

// Import the values from the JS file 
// This allows us to maintain a single source of truth for colors
// @ts-expect-error Missing declaration file for tailwindColors.js
import tailwindColorsJs from '../tailwindColors.js';

// Export the typed version
export const tailwindColors: TailwindColors = tailwindColorsJs;

// Generate color shades for a specific color type (for pie charts)
export const generateColorShades = (
  colorType: keyof TailwindColors = 'primary',
  isDarkMode = false
): string[] => {
  // Use different shade distribution based on dark/light mode
  if (isDarkMode) {
    return [
      tailwindColors[colorType][300], // Base
      tailwindColors[colorType][200], // Lighter
      tailwindColors[colorType][400], // Darker
      tailwindColors[colorType][100], // Even lighter
      tailwindColors[colorType][500], // Even darker
    ];
  }
  
  return [
    tailwindColors[colorType][500], // Base
    tailwindColors[colorType][400], // Lighter
    tailwindColors[colorType][600], // Darker
    tailwindColors[colorType][300], // Even lighter
    tailwindColors[colorType][700], // Even darker
  ];
};

export default tailwindColors; 