/**
 * Unified Product Types
 *
 * Single source of truth for all product-related types and definitions
 * in the application. This file centralizes product information to ensure
 * consistent typing throughout the application.
 */
import type { StatusRegistry, IconComponent } from './status.types';

/**
 * Enum of all available product types in the system
 */
export enum ProductType {
  ALL = 'ALL', // Special type to represent all products
  RESPIRATOR_MEDICAL_EVALUATION = 'RESPIRATOR_MEDICAL_EVALUATION',
  GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE = 'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE',
  ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE = 'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE',
  RESPIRATOR_FIT_TEST = 'RESPIRATOR_FIT_TEST',
}

/**
 * Server key to product type mapping
 * This handles variant names that might come from the server
 */
export const SERVER_KEY_TO_PRODUCT_TYPE: Record<string, ProductType> = {
  'RESPIRATOR_MEDICAL_EVALUATION': ProductType.RESPIRATOR_MEDICAL_EVALUATION,
  'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE': ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE,
  'GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE_EVALUATION': ProductType.GROUND_CANNABIS_DUST_MEDICAL_SURVEILLANCE,
  'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE': ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE,
  'ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE_EVALUATION': ProductType.ANIMAL_ALLERGY_MEDICAL_SURVEILLANCE,
  'RESPIRATOR_FIT_TEST': ProductType.RESPIRATOR_FIT_TEST,
};

/**
 * Helper function to get ProductType from server key string
 * Returns undefined if the key doesn't map to a known product type
 */
export function getProductTypeFromServerKey(serverKey: string): ProductType | undefined {
  return SERVER_KEY_TO_PRODUCT_TYPE[serverKey];
}

/**
 * Type guard to check if a string is a valid ProductType
 */
export function isValidProductType(value: string): value is ProductType {
  return Object.values(ProductType).includes(value as ProductType);
}

/**
 * Badge styling configuration for products
 */
export type ProductBadgeStyling = {
  variant: 'default' | 'secondary' | 'destructive' | 'success' | 'warning' | 'outline';
  icon: IconComponent;
  colorClasses?: string;
}

/**
 * Product definition that includes all necessary information
 */
export type ProductDefinition = {
  id: ProductType;
  key: string;
  name: string;
  shortName: string;
  description?: string;
  badge: ProductBadgeStyling;
  order: number; // Display order in lists and tabs
  statusRegistry?: StatusRegistry; // Optional reference to status registry for this product
}

/**
 * Type for the product registry
 */
export type ProductRegistry = Record<ProductType, ProductDefinition>;

/**
 * Product badge props with strong typing
 */
export type ProductBadgeProps = {
  productType: ProductType | string;
  className?: string;
  id?: string | number;
  showName?: boolean;
}

/**
 * Get products in defined display order
 */
export function getOrderedProductTypes(products: ProductRegistry): ProductType[] {
  return Object.values(products)
    .sort((a, b) => a.order - b.order)
    .map(product => product.id);
}

/**
 * Get product definition by key or type
 */
export function getProductDefinition(
  productIdentifier: string, 
  registry: ProductRegistry
): ProductDefinition | undefined {
  // First try direct lookup by enum
  if (isValidProductType(productIdentifier)) {
    return registry[productIdentifier as ProductType];
  }
  
  // Then search by key
  return Object.values(registry).find(product => product.key === productIdentifier);
}
