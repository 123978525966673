import {
  useRecordsQuery
} from '@/hooks/useRecordsQuery';
import type { HealthSubmission } from '@/types';
import { ProductType } from '@/types/product.types';
import type {
  FrontendQueryParams
} from '@/types/records-query-parameters';
import type * as React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

type SubmissionsContextValue = {
  // Product type filtering
  activeProductType: ProductType;
  setActiveProductType: (productType: ProductType) => void;

  // Submissions data
  submissionsData: HealthSubmission[] | undefined;
  isSubmissionsLoading: boolean;
  submissionsError: Error | null;
  refetchSubmissions: () => void;

  // Additional query params
  queryParams: FrontendQueryParams;
  setQueryParams: (params: FrontendQueryParams) => void;

  // Pagination metadata
  paginationMetadata: {
    itemsPerPage: number;
    page: number;
    totalItems: number;
    totalPages: number;
  };

  // Page navigation
  nextPage: () => void;
  previousPage: () => void;
  goToPage: (page: number) => void;
  setPageSize: (size: number) => void;
}

const SubmissionsContext = createContext<SubmissionsContextValue | undefined>(undefined);

type SubmissionsProviderProps = {
  children: React.ReactNode;
  initialQueryParams?: FrontendQueryParams;
}

export const SubmissionsProvider: React.FC<SubmissionsProviderProps> = ({
  children,
  initialQueryParams = { itemsPerPage: 10 } // Set default itemsPerPage to 10
}) => {
  // Product type state with ALL as default
  const [activeProductType, setActiveProductType] = useState<ProductType>(
    ProductType.ALL
  );

  // Query params state
  const [queryParams, setQueryParams] = useState<FrontendQueryParams>(initialQueryParams);

  // Combine params with product type filter
  const combinedParams = useMemo(() => {
    return {
      ...queryParams,
      // Use productType parameter with properly typed value
      productType: activeProductType
    };
  }, [queryParams, activeProductType]);

  // Fetch submissions using the standardized records query hook
  const {
    data: submissionsResponse,
    isLoading: isSubmissionsLoading,
    error: submissionsErrorRaw,
    refetch: refetchSubmissions,
  } = useRecordsQuery(combinedParams);

  // Convert submissions error to standard format
  const submissionsError = submissionsErrorRaw
    ? new Error(submissionsErrorRaw.message)
    : null;

  // Extract submissions data and metadata from the response
  const submissionsData = submissionsResponse?.items;

  // Setup pagination metadata with defaults
  const paginationMetadata = useMemo(() => ({
    itemsPerPage: submissionsResponse?.metadata.itemsPerPage ?? 25, // Default to 25 items per page
    page: submissionsResponse?.metadata.page ?? 1,
    totalItems: submissionsResponse?.metadata.totalItems ?? 0,
    totalPages: submissionsResponse?.metadata.totalPages ?? 1
  }), [submissionsResponse?.metadata]);

  // Page navigation handlers
  const goToPage = (page: number): void => {
    const validPage = Math.max(1, Math.min(page, paginationMetadata.totalPages));
    setQueryParams({
      ...queryParams,
      page: validPage
    });
  };

  const nextPage = (): void => {
    if (paginationMetadata.page < paginationMetadata.totalPages) {
      goToPage(paginationMetadata.page + 1);
    }
  };

  const previousPage = (): void => {
    if (paginationMetadata.page > 1) {
      goToPage(paginationMetadata.page - 1);
    }
  };

  // Page size handler
  const setPageSize = (size: number): void => {
    setQueryParams({
      ...queryParams,
      itemsPerPage: size,
      page: 1 // Reset to first page when changing page size
    });
  };

  // Memoize context value to prevent unnecessary renders
  const contextValue = useMemo(() => ({
    // Product type
    activeProductType,
    setActiveProductType,

    // Submissions data
    submissionsData,
    isSubmissionsLoading,
    submissionsError,
    refetchSubmissions,

    // Query params
    queryParams,
    setQueryParams,

    // Pagination
    paginationMetadata,
    nextPage,
    previousPage,
    goToPage,
    setPageSize
  }), [
    activeProductType,
    submissionsData,
    isSubmissionsLoading,
    submissionsError,
    queryParams,
    paginationMetadata
  ]);

  return (
    <SubmissionsContext.Provider value={contextValue}>
      {children}
    </SubmissionsContext.Provider>
  );
};

// Custom hook for consuming the submissions context
export const useSubmissions = (): SubmissionsContextValue => {
  const context = useContext(SubmissionsContext);

  if (context === undefined) {
    throw new Error('useSubmissions must be used within a SubmissionsProvider');
  }

  return context;
};
