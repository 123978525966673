/**
 * Dashboard Style Utilities
 * 
 * DEPRECATED: This file is maintained for backward compatibility.
 * Please import from '@/styles' or '@/styles/dashboard' instead.
 */

import { cardStyles, dashboardStyles } from '@/styles';
import type { ColorFamily } from '@/styles/style-types';
import type { CardStyleConfig } from '@/types/dashboard-style-types';

/**
 * Standard gradient styles for dashboard cards
 * @deprecated Use cardStyles from '@/styles' instead
 */
export const dashboardCardStyles: CardStyleConfig = cardStyles;

/**
 * Generate a consistent gradient class based on color family
 * @deprecated Use dashboardStyles.getCardGradientClass from '@/styles' instead
 */
export function getCardGradientClass(
  colorFamily: keyof CardStyleConfig,
  isDarkMode = false
): string {
  return dashboardStyles.getCardGradientClass(
    colorFamily as ColorFamily,
    isDarkMode
  );
}

/**
 * Get the full dashboard card class including gradient, border, and transition effects
 * @deprecated Use dashboardStyles.getDashboardCardClass from '@/styles' instead
 */
export function getDashboardCardClass(
  colorFamily: keyof CardStyleConfig,
  isDarkMode = false
): string {
  return dashboardStyles.getDashboardCardClass(
    colorFamily as ColorFamily,
    isDarkMode
  );
}

/**
 * Get the animation class for a card based on color family
 * @deprecated Use dashboardStyles.getCardAnimationClass from '@/styles' instead
 */
export function getCardAnimationClass(
  colorFamily: keyof CardStyleConfig,
): string {
  return dashboardStyles.getCardAnimationClass(
    colorFamily as ColorFamily
  );
}
