// src/components/layout/AppLayout.tsx
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import MobileSidebar from './MobileSidebar';
import { ThemeProvider, useTheme } from '@/components/layout/ThemeProvider';
import { AlertCircle, RefreshCw, Menu } from '@/components/ui/icon';
import { Button } from '@/components/ui/button';
import CustomLoader from './CustomLoader';
import { RouteFallback } from './RouteFallback';
import { useScrollReset } from '@/hooks/useScrollReset';
import { version } from '../../../package.json';

// Error boundary component to catch and handle errors gracefully
interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }
  
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }
  
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log the error to an error reporting service
    console.error('Error caught by boundary:', error);
    console.error('Component stack:', errorInfo.componentStack);
  }
  
  render(): React.ReactNode {
    if (this.state.hasError) {
      // Custom fallback UI when an error occurs
      if (this.props.fallback) {
        return this.props.fallback;
      }
      
      return (
        <div className="flex flex-col items-center justify-center h-screen bg-white dark:bg-blackish-900 p-6">
          <div className="w-full max-w-md p-6 bg-white dark:bg-blackish-800 rounded-lg shadow-md border border-red-100 dark:border-red-900">
            <div className="flex items-center text-red-600 dark:text-red-400 mb-4">
              <AlertCircle className="w-6 h-6 mr-2" />
              <h2 className="text-lg font-semibold">Something went wrong</h2>
            </div>
            
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              An error occurred while loading this page. Please try refreshing or return to the dashboard.
            </p>
            
            {this.state.error && (
              <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 rounded-md text-sm text-red-800 dark:text-red-300 overflow-auto max-h-24">
                {this.state.error.toString()}
              </div>
            )}
            
            <div className="flex space-x-4">
              <Button 
                onClick={() => window.location.reload()}
                className="flex items-center"
              >
                <RefreshCw className="w-4 h-4 mr-2" />
                Refresh Page
              </Button>
              
              <Button 
                variant="outline"
                onClick={() => window.location.href = '/'}
              >
                Go to Dashboard
              </Button>
            </div>
          </div>
        </div>
      );
    }
    
    return this.props.children;
  }
}

// New scroll reset hook
// Create this hook in a separate file: src/hooks/useScrollReset.ts
// export function useScrollReset(elementId = 'main-content-area'): void {
//   const { pathname } = useLocation();
//   
//   useEffect((): void => {
//     const element = document.getElementById(elementId);
//     if (element) {
//       element.scrollTop = 0;
//     }
//     window.scrollTo(0, 0);
//   }, [pathname, elementId]);
// }

const AppContent = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageChanging, setPageChanging] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const { } = useTheme(); // Keep the hook for context subscription
  
  // Use our scroll reset hook
  useScrollReset('main-content-area');

  // Simulate initial loading for smooth transition
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800);
    
    return () => clearTimeout(timer);
  }, []);

  // Listen for route changes to trigger page transition animations
  useEffect(() => {
    let isMounted = true;
    
    // Very brief fade out (70ms)
    setPageChanging(true);
    
    // Close mobile menu on navigation
    setMobileMenuOpen(false);
    
    // Reset scroll position on the main content area
    const contentArea = document.querySelector('.flex-1.p-4.md\\:p-6.overflow-auto');
    if (contentArea) {
      contentArea.scrollTop = 0;
    }
    
    // Fade in after content has likely updated
    const timer = setTimeout(() => {
      if (isMounted) {
        setPageChanging(false);
      }
    }, 70);
    
    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [location.pathname]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <ErrorBoundary>
      <div 
        className="h-screen w-screen overflow-hidden bg-gradient-to-br from-gray-50 to-white dark:from-blackish-900 dark:to-blackish-800 text-blackish-800 dark:text-gray-100 transition-colors duration-500"
      >
        {/* Custom loader that respects theme */}
        <CustomLoader isLoading={isLoading} />
        
        {/* Mobile menu overlay */}
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-50 md:hidden">
            {/* Semi-transparent backdrop */}
            <div 
              className="absolute inset-0 bg-black/30 dark:bg-black/50 backdrop-blur-sm" 
              onClick={toggleMobileMenu}
              aria-hidden="true"
            />
            
            {/* Mobile sidebar */}
            <div className="absolute inset-y-0 left-0 w-64 max-w-[75vw] h-full shadow-lg">
              <MobileSidebar 
                onClose={toggleMobileMenu} 
                onNavItemClick={toggleMobileMenu} 
              />
            </div>
          </div>
        )}
        
        <div className="flex h-full">
          {/* Desktop Sidebar - hidden on mobile */}
          <div className="hidden md:block">
            <Sidebar />
          </div>

          {/* Main content area with page transition effects */}
          <main className={`flex-1 overflow-auto transition-all duration-500 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
            <div className="h-full flex flex-col">
              {/* Mobile header - shown only on mobile */}
              <div className="md:hidden h-16 flex items-center justify-between px-4 border-b border-gray-200 dark:border-blackish-600 bg-white dark:bg-blackish-700">
                <button 
                  onClick={toggleMobileMenu}
                  className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-blackish-600"
                  aria-label="Open navigation menu"
                >
                  <Menu className="h-6 w-6 text-blackish-600 dark:text-gray-300" />
                </button>
                
                <div className="flex items-center">
                  <span className="text-lg font-bold bg-gradient-to-r from-primary-500 to-accent-500 bg-clip-text text-transparent font-rbno3">
                    PROXIMA INTERNAL
                  </span>
                </div>
                
                <div className="w-10">
                  {/* Placeholder for symmetry */}
                </div>
              </div>
              
              {/* Content area with extremely subtle page transition */}
              <div id="main-content-area" className="flex-1 p-0 md:p-6 overflow-auto min-h-0">
                <div className={`transition-opacity duration-50 ${pageChanging ? 'opacity-95' : 'opacity-100'}`}>
                  {/* Add ErrorBoundary around the Outlet to catch route-level errors */}
                  <ErrorBoundary fallback={<RouteFallback />}>
                    <Outlet />
                  </ErrorBoundary>
                </div>
              </div>
              
              {/* Footer area with gradient accent */}
              <footer className="h-8 border-t border-gray-200 dark:border-blackish-600 px-6 flex items-center justify-between text-xs text-gray-500 dark:text-gray-400 relative overflow-hidden">
                <div className="absolute inset-0 opacity-5 dark:opacity-10 bg-gradient-to-r from-primary-300 to-secondary-300 dark:from-primary-900 dark:to-secondary-900"></div>
                <span className="relative z-10">© 2025 Proxima Worktech</span>
                <span className="relative z-10 bg-gradient-to-r from-accent-500 to-primary-500 bg-clip-text text-transparent font-medium">v{version}</span>
              </footer>
            </div>
          </main>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const AppLayout = (): JSX.Element => {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default AppLayout;
